"use strict";

module.exports = {
  dialogContext: {
    changePassword: require.context('./components/dialog/changePassword', false, /\.js$/),
    confirmNavigate: require.context('./components/dialog/confirmNavigate', false, /\.js$/),
    logoutUser: require.context('./components/dialog/logoutUser', false, /\.js$/),
    artistInformation: require.context('./components/dialog/artistInformation', false, /\.js$/),
    firstTimVisitNotification: require.context('./components/dialog/firstTimVisitNotification', false, /\.js$/),
    postAuthNotification: require.context('./components/dialog/postAuthNotification', false, /\.js$/)
  }
};
"use strict";

exports.__esModule = true;
exports.GetDropdownOptions = exports.GetDropdownValue = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const GetDropdownValue = params => {
  const entity = params.entity,
        searchParams = params.searchParams,
        sortValue = params.sortValue;
  const sortVariations = {
    latest: {
      product: {
        sort_column: 'created_date',
        sort_direction: 'desc'
      }
    },
    latest_published: {
      product: {
        sort_column: 'published_date',
        sort_direction: 'desc'
      }
    },
    // oldest: {
    //   entities: [entity],
    //   sort_column: 'created_date',
    //   sort_direction: 'asc',
    //   filter: []
    // },
    trending: {
      product: {
        sort_column: 'likes_count',
        sort_direction: 'desc'
      },
      artist: {
        sort_column: 'followers_count',
        sort_direction: 'desc'
      },
      collection: {
        sort_column: 'total_public_design_likes',
        sort_direction: 'desc'
      }
    },
    top: {
      product: {
        sort_column: 'sales',
        sort_direction: 'desc'
      },
      artist: {
        sort_column: 'total_public_designs',
        sort_direction: 'desc'
      },
      collection: {
        sort_column: 'total_public_design_likes',
        sort_direction: 'desc'
      }
    },
    'all time': {
      product: {
        sort_column: 'sales',
        sort_direction: 'desc'
      }
    },
    'low to high': {
      product: {
        sort_column: 'pricing.retail',
        sort_direction: 'asc'
      }
    },
    'high to low': {
      product: {
        sort_column: 'pricing.retail',
        sort_direction: 'desc'
      }
    }
  }[sortValue] || {};
  const sort = sortVariations[entity] ? _objectSpread(_objectSpread(_objectSpread({}, searchParams), sortVariations[entity]), {}, {
    sortValue
  }) : _objectSpread(_objectSpread({}, searchParams), {}, {
    sort_column: 'created_date',
    sort_direction: 'desc',
    filter: []
  });
  return sort;
};

exports.GetDropdownValue = GetDropdownValue;

const GetDropdownOptions = entity => {
  if (!entity) return [];
  return {
    collection: [// {
    //   label: 'All',
    //   value: 'all'
    // },
    {
      label: 'Trending',
      value: 'trending'
    }, {
      label: 'Top',
      value: 'top'
    }],
    product: [{
      label: 'Latest',
      value: 'latest'
    }, // {
    //   label: 'Oldest',
    //   value: 'oldest'
    // },
    {
      label: 'Trending',
      value: 'trending'
    }, // {
    //   label: 'Top',
    //   value: 'top'
    // },
    // {
    //   label: 'All Time Top-Selling',
    //   value: 'all time'
    // },
    {
      label: 'Price: Low to High',
      value: 'low to high'
    }, {
      label: 'Price: High to Low',
      value: 'high to low'
    }],
    profile_products: [{
      label: 'Recent Creations',
      value: 'latest'
    }, {
      label: 'Top Creations',
      value: 'top'
    }, {
      label: 'Popularity',
      value: 'trending'
    }, {
      label: 'Best Selling',
      value: 'all time'
    }]
  }[entity] || [];
};

exports.GetDropdownOptions = GetDropdownOptions;
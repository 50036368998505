"use strict";

var _types = require("@App/types");

// eslint-disable-line
module.exports = {
  ResetCreatorGridData() {
    return {
      type: `RESET_CREATOR_DETAILS_GRID_DATA`
    };
  },

  UpdateArtistDetails(data) {
    return {
      type: `UPDATE_ARTIST_DETAILS${_types.REQUESTED}`,
      payload: data
    };
  },

  UpdateSocialMediaLinks(data) {
    return {
      type: `UPDATE_SOCIAL_MEDIA_LINKS${_types.REQUESTED}`,
      payload: data
    };
  },

  UpdateCreatorBiography(data) {
    return {
      type: `UPDATE_ARTIST_BIOGRAPHY${_types.REQUESTED}`,
      payload: data
    };
  },

  UpdateCoverPhotos(data) {
    return {
      type: `UPDATE_COVER_PHOTOS${_types.REQUESTED}`,
      payload: data
    };
  },

  UpdateProfilePhoto(data) {
    return {
      type: `UPDATE_PROFILE_PHOTO${_types.REQUESTED}`,
      payload: data
    };
  },

  GetCreatorProfileCollections(data) {
    return {
      type: `GET_CREATOR_PROFILE_COLLECTIONS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetArtistProducts(data) {
    return {
      type: `GET_ARTIST_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetProfileAllArtistProducts(data) {
    return {
      type: `GET_PROFILE_ALL_ARTIST_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  FilterProductsByCollection(data) {
    return {
      type: `FILTER_PRODUCTS_BY_COLLECTION${_types.REQUESTED}`,
      payload: data
    };
  },

  GetAssignProductCollectionGrid(data) {
    return {
      type: `GET_ASSIGN_PRODUCT_COLLECTION_GRID${_types.REQUESTED}`,
      payload: data
    };
  },

  GetArtistProductCategories(data) {
    return {
      type: `GET_ARTIST_PRODUCT_CATEGORIES${_types.REQUESTED}`,
      payload: data
    };
  },

  LikeArtist(data) {
    return {
      type: `LIKE_ARTIST${_types.REQUESTED}`,
      payload: data
    };
  },

  UnlikeArtist(data) {
    return {
      type: `UNLIKE_ARTIST${_types.REQUESTED}`,
      payload: data
    };
  },

  AddProductsToArtistPicks(data) {
    return {
      type: `ADD_PRODUCTS_TO_ARTIST_PICKS${_types.REQUESTED}`,
      payload: data
    };
  },

  RemoveProductsFromArtistPicKs(data) {
    return {
      type: `REMOVE_PRODUCTS_TO_ARTIST_PICKS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetProfileArtistPicksProducts(data) {
    return {
      type: `GET_PROFILE_ARTIST_PICKS_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetProfileCollectionProducts(data) {
    return {
      type: `GET_PROFILE_COLLECTION_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  ClearProfileCollectioProducts() {
    return {
      type: 'CLEAR_PROFILE_COLLECTION_PRODUCTS'
    };
  },

  GetStyleTypes(data) {
    return {
      type: `GET_STYLE_TYPES${_types.REQUESTED}`,
      payload: data
    };
  },

  GetArtistProfileStyles() {
    return {
      type: `GET_ARTIST_PROFILE_STYLES${_types.REQUESTED}`
    };
  },

  ResendEmailVerificationProfile(data) {
    return {
      type: `RESEND_EMAIL_VERIFICATION_PROFILE${_types.REQUESTED}`,
      payload: data
    };
  },

  GetCreatorDetailsApprovedProducts(data) {
    return {
      type: `GET_CREATOR_DETAILS_APPROVED_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  UpdateCreatorFeaturedProducts(data) {
    return {
      type: `UPDATE_CREATOR_FEATURED_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetCreatorPublicFeaturedProducts(data) {
    return {
      type: `GET_CREATOR_FEATURED_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetCreatorShopifyStores(data) {
    return {
      type: `GET_CREATOR_SHOPIFY_STORES${_types.REQUESTED}`,
      payload: data
    };
  }

};
"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  const isProceed = this.state.isProceed;
  return {
    dialogClassName: 'dnaDialog__container designDialog-closeButton',
    className: 'dnaDialog size-lg designDialog-closeButton designDialog__elementSelection dialog-active',
    fullPage: true,
    modal: true,
    focusOnMount: false,
    renderNode: document.body,
    closeOnEsc: true
  };
}
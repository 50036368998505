"use strict";

exports.__esModule = true;
exports.default = uploadFile;

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _set = _interopRequireDefault(require("lodash/set"));

var _get = _interopRequireDefault(require("lodash/get"));

var _useUpload = require("@common/components/FineUploaderHooks/useUpload.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function uploadFile(payload) {
  const id = payload.id,
        _payload$files = payload.files,
        files = _payload$files === void 0 ? [] : _payload$files,
        uniqueEntity = payload.uniqueEntity,
        _payload$endpoint = payload.endpoint,
        endpoint = _payload$endpoint === void 0 ? '/file/upload/entity' : _payload$endpoint,
        chunking = payload.chunking,
        _payload$responseVali = payload.responseValidator,
        responseValidator = _payload$responseVali === void 0 ? (res = {}) => res.success && Object.keys(res).length > 1 : _payload$responseVali,
        restParams = _objectWithoutPropertiesLoose(payload, ["id", "files", "uniqueEntity", "endpoint", "chunking", "responseValidator"]);

  return new Promise((resolve, reject) => {
    const uploader = (0, _useUpload.createUploader)({
      allowedExtensions: [],
      customHeaders: {},
      customParams: _objectSpread({
        entity: uniqueEntity,
        entity_id: id
      }, restParams),
      uploadEndpoint: `${window.config.upload}${endpoint}`,
      uploaderParams: coreParams => {
        if (!chunking) return coreParams;
        const updateParams = (0, _cloneDeep.default)(coreParams);
        Object.entries(chunking).forEach(([paramKey, paramVal]) => {
          (0, _set.default)(updateParams, `options.chunking.${paramKey}`, paramVal);
        });
        return updateParams;
      }
    });
    const isConcurrent = (0, _get.default)(uploader, 'options.chunking.concurrent') || {};
    const pendingResponses = {};
    uploader.on('uploadChunkSuccess', (fileId, _, response = {}) => {
      if (isConcurrent.enabled && responseValidator(response)) {
        pendingResponses[fileId] = response;
      }
    });
    uploader.on('complete', (fileId, name, response) => {
      resolve(isConcurrent.enabled && !responseValidator(response) ? pendingResponses[fileId] : response);
    });
    uploader.on('error', reject);
    uploader.methods.addFiles(files);
  });
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _default = () => ({
  errors: {},
  isValid: true
});

exports.default = _default;
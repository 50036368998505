"use strict";

var _actions = require("@App/actions");

var _base = require("@App/actions/base");

var _types = require("@App/types");

var _tools = require("@common/utils/tools");

var _tools2 = require("@23point5/core/controls/utils/tools");

var _axios = _interopRequireDefault(require("@utils/axios"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _effects = require("redux-saga/effects");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const uniqueEntity = 'editor_tutotial_videos';

function* GetEditorTutorialVideos({
  payload
}) {
  yield (0, _effects.put)({
    type: 'GET_EDITOR_TUTORIAL_VIDEOS_LOADING'
  }); // const config = {
  //   method: 'post',
  //   url: `/tutorial-video/grid`,
  //   data: {
  //     sort_column: 'created_date',
  //     sort_direction: 'desc',
  //     filter: [
  //       {
  //         key: 'status',
  //         value: ['Active'],
  //       },
  //     ],
  //     grid: true,
  //   },
  // }

  const config = {
    method: 'GET',
    url: `/tutorial-video?sort_column=created_date&sort_direction=desc&status=Active`
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'GET_EDITOR_TUTORIAL_VIDEOS');
  yield (0, _effects.put)(result);
  yield (0, _effects.put)({
    type: 'GET_EDITOR_TUTORIAL_VIDEOS_LOADING_DONE'
  });
}

module.exports = function* editorTutorialVideos() {
  yield (0, _effects.takeLatest)(`GET_EDITOR_TUTORIAL_VIDEOS${_types.REQUESTED}`, GetEditorTutorialVideos);
};
"use strict";

exports.__esModule = true;
exports.default = multipleUpload;

var _useUpload = require("@23point5/core/controls/FileUpload/hooks/useUpload");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function multipleUpload(_x) {
  return _multipleUpload.apply(this, arguments);
}

function _multipleUpload() {
  _multipleUpload = _asyncToGenerator(function* (payload) {
    const id = payload.id,
          _payload$files = payload.files,
          files = _payload$files === void 0 ? [] : _payload$files,
          uniqueEntity = payload.uniqueEntity,
          _payload$endpoint = payload.endpoint,
          endpoint = _payload$endpoint === void 0 ? '/file/upload/entity' : _payload$endpoint,
          currentUploader = payload.uploader,
          restParams = _objectWithoutPropertiesLoose(payload, ["id", "files", "uniqueEntity", "endpoint", "uploader"]);

    const result = yield new Promise((resolve, reject) => {
      const results = [];
      const uploader = (0, _useUpload.createUploader)({
        uploader: currentUploader,
        allowedExtensions: [],
        customHeaders: {},
        customParams: _objectSpread({
          entity: uniqueEntity,
          entity_id: id
        }, restParams),
        uploadEndpoint: `${window.config.upload}${endpoint}`
      });
      uploader.on('complete', (order, name, response) => {
        results.push(_objectSpread(_objectSpread({}, response), {}, {
          file_name: name
        }));
      });
      uploader.on('allComplete', () => {
        resolve(results);
      });
      uploader.on('error', reject);
      uploader.methods.addFiles(files);
    });
    return {
      success: true,
      files: result
    };
  });
  return _multipleUpload.apply(this, arguments);
}
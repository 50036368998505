"use strict";

exports.__esModule = true;
exports.default = configureStore;

var _redux = require("redux");

var _connectedReactRouter = require("connected-react-router");

var _history = _interopRequireDefault(require("@23point5/core/controls/history"));

var _mergeReducers = _interopRequireDefault(require("@utils/mergeReducers"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-underscore-dangle */
function configureStore(initialState, middlewares = [], url = '') {
  const history = (0, _history.default)(url);
  const composedEnhancers = (0, _redux.compose)((0, _redux.applyMiddleware)(...middlewares, (0, _connectedReactRouter.routerMiddleware)(history)), typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);
  const store = (0, _redux.createStore)((0, _mergeReducers.default)({}, history), initialState, composedEnhancers); // Reducer registry

  store.injectedReducers = {}; // Saga registry

  store.injectedSagas = {};

  if (module.hot) {
    module.hot.accept('./mergeReducers', () => {
      store.replaceReducer((0, _mergeReducers.default)(store.injectedReducers, (0, _history.default)()));
    });
  }

  return store;
}
/* eslint-enable */
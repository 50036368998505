"use strict";

exports.__esModule = true;
exports.default = void 0;

var _Loadable = _interopRequireDefault(require("@pages/DesignEditor/Loadable"));

var _Loadable2 = _interopRequireDefault(require("@pages/CollectionProduct/Loadable"));

var _Loadable3 = _interopRequireDefault(require("@pages/ProductPreview/Loadable"));

var _ProtectedRoutes = _interopRequireDefault(require("@base/ProtectedRoutes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const protectedRoutes = [{
  path: '/create/:id?',
  component: _Loadable.default,
  exact: true
}, {
  path: '/edit/:product_name?',
  component: _Loadable.default,
  exact: true
}, {
  path: '/customize/:style_type/:user_handle/:product_name',
  component: _Loadable.default,
  exact: true
}, {
  path: '/product-preview/:id?',
  component: _Loadable3.default,
  exact: true
}, {
  path: '/collection-product-preview/:id?',
  component: _Loadable2.default,
  exact: true
}];
const routes = [{
  component: _ProtectedRoutes.default,
  routes: protectedRoutes,
  exact: true
}];
var _default = routes;
exports.default = _default;
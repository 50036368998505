"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = require("react");

var _store = _interopRequireDefault(require("store2"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _actions = require("@App/actions");

var _tools = require("@23point5/core/controls/utils/tools");

var _useWindowSize = _interopRequireDefault(require("./useWindowSize"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = props => {
  const _props$session = props.session,
        session = _props$session === void 0 ? {} : _props$session,
        _props$sessionCart = props.sessionCart,
        sessionCart = _props$sessionCart === void 0 ? {} : _props$sessionCart,
        dispatch = props.dispatch,
        history = props.history,
        shopifyClient = props.shopifyClient,
        location = props.location;

  const _ref = session || {},
        session_id = _ref.id;

  const isCartSidePanelVisible = sessionCart.isCartSidePanelVisible,
        _sessionCart$artist_c = sessionCart.artist_cart,
        artist_cart = _sessionCart$artist_c === void 0 ? {} : _sessionCart$artist_c;
  const cart_items = sessionCart.cart_items,
        unavailable_items = sessionCart.unavailable_items;

  const _useState = (0, _react.useState)([]),
        list = _useState[0],
        setList = _useState[1];

  const _useState2 = (0, _react.useState)('30%'),
        cartContainerWidth = _useState2[0],
        setCartContainerWidth = _useState2[1];

  const _useState3 = (0, _react.useState)([]),
        processingItems = _useState3[0],
        setProcessingItems = _useState3[1];

  const _useState4 = (0, _react.useState)(false),
        isSubTotalLoading = _useState4[0],
        setIsSubTotalLoading = _useState4[1];

  const windowSize = (0, _useWindowSize.default)();
  const hasPendingProducts = !!(list || []).filter(e => e.is_pending).length;
  (0, _react.useEffect)(() => {
    if (windowSize.width >= 1751) {
      setCartContainerWidth('30%');
    }

    if (windowSize.width >= 1651) {
      setCartContainerWidth('30%');
    }

    if (windowSize.width <= 1650) {
      setCartContainerWidth('30%');
    }

    if (windowSize.width <= 1315) {
      setCartContainerWidth('30%');
    }

    if (windowSize.width <= 1000) {
      setCartContainerWidth('40%');
    }

    if (windowSize.width <= 981) {
      setCartContainerWidth('100%');
    }

    if (windowSize.width <= 669) {
      setCartContainerWidth('100%');
    }
  }, [windowSize]);
  (0, _react.useEffect)(() => {
    if (shopifyClient) {
      dispatch((0, _actions.GetArtistCart)({
        shopifyClient
      }));
    }
  }, [session_id, shopifyClient]);
  (0, _react.useEffect)(() => {
    setList([...(cart_items || []), ...(unavailable_items || [])]);
    setProcessingItems([]);
  }, [cart_items]);
  (0, _react.useEffect)(() => {
    const cartItemsCount = (list || []).reduce((acc, cur) => !cur.is_unavailable ? Number(Number(acc) + Number(cur.quantity)) : acc, 0);
    dispatch((0, _actions.SetCartItemsCount)(cartItemsCount));
  }, [list]);
  (0, _react.useEffect)(() => {
    (0, _tools.debouncer)(() => {
      // check if list has to updated items
      const to_be_updated_items = (list || []).filter(e => e.to_be_updated);
      const hasUpdatedItems = !!to_be_updated_items.length;
      const updated_shopify_items = to_be_updated_items.filter(e => !e.is_pending);
      const updated_pending_items = to_be_updated_items.filter(e => e.is_pending);

      if (hasUpdatedItems && updated_shopify_items.length) {
        setIsSubTotalLoading(true);
        dispatch((0, _actions.UpdateSessionCart)({
          shopifyClient,
          line_items_to_update: to_be_updated_items,
          isTriggerCartLoading: false,
          callback: () => {
            setIsSubTotalLoading(false);
          }
        }));
        const cartItemsCount = (list || []).reduce((acc, cur) => !cur.is_unavailable ? Number(Number(acc) + Number(cur.quantity)) : acc, 0);
        dispatch((0, _actions.SetCartItemsCount)(cartItemsCount));
      }

      if (hasUpdatedItems && updated_pending_items.length) {
        dispatch((0, _actions.UpdatePendingCartItems)({
          updated_pending_items
        }));
      }
    }, 300);
  }, [list]);

  const handleCloseSidePane = () => {
    dispatch((0, _actions.HideSideCartPanel)());
  };

  const handleUpdateCartItems = newList => {
    const localStorageCarts = JSON.parse(_store.default.get('car23point5-cartsts'));
    const cart_key = session.id ? session.id : 'guest';
    const session_cart = localStorageCarts && localStorageCarts[cart_key];

    const new_carts = _objectSpread(_objectSpread({}, localStorageCarts), {}, {
      [cart_key]: _objectSpread(_objectSpread({}, session_cart), {}, {
        products: newList
      })
    });

    const cart_items_count = newList.reduce((prev, cur) => Number(Number(prev) + Number(cur.quantity)), 0);
    dispatch((0, _actions.SetCartItemsCount)(cart_items_count));

    _store.default.set('23point5-carts', JSON.stringify(new_carts));

    setList(newList);
    dispatch({
      type: 'SET_CART_UPDATED',
      payload: false
    });
  };

  const handleSizeChange = (cartItem = {}, selected_size) => {
    const _cartItem$variants = cartItem.variants,
          variants = _cartItem$variants === void 0 ? [] : _cartItem$variants;
    const selected_size_product_variant = variants.find(e => (e.title || '').trim().toLowerCase() === (selected_size || '').trim().toLowerCase()) || {};
    const existingItem = list.find(item => item.shopify_product_variant_id === `gid://shopify/ProductVariant/${selected_size_product_variant.id}`);

    const new_item = _objectSpread(_objectSpread({}, (0, _omit.default)(cartItem, ['line_item_id'])), {}, {
      quantity: cartItem.quantity,
      shopify_product_variant_id: `gid://shopify/ProductVariant/${selected_size_product_variant.id}`
    });

    setProcessingItems([cartItem, new_item]);
    const updatedItems = !!existingItem ? list.filter(e => !e.is_pending).filter(e => e.shopify_product_variant_id !== cartItem.shopify_product_variant_id).map(e => {
      return e.shopify_product_variant_id === new_item.shopify_product_variant_id ? _objectSpread(_objectSpread({}, e), {}, {
        quantity: Number(e.quantity) + Number(cartItem.quantity)
      }) : e;
    }) : [_objectSpread(_objectSpread({}, (0, _omit.default)(cartItem, ['line_item_id'])), {}, {
      quantity: cartItem.quantity,
      shopify_product_variant_id: `gid://shopify/ProductVariant/${selected_size_product_variant.id}`
    }), ...list.filter(e => !e.is_pending).filter(e => e.shopify_product_variant_id !== cartItem.shopify_product_variant_id)];
    dispatch((0, _actions.ChangeCartItemProductVariant)({
      line_items: updatedItems,
      isTriggerCartLoading: false,
      shopifyClient,
      postDispatches: [(updatedShopifyCheckoutItems = []) => {
        setProcessingItems([]);
        const newUpdatedShopifyCheckoutItems = updatedShopifyCheckoutItems.map(updatedItem => {
          const _updatedItem$shopify_ = updatedItem.shopify_product_id.split('/').reverse(),
                shopifyProductIdNumber = _updatedItem$shopify_[0];

          const match_product = (cart_items || []).find(prevItem => Number(prevItem.shopify_product_id) === Number(shopifyProductIdNumber)) || {};
          return _objectSpread(_objectSpread({}, updatedItem), (0, _pick.default)(match_product, ['id', 'artist', 'sizes', 'artist_name', 'availability', 'base_price', 'is_valid_product', 'status', 'stock', 'style_id', 'style_type', 'variants', 'visibility', 'style_type_name', 'product_name']));
        });
        const newList = [...list.filter(e => e.is_pending), ...newUpdatedShopifyCheckoutItems];
        setList(newList);
      }]
    }));
  };

  const handleRemoveItem = product => {
    const _ref2 = product || {},
          line_item_id = _ref2.line_item_id;

    if (!line_item_id) return;
    dispatch((0, _actions.RemoveFromSessionCart)({
      line_item_ids: [line_item_id],
      shopifyClient
    }));
    gtag('event', 'remove_item', {
      event_category: 'Checkout',
      event_label: 'Remove Cart Item'
    });
  };

  const handleQuantityChange = (action, cartItem) => {
    const stock = cartItem.stock,
          quantity = cartItem.quantity,
          availability = cartItem.availability;

    if (action === 'increment' && availability === 'Limited Edition' && Number(quantity) >= Number(stock)) {
      dispatch((0, _actions.ShowError)({
        message: 'Quantity cannot exceed the available stocks.'
      }));
      return;
    }

    if (action === 'decrement' && quantity - 1 < 1) {
      return;
    }

    let new_quantity = quantity;

    if (action === 'decrement') {
      new_quantity = quantity - 1;
    }

    if (action === 'increment') {
      new_quantity = quantity + 1;
    }

    const updated_item = _objectSpread(_objectSpread({}, cartItem), {}, {
      quantity: Number(new_quantity),
      to_be_updated: true
    });

    setList((prev = []) => prev.map(item => {
      if (item.shopify_product_variant_id === cartItem.shopify_product_variant_id) {
        return updated_item;
      }

      return item;
    }));
  };

  const handleContinueShoppingOnClick = () => {
    history.push(`/shop-product`);
    dispatch((0, _actions.HideSideCartPanel)());
  };

  const handleProceedToCheckout = () => {
    dispatch((0, _actions.ProceedToShopifyCheckout)({
      shopifyClient
    }));
  };

  const handleViewProduct = (cartItem = {}) => {
    const _cartItem$artist = cartItem.artist,
          cartItemArtist = _cartItem$artist === void 0 ? {} : _cartItem$artist,
          item_artist_name = cartItem.artist_name,
          item_style_type_name = cartItem.style_type_name,
          product_name = cartItem.product_name,
          is_pending = cartItem.is_pending,
          size = cartItem.size;

    if (is_pending) {
      history.push(`/product-preview/${cartItem.id}`);
      dispatch((0, _actions.HideSideCartPanel)());
      return;
    }

    if (cartItem.status === 'Approved' && cartItem.visibility === 'Private' && session.id === cartItem.artist_id) {
      history.push(`/create/${cartItem.id}`, {
        viewMode: 'read_only'
      });
      dispatch((0, _actions.HideSideCartPanel)());
      return;
    }

    if (cartItem.status === 'Approved' && cartItem.visibility === 'Private' && session_id !== cartItem.artist_id) {
      history.push(`/product-preview/${cartItem.id}`);
      dispatch((0, _actions.HideSideCartPanel)());
      return;
    }

    const code = cartItemArtist.code,
          username = cartItemArtist.username;
    const _location$pathname = location.pathname,
          pathname = _location$pathname === void 0 ? '' : _location$pathname;
    const splittedPathname = pathname.split('/');
    const _splittedPathname$ = splittedPathname[1],
          currentPageEntity = _splittedPathname$ === void 0 ? '' : _splittedPathname$,
          _splittedPathname$2 = splittedPathname[2],
          params_style_type_name = _splittedPathname$2 === void 0 ? '' : _splittedPathname$2,
          _splittedPathname$3 = splittedPathname[3],
          artist_handle_params = _splittedPathname$3 === void 0 ? '' : _splittedPathname$3,
          _splittedPathname$4 = splittedPathname[4],
          encodedProductName = _splittedPathname$4 === void 0 ? '' : _splittedPathname$4;

    if ((currentPageEntity || '').toLowerCase() === 'product') {
      const decoded_product_name = encodedProductName.trim().replace(/_/g, ' ');
      const splitted_user_handle = artist_handle_params.split('-').reverse();
      const params_username = splitted_user_handle.length === 1 ? splitted_user_handle[0] : '';
      const params_code = splitted_user_handle.length > 1 ? splitted_user_handle[0] : '';

      if (decoded_product_name === product_name && params_style_type_name === item_style_type_name && (params_username === username || params_code === code)) {
        dispatch((0, _actions.HideSideCartPanel)());
        return;
      }
    }

    if (item_artist_name && item_style_type_name && (username || code)) {
      const creator_handle = username || `${(item_artist_name || '').trim().replace(/\s/g, '-')}-${code}`;
      const encoded_product_name = product_name.trim().replace(/\s/g, '_');
      const splittedStyleTypeName = item_style_type_name.split(' ');
      const styleTypeName = splittedStyleTypeName.length > 1 ? splittedStyleTypeName.join('-') : item_style_type_name;
      history.push(`/product/${styleTypeName}/${creator_handle}/${encoded_product_name}?code=${code}`, {
        size
      });
      dispatch((0, _actions.HideSideCartPanel)());
    }
  };

  const handleRemovePendingItem = cart_item => {
    if (cart_item) {
      setProcessingItems([cart_item]);
      const updated_pending_items = list.filter(e => e.is_pending).filter(e => {
        if (e.id === cart_item.id && e.size === cart_item.size) {
          return null;
        }

        return e;
      });
      dispatch((0, _actions.RemovePendingItemFromCart)({
        updated_pending_items,
        callback: (updatedPendingItems = []) => {
          setList([...list.filter(e => !e.is_pending), ...updatedPendingItems]);
          setProcessingItems([]);
        }
      }));
    }
  };

  const handlePendingItemSizeChange = (cart_item, selected_size) => {
    if (cart_item) {
      setProcessingItems([cart_item, _objectSpread(_objectSpread({}, cart_item), {}, {
        size: selected_size
      })]);
      const existingItem = list.filter(e => e.is_pending).find(e => e.id === cart_item.id && e.size === selected_size);
      const updated_pending_items = existingItem ? list.filter(e => e.is_pending).filter(e => e.id === cart_item.id && e.size === cart_item.size).map(e => {
        if (e.id === existingItem.id && e.size === existingItem.size) {
          return _objectSpread(_objectSpread({}, e), {}, {
            quantity: Number(e.quantity) + Number(cart_item.quantity)
          });
        }

        return e;
      }) : list.filter(e => e.is_pending).map(e => {
        if (e.id === cart_item.id && e.size === cart_item.size) {
          return _objectSpread(_objectSpread({}, e), {}, {
            size: selected_size
          });
        }

        return e;
      });
      dispatch((0, _actions.UpdatePendingCartItems)({
        updated_pending_items,
        callback: updatedPendingItems => {
          setList([...updatedPendingItems, ...list.filter(e => !e.is_pending)]);
          setProcessingItems([]);
        }
      }));
    }
  };

  const handlePendingItemQuantityChange = (action, cartItem) => {
    const stock = cartItem.stock,
          quantity = cartItem.quantity,
          availability = cartItem.availability,
          _cartItem$pricing = cartItem.pricing,
          pricing = _cartItem$pricing === void 0 ? {} : _cartItem$pricing;

    if (action === 'increment' && availability === 'Limited Edition' && Number(quantity) >= Number(stock)) {
      dispatch((0, _actions.ShowError)({
        message: 'Quantity cannot exceed the available stocks.'
      }));
      return;
    }

    if (action === 'decrement' && quantity - 1 < 1) {
      return;
    }

    let new_quantity = quantity;

    if (action === 'decrement') {
      new_quantity = quantity - 1;
    }

    if (action === 'increment') {
      new_quantity = quantity + 1;
    }

    const updated_item = _objectSpread(_objectSpread({}, cartItem), {}, {
      quantity: Number(new_quantity),
      to_be_updated: true
    });

    const updatedList = list.map(item => {
      if (item.id === cartItem.id && item.size === cartItem.size) {
        return updated_item;
      }

      return item;
    });
    setList(updatedList);
  };

  const handleDiscoverProductsOnClick = () => {
    history.push(`/shop-product`);
    dispatch((0, _actions.HideSideCartPanel)());
  };

  const handleViewCreator = (cartItem = {}) => {
    const _cartItem$artist2 = cartItem.artist,
          artist = _cartItem$artist2 === void 0 ? {} : _cartItem$artist2,
          artist_name = cartItem.artist_name;
    const username = artist.username,
          code = artist.code;

    if ((username || code) && artist_name) {
      const artist_code_handle = `${artist_name.trim().replace(/\s/g, '-')}-${code}`;
      const url_params = username || artist_code_handle;
      history.push(`/creator/${url_params}`);
    }
  };

  return {
    values: {
      list,
      cartContainerWidth,
      processingItems,
      hasPendingProducts,
      isSubTotalLoading
    },
    handlers: {
      handleCloseSidePane,
      handleUpdateCartItems,
      handleSizeChange,
      handleContinueShoppingOnClick,
      handleRemoveItem,
      handleQuantityChange,
      handleProceedToCheckout,
      handleViewProduct,
      handleRemovePendingItem,
      handlePendingItemSizeChange,
      handlePendingItemQuantityChange,
      handleDiscoverProductsOnClick,
      handleViewCreator
    }
  };
};

exports.default = _default;
"use strict";

var _effects = require("redux-saga/effects");

var _tools = require("@common/utils/tools");

var _tools2 = require("@23point5/core/controls/utils/tools");

var _types = require("../types");

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function* NavigateToRoot({
  payload
}) {
  const route = payload.route,
        uniqueEntity = payload.uniqueEntity,
        _payload$requestGridO = payload.requestGridOnClose,
        requestGridOnClose = _payload$requestGridO === void 0 ? true : _payload$requestGridO,
        _payload$formOnCloseD = payload.formOnCloseDispatches,
        formOnCloseDispatches = _payload$formOnCloseD === void 0 ? [] : _payload$formOnCloseD;
  const promises = [(0, _effects.call)(_tools.pushHistory.bind(route)), (0, _effects.put)({
    type: _types.SHOW_FULL_DIALOG,
    payload: false
  }), (0, _effects.put)({
    type: _types.CLEAR_NAVI
  })]; // MODIFIED: TO ADD CUSTOM CHECKING FOR GRID REQUEST AFTER FORM IS CLOSED

  if (requestGridOnClose) {
    // IF A GRID HAS TO BE REQUESTED AFTER FULLDIALOG CLOSING
    const gridProperties = yield (0, _effects.select)(_tools.getState.bind(`${uniqueEntity}.properties`));
    promises.push((0, _effects.put)({
      type: _types.GET_NODE_GRID + _types.REQUESTED,
      payload: _objectSpread(_objectSpread({}, gridProperties), {}, {
        uniqueEntity
      }),
      makeDelay: true
    }));
  }

  yield (0, _effects.all)(promises); // MODIFIED: TO ADD CUSTOM CHECKING FOR GRID REQUEST AFTER FORM IS CLOSED

  if (formOnCloseDispatches) {
    const chainFunctions = (0, _tools2.flowAsync)(formOnCloseDispatches.filter(e => typeof e === 'function'));
    const chainDispatches = formOnCloseDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
    promises.push(...chainDispatches);
    yield (0, _effects.call)(() => chainFunctions());
  }
}

function* LocationChange({
  payload
}) {
  const isFirstRendering = payload.isFirstRendering,
        location = payload.location;

  if (isFirstRendering) {
    _tools.pushHistory.call(location);
  }
}

function* ShowDialog({
  payload
}) {
  const customDialogDispatches = payload.customDialogDispatches,
        restPayload = _objectWithoutPropertiesLoose(payload, ["customDialogDispatches"]);

  yield (0, _effects.put)({
    type: _types.SHOW_DIALOG,
    payload: restPayload
  });
  yield (0, _effects.all)((customDialogDispatches || []).map(e => (0, _effects.put)(e)));
}

module.exports = function* base() {
  // GET
  yield (0, _effects.takeLatest)(_types.SHOW_DIALOG + _types.REQUESTED, ShowDialog);
  yield (0, _effects.takeLatest)(_types.NAVIGATE_TO_ROOT + _types.REQUESTED, NavigateToRoot);
  yield (0, _effects.takeLatest)('@@router/LOCATION_CHANGE', LocationChange);
};
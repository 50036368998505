"use strict";

exports.__esModule = true;
exports.default = Onmount;

function Onmount() {
  const _this$props = this.props,
        dispatch = _this$props.dispatch,
        session = _this$props.session,
        data = _this$props.dialog.data;
}
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  list: {
    count: 0,
    data: []
  },
  properties: {},
  list_properties: {},
  filters: {},
  details: null,
  refreshGrid: false,
  isExporting: false,
  isProcessing: false,
  isGridFetching: false,
  resetSelectedRows: false
};
exports.default = _default;
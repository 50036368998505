"use strict";

exports.__esModule = true;
exports.default = void 0;

var _zustand = require("zustand");

var _middleware = require("zustand/middleware");

const store = (0, _zustand.create)((set, get) => ({
  uploadedFiles: []
}));
var _default = store;
exports.default = _default;
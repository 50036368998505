"use strict";

exports.__esModule = true;
exports.default = _default;

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  const _this$props = this.props,
        dialog = _this$props.dialog,
        session = _this$props.session;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  const backgroundPhotos = data.backgroundPhotos;
  return {
    currentTab: 'upload',
    backgroundPhotos: [],
    unsupportedBackgroundImages: [],
    isUploading: false,
    selectedPattern: {},
    recentPatterns: [],
    errors: {}
  };
}
"use strict";

exports.__esModule = true;
exports.default = Onmount;

var _actions = require("@pages/CreatorDetails/actions");

function Onmount() {
  const _this$props = this.props,
        dispatch = _this$props.dispatch,
        session = _this$props.session,
        data = _this$props.dialog.data; // dispatch(GetStylesList())

  dispatch((0, _actions.GetProductDetails)(data.selected_id));
}
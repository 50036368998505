"use strict";

exports.__esModule = true;
exports.default = useUploadProgress;

var _react = require("react");

function useUploadProgress(props) {
  const uploader = props.uploader,
        id = props.id;
  const isTotalProgress = Boolean(!id);

  const _useState = (0, _react.useState)({
    bytesUploaded: null,
    totalSize: null
  }),
        state = _useState[0],
        setState = _useState[1];

  (0, _react.useEffect)(() => {
    if (isTotalProgress) {
      uploader.on('totalProgress', onTotalProgress);
    } else {
      uploader.on('progress', onFileProgress);
    }

    return () => {
      if (isTotalProgress) {
        uploader.off('totalProgress', onTotalProgress);
      } else {
        uploader.off('progress', onFileProgress);
      }
    };
  }, []);
  return [state];

  function onTotalProgress(bytesUploaded, totalSize) {
    setState({
      bytesUploaded,
      totalSize,
      isTotalProgress
    });
  }

  function onFileProgress(fileId, name, bytesUploaded, totalSize) {
    if (fileId === props.id) {
      setState({
        bytesUploaded,
        totalSize,
        isTotalProgress
      });
    }
  }
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

function Title() {
  const isSizeGuideShown = this.state.isSizeGuideShown;
  if (isSizeGuideShown) return null; // return 'Add To Cart'

  return '';
}

var _default = Title;
exports.default = _default;
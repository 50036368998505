"use strict";

exports.__esModule = true;
exports.default = _default;

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  return {
    fieldValues: {
      password: '',
      password2: ''
    },
    errors: {}
  };
}
"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  const isSizeGuideShown = this.state.isSizeGuideShown;
  return {
    dialogClassName: `dnaDialog__container ${isSizeGuideShown ? 'size-guide-shown' : ''} create-add-box`,
    className: `dnaDialog size-md editorAddToCartDialog`
  };
}
"use strict";

exports.__esModule = true;
exports.makePercentage = exports.buttonTextColor = exports.getLuminance = exports.setGridDimension = exports.getGutter = exports.ellipsis = exports.pageTableIcon = exports.dnaIcon = exports.materialIcon = exports.flex = exports.applyScrollbar = exports.coloralpha = exports.colormod = exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = [{
  dna_fontsize: '14px',
  dna_color_pink: '#FD5C5B',
  dna_color_red: '#de585b',
  dna_color_orange: '#F29B34',
  dna_color_lightyellow: '#FFE42D',
  dna_color_yellow: '#FBBC05',
  dna_color_darkyellow: '#D39400',
  dna_color_lightgreen: '#e4eadd',
  dna_color_green: '#71BA51',
  dna_color_greendark: '#65A648',
  dna_color_lightblue: '#D6ECFA',
  dna_color_blue: '#4285F4',
  dna_color_purple: '#3F51B5',
  dna_color_darkpurple: '#12021d',
  dna_color_faint: '#f0f0f0',
  dna_color_white: '#ffffff',
  dna_color_lightgray: '#EEE9E9',
  dna_color_lightgray2: '#E6EEF1',
  dna_color_gray: '#616161',
  dna_color_darkgray: '#424242',
  dna_color_black: '#1e1e1e',
  dna_color_disabled: '#eeeeee',
  dna_color_primary: '#4285F4',
  dna_color_secondary: '#3F51B5',
  dna_color_tertiary: '#F29B34',
  dna_color_accent: '#4285F4',
  dna_color_info: '#26ADE4',
  dna_color_success: '#71BA51',
  dna_color_warning: '#FBBC05',
  dna_color_danger: '#EE543A',
  dna_color_error: '#D42327',
  dna_color_default: '#9e9e9c',
  dna_color_light: '#E0E0E0',
  dna_color_lighter: '#E8E8E8',
  dna_color_lightest: '#F0F0F0',
  dna_color_dark: '#1f1f1f',
  dna_color_cancel: '#e0e0e0',
  // UTILITIES
  dna_color_title: '#3d3d3d',
  dna_color_subtitle: '#9e9e9c',
  dna_color_text: '#58595F',
  dna_color_text_default: '#333333',
  dna_color_border: '#DCDCDC',
  dna_color_divider: 'rgba(#000 , .12)',
  dna_box_shadow: '0 3px 5px 0 rgba(0,0,0,0.1)',
  dna_box_shadow_active: '0 3px 10px 0 rgba(0,0,0,0.25)',
  dna_color_text_error: '#f44336',
  dna_color_bodyBg: '#ffffff',
  dna_color_container: '#F5FAFE',
  dna_color_textfield_icon: '#757575',
  dna_color_error_message_bg: '#C26565',
  primary_color_light: '#5AAFF4',
  primary_color_lighter: '#73CFF4',
  primary_color_lightest: '#C7EFFF',
  dna_header_height: '75px',
  dna_sidebar_width: '60px',
  // DATATABLE
  dna_theme_datatable_text: '#333333',
  dna_theme_datatable_bg: '#ffffff',
  dna_theme_datatable_header_bg: '#F6F7FB',
  dna_theme_datatable_bg_light: '#F0F0F0',
  dna_theme_datatable_header_text: '#333333',
  dna_theme_datatable_header_border: '#60646D',
  dna_theme_datatable_body_border: '#D2D7D3',
  dna_theme_datatable_sorted_row: '#F3F3F3',
  dna_theme_datatable_hover_row: '#F0F0F0',
  dna_theme_datatable_sorted_row: '#E0E0E0',
  dna_theme_datatable_header_font_size: '1rem',
  dna_theme_datatable_header_font_weight: 'normal',
  dna_theme_datatable_header_font_weight_active: 'bold',
  dna_theme_datatable_header_text_transform: 'capitalize',
  dna_theme_datatable_body_font_size: '1rem',
  dna_theme_datatable_body_font_weight: 'normal',
  dna_theme_datatable_body_font_weight_active: 'bold',
  dna_theme_datatable_body_text_transform: 'none',
  dna_theme_fspanel_header_bg: '#ffffff',
  dna_theme_fspanel_header_text: '#60646D',
  dna_portal_transition: '300ms cubic-bezier(0.505, 0.000, 0.425, 1.000)',
  dna_transition_easing1: 'cubic-bezier(0.64, 0.57, 0.67, 1.53)',
  dna_transition_easing2: 'cubic-bezier(0, 0, 0.29, 1.01)',
  dnaTable_pos_left: '280px',
  dnaTable_pos_left_mini: '84px',
  dnaTable_pos_left_phone: '16px',
  dnaTable_pos_right: '24px',
  dnaTable_pos_right_filter: '292px',
  dnaTable_pos_right_phone: '16px',
  dnaTable_pos_bottom_fullpage: '65px',
  dnaTable_preloader_bar_color: '#D6ECFA',
  dnaTable_preloader_bar_top: '42px',
  dnaTable_table_header: '#F6F7FB',
  dnaTable_table_header_sorted: '#ECEFFB',
  dnaTable_table_body_sorted: '#F6F7FB',
  // DATATABLE FILTER
  dataTableFilter_dropdownMenu_bgColor: '#F0F0F0',
  dataTableFilter_dropdownMenu_textColor: '#3d3d3d',
  // SORTABLE TABS
  sortableTabs_pos_top: '53px',
  sortableTabs_pos_top_phone: '54px',
  sortableTabs_pos_left: '272px',
  sortableTabs_pos_left_mini: '76px',
  sortableTabs_pos_left_phone: '16px',
  sortableTabs_pos_right: '16px',
  sortableTabs_pos_right_phone: '16px',
  sortableTabs_pos_bottom_fullpage: '65px',
  sortableTabs_bed_bgColor: '#f0f0f0',
  sortableList_section_header_bgColor: '#E6EEF1',
  sortableList_section_titleColor: '#3d3d3d',
  sortable_handlebar_iconSize: '1.2rem',
  sortable_header_bg: '#E6EEF1',
  md_table_header: '#F6F7FB',
  md_table_header_text_color: '#3d3d3d',
  md_table_borderBottom: '2px solid #4285F4',
  sortable_chosen_color: '#7DAEFF',
  sortableList_minHeight: '1em',
  // PIVOT TABLE
  dna_theme_pivottable_bg_light: '#F0F0F0',
  // RESPONSIVE VIEW
  dna_screen_size_4k: '(max-width:3000px)',
  dna_screen_size_xl: '(max-width:1920px)',
  dna_screen_size_lg: '(max-width:1200px)',
  dna_screen_size_md: '(max-width:992px)',
  dna_screen_size_sm: '(max-width:768px)',
  dna_screen_size_xs: '(max-width:420px)',
  responsive_laptop: 'only screen and (max-width:1440px)',
  responsive_tablet: 'only screen and (min-device-width:1024px) and (max-device-width:1366px), (min-width: 1024px) and (max-width: 1366px)',
  responsive_tablet_mini: 'only screen and (min-device-width:768px) and (max-device-width:1024px), (min-width: 768px) and (max-width: 1024px)',
  responsive_tablet_portrait: 'only screen and (min-device-width:768px) and (max-device-width:1024px), (min-width: 768px) and (max-width: 1024px)',
  responsive_tablet_landscape: 'only screen and (min-device-width:768px) and (max-device-width:1024px), (min-width: 768px) and (max-width: 1024px)',
  responsive_phone: 'only screen and (min-device-width:320px) and (max-device-width:736px), (min-width:320px) and (max-width:736px)',
  responsive_phone_portrait: 'only screen and (min-device-width:320px) and (max-device-width:736px), (min-width:320px) and (max-width:736px)',
  responsive_phone_landscape: 'only screen and (min-device-width:320px) and (max-device-width:736px), (min-width:320px) and (max-width:736px)',
  responsive_orientation_landscape: 'and (orientation: landscape)',
  responsive_orientation_portrait: 'and (orientation: portrait)',
  // INPUT FIELDS
  dna_input_text_color: '#333333',
  dna_input_text_color_hover: '#333333',
  dna_input_text_color_disabled: '#8B8B8B',
  dna_input_placeholder_text_color: '#808080',
  dna_input_placeholder_text_color_hover: '#1E1E1E',
  dna_input_placeholder_text_color_disabled: '#cccccc',
  dna_input_border_color: '#cccccc',
  dna_input_border_color_hover: '#cccccc',
  dna_input_border_color_disabled: '#cccccc',
  dna_input_bg_color: '#fff',
  dna_input_bg_color_hover: '#fff',
  dna_input_bg_color_disabled: '#f0f0f0',
  dna_input_icon_color: '#78909C',
  dna_input_icon_color_hover: '#78909C',
  dna_input_icon_color_disabled: '#cccccc',
  dna_input_label_scale: '0.87',
  dna_input_placeholder_size: '1rem',
  dna_input_placeholder_size_active: '12px',
  dna_input_error_fontsize: '10px',
  dna_input_main_fontsize: '1.5rem',
  // DATEPICKER
  datePicker_header_bg_color: '#ffe3f3',
  datePicker_text_color: '#4a062d',
  // WIZARD
  wizard_tab_bgcolor: '#DBDBDB',
  wizard_tab_bgcolor_active: '#4285F4',
  wizard_tab_bgcolor_done: '#4285F4',
  wizard_tab_bgcolor_error: '#de585b',
  wizard_tab_bgcolor_valid: '#4285F4',
  wizard_circle_size: '40px',
  wizard_line_size: '6px',
  wizard_step_size: '3.5rem',
  wizard_step_line: '.09em',
  wizard_inactive_step_bg: '#CCC',
  wizard_line: '#CCC',
  wizard_tabs_padding: '2em',
  // SCROLLBAR
  scrollbar_track_bg: '#eeeeee',
  scrollbar_thumb_bg: '#9e9e9e',
  scrollbar_track_bg_active: '#e0e0e0',
  scrollbar_thumb_bg_active: '#000000',
  scrollbar_curve: '8px',
  scrollbar_size: '8px',
  // ICONS
  dna_icon_size: '1.25rem',
  dna_icon_mainsize: '1.5rem',
  dna_icon_boxsize: '2rem',
  // BUTTONS
  dna_button_fontsize: '1rem',
  dna_button_minheight: '1.5rem',
  dna_button_padding: '0.25rem 1rem'
}];
exports.default = _default;

const colormod = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');

  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  lum = lum || 0; // convert to decimal and change luminosity

  let rgb = '#';
  let c;
  let i;

  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

exports.colormod = colormod;

const coloralpha = (hex, alpha = 1) => {
  const _hex$match$map = hex.match(/\w\w/g).map(x => parseInt(x, 16)),
        r = _hex$match$map[0],
        g = _hex$match$map[1],
        b = _hex$match$map[2];

  return `rgba(${r},${g},${b},${alpha})`;
};

exports.coloralpha = coloralpha;

const applyScrollbar = () => {
  const scrollbar_track_bg = '#eeeeee';
  const scrollbar_thumb_bg = '#9e9e9e';
  const scrollbar_track_bg_active = '#e0e0e0';
  const scrollbar_thumb_bg_active = '#000000';
  const scrollbar_curve = '8px';
  const scrollbar_size = '8px';
  return `
    // width: 100%;
    // overflow-y: auto;
    // overflow-x: hidden;
    // position: relative;

    &::-webkit-scrollbar {
      width: ${scrollbar_size};
      height: ${scrollbar_size};
      background-color: inherit;
    }

    &::-webkit-scrollbar-track {
      background-color: ${scrollbar_track_bg};
      border-radius: ${scrollbar_curve};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${scrollbar_thumb_bg};
      border-radius: 4em;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        background-color: ${scrollbar_track_bg_active};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${scrollbar_thumb_bg_active};
      }
    }
  `;
};

exports.applyScrollbar = applyScrollbar;

const flex = (x = 'flex-start', y = 'flex-start', dir = 'row', impt = false) => `
    display: flex${impt ? ' !important' : ''};
    justify-content: ${x}${impt ? ' !important' : ''};
    align-items: ${y}${impt ? ' !important' : ''};
    flex-direction: ${dir}${impt ? ' !important' : ''};
  `;

exports.flex = flex;

const materialIcon = icon => `
    content:'${icon}';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  `;

exports.materialIcon = materialIcon;

const dnaIcon = icon => `
    content: '${icon}';
    font-family: "dna-v51-base-icons" !important;
    font-weight: normal;
    font-style: normal;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `;

exports.dnaIcon = dnaIcon;

const pageTableIcon = icon => `
    content: '${icon}';
    font-family: "dna-pagetable-v5" !important;
    font-weight: normal;
    font-style: normal;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `;

exports.pageTableIcon = pageTableIcon;

const ellipsis = (mwidth = '250px') => `
    width: ${mwidth} !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  `;

exports.ellipsis = ellipsis;

const getGutter = (val, dir) => {
  if (dir === null) dir = 'horizontal';
  let gutter = '';
  const unitMatch = /(px|pt|cm|mm|in|pc|em|ex|ch|rem|vw|vh|vmin|vmax|%)$/; // SPLITTING THE GUTTER PROP STRING INTO AN ARRAY

  if (val && val.indexOf(' ') >= 0) {
    const gutterArray = _lodash.default.split(val, ' ');

    const marTop = gutterArray[0],
          marRight = gutterArray[1],
          marBottom = gutterArray[2],
          marLeft = gutterArray[3];
    gutter = '';

    if (dir === 'horizontal') {
      // check if marLeft and marRight has units
      if (marRight) {
        if (marRight.match(unitMatch)) {
          gutter += ` - (${marRight}`;
        } else {
          gutter += ' - (0px';
        }
      } else {
        gutter += ' - (0px';
      }

      if (marLeft) {
        if (marLeft.match(unitMatch)) {
          gutter += ` + ${marLeft})`;
        } else if (gutterArray.length >= 2) {
          gutter += ' * 2)';
        } else {
          gutter += ' + 0px)';
        }
      } else if (gutterArray.length >= 2) {
        gutter += ' * 2)';
      } else {
        gutter += ' + 0px)';
      }
    } else if (dir === 'vertical') {
      // check if marTop and marBottom has units
      if (marTop) {
        if (marTop.match(unitMatch)) {
          gutter += ` - (${marTop}`;
        } else {
          gutter += ' - (0px';
        }
      } else {
        gutter += ' - (0px';
      }

      if (marBottom) {
        if (marBottom.match(unitMatch)) {
          gutter += ` + ${marBottom})`;
        } else if (gutterArray.length >= 2) {
          gutter += ' * 2)';
        } else {
          gutter += ' + 0px)';
        }
      } else if (gutterArray.length >= 2) {
        gutter += ' * 2)';
      } else {
        gutter += ' + 0px)';
      }
    }
  } else if (val != undefined) {
    const num = val;

    if (val.match(unitMatch)) {
      gutter = ` - (${num} * 2)`;
    }
  }

  return gutter;
};

exports.getGutter = getGutter;

const setGridDimension = (comp, extra, willGrow) => {
  let _ref = '',
      widthString = _ref.widthString,
      minWidthString = _ref.minWidthString,
      maxWidthString = _ref.maxWidthString,
      offsetString = _ref.offsetString,
      heightString = _ref.heightString,
      growString = _ref.growString;
  let isWidthAuto = false;

  if (comp.colspan || comp.width) {
    widthString = `width: calc(`;
    minWidthString = `min-width: calc(`;
    maxWidthString = `max-width: calc(`; // choose colspan over width

    if (comp.colspan && comp.width) {
      widthString += `${comp.colspan / 12 * 100}%`;
      minWidthString += `${comp.colspan / 12 * 100}%`;
      maxWidthString += `${comp.colspan / 12 * 100}%`;
      isWidthAuto = false; // otherwise
    } else if (comp.colspan && !comp.width) {
      widthString += `${comp.colspan / 12 * 100}%`;
      minWidthString += `${comp.colspan / 12 * 100}%`;
      maxWidthString += `${comp.colspan / 12 * 100}%`;
      isWidthAuto = false;
    } else if (!comp.colspan && comp.width) {
      widthString += `${comp.width}`;
      minWidthString += `${comp.width}`;
      maxWidthString += `${comp.width}`;
      isWidthAuto = false;
    } else {
      widthString = `auto`;
      minWidthString = `auto`;
      maxWidthString = `auto`;
      isWidthAuto = true;
    }
  } else {
    widthString = `width: auto`;
    minWidthString = `min-width: auto`;
    maxWidthString = `max-width: auto`;
    isWidthAuto = true;
  } // add gutter


  if (comp.gutter && !isWidthAuto) {
    widthString += `${getGutter(comp.gutter, 'horizontal')}`;
    minWidthString += `${getGutter(comp.gutter, 'horizontal')}`;
    maxWidthString += `${getGutter(comp.gutter, 'horizontal')}`;
  } // to accommodate multifields' extra right padding of 2rem
  // also serves as a clutch for other purposes


  if (extra) {
    widthString += ` - ${extra}`;
    minWidthString += ` - ${extra}`;
    maxWidthString += ` - ${extra}`;
  } // add offset


  if (comp.offset) {
    widthString += ` - (${comp.offset / 12 * 100}%`;
    minWidthString += ` - (${comp.offset / 12 * 100}%`;
    maxWidthString += ` - (${comp.offset / 12 * 100}%`;
    offsetString = `margin-left: calc(${comp.offset / 12 * 100}%`;

    if (comp.gutter && !isWidthAuto) {
      widthString += `${getGutter(comp.gutter, 'horizontal')}`;
      minWidthString += `${getGutter(comp.gutter, 'horizontal')}`;
      maxWidthString += `${getGutter(comp.gutter, 'horizontal')}`;
      offsetString += `${getGutter(comp.gutter, 'horizontal')}`;
    }

    widthString += `)`;
    minWidthString += `)`;
    maxWidthString += `)`; // terminate offset string

    offsetString += `) !important`;
  } // add indent


  if (comp.indent) {
    widthString += ` - (${comp.indent}`;
    minWidthString += ` - (${comp.indent}`;
    maxWidthString += ` - (${comp.indent}`;
    offsetString = `margin-left: calc(${comp.indent}`;

    if (comp.gutter && !isWidthAuto) {
      widthString += `${getGutter(comp.gutter, 'horizontal')}`;
      minWidthString += `${getGutter(comp.gutter, 'horizontal')}`;
      maxWidthString += `${getGutter(comp.gutter, 'horizontal')}`;
      offsetString += `${getGutter(comp.gutter, 'horizontal')}`;
    }

    widthString += `)`;
    minWidthString += `)`;
    maxWidthString += `)`; // terminate offset string

    offsetString += `) !important`;
  } // terminate width


  if (!isWidthAuto) {
    widthString += `);`;
    minWidthString += `);`;
    maxWidthString += `);`;
  }

  if (comp.height) {
    heightString = `height: ${comp.height}`;
  } // if no colspan and width
  // if (!comp.colspan && !comp.width) {
  //   growString = `flex-grow: 1`
  // }


  return `
    ${widthString !== undefined ? widthString : ''};
    ${minWidthString !== undefined ? minWidthString : ''};
    // ${maxWidthString !== undefined ? maxWidthString : ''};
    ${offsetString !== undefined ? offsetString : ''};
    ${heightString !== undefined ? heightString : ''};
    ${growString !== undefined ? growString : ''};
  `;
};

exports.setGridDimension = setGridDimension;

const getLuminance = color => {
  var color = `${color}`;
  const isHEX = color.indexOf('#') == 0;
  const isRGB = color.indexOf('rgb') == 0;

  if (isHEX) {
    var m = color.substr(1).match(color.length == 7 ? /(\S{2})/g : /(\S{1})/g);
    if (m) var r = parseInt(m[0], 16);
    var g = parseInt(m[1], 16);
    var b = parseInt(m[2], 16);
  }

  if (isRGB) {
    var m = color.match(/(\d+){3}/g);
    if (m) var r = m[0];
    var g = m[1];
    var b = m[2];
  }

  if (typeof r !== 'undefined') return (r * 299 + g * 587 + b * 114) / 1000;
};

exports.getLuminance = getLuminance;

const buttonTextColor = color => {
  // Variables for red, green, blue values
  let r;
  let g;
  let b;
  let hsp; // Check the format of the color, HEX or RGB?

  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;
    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  } // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html


  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)); // Using the HSP value, determine whether the color is light or dark

  if (hsp > 127.5) {
    return '#1f1f1f';
  }

  return '#ffffff';
};

exports.buttonTextColor = buttonTextColor;

const makePercentage = (val, total) => {
  let percent = 0;
  val = Number(val);
  total = Number(total);

  if (val > 0 && total > 0) {
    percent = val / total * 100;
  }

  return percent;
};

exports.makePercentage = makePercentage;
"use strict";

exports.__esModule = true;
exports.GetUserSession = GetUserSession;
exports.GetSessionCompanyDetails = GetSessionCompanyDetails;
exports.LoginUser = LoginUser;
exports.SetFilterTags = SetFilterTags;
exports.LogoutUser = LogoutUser;
exports.UpdateUserSession = UpdateUserSession;
exports.UpdateCompanySession = UpdateCompanySession;
exports.UpdateUserPreferences = UpdateUserPreferences;
exports.ChangeUserPassword = ChangeUserPassword;
exports.SetUserPassword = SetUserPassword;
exports.ValidateToken = ValidateToken;
exports.RegisterUser = RegisterUser;
exports.UpdateUsedToken = UpdateUsedToken;
exports.SendResetPasswordEmail = SendResetPasswordEmail;
exports.ClearUserSession = ClearUserSession;
exports.UpdateSessionData = UpdateSessionData;
exports.profilePictureUpdating = profilePictureUpdating;
exports.UploadProfilePicture = UploadProfilePicture;
exports.UploadBackgroundOrElementImage = UploadBackgroundOrElementImage;
exports.UploadTempProfilePicture = UploadTempProfilePicture;
exports.CoverPhotosUploading = CoverPhotosUploading;
exports.UploadCoverPhotos = UploadCoverPhotos;
exports.SetToCustomizeDesign = SetToCustomizeDesign;
exports.ClearToCustomizeDesign = ClearToCustomizeDesign;
exports.GetSearchResult = GetSearchResult;
exports.SetPreviousPath = SetPreviousPath;
exports.SetDetailsParamsID = SetDetailsParamsID;
exports.SetCreatorDetailsUrlParams = SetCreatorDetailsUrlParams;
exports.Subscribe = Subscribe;
exports.ResetSubscribe = ResetSubscribe;
exports.SetDetailsRoute = SetDetailsRoute;
exports.GetMoreDesignsFromArtist = GetMoreDesignsFromArtist;
exports.GetSimiarDesigns = GetSimiarDesigns;
exports.ResendVerificationEmail = ResendVerificationEmail;
exports.DisableFirstUsePrompt = DisableFirstUsePrompt;
exports.DisableLogoutPrompt = DisableLogoutPrompt;
exports.ClearSearchResults = ClearSearchResults;
exports.LikesUpdating = LikesUpdating;
exports.LikesUpdated = LikesUpdated;
exports.SavingDesignChanges = SavingDesignChanges;
exports.SavingDesignFinished = SavingDesignFinished;
exports.DismissFirstVisitNotification = DismissFirstVisitNotification;
exports.SendFeedback = SendFeedback;
exports.FetchCreatorDetailsOnClick = FetchCreatorDetailsOnClick;
exports.FetchProductRelatedDataOnClick = FetchProductRelatedDataOnClick;
exports.SetProductsPageParams = SetProductsPageParams;
exports.ResetUserPassword = ResetUserPassword;
exports.UpdateProfilePhotoSession = UpdateProfilePhotoSession;

var _types = require("../types");

function GetUserSession(data) {
  return {
    type: _types.GET_USER_SESSION + _types.REQUESTED,
    payload: data
  };
}

function GetSessionCompanyDetails(data) {
  return {
    type: _types.GET_SESSION_COMPANY_DETAILS + _types.REQUESTED,
    payload: data
  };
}

function LoginUser(data) {
  return {
    type: _types.LOGIN_USER + _types.REQUESTED,
    payload: data
  };
}

function SetFilterTags(data) {
  return {
    type: `${_types.SET_FILTER_TAGS}`,
    payload: data
  };
}

function LogoutUser() {
  return {
    type: _types.LOGOUT_USER + _types.REQUESTED
  };
}

function UpdateUserSession(data) {
  return {
    type: `${_types.UPDATE_USER_SESSION}${_types.REQUESTED}`,
    payload: data
  };
}

function UpdateCompanySession(data) {
  return {
    type: `${_types.UPDATE_COMPANY_SESSION}${_types.REQUESTED}`,
    payload: data
  };
}

function UpdateUserPreferences(data) {
  return {
    type: `${_types.UPDATE_USER_PREFERENCES}${_types.REQUESTED}`,
    payload: data
  };
}

function ChangeUserPassword(data) {
  return {
    type: `${_types.CHANGE_USER_PASSWORD}${_types.REQUESTED}`,
    payload: data
  };
}

function SetUserPassword(data) {
  return {
    type: `SET_USER_PASSWORD${_types.REQUESTED}`,
    payload: data
  };
}

function ValidateToken(data) {
  return {
    type: `${_types.VALIDATE_TOKEN}${_types.REQUESTED}`,
    payload: data
  };
}

function RegisterUser(data) {
  return {
    type: `${_types.REGISTER_USER}${_types.REQUESTED}`,
    payload: data
  };
}

function UpdateUsedToken(data) {
  return {
    type: `${_types.UPDATE_USED_TOKEN}${_types.REQUESTED}`,
    payload: data
  };
}

function SendResetPasswordEmail(data) {
  return {
    type: `${_types.SEND_RESET_PASSWORD_EMAIL}${_types.REQUESTED}`,
    payload: data
  };
}

function ClearUserSession(data) {
  return {
    type: _types.CLEAR_USER_SESSION,
    payload: data
  };
}

function UpdateSessionData(data) {
  return {
    type: _types.UPDATE_SESSION_DATA,
    payload: data
  };
}

function profilePictureUpdating() {
  return {
    type: _types.PROFILE_PICTURE_UPDATING
  };
}

function UploadProfilePicture(data) {
  return {
    type: `${_types.UPLOAD_PROFILE_PICTURE}${_types.REQUESTED}`,
    payload: data
  };
}

function UploadBackgroundOrElementImage(data) {
  return {
    type: `UPLOAD_BACKGROUND_OR_ELEMENT_IMAGE${_types.REQUESTED}`,
    payload: data
  };
}

function UploadTempProfilePicture(data) {
  return {
    type: `${_types.UPLOAD_TEMP_PROFILE_PICTURE}${_types.REQUESTED}`,
    payload: data
  };
}

function CoverPhotosUploading() {
  return {
    type: _types.COVER_PHOTOS_UPLOADING
  };
}

function UploadCoverPhotos(data) {
  return {
    type: `${_types.UPLOAD_COVER_PHOTOS}${_types.REQUESTED}`,
    payload: data
  };
}

function SetToCustomizeDesign(data) {
  return {
    type: 'SET_TO_CUSTOMIZE_DESIGN',
    payload: data
  };
}

function ClearToCustomizeDesign() {
  return {
    type: 'CLEAR_TO_CUSTOMIZE_DESIGN'
  };
}

function GetSearchResult(data) {
  return {
    type: _types.GET_SEARCH_RESULT + _types.REQUESTED,
    payload: data
  };
}

function SetPreviousPath(path) {
  return {
    type: 'SET_PREVIOUS_PATH',
    payload: path
  };
}

function SetDetailsParamsID(data) {
  return {
    type: 'SET_DETAILS_PARAMS_ID',
    payload: data
  };
}

function SetCreatorDetailsUrlParams(data) {
  return {
    type: 'SET_CREATOR_DETAILS_URL_PARAMS',
    payload: data
  };
}

function Subscribe(data) {
  return {
    type: `SUBSCRIBE${_types.REQUESTED}`,
    payload: data
  };
}

function ResetSubscribe(data) {
  return {
    type: `RESET_SUBSCRIBE`
  };
}

function SetDetailsRoute(data) {
  return {
    type: 'SET_DETAILS_ROUTE',
    payload: data
  };
}

function GetMoreDesignsFromArtist(data) {
  return {
    type: `GET_MORE_DESIGNS_FROM_ARTIST${_types.REQUESTED}`,
    payload: data
  };
}

function GetSimiarDesigns(data) {
  return {
    type: `GET_SIMILAR_DESIGNS${_types.REQUESTED}`,
    payload: data
  };
}

function ResendVerificationEmail(data) {
  return {
    type: `RESEND_VERIFICATION_EMAIL${_types.REQUESTED}`,
    payload: data
  };
}

function DisableFirstUsePrompt() {
  return {
    type: `DISABLE_FIRST_USE_PROMPT${_types.REQUESTED}`
  };
}

function DisableLogoutPrompt() {
  return {
    type: `DISABLE_LOGOUT_PROMPT${_types.REQUESTED}`
  };
}

function ClearSearchResults() {
  return {
    type: 'CLEAR_SEARCH_RESULTS'
  };
}

function LikesUpdating() {
  return {
    type: `LIKES_UPDATING`
  };
}

function LikesUpdated() {
  return {
    type: `LIKES_UPDATED`
  };
}

function SavingDesignChanges() {
  return {
    type: `SAVING_DESIGN_CHANGES`
  };
}

function SavingDesignFinished() {
  return {
    type: `SAVING_DESIGN_FINISHED`
  };
}

function DismissFirstVisitNotification() {
  return {
    type: `DISMISS_FIRST_VISIT_NOTIFICATION${_types.REQUESTED}`
  };
}

function SendFeedback(data) {
  return {
    type: `SEND_FEEDBACK${_types.REQUESTED}`,
    payload: data
  };
}

function FetchCreatorDetailsOnClick(data) {
  return {
    type: `FETCH_CREATOR_DETAILS_ON_CLICK${_types.REQUESTED}`,
    payload: data
  };
}

function FetchProductRelatedDataOnClick(data) {
  return {
    type: `FETCH_PRODUCT_RELATED_DATA_ON_CLICK${_types.REQUESTED}`,
    payload: data
  };
}

function SetProductsPageParams(data) {
  return {
    type: `SET_PRODUCTS_PAGE_PARAMS${_types.REQUESTED}`,
    payload: data
  };
}

function ResetUserPassword(data) {
  return {
    type: `RESET_USER_PASSWORD${_types.REQUESTED}`,
    payload: data
  };
}

function UpdateProfilePhotoSession(data) {
  return {
    type: `UPDATE_PROFILE_PHOTO_SESSION${_types.REQUESTED}`,
    payload: data
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@pages/DesignEditor/actions");

var _actions2 = require("@App/actions");

var _omit = _interopRequireDefault(require("lodash/omit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const EventHandlers = {
  handleInputChange(key, value) {},

  handleSave() {
    const _this$props = this.props,
          dispatch = _this$props.dispatch,
          _this$props$dialog = _this$props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog;
    const _this$state$fieldValu = this.state.fieldValues,
          fieldValues = _this$state$fieldValu === void 0 ? {} : _this$state$fieldValu;
  },

  handleCancel() {
    this.handleHide();
  }

};
var _default = EventHandlers;
exports.default = _default;
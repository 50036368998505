"use strict";

exports.__esModule = true;
exports.getModeProps = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const isCreatableProps = ({
  onCreateOption
}) => ({
  isCreatable: true,
  formatCreateLabel: val => `+ ${val}`,
  createOptionPosition: 'first',
  onCreateOption
});

const isMultiProps = {
  isMulti: true,
  pill: true
};

const pillsOnlyProps = _objectSpread(_objectSpread({}, isMultiProps), {}, {
  components: {
    DropdownIndicator: () => null,
    Menu: () => null
  }
});

const getProps = (curr, rawProps) => {
  switch (curr) {
    case 'isCreatable':
      return isCreatableProps(rawProps);

    case 'isMulti':
      return isMultiProps;

    case 'pillsOnly':
      return _objectSpread(_objectSpread({}, isCreatableProps(rawProps)), pillsOnlyProps);

    default:
      return curr ? {
        [curr]: true
      } : {};
  }
};

const getModeProps = rawProps => {
  const mode = rawProps.mode,
        _rawProps$modes = rawProps.modes,
        modes = _rawProps$modes === void 0 ? [] : _rawProps$modes;

  if (mode || modes.length) {
    const items = modes.length ? modes : [mode];

    const _items$reduce = items.reduce(({
      props
    }, curr) => ({
      props: _objectSpread(_objectSpread({}, props), getProps(curr, rawProps))
    }), {}),
          modeProps = _items$reduce.props,
          modeClassnames = _items$reduce.classnames;

    return {
      modeProps,
      modeClassnames,
      mergedProps: _objectSpread(_objectSpread({}, modeProps), rawProps)
    };
  }

  return {
    mergedProps: rawProps
  };
};

exports.getModeProps = getModeProps;
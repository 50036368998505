"use strict";

exports.__esModule = true;
exports.default = void 0;
const EventHandlers = {
  handleInputChange(key, value) {},

  handleSave() {},

  handleCancel() {
    this.handleHide();
  }

};
var _default = EventHandlers;
exports.default = _default;
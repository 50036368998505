"use strict";

exports.__esModule = true;
exports.default = void 0;

var _validator = _interopRequireDefault(require("validator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Validations() {
  const fieldValues = this.state.fieldValues;
  const errors = {};

  if (_validator.default.isEmpty(fieldValues.product_name.trim())) {
    errors.product_name = 'Product Name is required';
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0
  };
}

var _default = Validations;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _createReducer = require("@23point5/core/controls/utils/createReducer");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const INITIAL_STATE = {
  global_products_list: {
    data: [],
    count: 0
  },
  shopify_publishing_products: [],
  isSendingResetPassEmail: false,
  isProcessingApp: false
};
const actionHandlers = {
  GET_GLOBAL_PRODUCT_LISTING: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    global_products_list: payload
  }),
  SHOPIFY_PRODUCT_PUBLISH_PROCESSING: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    shopify_publishing_products: [...state.shopify_publishing_products, payload]
  }),
  SHOPIFY_PRODUCT_PUBLISH_DONE_PROCESSING: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    shopify_publishing_products: state.shopify_publishing_products.filter(product => product.id !== payload.id)
  }),
  SHOPIFY_PRODUCT_PUBLISH_CLEAR_PROCESSING: state => _objectSpread(_objectSpread({}, state), {}, {
    shopify_publishing_products: []
  }),
  SENDING_RESET_PASSWORD_EMAIL: state => _objectSpread(_objectSpread({}, state), {}, {
    isSendingResetPassEmail: true
  }),
  SENDING_RESET_PASSWORD_EMAIL_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isSendingResetPassEmail: false
  }),
  PROCESSING_APP: state => _objectSpread(_objectSpread({}, state), {}, {
    isProcessingApp: true
  })
};

var _default = (0, _createReducer.buildReducer)(INITIAL_STATE, actionHandlers);

exports.default = _default;
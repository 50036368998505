"use strict";

exports.__esModule = true;
exports.withStyled = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styleVariables = _interopRequireWildcard(require("@23point5/controls/src/styleVariables"));

var _tools = require("@23point5/core/controls/utils/tools");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const styleVariables = _styleVariables.default[0];

const withStyled = Comp => (0, _styledComponents.default)(Comp)`
  font-size: ${styleVariables.dna_fontsize};

  ${(0, _styleVariables.flex)('flex-start', 'flex-end')}

  padding: 0;
  padding-bottom: 1rem;
  position: relative;

  margin: ${props => props.gutter};
  ${props => (0, _styleVariables.setGridDimension)(props)}
  min-height: 60px;
  height: auto;
  box-sizing: border-box;

  .flag {
    ${(0, _styleVariables.flex)('flex-start', 'stretch')};
    flex-wrap: nowrap;
    &-icon {
      ${(0, _styleVariables.flex)('flex-start', 'center')};

      .dnaImageLoader {
        width: 1.25rem !important;
        img {
          margin: 0;
          width: 1.25rem;
          height: auto;
          border: 1px solid ${styleVariables.dna_color_border};
        }
      }
    }
    &-name {
      padding-left: 0.5rem;
      ${(0, _styleVariables.flex)('flex-start', 'center')};
      flex-grow: 1;
      font-size: ${styleVariables.dna_fontsize};
      ${(0, _styleVariables.ellipsis)('100%')};
    }
  }

  .autoComplete {
    /* .autoComplete__icon */
    &__icon {
      left: 0;
      bottom: 1.25rem;
      flex-grow: 0;
      position: absolute;
      ${(0, _styleVariables.flex)('center', 'center')}
      width: ${styleVariables.dna_icon_boxsize};
      height: ${styleVariables.dna_icon_boxsize};
      font-size: ${styleVariables.dna_icon_size};

      i,
      .dnaicon,
      .md-icon,
      .fa {
        ${(0, _styleVariables.flex)('center', 'center')}
        width: ${styleVariables.dna_icon_boxsize};
        height: ${styleVariables.dna_icon_boxsize};
        font-size: ${styleVariables.dna_icon_size};
        color: ${props => props.iconcolor || styleVariables.dna_input_icon_color};
        &:before {
          ${(0, _styleVariables.flex)('center', 'center')}
          width: ${styleVariables.dna_icon_boxsize};
          height: ${styleVariables.dna_icon_boxsize};
          font-size: ${styleVariables.dna_icon_size};
        }
      }
    }

    &__selectWrapper {
      flex-grow: 1;
      width: 100%;
      position: relative;
      margin-left: 2.4rem;
      margin-bottom: 0.25rem;
    }

    /* .autoComplete__placeholder */
    &__placeholder {
      position: absolute;
      transform: translateY(0.6rem);
      z-index: 10;
      top: 0.55rem;
      color: ${styleVariables.dna_input_placeholder_text_color};
      transition: 0.15s ease-in-out;
      transform-origin: top left;
      font-size: ${styleVariables.dna_input_placeholder_size};
      pointer-events: none;
      display: block;
      max-width: calc(100% - 1.5rem);

      .autoComplete__placeholderText {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    /* .autoComplete__select */
    &__select {
      /* padding-top: 19px; */

      .reactSelect {
        /* .reactSelect__control */
        &__control {
          border: none !important;
          border-bottom: 1px solid ${styleVariables.dna_color_light} !important;
          border-radius: 0 !important;
          box-shadow: none;
          position: relative;
          width: 100%;
          background-color: transparent;
          padding-top: 0;

          ${(0, _styleVariables.flex)('flex-start', 'stretch')}
          flex-wrap: nowrap;

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            z-index: 10;
            left: 0;
            top: calc(100% - 0px);
            background-color: ${styleVariables.dna_color_primary};

            transform: scaleX(0);
            transition: 0.15s ease-in-out;
            transform-origin: top left;
          }
        }

        &__placeholder {
          display: none;
        }

        /* .reactSelect__value-container */
        &__value-container {
          cursor: pointer;
          position: relative;
          padding: 0.25rem 0 0.15rem 0;

          + div {
            cursor: pointer;
          }

          &:after {
            content: '';
            width: 1px;
            height: 50%;
            position: absolute;
            z-index: 100;
            display: none;
            top: 50%;
            margin-top: -25%;
            right: 0;
            background-color: ${styleVariables.dna_color_light};
          }

          .reactSelect__single-value {
            margin: 0;
            margin-top: 0.25rem;

            ~ div {
              margin: 0;
              padding: 0;
              padding-top: 0.5rem;

              .reactSelect__input {
                margin-top: 0rem;
              }
            }
          }

          > div:nth-child(1):not(.reactSelect__single-value):not(.reactSelect__multi-value) {
            margin: 0;
            padding: 0;
            padding-top: 0.5rem;

            .reactSelect__input {
              margin-top: 0rem;
            }
          }

          .reactSelect__multi-value {
            background-color: ${styleVariables.dna_color_light};
            border-radius: 4px;
            padding: 0.2rem 0.5rem;
            transition: 0.15s ease-in-out;

            ${(0, _styleVariables.flex)('center', 'center')}

            /* .reactSelect__label */
            &__label {
              color: ${styleVariables.dna_color_dark};
              font-size: ${styleVariables.dna_input_placeholder_size};
              line-height: 1;
              margin-right: 0.5rem;
            }

            /* .reactSelect__remove */
            &__remove {
              background-color: ${(0, _styleVariables.coloralpha)(styleVariables.dna_color_red, 1)};
              color: ${styleVariables.dna_color_white};
              padding: 0;
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              line-height: 1;
              transition: 0.15s ease-in-out;

              &:hover {
                /* background-color: ${(0, _styleVariables.coloralpha)(styleVariables.dna_color_red, 1)}; */
                transform: scale(1.25);
              }
            }

            &:hover {
              background-color: ${(0, _styleVariables.colormod)(styleVariables.dna_color_light, 0.075)};
            }
          }
        }

        /* .reactSelect__menu */
        &__menu {
          position: absolute;
          top: calc(100% + 1px);
          left: 0;
          border: 1px solid ${styleVariables.dna_color_light};
          margin-top: 0;
          box-shadow: ${styleVariables.dna_box_shadow};
          z-index: 20;

          /* .reactSelect__menu-list */
          &-list {
            padding: 0;
            font-size: 1rem;
            max-height: 150px;
            color: #000;
            ${_styleVariables.applyScrollbar};
          }
        }

        /* .reactSelect__option */
        &__option {
          .flag {
            ${(0, _styleVariables.flex)('flex-start', 'center')};
            .flag-icon {
              ${(0, _styleVariables.flex)('center', 'center')};
              margin-right: 0.5rem;

              .dnaImageLoader {
                width: 1.25rem;
              }
            }
          }
        }

        /* .reactSelect__indicators */
        &__indicators {
          width: auto;
          height: auto;
          ${(0, _styleVariables.flex)('center', 'center')}

          .reactSelect__indicator-separator {
            display: none;
          }

          .reactSelect__clear-indicator {
            ${(0, _styleVariables.flex)('center', 'center')};
            svg {
              display: none;
            }
            &:before {
              ${(0, _styleVariables.flex)('center', 'center')};
              ${(0, _styleVariables.dnaIcon)('t')};
              font-size: ${styleVariables.dna_input_placeholder_size};
              color: ${styleVariables.dna_color_dark};
              background-color: transparent;
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 50%;
            }

            &:hover {
              &:before {
                color: #fff;
                background-color: ${styleVariables.dna_color_danger};
              }
            }
          }

          .reactSelect__dropdown-indicator {
            ${(0, _styleVariables.flex)('center', 'center')};
            svg {
              display: none;
            }
            &:before {
              ${(0, _styleVariables.flex)('center', 'center')};
              ${(0, _styleVariables.dnaIcon)('u')};
              font-size: ${styleVariables.dna_input_placeholder_size};
              color: ${styleVariables.dna_color_dark};
            }
          }
        }
      }
    }

    /* .autoComplete__message */
    &__message {
      left: 0;
      bottom: -1.2rem;
      font-size: ${styleVariables.dna_input_error_fontsize};
      position: absolute;
      color: ${styleVariables.dna_input_placeholder_text_color};

      &.error {
        color: ${styleVariables.dna_color_text_error};
      }
    }

    /* MODIFY DROPDOWN LOCATION (FIX OVERFLOW ISSUE) */
    &.menu--mid {
      .autoComplete__select {
        .reactSelect__menu {
          top: auto;
          bottom: 50%;
          transform: translateY(calc(50% + 20px));
        }
      }
    }
    &.menu--top {
      .autoComplete__select {
        .reactSelect__menu {
          top: auto;
          bottom: 100%;
        }
      }
    }

    // WHEN USED TOGETHER WITH AVATAR/PROFILE IMAGE
    &.profile__field {
      height: 5em;
    }
  }

  /* error */
  &.md-text--error {
    .autoComplete {
      /* autoComplete__placeholder */
      &__placeholder {
        color: ${styleVariables.dna_color_danger};
      }

      /* autoComplete__select */
      &__select {
        .reactSelect {
          /* .reactSelect__control */
          &__control {
            border-bottom: 1px solid ${styleVariables.dna_color_danger} !important;
          }
        }
      }

      /* autoComplete__icon */
      &__icon {
        i,
        .dnaicon,
        .md-icon,
        .fa {
          color: ${styleVariables.dna_color_text_error};
          &:before {
            color: ${styleVariables.dna_color_text_error};
          }
        }
      }
    }

    .autoComplete__select > div {
      .css-1492t68 {
        color: ${styleVariables.dna_color_danger};
      }
    }

    &.textError-absolute {
      .autoComplete__message {
        position: absolute;
        left: 2rem;
      }
    }
  }

  &.disabled {
    .autoComplete__select > div {
      > div:nth-child(2) {
        opacity: 0;
      }
    }
  }

  &.active {
    .autoComplete__placeholder {
      transform: translateY(-1.25rem);
      transition: 0.15s ease-in-out;
      font-size: ${styleVariables.dna_input_placeholder_size_active} !important;
    }
  }

  &.focused {
    .autoComplete__placeholder {
      color: ${styleVariables.dna_color_black} !important;
      transform: translateY(-1.25rem);
      transition: 0.15s ease-in-out;
      font-size: ${styleVariables.dna_input_placeholder_size_active} !important;
    }
    .autoComplete__icon {
      i,
      .dnaicon,
      .md-icon,
      .fa {
        color: ${styleVariables.dna_color_black};
      }
    }
    .autoComplete__select {
      > div {
        &:before {
          transform: scaleX(1);
          transition: 0.15s ease-in-out;
        }
      }
    }
  }

  &.no-icon {
    .autoComplete__selectWrapper {
      margin-left: 0;
      flex-grow: 1;
      width: 100%;

      padding-left: 0;

      position: relative;
    }
    .autoComplete__icon {
      display: none !important;
    }
  }

  &.languageSelect {
    width: 180px !important;
    min-height: auto;
    .autoComplete__icon {
      display: none;
    }

    .autoComplete__selectWrapper {
      margin-bottom: 0;
    }

    .autoComplete__select {
      padding-top: 0;
    }

    [role='option'] {
      padding: 0.2rem 0.5rem;
    }

    .reactSelect {
      /* .reactSelect__control */
      &__control {
        border: 1px solid ${styleVariables.dna_color_light} !important;
      }
    }
  }

  &.mainField {
    padding-bottom: 0.5rem;
    .autoComplete__icon {
      bottom: 1rem;
      i,
      .dnaicon,
      .md-icon,
      .fa {
        font-size: ${styleVariables.dna_icon_mainsize};
        &:before {
          font-size: ${styleVariables.dna_icon_mainsize};
        }
      }
    }
    .autoComplete__placeholder {
      font-size: ${styleVariables.dna_icon_mainsize};
      transform: translateY(0rem);
    }

    .autoComplete__select {
      .reactSelect__control {
        .reactSelect__input input {
          font-size: ${styleVariables.dna_icon_mainsize};
          padding-bottom: 0.5rem;
        }
      }

      .reactSelect__value-container {
        .reactSelect__multi-value__label {
          font-size: ${styleVariables.dna_icon_mainsize};
          margin-top: 0rem;
        }
        .reactSelect__single-value {
          font-size: ${styleVariables.dna_icon_mainsize};
          margin-top: 0.25rem;
        }
      }
    }
    &.focused,
    &.active {
      .autoComplete__placeholder {
        transform: translateY(-1.55rem);
        font-size: ${styleVariables.dna_input_placeholder_size_active} !important;
      }
    }
  }

  &.autoComplete_size--medium {
    .autoComplete__placeholder {
      font-size: 1.25rem;
      transform: translateY(0.2rem);
    }

    .autoComplete__select {
      .reactSelect__control {
        .reactSelect__input input {
          font-size: 1.25rem !important;
          padding-bottom: 0.5rem;
        }
      }

      .reactSelect__value-container {
        .reactSelect__multi-value__label {
          font-size: 1.25rem;
          margin-top: 0rem;
        }
        .reactSelect__single-value {
          font-size: 1.25rem;
          margin-top: 0.4rem;
        }
      }
    }
    &.focused,
    &.active {
      .autoComplete__placeholder {
        transform: translateY(-1.2rem);
        font-size: ${styleVariables.dna_input_placeholder_size_active} !important;
      }
    }
  }

  &.autoComplete_size--large {
    .autoComplete__placeholder {
      font-size: 1.5rem;
      transform: translateY(0rem);
    }

    .autoComplete__select {
      .reactSelect__control {
        .reactSelect__input input {
          font-size: 1.5rem !important;
          padding-bottom: 0.5rem;
        }
      }

      .reactSelect__value-container {
        .reactSelect__multi-value__label {
          font-size: 1.5rem;
          margin-top: 0rem;
        }
        .reactSelect__single-value {
          font-size: 1.5rem;
          margin-top: 0.25rem;
        }
      }
    }
    &.focused,
    &.active {
      .autoComplete__placeholder {
        transform: translateY(-1.45rem);
        font-size: ${styleVariables.dna_input_placeholder_size_active} !important;
      }
    }
  }

  &.disabled {
    .autoComplete__icon {
      i,
      .dnaicon,
      .md-icon,
      .fa {
        color: ${styleVariables.dna_input_icon_color_disabled};
      }
    }

    .autoComplete__placeholder {
      color: ${styleVariables.dna_input_placeholder_text_color_disabled};
    }

    .autoComplete__select .reactSelect__value-container .reactSelect__single-value {
      color: ${styleVariables.dna_input_text_color_disabled};
    }

    .autoComplete__select .reactSelect__value-container .reactSelect__multi-value {
      background-color: #f0f0f0;
      .reactSelect__multi-value__label {
        color: ${styleVariables.dna_input_text_color_disabled};
      }
      .reactSelect__multi-value__remove {
        background-color: ${styleVariables.dna_input_placeholder_text_color_disabled};
      }
    }
  }

  &.autoComplete_style--border {
    padding-bottom: 0;
    .autoComplete__icon {
      bottom: 0;
    }
    .autoComplete__selectWrapper {
      border-width: 1px;
      border-style: solid;
      ${props => props.systemcolor || props.color ? (0, _tools.setTargetColor)(props.systemcolor, props.color, 'border-color') : `border-color: ${styleVariables.dna_color_border}`};
      margin-bottom: 0;
      background-color: ${styleVariables.dna_color_white};

      .autoComplete__select {
        .reactSelect__control {
          min-height: auto !important;
        }
      }
    }
    .reactSelect__value-container {
      padding: 0 0.5rem !important;
      min-height: 30px;

      .reactSelect__single-value {
        margin: 0 !important;
        ${props => props.systemcolor || props.color ? (0, _tools.setTargetColor)(props.systemcolor, props.color, 'color') : `color: ${styleVariables.dna_input_text_color}`};
      }

      > div:nth-child(1):not(.reactSelect__single-value):not(.reactSelect__multi-value) {
        margin: 0 !important;
      }
    }
  }

  &.autoComplete_style--boxed {
    padding-bottom: 0;
    min-height: auto;
    .autoComplete__icon {
      bottom: 0;
    }
    .autoComplete__selectWrapper {
      border-width: 1px;
      border-style: solid;
      ${props => props.systemcolor || props.color ? (0, _tools.setTargetColor)(props.systemcolor, props.color, 'border-color') : `border-color: ${styleVariables.dna_color_border}`};
      margin-bottom: 0;
      background-color: ${styleVariables.dna_color_white};

      .autoComplete__select {
        .reactSelect__control {
          min-height: auto !important;
        }
      }
    }
    .reactSelect__value-container {
      padding: 0 0.5rem !important;
      min-height: 30px;

      .reactSelect__single-value {
        margin: 0 !important;
        ${props => props.systemcolor || props.color ? (0, _tools.setTargetColor)(props.systemcolor, props.color, 'color') : `color: ${styleVariables.dna_input_text_color}`};
      }

      > div:nth-child(1):not(.reactSelect__single-value):not(.reactSelect__multi-value) {
        margin: 0 !important;
      }
    }
  }

  &.adjustMenu {
    .autoComplete__select {
      .reactSelect__menu {
        top: auto;
        bottom: 0;
      }
    }
  }

  /* .autoComplete__menuPosition */
  &.autoComplete__menuPosition {
    &--top {
      .autoComplete__select {
        .reactSelect__menu {
          top: auto;
          bottom: 0;
        }
      }
    }
  }

  &.disableMenu {
    .reactSelect__indicators,
    .reactSelect__input,
    .reactSelect__menu {
      display: none !important;
    }
  }

  &.pill {
    .autoComplete__selectWrapper {
      .autoComplete__select {
        .reactSelect__control {
          .reactSelect__value-container {
            &.reactSelect__value-container--is-multi {
              .reactSelect__multi-value {
                border-radius: 2rem;
              }
            }
          }
        }
      }
    }
  }
`;

exports.withStyled = withStyled;
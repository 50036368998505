"use strict";

exports.__esModule = true;
exports.default = Onmount;

var _actions = require("@pages/DesignEditor/actions");

var _actions2 = require("@App/actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function Onmount() {
  const _this$props = this.props,
        dispatch = _this$props.dispatch,
        session = _this$props.session,
        data = _this$props.dialog.data;
  const _data$product_data = data.product_data,
        product_data = _data$product_data === void 0 ? {} : _data$product_data;
  const handleSetInitialCollections = this.EventHandlers.handleSetInitialCollections;
  const _this$state$fieldValu = this.state.fieldValues,
        fieldValues = _this$state$fieldValu === void 0 ? {} : _this$state$fieldValu;
  dispatch((0, _actions.CreatePublishDialogOnmount)());
  dispatch((0, _actions2.GetOptionsList)({
    key: 'artist_custom_price',
    url: `artist`,
    source: 'dialog',
    exclude_status: true,
    fields: 'id,custom_style_pricing',
    [`id:eq`]: session.id,
    [`status:ne`]: 'Deleted'
  }));
  dispatch((0, _actions.PublishDialogGetLatestStyle)({
    style_type: product_data.style_type,
    callback: (result = {}) => {
      this.setState(prev => {
        const initial_retail = Number(result.price) + Number(result.default_markup);
        const static_make_price = (product_data.style_type_name || '').toLowerCase() === 'hoodie' ? 45 : 28;
        const initial_markup = initial_retail - (Number(result.make_price) || static_make_price);
        return _objectSpread(_objectSpread({}, prev), {}, {
          latest_style: result,
          fieldValues: _objectSpread(_objectSpread({}, prev.fieldValues), {}, {
            base_price: result.price || product_data.fieldValues.base_price || 0.0,
            pricing: {
              markup: initial_markup,
              retail: initial_retail
            }
          })
        });
      });
    }
  }));
}
"use strict";

var _types = require("@App/types");

// eslint-disable-line
module.exports = {
  // EditorSaveDesignChanges(data) {
  //   return {
  //     type: `EDITOR_SAVE_DESIGN_CHANGES${REQUESTED}`,
  //     payload: data
  //   }
  // },
  EditorDeleteDesign(data) {
    return {
      type: `EDITOR_DELETE_DESIGN${_types.REQUESTED}`,
      payload: data
    };
  }

};
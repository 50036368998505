"use strict";

exports.__esModule = true;
exports.default = void 0;

var _useUpload = _interopRequireDefault(require("@common/components/FineUploaderHooks/useUpload"));

var _react = require("react");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = args => {
  const _args$uploaderProps = args.uploaderProps,
        uploaderProps = _args$uploaderProps === void 0 ? {} : _args$uploaderProps,
        _args$callbacks = args.callbacks,
        callbacks = _args$callbacks === void 0 ? {} : _args$callbacks,
        fileMd5 = args.fileMd5;
  const rawUploadResponses = (0, _react.useRef)({});
  const uploaderRef = (0, _react.useRef)(null);
  const md5Ref = (0, _react.useRef)(fileMd5);
  const uploaderHandlersAndState = (0, _useUpload.default)(_objectSpread(_objectSpread({}, uploaderProps), {}, {
    autoUpload: true,
    onFileUploaded: (id, file_name, response) => {
      // if (response && response.id) {
      rawUploadResponses.current = _objectSpread(_objectSpread({}, rawUploadResponses.current), {}, {
        [response.id]: _objectSpread(_objectSpread({}, response), {}, {
          file_name
        })
      }); // }
    },
    onAllFilesUploaded: () => {
      const formatted = Object.values(rawUploadResponses.current).map(rawFile => {
        const _rawFile$file_path = rawFile.file_path,
              file_path = _rawFile$file_path === void 0 ? '' : _rawFile$file_path,
              _rawFile$thumbnail_pa = rawFile.thumbnail_path,
              thumbnail_path = _rawFile$thumbnail_pa === void 0 ? '' : _rawFile$thumbnail_pa,
              _rawFile$raw_file_pat = rawFile.raw_file_path,
              raw_file_path = _rawFile$raw_file_pat === void 0 ? '' : _rawFile$raw_file_pat,
              fileID = rawFile.id,
              _rawFile$height = rawFile.height,
              height = _rawFile$height === void 0 ? 0 : _rawFile$height,
              _rawFile$width = rawFile.width,
              width = _rawFile$width === void 0 ? 0 : _rawFile$width,
              _rawFile$file_name = rawFile.file_name,
              file_name = _rawFile$file_name === void 0 ? '' : _rawFile$file_name;
        const fileType = file_path.split('.').pop();
        const is_low_res = Number(height) < 4000 && Number(width) < 4000;
        return _objectSpread({
          key: fileID,
          name: file_name,
          texture: file_path,
          originalTexture: file_path,
          thumb: thumbnail_path,
          raw: raw_file_path,
          type: fileType,
          is_uploaded: true,
          is_low_res,
          width,
          height,
          file_hash: md5Ref.current.current
        }, 'adjusted_width' in rawFile && 'adjusted_height' in rawFile && {
          adjusted_width: rawFile.adjusted_width,
          adjusted_height: rawFile.adjusted_height
        });
      }).filter(Boolean);

      if (callbacks.handleOnUploadSuccess) {
        callbacks.handleOnUploadSuccess(formatted);
      }

      rawUploadResponses.current = {};
      return true;
    },
    onError: errorArgs => {
      if (callbacks.handleOnUploadError) {
        callbacks.handleOnUploadError(errorArgs);
      }
    }
  }));
  return [uploaderHandlersAndState, uploaderRef];
};

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _createReducer = require("@23point5/core/controls/utils/createReducer");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const INITIAL_STATE = {
  isCartSidePanelVisible: false,
  side_cart_data: {},
  cartItemsCount: 0,
  isCartLoading: false,
  isCartUpdated: true,
  artist_cart: null,
  shopify_checkout: null,
  cart_items: [],
  unavailable_items: [],
  isRedirectingToShopifyCheckout: false,
  isMergeLocalCart: false
};
const actionHandlers = {
  SHOW_SIDE_CART_PANEL: (state, {
    payload = {}
  }) => _objectSpread(_objectSpread({}, state), {}, {
    isCartSidePanelVisible: true,
    side_cart_data: _objectSpread(_objectSpread({}, state.side_cart_data), payload.data)
  }),
  HIDE_SIDE_CART_PANEL: state => _objectSpread(_objectSpread({}, state), {}, {
    isCartSidePanelVisible: false,
    side_cart_data: {}
  }),
  SET_CART_ITEMS_COUNT: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    cartItemsCount: payload
  }),
  CART_LOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    isCartLoading: true
  }),
  CART_LOADING_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isCartLoading: false
  }),
  SET_CART_UPDATED: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    isCartUpdated: payload
  }),
  GET_ARTIST_CART: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    artist_cart: payload.artist_cart,
    shopify_checkout: payload.shopify_checkout,
    cart_items: payload.cart_items,
    unavailable_items: payload.unavailable_items
  }),
  SET_ARTIST_CHECKOUT: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    shopify_checkout: payload
  }),
  REDIRECTING_TO_SHOPIFY_CHECKOUT: state => _objectSpread(_objectSpread({}, state), {}, {
    isRedirectingToShopifyCheckout: true
  }),
  REDIRECTING_TO_SHOPIFY_CHECKOUT_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isRedirectingToShopifyCheckout: false
  }),
  TRIGGER_MERGE_LOCAL_CART_TO_ACCOUNT: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    isMergeLocalCart: payload
  }),
  SET_ARTIST_CART_ITEMS: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    cart_items: payload
  })
};

var _default = (0, _createReducer.buildReducer)(INITIAL_STATE, actionHandlers);

exports.default = _default;
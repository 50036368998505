"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  return {
    dialogClassName: 'dnaDialog__container feedbackDialog',
    className: 'dnaDialog size-sm dnaDialog--newConfirmation feedbackDialog'
  };
}
"use strict";

exports.__esModule = true;
exports.default = root;

var _effects = require("redux-saga/effects");

var _session = _interopRequireDefault(require("./session"));

var _node = _interopRequireDefault(require("./node"));

var _base = _interopRequireDefault(require("./base"));

var _shared = _interopRequireDefault(require("./shared"));

var _editor = _interopRequireDefault(require("./editor"));

var _sessionCart = _interopRequireDefault(require("./sessionCart"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function* root() {
  yield [(0, _effects.fork)(_session.default), (0, _effects.fork)(_node.default), (0, _effects.fork)(_base.default), (0, _effects.fork)(_shared.default), (0, _effects.fork)(_editor.default), (0, _effects.fork)(_sessionCart.default)];
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@pages/CreatorDetails/actions");

var _history = _interopRequireDefault(require("@23point5/core/controls/history"));

var _actions2 = require("@App/actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleSave(action) {
    if (this.isValid()) {
      const _this$props = this.props,
            dialog = _this$props.dialog,
            session = _this$props.session,
            dispatch = _this$props.dispatch;
      this.action = action;
    }
  },

  handleSelectRating(id, rating) {
    const _this$state$survey_it = this.state.survey_items,
          survey_items = _this$state$survey_it === void 0 ? [] : _this$state$survey_it;
    const new_survey_items = survey_items.map(e => {
      if (e.id === id) {
        return _objectSpread(_objectSpread({}, e), {}, {
          rating
        });
      }

      return e;
    });
    this.setState({
      survey_items: new_survey_items
    });
  },

  handleGoToAccountSettings() {
    const dispatch = this.props.dispatch;
    const history = (0, _history.default)();
    history.push('/account-setting');
    dispatch((0, _actions2.HideDialog)());
  }

};
var _default = EventHandlers;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _createReducer = require("@23point5/core/controls/utils/createReducer");

var _get = _interopRequireDefault(require("lodash/get"));

var _omit = _interopRequireDefault(require("lodash/omit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const INITIAL_STATE = {
  data: null,
  switch_list: {
    data: null,
    count: 0
  },
  logout: true,
  isProcessing: false,
  isValidating: false,
  isUpdatingPrefs: false,
  prefsResponse: null,
  resetPassword: null,
  changePassword: null,
  coverPhotosUpdating: false,
  profilePictureUpdating: false,
  tempProfilePictureUpdating: false,
  search: {
    value: '',
    collection: {},
    artist: {},
    product: {}
  },
  prevPath: '',
  detailsRoute: '',
  isProccessingLikes: false,
  isSavingDesignChanges: false,
  isTriggerPostVerificationDispatches: false,
  isFeedbackDialogVisible: false,
  isFeedbackLoading: false,
  isSubscribeLoading: false,
  subscription: {
    response: ''
  },
  isShowPostVerification: false,
  connecting_to_store_loading: false
};

const resetLoadingState = state => _objectSpread(_objectSpread({}, state), {}, {
  isProcessing: false,
  isValidating: false,
  isUpdatingPrefs: false
});

const actionHandlers = {
  CLEAR_TOASTS: state => _objectSpread(_objectSpread({}, state), {}, {
    sucessMessage: ''
  }),
  GET_USER_SESSION: (state, action) => {
    const _action$payload = action.payload,
          data = _action$payload.data,
          languages = _action$payload.languages;
    return _objectSpread(_objectSpread({}, state), {}, {
      languages,
      logout: false,
      data: _objectSpread(_objectSpread({}, data), {}, {
        preferences: _objectSpread(_objectSpread({}, (0, _get.default)(data, 'preferences')), {}, {
          filters: [...((0, _get.default)(data, 'preferences.filters') || [])]
        }),
        logged_in: _objectSpread(_objectSpread({}, (0, _get.default)(data, 'logged_in')), {}, {
          preferences: _objectSpread(_objectSpread({}, (0, _get.default)(data, 'logged_in.preferences')), {}, {
            filters: [...((0, _get.default)(data, 'logged_in.preferences.filters') || [])]
          })
        })
      })
    });
  },
  GET_SWITCHED_ACCOUNT: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    data: _objectSpread(_objectSpread({}, state.data), {}, {
      logged_in: _objectSpread(_objectSpread({}, (0, _get.default)(state, 'data.logged_in')), {}, {
        preferences: _objectSpread(_objectSpread({}, (0, _get.default)(state, 'data.logged_in.preferences')), {}, {
          switched_account: action.payload
        })
      })
    })
  }),
  SOCKET_MAIN_TBL_ARTIST: (state = {}, {
    payload = {}
  }) => {
    const _ref = state || {},
          _ref$data = _ref.data,
          data = _ref$data === void 0 ? {} : _ref$data;

    const isReloadPageAfterVerify = data.id === payload.id; // && !data.is_verified && payload.is_verified

    return _objectSpread(_objectSpread({}, state), {}, {
      isReloadPageAfterVerify
    });
  },
  GET_SEARCH_RESULT: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    search: _objectSpread(_objectSpread({}, state.search), action.payload),
    isLoadingSearch: false
  }),
  GET_SEARCH_RESULT_REQUESTED: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    isLoadingSearch: true
  }),
  CLEAR_SEARCH_RESULTS: state => _objectSpread(_objectSpread({}, state), {}, {
    search: {},
    isLoadingSearch: false
  }),
  GET_LANGUAGES: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    languages: action.payload
  }),
  GET_SWITCH_USER_LIST: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    switch_list: action.payload
  }),
  LOGIN_USER: (state, action) => {
    const _action$payload2 = action.payload,
          data = _action$payload2.data,
          languages = _action$payload2.languages;
    return _objectSpread(_objectSpread({}, state), {}, {
      languages,
      isProcessing: false,
      // isProcessingUser: false,
      isValidating: false,
      resetPassword: null,
      changePassword: null,
      logout: false,
      data: _objectSpread(_objectSpread({}, data), {}, {
        preferences: _objectSpread(_objectSpread({}, data.preferences), {}, {
          filters: [...((0, _get.default)(data, 'preferences.filters') || [])]
        }),
        logged_in: _objectSpread(_objectSpread({}, data.logged_in), {}, {
          preferences: _objectSpread(_objectSpread({}, (0, _get.default)(data, 'logged_in.preferences')), {}, {
            filters: [...((0, _get.default)(data, 'logged_in.preferences.filters') || [])]
          })
        })
      })
    });
  },
  PROCESSING_USER: state => _objectSpread(_objectSpread({}, state), {}, {
    isProcessing: true,
    isProcessingUser: true
  }),
  PROCESSING_USER_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isProcessing: false,
    isProcessingUser: false
  }),
  LOGOUT_USER: () => _objectSpread(_objectSpread({}, INITIAL_STATE), {}, {
    logout: true
  }),
  CHANGE_USER_PASSWORD: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    changePassword: action.payload,
    resetPassword: null,
    isProcessing: false,
    isProcessingUser: false,
    error: null
  }),
  CLEAR_USER_SESSION: state => _objectSpread(_objectSpread({}, state), {}, {
    error: null,
    resetPassword: null,
    changePassword: null,
    isProcessing: false
  }),
  PREFERENCES_UPDATING: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdatingPrefs: true
  }),
  PREFERENCES_UPDATED: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdatingPrefs: false
  }),
  UPDATE_USER_PREFERENCES: (state, action) => {
    const preferences = action.payload.preferences;

    if (preferences) {
      const data = _objectSpread(_objectSpread({}, state.data), {}, {
        preferences
      });

      return _objectSpread(_objectSpread({}, state), {}, {
        data,
        prefsResponse: action.payload
      });
    }

    return _objectSpread(_objectSpread({}, state), {}, {
      prefsResponse: action.payload
    });
  },
  UPDATE_USER_SESSION: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    data: _objectSpread(_objectSpread({}, state.data), action.payload)
  }),
  UPDATE_COMPANY_SESSION: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    data: _objectSpread(_objectSpread({}, state.data), {}, {
      company_name: action.payload.name,
      logged_in: _objectSpread(_objectSpread({}, state.data.logged_in), {}, {
        company_name: (0, _get.default)(state, 'data.logged_in.id') === state.data.id ? action.payload.name : (0, _get.default)(state, 'data.logged_in.id')
      })
    })
  }),
  VALIDATE_TOKEN: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    logout: false,
    validated: action.payload,
    isValidating: false
  }),
  VALIDATE_TOKEN_REQUESTED: state => _objectSpread(_objectSpread({}, state), {}, {
    isValidating: true
  }),
  REGISTER_USER: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    registered: action.payload,
    isProcessing: false,
    resetPassword: null,
    changePassword: null
  }),
  REGISTER_USER_REQUESTED: state => _objectSpread(_objectSpread({}, state), {}, {
    signup_warning_message: ''
  }),
  UPDATE_USED_TOKEN: state => _objectSpread(_objectSpread({}, state), {}, {
    registered: null,
    validated: false
  }),
  SEND_RESET_PASSWORD_EMAIL: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    resetPassword: action.payload,
    changePassword: null,
    isProcessing: false,
    isValidating: false
  }),
  UPDATE_SESSION_DATA: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), (0, _omit.default)(payload, ['logged_in', 'login_artist_id', 'menus', 'metada', 'authenticated', 'updated_date', 'authenticated', 'access_token', 'logout'])),
  COVER_PHOTOS_UPLOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    coverPhotosUpdating: true
  }),
  UPLOAD_COVER_PHOTOS: (state, {
    payload
  }) => _objectSpread(_objectSpread(_objectSpread({}, state), payload), {}, {
    coverPhotosUpdating: false
  }),
  PROFILE_PICTURE_UPDATING: state => _objectSpread(_objectSpread({}, state), {}, {
    profilePictureUpdating: true
  }),
  TEMP_PROFILE_PICTURE_UPDATING: state => _objectSpread(_objectSpread({}, state), {}, {
    tempProfilePictureUpdating: true
  }),
  TEMP_PROFILE_PICTURE_UPDATED: state => _objectSpread(_objectSpread({}, state), {}, {
    tempProfilePictureUpdating: false
  }),
  UPLOAD_PROFILE_PICTURE: (state, {
    payload
  }) => _objectSpread(_objectSpread(_objectSpread({}, state), payload), {}, {
    profilePictureUpdating: false
  }),
  SESSION_UPDATING: state => _objectSpread(_objectSpread({}, state), {}, {
    isProcessing: true
  }),
  SESSION_UPDATED: state => _objectSpread(_objectSpread({}, state), {}, {
    isProcessing: false
  }),
  ERROR_400: state => resetLoadingState(state),
  ERROR_401: () => _objectSpread(_objectSpread({}, INITIAL_STATE), {}, {
    logout: true
  }),
  EXCEPTION: state => resetLoadingState(state),
  SET_TO_CUSTOMIZE_DESIGN: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    to_personalize_design: payload
  }),
  CLEAR_TO_CUSTOMIZE_DESIGN: state => _objectSpread(_objectSpread({}, state), {}, {
    to_personalize_design: {}
  }),
  SET_PREVIOUS_PATH: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    prevPath: action.payload
  }),
  SET_DETAILS_ROUTE: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    detailsRoute: action.payload
  }),
  SET_DETAILS_PARAMS_ID: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    details_params_id: payload
  }),
  SET_CREATOR_DETAILS_URL_PARAMS: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    creator_details_url_params: payload
  }),
  SET_PASSWORD_PROCESSING: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    isSetPasswordProcessing: payload
  }),
  DISABLE_POST_VERIFICATION_DISPATCHES: state => _objectSpread(_objectSpread({}, state), {}, {
    isTriggerPostVerificationDispatches: false
  }),
  SET_DESIGNER_PHASE: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    designer_phase: payload
  }),
  LIKES_UPDATING: state => _objectSpread(_objectSpread({}, state), {}, {
    isProccessingLikes: true
  }),
  LIKES_UPDATED: state => _objectSpread(_objectSpread({}, state), {}, {
    isProccessingLikes: false
  }),
  SAVING_DESIGN_CHANGES: state => _objectSpread(_objectSpread({}, state), {}, {
    isSavingDesignChanges: true
  }),
  SAVING_DESIGN_FINISHED: state => _objectSpread(_objectSpread({}, state), {}, {
    isSavingDesignChanges: false
  }),
  SHOW_FEEDBACK_DIALOG: state => _objectSpread(_objectSpread({}, state), {}, {
    isFeedbackDialogVisible: true
  }),
  HIDE_FEEDBACK_DIALOG: state => _objectSpread(_objectSpread({}, state), {}, {
    isFeedbackDialogVisible: false
  }),
  SEND_FEEDBACK_REQUESTED: state => _objectSpread(_objectSpread({}, state), {}, {
    isFeedbackLoading: true
  }),
  FEEDBACK_LOADING_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isFeedbackLoading: false
  }),
  FETCHING_CREATOR_DETAILS_ON_CLICK_LOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    fetchingCreatorDetailsLoading: true
  }),
  FETCHING_CREATOR_DETAILS_ON_CLICK_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    fetchingCreatorDetailsLoading: false
  }),
  FETCHING_PRODUCT_RELATED_DATA_ON_CLICK_LOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    fetchingProductRelatedDataLoading: true
  }),
  FETCHING_PRODUCT_RELATED_DATA_ON_CLICK_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    fetchingProductRelatedDataLoading: false
  }),
  SUBSCRIBE_REQUESTED: state => _objectSpread(_objectSpread({}, state), {}, {
    subscription: {
      response: ''
    }
  }),
  SUBSCRIBE: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    subscription: payload
  }),
  SUBSCRIBE_LOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubscribeLoading: true
  }),
  SUBSCRIBE_LOADING_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isSubscribeLoading: false
  }),
  RESET_SUBSCRIBE: state => _objectSpread(_objectSpread({}, state), {}, {
    subscription: {
      response: ''
    }
  }),
  SET_SIGNUP_WARNING_MESSAGE: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    signup_warning_message: payload
  }),
  SHOW_POST_VERIFICATION: state => _objectSpread(_objectSpread({}, state), {}, {
    isShowPostVerification: true
  }),
  DISABLE_POST_VERIFICATION: state => _objectSpread(_objectSpread({}, state), {}, {
    isShowPostVerification: false
  }),
  IS_CONNECT_TO_STORE_LOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    connecting_to_store_loading: true
  }),
  IS_CONNECT_TO_STORE_LOADING_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    connecting_to_store_loading: false
  })
};

var _default = (0, _createReducer.buildReducer)(INITIAL_STATE, actionHandlers);

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _reselect = require("reselect");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var _default = uniqueEntity => (0, _reselect.createSelector)(state => state.global.session, state => state.global.dialog, state => state.global.fullDialog, state => state.global.preview.details, state => state.global.preview.isVisible, state => state.global.error, state => state.global.toast, state => state[uniqueEntity], state => state.global.editor, state => state.global.shared, state => state.global.quicktip, (session, dialog, fullDialog, details, isVisible, error, toast, stateEntity, editor, shared, quicktip) => {
  const data = session.data,
        otherProps = _objectWithoutPropertiesLoose(session, ["data"]);

  return {
    [uniqueEntity]: stateEntity,
    session: _objectSpread(_objectSpread({}, data), otherProps),
    error: error.data,
    preview: {
      details,
      isVisible
    },
    dialog,
    fullDialog,
    toast,
    editor,
    shared,
    quicktip
  };
});

exports.default = _default;
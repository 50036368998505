"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  return {
    dialogClassName: 'dnaDialog__container',
    className: 'dnaDialog size-sm dnaDialog--newConfirmation postAuthNotificationDialog'
  };
}
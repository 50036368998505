"use strict";

exports.__esModule = true;
exports.default = Onmount;

var _actions = require("@pages/DesignEditor/actions");

function Onmount() {
  const _this$props = this.props,
        dispatch = _this$props.dispatch,
        session = _this$props.session,
        _this$props$dialog$da = _this$props.dialog.data,
        data = _this$props$dialog$da === void 0 ? {} : _this$props$dialog$da;
  const _data$threed_data = data.threed_data,
        threed_data = _data$threed_data === void 0 ? {} : _data$threed_data;
  const _threed_data$style_ty = threed_data.style_type_name,
        style_type_name = _threed_data$style_ty === void 0 ? '' : _threed_data$style_ty; // dispatch(GetElementDialogOnMountData({ style_type_name }))
}
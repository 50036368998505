"use strict";

exports.__esModule = true;
exports.default = _default;

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  const dialog = this.props.dialog;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  return {
    selectedSize: '',
    sizes: [],
    isSizeGuideShown: false,
    quantity: 1,
    errors: {}
  };
}
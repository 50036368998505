"use strict";

exports.__esModule = true;
exports.default = void 0;

var _createReducer = require("@23point5/core/controls/utils/createReducer");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const INITIAL_STATE = {
  contexts: {},
  previewValues: {},
  isPreviewVisible: false
};
const actionHandlers = {
  REGISTER_FORM_CONTEXT: (state, action) => {
    if (Array.isArray(action.payload)) {
      const contexts = action.payload.reduce((acc, {
        entityReducer,
        context
      }) => _objectSpread(_objectSpread({}, acc), {}, {
        [entityReducer]: context
      }), {});
      return _objectSpread(_objectSpread({}, state), {}, {
        contexts: _objectSpread(_objectSpread({}, state.contexts), contexts)
      });
    }

    const _action$payload = action.payload,
          entityReducer = _action$payload.entityReducer,
          context = _action$payload.context;
    return state[entityReducer] ? state : _objectSpread(_objectSpread({}, state), {}, {
      contexts: _objectSpread(_objectSpread({}, state.contexts), {}, {
        [entityReducer]: context
      })
    });
  },
  GET_PREVIEW_DETAILS: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    previewValues: action.payload
  }),
  SET_PREVIEW_VISIBLE: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    isPreviewVisible: action.payload
  })
};

var _default = (0, _createReducer.buildReducer)(INITIAL_STATE, actionHandlers);

exports.default = _default;
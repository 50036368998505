"use strict";

exports.__esModule = true;
exports.default = void 0;

var _useUpload = _interopRequireDefault(require("@common/components/FineUploaderHooks/useUpload"));

var _react = require("react");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = args => {
  const _args$uploaderProps = args.uploaderProps,
        uploaderProps = _args$uploaderProps === void 0 ? {} : _args$uploaderProps,
        _args$callbacks = args.callbacks,
        callbacks = _args$callbacks === void 0 ? {} : _args$callbacks;

  const _useState = (0, _react.useState)({}),
        rawUploadResponses = _useState[0],
        setRawUploadResponses = _useState[1];

  const uploaderRef = (0, _react.useRef)(null);
  const uploaderHandlersAndState = (0, _useUpload.default)(_objectSpread(_objectSpread({}, uploaderProps), {}, {
    autoUpload: true,
    onFileUploaded: (id, _, response) => {
      setRawUploadResponses(prevUploads => _objectSpread(_objectSpread({}, prevUploads), {}, {
        [id]: response
      }));
    },
    onAllFilesUploaded: files => {
      const formatted = files.map(id => {
        const rawFile = rawUploadResponses[id];
        const _rawFile$file_path = rawFile.file_path,
              file_path = _rawFile$file_path === void 0 ? '' : _rawFile$file_path,
              _rawFile$thumbnail_pa = rawFile.thumbnail_path,
              thumbnail_path = _rawFile$thumbnail_pa === void 0 ? '' : _rawFile$thumbnail_pa,
              _rawFile$raw_file_pat = rawFile.raw_file_path,
              raw_file_path = _rawFile$raw_file_pat === void 0 ? '' : _rawFile$raw_file_pat,
              fileID = rawFile.id,
              height = rawFile.height,
              width = rawFile.width,
              _rawFile$thumbnail_ba = rawFile.thumbnail_background_photo_path,
              thumbnail_background_photo_path = _rawFile$thumbnail_ba === void 0 ? '' : _rawFile$thumbnail_ba;
        const fileType = file_path.split('.').pop();
        const is_low_res = Number(height) < 8000 || Number(width) < 8000;
        return rawFile && {
          key: fileID,
          name: fileID,
          texture: `${window.config.upload}/file?path=${file_path}`,
          originalTexture: `${window.config.upload}/file?path=${file_path}`,
          thumb: `${window.config.upload}/file?path=${thumbnail_path}`,
          raw: `${window.config.upload}/file?path=${raw_file_path}`,
          thumbnail_background_photo_path,
          type: fileType,
          is_uploaded: true,
          is_low_res
        };
      });

      if (callbacks.handleOnUploadSuccess) {
        callbacks.handleOnUploadSuccess(formatted);
      }

      setRawUploadResponses({});
      return true;
    },
    onError: args => {
      onUploadError(args);
    }
  }));
  return [uploaderHandlersAndState, uploaderRef];
};

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.REORDER_GRID = exports.SOCKET_REVISION_TBL_NODE = exports.SOCKET_MAIN_TBL_NODE = exports.NAVIGATE_TO_ROOT = exports.EXPORTING_NODE = exports.UPDATING_NODE = exports.CLEAR_NODE = exports.EXPORT_CSV_NODE = exports.DELETE_NODE = exports.SAVE_NODE = exports.UPDATE_NODE = exports.CREATE_NODE = exports.REMOVE_IMAGE = exports.UPLOAD_IMAGE = exports.GET_NODE_GRID = exports.GET_PREVIEW_DETAILS = exports.GET_MULTIPLE_NODE_DETAILS = exports.GET_NODE_DETAILS = exports.GET_NODE_FILTER = void 0;
const GET_NODE_FILTER = 'GET_NODE_FILTER';
exports.GET_NODE_FILTER = GET_NODE_FILTER;
const GET_NODE_DETAILS = 'GET_NODE_DETAILS';
exports.GET_NODE_DETAILS = GET_NODE_DETAILS;
const GET_MULTIPLE_NODE_DETAILS = 'GET_MULTIPLE_NODE_DETAILS';
exports.GET_MULTIPLE_NODE_DETAILS = GET_MULTIPLE_NODE_DETAILS;
const GET_PREVIEW_DETAILS = 'GET_PREVIEW_DETAILS';
exports.GET_PREVIEW_DETAILS = GET_PREVIEW_DETAILS;
const GET_NODE_GRID = 'GET_NODE_GRID';
exports.GET_NODE_GRID = GET_NODE_GRID;
const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
exports.UPLOAD_IMAGE = UPLOAD_IMAGE;
const REMOVE_IMAGE = 'REMOVE_IMAGE';
exports.REMOVE_IMAGE = REMOVE_IMAGE;
const CREATE_NODE = 'CREATE_NODE';
exports.CREATE_NODE = CREATE_NODE;
const UPDATE_NODE = 'UPDATE_NODE';
exports.UPDATE_NODE = UPDATE_NODE;
const SAVE_NODE = 'SAVE_NODE';
exports.SAVE_NODE = SAVE_NODE;
const DELETE_NODE = 'DELETE_NODE';
exports.DELETE_NODE = DELETE_NODE;
const EXPORT_CSV_NODE = 'EXPORT_CSV_NODE';
exports.EXPORT_CSV_NODE = EXPORT_CSV_NODE;
const CLEAR_NODE = 'CLEAR_NODE';
exports.CLEAR_NODE = CLEAR_NODE;
const UPDATING_NODE = 'UPDATING_NODE';
exports.UPDATING_NODE = UPDATING_NODE;
const EXPORTING_NODE = 'EXPORTING_NODE';
exports.EXPORTING_NODE = EXPORTING_NODE;
const NAVIGATE_TO_ROOT = 'NAVIGATE_TO_ROOT';
exports.NAVIGATE_TO_ROOT = NAVIGATE_TO_ROOT;
const SOCKET_MAIN_TBL_NODE = 'SOCKET_MAIN_TBL_NODE';
exports.SOCKET_MAIN_TBL_NODE = SOCKET_MAIN_TBL_NODE;
const SOCKET_REVISION_TBL_NODE = 'SOCKET_REVISION_TBL_NODE';
exports.SOCKET_REVISION_TBL_NODE = SOCKET_REVISION_TBL_NODE;
const REORDER_GRID = 'REORDER_GRID';
exports.REORDER_GRID = REORDER_GRID;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@App/actions");

var _tools = require("@23point5/core/controls/utils/tools");

const EventHandlers = {
  handleYes() {
    const _this$props = this.props,
          dispatch = _this$props.dispatch,
          _this$props$dialog = _this$props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog;

    const _ref = dialog.data || {},
          _ref$onConfirm = _ref.onConfirm,
          onConfirm = _ref$onConfirm === void 0 ? () => {} : _ref$onConfirm;

    const _dialog$onProceedCall = dialog.onProceedCallback,
          onProceedCallback = _dialog$onProceedCall === void 0 ? () => {} : _dialog$onProceedCall;
    onProceedCallback({
      callback: () => {
        onConfirm();
        this.handleHide();
      }
    });
  },

  handleNo() {
    const _this$props2 = this.props,
          dispatch = _this$props2.dispatch,
          _this$props2$dialog = _this$props2.dialog,
          dialog = _this$props2$dialog === void 0 ? {} : _this$props2$dialog;

    const _ref2 = dialog.data || {},
          _ref2$onCancel = _ref2.onCancel,
          onCancel = _ref2$onCancel === void 0 ? () => {} : _ref2$onCancel;

    onCancel();
    this.handleHide();
  }

};
var _default = EventHandlers;
exports.default = _default;
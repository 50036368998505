"use strict";

exports.__esModule = true;
exports.default = _default;

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
  NOTE: You can use props here to initialize state
*/
const autoGeneratedProductName = (args = {}) => {
  const isCustomize = args.isCustomize,
        isCopy = args.isCopy;

  if (isCopy) {
    return `Copy ${(0, _moment.default)().format('MM-DD-YYYY-h-mm-ss')}`;
  }

  return !isCustomize ? `Design ${(0, _moment.default)().format('MM-DD-YYYY-h-mm-ss')}` : `Customized ${(0, _moment.default)().format('MM-DD-YYYY-h-mm-ss')}`;
};

function _default() {
  const dialog = this.props.dialog;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  const _data$product_data = data.product_data,
        product_data = _data$product_data === void 0 ? {} : _data$product_data,
        _data$isSaveAsCopy = data.isSaveAsCopy,
        isSaveAsCopy = _data$isSaveAsCopy === void 0 ? false : _data$isSaveAsCopy;
  const copy_product_name = autoGeneratedProductName({
    isCustomize: product_data.is_customize || product_data.isCustomize,
    isCopy: true
  });
  return {
    fieldValues: {
      product_name: isSaveAsCopy ? copy_product_name : product_data.product_name
    },
    errors: {}
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _DnaButtonGroup = _interopRequireDefault(require("@23point5/controls/lib/wrappers/DnaButtonGroup"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ActionButtons() {
  const action = this.action;
  const _this$props$dialog = this.props.dialog,
        isUpdating = _this$props$dialog.isUpdating,
        _this$props$dialog$da = _this$props$dialog.data,
        data = _this$props$dialog$da === void 0 ? {} : _this$props$dialog$da;
  const _data$threed_data = data.threed_data,
        threed_data = _data$threed_data === void 0 ? {} : _data$threed_data,
        isSaveAsCopy = data.isSaveAsCopy;
  const _this$EventHandlers = this.EventHandlers,
        handleSave = _this$EventHandlers.handleSave,
        handleCancel = _this$EventHandlers.handleCancel,
        handleSaveAsCopy = _this$EventHandlers.handleSaveAsCopy;
  const actions = [];
  return null;
}

var _default = ActionButtons;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _tools = require("@23point5/core/controls/utils/tools");

const nextSelector = 'button:not([disabled]).paddle.right-paddle';
const prevSelector = 'button:not([disabled]).paddle.left-paddle';
const customizeSelector = 'button:not([data-for="productDetails"]).cta-md.customize.w-button.w-icon-cta';
var _default = {
  DETAILS_START: [{
    title: 'Size guide',
    text: 'Click here to view the table for our size guide which contains a weight and height table to help you find the appropriate size based on your chosen physique.',
    selector: '.size-guide-link-btn',
    position: 'right',
    adjustLeft: -25,
    onClose: showQuickTip => {
      showQuickTip('');
      (0, _tools.debouncer)(() => showQuickTip('DETAILS_MORE'));
    }
  }],
  DETAILS_MORE: [{
    title: 'More Details',
    text: 'Click here to know more about the fabric used on our clothing, delivery details and the product\'s return and exchange policy.',
    selector: 'button[data-for="productDetails"].cta-md.customize.w-button.w-icon-cta',
    position: 'left',
    containerHeight: 145,
    onClose: showQuickTip => {
      showQuickTip('');
      (0, _tools.debouncer)(() => showQuickTip('DETAILS_AVATAR'));
    }
  }],
  DETAILS_AVATAR: [{
    title: 'Changing avatars',
    text: 'View the design on our different avatars; on Neo, Trinity and Sky!',
    selector: '.avatar-wrapper',
    position: 'top',
    adjustTop: -5,
    containerHeight: 145,
    onClose: showQuickTip => {
      showQuickTip('');
      if (document.querySelector(prevSelector)) (0, _tools.debouncer)(() => showQuickTip('DETAILS_PREV'));else if (document.querySelector(nextSelector)) (0, _tools.debouncer)(() => showQuickTip('DETAILS_NEXT'));else if (document.querySelector(customizeSelector)) (0, _tools.debouncer)(() => showQuickTip('DETAILS_CUSTOMIZE'));
    }
  }],
  DETAILS_PREV: [{
    title: 'Previous item on the list',
    text: 'View the previous item on the list.',
    selector: 'button:not([disabled]).paddle.left-paddle',
    position: 'right',
    onClose: showQuickTip => {
      showQuickTip('');
      if (document.querySelector(nextSelector)) (0, _tools.debouncer)(() => showQuickTip('DETAILS_NEXT'));else if (document.querySelector(customizeSelector)) (0, _tools.debouncer)(() => showQuickTip('DETAILS_CUSTOMIZE'));
    }
  }],
  DETAILS_NEXT: [{
    title: 'Next on the list',
    text: 'View the next item on the list.',
    selector: 'button:not([disabled]).paddle.right-paddle',
    position: 'left',
    onClose: showQuickTip => {
      showQuickTip('');
      if (document.querySelector(customizeSelector)) (0, _tools.debouncer)(() => showQuickTip('DETAILS_CUSTOMIZE'));
    }
  }],
  DETAILS_CUSTOMIZE: [{
    title: 'Customizing products',
    text: 'Customize this product. Make it more personalized!',
    selector: 'button:not([data-for="productDetails"]).cta-md.customize.w-button.w-icon-cta',
    position: 'left',
    adjustLeft: 5,
    onClose: showQuickTip => {
      showQuickTip('');
      (0, _tools.debouncer)(() => showQuickTip('DETAILS_AVATAR'));
    }
  }]
};
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _createReducer = require("@23point5/core/controls/utils/createReducer");

const INITIAL_STATE = {};
const actionHandlers = {
  SOCKET: (state, {
    id: message_id,
    app_id,
    session_id
  }) => {
    if (socket && socket.write) {
      socket.write({
        event: 'messageAck',
        message_id,
        app_id,
        session_id
      });
    }

    return state;
  }
};

var _default = (0, _createReducer.buildReducer)(INITIAL_STATE, actionHandlers);

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _last = _interopRequireDefault(require("lodash/last"));

var _createReducer = require("@23point5/core/controls/utils/createReducer");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const INITIAL_STATE = {
  raw: [],
  paging_list: [],
  previous_node: {},
  data: {},
  isUpdating: false,
  isVisible: false,
  hasChanged: false
};
const actionHandlers = {
  SET_TAB_INDEX: (state, {
    payload
  }) => {
    const newRaw = state.raw.map(item => _objectSpread(_objectSpread({}, item), {}, {
      tab_index: item.active ? payload : item.tab_index
    }));
    return _objectSpread(_objectSpread({}, state), {}, {
      raw: newRaw
    });
  },
  GET_PREVIOUS_NODE: (state, {
    payload
  }) => {
    const isFound = state.raw.find(i => i.id === payload.id);
    let previousNode = {};

    if (isFound) {
      previousNode = _objectSpread(_objectSpread({}, isFound), {}, {
        goBack: true
      });
    }

    delete previousNode.active;
    delete previousNode.form_data;
    return _objectSpread(_objectSpread({}, state), {}, {
      previous_node: previousNode
    });
  },
  ADD_PAGING_LIST: (state, {
    payload
  }) => {
    const oldObject = state.paging_list.find(f => f.parent === payload.parent && f.type === payload.type);
    const oldList = oldObject ? oldObject.data : [];
    const newDataList = payload.data.map(e => ({
      id: e.id,
      name: e.actual_visit_date ? formatDate(e.actual_visit_date, 'lll') : e.name || `${e.first_name} ${e.last_name}`,
      //eslint-disable-line
      route: e.route || `/${payload.uniqueEntity}/${e.id}${payload.routeExt || ''}`,
      type: payload.type,
      parent: payload.parent
    }));

    const newList = _objectSpread(_objectSpread({}, payload), {}, {
      data: payload.start === 0 ? newDataList : [...oldList, ...newDataList]
    }); //eslint-disable-line


    const paging_list = [...state.paging_list].filter(f => !(f.parent === payload.parent && f.type === payload.type));
    paging_list.push(newList);
    return _objectSpread(_objectSpread({}, state), {}, {
      paging_list
    });
  },
  SET_ACTIVE_FORM: (state, {
    payload
  }) => {
    const isFound = state.raw.find(i => i.id === payload.id);

    if (isFound) {
      const newRaw = state.raw.map(item => _objectSpread(_objectSpread({}, item), {}, {
        name: item.id === payload.id ? payload.name : item.name,
        active: item.id === payload.id,
        form_data: item.id === payload.id ? _objectSpread({}, payload.form_data) : item.form_data
      }));
      return _objectSpread(_objectSpread({}, state), {}, {
        raw: newRaw
      });
    }

    const newRaw = state.raw.map(item => _objectSpread(_objectSpread({}, item), {}, {
      active: false
    }));
    newRaw.push(_objectSpread(_objectSpread({}, payload), {}, {
      active: true,
      parent: payload.parent
    }));
    return _objectSpread(_objectSpread({}, state), {}, {
      raw: newRaw,
      data: _objectSpread(_objectSpread({}, state.data), {}, {
        parent_node_id: payload.parent
      })
    });
  },
  GET_MULTIPLE_NODE_DETAILS: (state, {
    payload
  }) => {
    const lastForm = (0, _last.default)(payload);
    const payloadRaw = payload.reduce((acc, curr) => {
      const parent = ((0, _last.default)(acc) || {}).id;
      const active = curr.id === lastForm.id;
      return [...acc, _objectSpread(_objectSpread({}, curr), {}, {
        parent,
        active
      })];
    }, []);
    const notInOldRaw = payloadRaw.filter(e => !state.raw.map(s => s.id).includes(e.id));
    const newRaw = state.raw.map(item => {
      const foundItem = payloadRaw.find(p => p.id === item.id);

      if (foundItem) {
        return foundItem;
      }

      return _objectSpread(_objectSpread({}, item), {}, {
        active: false
      });
    }).concat(notInOldRaw);
    return _objectSpread(_objectSpread({}, state), {}, {
      raw: newRaw,
      data: _objectSpread(_objectSpread({}, state.data), {}, {
        parent_node_id: undefined
      })
    });
  },
  ADD_NAVIGATION_NODE: (state, {
    payload
  }) => {
    const found = state.raw.find(item => item.id === payload.id);

    if (!found) {
      const newRaw = payload.parent ? state.raw.map(item => _objectSpread(_objectSpread({}, item), {}, {
        active: false
      })) : [];
      newRaw.push(_objectSpread(_objectSpread({}, payload), {}, {
        active: true
      }));
      return _objectSpread(_objectSpread({}, state), {}, {
        raw: newRaw
      });
    }

    const updatePlain = state.raw.map(item => _objectSpread(_objectSpread({}, item), {}, {
      active: !!(found.id === item.id),
      tab_index: found.id === item.id ? 0 : item.tab_index,
      name: found.id === item.id ? payload.name : item.name,
      form_data: found.id === item.id ? payload.form_data : item.form_data
    }));
    return _objectSpread(_objectSpread({}, state), {}, {
      raw: updatePlain
    });
  },
  UPDATE_NAVIGATION_NODE: (state, {
    payload
  }) => {
    const updatePlain = state.raw.map(item => _objectSpread(_objectSpread({}, item), {}, {
      name: payload.id === item.id ? payload.name : item.name,
      form_data: payload.id === item.id && payload.form_data ? payload.form_data : item.form_data
    }));
    return _objectSpread(_objectSpread({}, state), {}, {
      raw: updatePlain
    });
  },
  DELETE_NAVIGATION_NODE: (state, {
    payload
  }) => {
    const updatePlain = state.raw.filter(item => item.id !== payload.id);
    return _objectSpread(_objectSpread({}, state), {}, {
      raw: updatePlain
    });
  },
  SHOW_FULL_DIALOG: (state, action) => _objectSpread(_objectSpread({}, action.payload ? state : INITIAL_STATE), {}, {
    isVisible: action.payload,
    hasChanged: action.payload && state.hasChanged
  }),
  SET_FULL_DIALOG_DATA: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    data: _objectSpread(_objectSpread({}, state.data), payload)
  }),
  FORM_CHANGED: (state, action) => _objectSpread(_objectSpread({}, state), {}, {
    hasChanged: action.payload
  }),
  CLEAR_NAVI: state => _objectSpread(_objectSpread({}, state), {}, {
    raw: []
  }),
  CLEAR_DIALOGS: state => _objectSpread(_objectSpread({}, state), {}, {
    raw: []
  }),
  FORM_UPDATING: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdating: true,
    hasChanged: false
  }),
  FORM_UPDATED: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdating: false
  }),
  ERROR_401: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdating: false
  }),
  ERROR_400: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdating: false
  }),
  EXCEPTION: state => _objectSpread(_objectSpread({}, state), {}, {
    isUpdating: false
  }),
  '@@router/LOCATION_CHANGE': (state, action) => {
    const routes = action.payload.location.pathname.split('/').filter(Boolean);

    if (routes.length <= 2) {
      return INITIAL_STATE;
    }

    return state;
  }
};

var _default = (0, _createReducer.buildReducer)(INITIAL_STATE, actionHandlers);

exports.default = _default;
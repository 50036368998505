"use strict";

exports.__esModule = true;
exports.default = void 0;

function Title() {
  const isProceed = this.state.isProceed;
  return !isProceed ? 'Creator Confirmation' : 'Be a Creator';
}

var _default = Title;
exports.default = _default;
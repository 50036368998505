"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@App/actions");

const EventHandlers = {
  handleSave(action) {
    if (this.isValid()) {
      const _this$props = this.props,
            dialog = _this$props.dialog,
            session = _this$props.session,
            dispatch = _this$props.dispatch;
      const fieldValues = this.state.fieldValues;
      this.action = action;
      dispatch((0, _actions.ChangeUserPassword)({
        id: dialog.data.id,
        password: fieldValues.password,
        metadata: {
          initiated_by: session.id
        },
        action
      }));
    }
  }

};
var _default = EventHandlers;
exports.default = _default;
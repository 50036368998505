"use strict";

exports.__esModule = true;
exports.default = UploadProgress;

var _react = _interopRequireDefault(require("react"));

var _store = _interopRequireDefault(require("@pages/DesignEditor/store"));

var _useUploadProgress2 = _interopRequireDefault(require("../../../Editor/RightPanel/v1/hooks/useUploadProgress"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function UploadProgress(props) {
  const uploader = props.uploader,
        id = props.id;

  const _useUploadProgress = (0, _useUploadProgress2.default)({
    id,
    uploader
  }),
        uploadState = _useUploadProgress[0];

  const percentage = Math.floor(uploadState.bytesUploaded / uploadState.totalSize * 100) || 0;
  const newFile = uploader.methods.getFile(id);
  if (!newFile) return null;

  _store.default.setState(state => {
    const prev = state.uploadedFiles;
    const file = prev.find(data => data.uid === id);

    if (!file) {
      return {
        uploadedFiles: [{
          uid: id,
          name: newFile.path,
          status: 'upoading',
          percent: percentage
        }, ...state.uploadedFiles]
      };
    }

    return {
      uploadedFiles: prev.map(data => data.uid === id ? _objectSpread(_objectSpread({}, data), {}, {
        percent: percentage,
        status: percentage < 100 ? 'uploading' : 'done'
      }) : data)
    };
  });

  return null;
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _validator = _interopRequireDefault(require("validator"));

var _tools = require("@common/utils/tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Validations() {
  const getTranslation = this.props.getTranslation;
  const _this$state = this.state,
        fieldValues = _this$state.fieldValues,
        profile_photo = _this$state.profile_photo,
        currentIndex = _this$state.currentIndex;
  let fields = [];

  switch (currentIndex) {
    case 0:
      fields = [{
        field: 'username',
        label: 'Username'
      }, {
        field: 'first_name',
        label: 'First Name'
      } // {
      //   field: 'last_name',
      //   label: 'Last Name'
      // },
      // {
      //   field: 'short_description',
      //   label: 'Short Bio'
      // }
      ];
      break;

    case 2:
      fields = [// {
        //   field: 'paypal_email',
        //   label: 'PayPal Email'
        // }
      ];
      break;

    default:
  }

  const errors = (0, _tools.regularValidation)(fieldValues, getTranslation, fields);

  if (currentIndex === 2) {
    if (fieldValues.paypal_email && !_validator.default.isEmail(fieldValues.paypal_email)) {
      errors.paypal_email = 'PayPal Email is invalid.';
    }
  } // if (currentIndex === 1 && profile_photo.file_path.length < 1) {
  //   errors.profile_photo = 'Photo is required'
  // }


  return {
    errors,
    isValid: Object.keys(errors).length === 0
  };
}

var _default = Validations;
exports.default = _default;
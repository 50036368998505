"use strict";

exports.__esModule = true;
exports.GET_RESOURCE_DETAILS = exports.GET_OPTIONS_LIST = exports.GET_USER_LIST = exports.GET_CLIENT_LIST = void 0;
const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
exports.GET_CLIENT_LIST = GET_CLIENT_LIST;
const GET_USER_LIST = 'GET_USER_LIST';
exports.GET_USER_LIST = GET_USER_LIST;
const GET_OPTIONS_LIST = 'GET_OPTIONS_LIST';
exports.GET_OPTIONS_LIST = GET_OPTIONS_LIST;
const GET_RESOURCE_DETAILS = 'GET_RESOURCE_DETAILS';
exports.GET_RESOURCE_DETAILS = GET_RESOURCE_DETAILS;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@App/actions");

var _pick = _interopRequireDefault(require("lodash/pick"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _styleDesciptions = _interopRequireDefault(require("@utils/styleDesciptions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleSizeChange(selectedSize = '') {
    this.setState({
      selectedSize
    });
  },

  handleQuantityChange(action) {
    const quantity = this.state.quantity;
    const quantity_copy = quantity;
    const newQuantity = {
      increment: quantity_copy + 1,
      decrement: quantity_copy - 1
    }[action] || quantity_copy;
    this.setState({
      quantity: newQuantity
    });
  },

  handleAdd() {
    const _this$props = this.props,
          _this$props$dialog = _this$props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog,
          _this$props$session = _this$props.session,
          session = _this$props$session === void 0 ? {} : _this$props$session;
    const _dialog$data = dialog.data,
          data = _dialog$data === void 0 ? {} : _dialog$data;
    const _data$product_data = data.product_data,
          product_data = _data$product_data === void 0 ? {} : _data$product_data,
          handleAddToCart = data.handleAddToCart;
    const _this$state = this.state,
          _this$state$sizes = _this$state.sizes,
          sizes = _this$state$sizes === void 0 ? [] : _this$state$sizes,
          _this$state$quantity = _this$state.quantity,
          quantity = _this$state$quantity === void 0 ? 1 : _this$state$quantity,
          selectedSize = _this$state.selectedSize;
    const product_size_codes = sizes.length ? sizes.map(size => size.code) : [];
    const size_codes = [...new Set(product_size_codes)];
    const _size_codes$ = size_codes[0],
          default_selected_size = _size_codes$ === void 0 ? '' : _size_codes$;
    const product_size = selectedSize || default_selected_size;

    const _ref = sizes.find(e => e.code === product_size) || {},
          _ref$code = _ref.code,
          size_code = _ref$code === void 0 ? '' : _ref$code;

    const pickedProductFields = ['id', 'product_name', 'pricing', 'file_path', 'thumbnail_path', 'artist', 'artist_name', 'status', 'is_customize', 'style_type_name'];

    const description = _styleDesciptions.default[product_data.style_type_name.toLowerCase()].content;

    handleAddToCart(_objectSpread(_objectSpread({}, (0, _pick.default)(product_data, pickedProductFields)), {}, {
      size: size_code,
      quantity,
      sizes,
      status: 'Pending Approval',
      description
    }));
    this.handleHide();
  },

  handleToggleSizeGuideVisibility() {
    this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      isSizeGuideShown: !prev.isSizeGuideShown
    }));
  },

  handleCancel() {
    this.handleHide();
  }

};
var _default = EventHandlers;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.AddNavigationNodegation = AddNavigationNodegation;
exports.NavigateToRoot = NavigateToRoot;
exports.GetPreviousNode = GetPreviousNode;
exports.SetTabIndex = SetTabIndex;
exports.SetDialogData = SetDialogData;
exports.SetFullDialogData = SetFullDialogData;
exports.ShowDialog = ShowDialog;
exports.ShowFullDialog = ShowFullDialog;
exports.ShowSuccess = ShowSuccess;
exports.FormUpdating = FormUpdating;
exports.FormUpdated = FormUpdated;
exports.DialogUpdating = DialogUpdating;
exports.DialogUpdated = DialogUpdated;
exports.HideDialog = HideDialog;
exports.ClearDialog = ClearDialog;
exports.ShowError = ShowError;
exports.ClearError = ClearError;
exports.FormChanged = FormChanged;
exports.RegisterFormContext = RegisterFormContext;
exports.SetPreviewVisible = SetPreviewVisible;
exports.ClearNavi = ClearNavi;
exports.ClearToasts = ClearToasts;
exports.QuickTip = QuickTip;

var _types = require("../types");

function AddNavigationNodegation(data) {
  return {
    type: _types.ADD_NAVIGATION_NODE,
    payload: data
  };
}

function NavigateToRoot(data) {
  return {
    type: `${_types.NAVIGATE_TO_ROOT}${_types.REQUESTED}`,
    payload: data
  };
}

function GetPreviousNode(data) {
  return {
    type: _types.GET_PREVIOUS_NODE,
    payload: data
  };
}

function SetTabIndex(data) {
  return {
    type: _types.SET_TAB_INDEX,
    payload: data
  };
}

function SetDialogData(data) {
  return {
    type: _types.SET_DIALOG_DATA,
    payload: data
  };
}

function SetFullDialogData(data) {
  return {
    type: _types.SET_FULL_DIALOG_DATA,
    payload: data
  };
}

function ShowDialog(data) {
  return {
    type: `${_types.SHOW_DIALOG}${_types.REQUESTED}`,
    payload: data
  };
}

function ShowFullDialog(data) {
  return {
    type: _types.SHOW_FULL_DIALOG,
    payload: data
  };
}

function ShowSuccess(data) {
  return {
    type: _types.SUCCESS_TOASTS,
    payload: data
  };
}

function FormUpdating() {
  return {
    type: _types.FORM_UPDATING
  };
}

function FormUpdated() {
  return {
    type: _types.FORM_UPDATED
  };
}

function DialogUpdating() {
  return {
    type: _types.DIALOG_UPDATING
  };
}

function DialogUpdated() {
  return {
    type: _types.DIALOG_UPDATED
  };
}

function HideDialog(data) {
  return {
    type: _types.HIDE_DIALOG,
    payload: data
  };
}

function ClearDialog() {
  return {
    type: _types.CLEAR_DIALOGS
  };
}

function ShowError(payload) {
  return {
    type: _types.ERROR_400,
    payload
  };
}

function ClearError() {
  return {
    type: _types.CLEAR_ERROR
  };
}

function FormChanged(changed = true) {
  return {
    type: _types.FORM_CHANGED,
    payload: changed
  };
}

function RegisterFormContext(data) {
  return {
    type: _types.REGISTER_FORM_CONTEXT,
    payload: data
  };
}

function SetPreviewVisible(data) {
  return {
    type: _types.SET_PREVIEW_VISIBLE,
    payload: data
  };
}

function ClearNavi() {
  return {
    type: _types.CLEAR_NAVI
  };
}

function ClearToasts() {
  return {
    type: _types.CLEAR_TOASTS
  };
}

function QuickTip(event) {
  return {
    type: _types.QUICK_TIP,
    payload: {
      /* Uncomment code below to enable product details quick tips */
      // quickTipEvent: event

      /* Remove code below after enabling code above*/
      quickTipEvent: ''
    }
  };
}
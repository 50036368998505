"use strict";

exports.__esModule = true;
exports.default = void 0;

var _validator = _interopRequireDefault(require("validator"));

var _tools = require("@common/utils/tools");

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function Validations(data = {}, required_fields = [], custom_label = {}, custom_validation = {}) {
  const checkEmpty = toCheck => {
    const cleanCheck = typeof toCheck === 'string' ? _validator.default.isEmpty(toCheck) : (0, _isEmpty.default)(toCheck);
    return cleanCheck;
  };

  const errors = Object.keys(data).reduce((error, key) => {
    // required fields logic
    // custom valiadation logic
    if (required_fields.includes(key) && checkEmpty(data[key])) {
      return _objectSpread(_objectSpread({}, error), {}, {
        [key]: `${(0, _tools.capitalizeFirstLetter)(custom_label[key] || key)} is required.`
      });
    }

    if (custom_validation[key]) {
      return _objectSpread(_objectSpread({}, error), {}, {
        [key]: custom_validation[key](data[key])
      });
    }

    return error;
  }, {});
  const retail = data.pricing.retail || "";

  if (!retail) {
    errors.retail = `Retail price is required.`;
  } else if (Number(retail || 0) < Number(data.base_price)) {
    errors.retail = `Invalid retail price.`;
  }

  const regexPattern = /^[a-zA-Z0-9\s-]+$/;

  if (!!data.product_name && !regexPattern.test(data.product_name)) {
    errors.product_name = 'Entered value is invalid.';
  }

  const trimmed_errors = Object.entries(errors).reduce((acc, [key, value]) => {
    if (!!value) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        [key]: value
      });
    }

    return acc;
  }, {});
  return {
    fieldErrors: trimmed_errors,
    isValid: (0, _isEmpty.default)(trimmed_errors)
  };
}

var _default = Validations;
exports.default = _default;
"use strict";

var _tools = require("@23point5/core/controls/utils/tools");

var _actions = require("@App/actions");

var _types = require("@App/types");

var _tools2 = require("@common/utils/tools");

var _axios = _interopRequireDefault(require("@utils/axios"));

var _concurrentUpload = _interopRequireDefault(require("@utils/concurrentUpload"));

var _multipleUpload = _interopRequireDefault(require("@utils/multipleUpload"));

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _fill = _interopRequireDefault(require("lodash/fill"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _effects = require("redux-saga/effects");

var _v = _interopRequireDefault(require("uuid/v4"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const FIELDS = ['method', 'index', 'index_value', 'collection', 'sort_column', 'sort_direction', 'fields', 'offset', 'limit', 'search', ':contains', ':eq', ':ne', ':gt', ':ge', ':lt', ':le'];

const shapedQueryPayload = payloadQuery => (0, _tools2.contains)(payloadQuery.exclude_status ? payloadQuery : (0, _tools2.setStatus)(payloadQuery), FIELDS);

function* EditorDeleteDesign({
  payload
}) {
  const config = {
    method: 'DELETE',
    url: `/product/${payload.id}/batch?artist_id=${payload.artist_id}`
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'EDITOR_DELETE_DESIGN');

  if (result.type === 'EDITOR_DELETE_DESIGN') {
    if (payload.callback) {
      payload.callback();
    }

    yield (0, _effects.put)({
      type: 'CLEAR_EDITOR_SAVED_DESIGN'
    });
  }

  yield (0, _effects.put)(result);
}

module.exports = function* DesignEditorSaga() {
  yield (0, _effects.takeLatest)(`EDITOR_DELETE_DESIGN${_types.REQUESTED}`, EditorDeleteDesign);
};
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@App/actions");

const EventHandlers = {
  handleYes(action) {
    const dispatch = this.props.dispatch;
    const dontShowAgainToggle = this.state.dontShowAgainToggle;
    this.action = action;

    if (dontShowAgainToggle) {
      dispatch((0, _actions.DisableLogoutPrompt)());
    }

    dispatch((0, _actions.LogoutUser)()); // close the queue

    socket.end(); // open the socket

    socket.open(); // remove event listener

    socket.removeAllListeners('reconnected');
    socket.isQueue = false;
  },

  handleDontShowToggle(value) {
    this.setState({
      dontShowAgainToggle: value
    });
  }

};
var _default = EventHandlers;
exports.default = _default;
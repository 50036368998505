"use strict";

exports.__esModule = true;
exports.default = _default;

var _tools = require("@common/utils/tools");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _default() {
  const dialog = this.props.dialog;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  const _data$isDirectFilup = data.isDirectFilup,
        isDirectFilup = _data$isDirectFilup === void 0 ? false : _data$isDirectFilup,
        _data$session_data = data.session_data,
        session_data = _data$session_data === void 0 ? {} : _data$session_data;
  const fieldValues = {
    id: '',
    username: '',
    first_name: '',
    last_name: '',
    short_description: '',
    paypal_email: ''
  };
  (0, _tools.copyObjectValues)(fieldValues, _objectSpread({}, session_data));
  const profile_photo = {
    file_path: '',
    state: 'EMPTY'
  };

  if (profile_photo.file_path) {
    profile_photo.state = 'LOADED';
  }

  return {
    fieldValues,
    profile_photo,
    currentIndex: 0,
    isProceed: isDirectFilup,
    errors: {}
  };
}
"use strict";

exports.__esModule = true;
exports.default = Onmount;

var _actions = require("@pages/DesignEditor/actions");

function Onmount() {
  const _this$props = this.props,
        dispatch = _this$props.dispatch,
        session = _this$props.session,
        _this$props$dialog$da = _this$props.dialog.data,
        data = _this$props$dialog$da === void 0 ? {} : _this$props$dialog$da;
  const _data$product_data = data.product_data,
        product_data = _data$product_data === void 0 ? {} : _data$product_data;
  const _product_data$style_t = product_data.style_type_name,
        style_type_name = _product_data$style_t === void 0 ? '' : _product_data$style_t,
        _product_data$style_i = product_data.style_id,
        style_id = _product_data$style_i === void 0 ? '' : _product_data$style_i;
  dispatch((0, _actions.CreateGetStyleSizes)({
    style_id,
    postDispatches: [result => {
      this.setState({
        sizes: result
      });
    }]
  }));
}
"use strict";

exports.__esModule = true;
exports.default = concurrentUpload;

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _set = _interopRequireDefault(require("lodash/set"));

var _useUpload = require("@23point5/core/controls/FileUpload/hooks/useUpload");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function concurrentUpload(payload) {
  const _payload$key = payload.key,
        key = _payload$key === void 0 ? '' : _payload$key,
        _payload$files = payload.files,
        files = _payload$files === void 0 ? [] : _payload$files,
        _payload$entity = payload.entity,
        entity = _payload$entity === void 0 ? '' : _payload$entity,
        _payload$entity_id = payload.entity_id,
        entity_id = _payload$entity_id === void 0 ? '' : _payload$entity_id,
        _payload$responseVali = payload.responseValidator,
        responseValidator = _payload$responseVali === void 0 ? (res = {}) => res.success && Object.keys(res).length > 1 : _payload$responseVali,
        restParams = _objectWithoutPropertiesLoose(payload, ["key", "files", "entity", "entity_id", "responseValidator"]);

  return new Promise((resolve, reject) => {
    const uploader = (0, _useUpload.createUploader)({
      allowedExtensions: [],
      customHeaders: {},
      customParams: _objectSpread({
        entity,
        entity_id,
        key,
        concurrent: true
      }, restParams),
      uploaderParams: coreParams => {
        const updateParams = (0, _cloneDeep.default)(coreParams);
        (0, _set.default)(updateParams, 'options.chunking.partSize', 5000000);
        (0, _set.default)(updateParams, 'options.chunking.concurrent', {
          enabled: true
        });
        (0, _set.default)(updateParams, 'options.maxConnections', 5);
        (0, _set.default)(updateParams, 'options.chunking.success', {
          endpoint: `${window.config.upload}/file/upload/combine`
        }); // set(updateParams, 'options.mandatory', true)

        (0, _set.default)(updateParams, 'options.concurrent', true);
        (0, _set.default)(updateParams, 'options.request.endpoint', `${window.config.upload}/file/upload/entity`);
        return updateParams;
      }
    });
    const pendingResponses = {};
    uploader.on('uploadChunkSuccess', (fileId, _, response = {}) => {
      if (responseValidator(response)) {
        pendingResponses[fileId] = response;
      }
    });
    uploader.on('complete', (fileId, name, response) => {
      resolve(!responseValidator(response) ? pendingResponses[fileId] : response);
    });
    uploader.on('error', reject);
    uploader.methods.addFiles(files);
  });
}
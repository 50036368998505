"use strict";

exports.__esModule = true;
exports.default = mergeReducers;

var _createReducer = require("@23point5/core/controls/utils/createReducer");

var _reducer = _interopRequireDefault(require("@App/reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function mergeReducers(injectedReducers = {}, history) {
  return (0, _createReducer.createCombineReducers)(_reducer.default, injectedReducers, history);
}
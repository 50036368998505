"use strict";

exports.__esModule = true;
exports.default = _default;

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  const dialog = this.props.dialog;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  const _data$product_data = data.product_data,
        product_data = _data$product_data === void 0 ? {} : _data$product_data,
        _data$isSaveAsCopy = data.isSaveAsCopy,
        isSaveAsCopy = _data$isSaveAsCopy === void 0 ? false : _data$isSaveAsCopy;
  return {
    fieldValues: {},
    errors: {}
  };
}
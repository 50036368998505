"use strict";

exports.__esModule = true;
exports.default = void 0;

var _pick = _interopRequireDefault(require("lodash/pick"));

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _actions = require("@App/actions");

var _history = _interopRequireDefault(require("@23point5/core/controls/history"));

var _tools = require("@common/utils/tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleSave() {
    const _this$props = this.props,
          dispatch = _this$props.dispatch,
          session = _this$props.session,
          dialog = _this$props.dialog;
    const _dialog$data = dialog.data,
          _dialog$data$isRedire = _dialog$data.isRedirectToHome,
          isRedirectToHome = _dialog$data$isRedire === void 0 ? false : _dialog$data$isRedire,
          redirectToHome = _dialog$data.redirectToHome,
          redirectToProfile = _dialog$data.redirectToProfile;
    const fieldValues = this.state.fieldValues;

    if (this.isValid()) {
      dispatch((0, _actions.UpdateNode)(_objectSpread(_objectSpread({
        source: 'dialog',
        uniqueEntity: 'artist'
      }, fieldValues), {}, {
        id: session.id,
        is_customer: false,
        // customMessage: isRedirectToHome ? 'Successfully Updated Artist Information' : 'Successfully Updated to Artist',
        isShowToastAfter: false,
        postDispatches: [() => isRedirectToHome ? redirectToHome() : redirectToProfile()]
      })));
    }
  },

  handleTabChange(nextIndex) {
    const _this$state = this.state,
          currentIndex = _this$state.currentIndex,
          fieldValues = _this$state.fieldValues;

    if (nextIndex < currentIndex) {
      this.setState({
        currentIndex: nextIndex
      });
      return;
    }

    if (nextIndex > currentIndex && this.isValid()) {
      this.setState({
        currentIndex: nextIndex
      });
    }
  },

  handleNext() {
    const _this$state2 = this.state,
          currentIndex = _this$state2.currentIndex,
          fieldValues = _this$state2.fieldValues;

    if (this.isValid()) {
      this.setState({
        currentIndex: currentIndex + 1
      });
    }
  },

  handleOnDrop(files) {
    const _this$props2 = this.props,
          dispatch = _this$props2.dispatch,
          session = _this$props2.session;
    const errors = (0, _cloneDeep.default)(this.state.errors);
    delete errors.profile_photo;
    const allowedFileTypes = ['png', 'jpeg', 'jpg'];
    const isValid = (0, _tools.isFilesAllowed)(files, allowedFileTypes);

    if (!isValid) {
      dispatch((0, _actions.ShowError)({
        message: 'Uploaded file contain invalid file types.'
      }));
      return;
    }

    const file = files[0];
    this.setState(({
      profile_photo
    }) => ({
      profile_photo: _objectSpread(_objectSpread({}, profile_photo), {}, {
        isEmpty: false,
        state: 'LOADING'
      }),
      errors
    }));
    dispatch((0, _actions.UploadTempProfilePicture)({
      id: session.id,
      imageFile: file,
      callback: data => {
        const file_path = data.file_path;
        this.setState(({
          profile_photo
        }) => ({
          profile_photo: _objectSpread(_objectSpread({}, profile_photo), {}, {
            file_path,
            state: 'LOADED'
          })
        }), () => {
          dispatch((0, _actions.UpdateSessionData)({
            file_path
          }));
        });
      }
    }));
  },

  handleRemoveImage() {
    const _this$props3 = this.props,
          dispatch = _this$props3.dispatch,
          session = _this$props3.session;
    this.setState(({
      profile_photo
    }) => ({
      profile_photo: _objectSpread(_objectSpread({}, profile_photo), {}, {
        file_path: '',
        state: 'EMPTY'
      })
    }));
  }

};
var _default = EventHandlers;
exports.default = _default;
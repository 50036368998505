"use strict";

exports.__esModule = true;
exports.default = getMetadata;

const getOs = userAgent => {
  if (/windows/i.test(userAgent)) {
    return 'Windows';
  }

  if (/iphone/i.test(userAgent)) {
    return 'iOS';
  }

  if (/ipad/i.test(userAgent)) {
    return 'iOS';
  }

  if (/macintosh/i.test(userAgent)) {
    return 'Mac OS';
  }

  return '';
};

function getMetadata() {
  var _navigator$userAgentD;

  const browser = navigator.userAgent;
  const os = ((_navigator$userAgentD = navigator.userAgentData) === null || _navigator$userAgentD === void 0 ? void 0 : _navigator$userAgentD.platform) || getOs(browser);
  const isMobile = /Mobi/.test(browser);
  return {
    browser,
    os,
    platform: isMobile ? 'Mobile' : 'Web'
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _types = require("@App/types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = (config, type) => {
  const headers = config.headers || {};
  Object.assign(headers, {
    target: headers.target || 'API'
  });
  Object.assign(config, {
    headers,
    withCredentials: true,

    validateStatus(status) {
      return status >= 200 && status < 500;
    }

  });
  return (0, _axios.default)(config).then(result => {
    if (result.status >= 400) {
      result.data.status = result.status;
      return {
        type: result.status === 401 ? _types.ERROR_401 : _types.ERROR_400,
        payload: result.data,
        response: result
      };
    }

    return {
      type,
      payload: result.data,
      response: result
    };
  }).catch(err => ({
    type: _types.EXCEPTION,
    payload: {
      message: 'Oops! Something is wrong, Please check back!'
    }
  }));
};

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = useFileStatus;

var _react = require("react");

function useFileStatus(props) {
  const _useState = (0, _react.useState)(''),
        status = _useState[0],
        setStatus = _useState[1];

  const uploader = props.uploader,
        fileId = props.id;
  const statusEnum = uploader.qq.status;
  (0, _react.useEffect)(() => {
    uploader.on('statusChange', onStatusChanged);
    return () => {
      uploader.off('statusChange', onStatusChanged);
    };
  }, []);
  const handlers = {
    triggerPause: () => uploader.methods.pauseUpload(fileId),
    triggerResume: () => uploader.methods.continueUpload(fileId),
    triggerCancel: () => uploader.methods.cancel(fileId)
  };
  const state = {
    isPausable: status === statusEnum.PAUSED,
    isResumable: status === statusEnum.UPLOADING,
    isCancelable: [statusEnum.DELETE_FAILED, statusEnum.PAUSED, statusEnum.QUEUED, statusEnum.UPLOAD_RETRYING, statusEnum.SUBMITTED, statusEnum.UPLOADING, statusEnum.UPLOAD_FAILED].includes(status),
    status
  };
  return [state, handlers];

  function onStatusChanged(id, oldStatus, newStatus) {
    if (id === fileId) {
      setStatus(newStatus);
    }
  }
}
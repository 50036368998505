"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@App/actions");

const EventHandlers = {
  handleYes() {
    const resolve = this.props.dialog.data.resolve;
    this.props.dispatch((0, _actions.HideDialog)());
    resolve(true);
  },

  handleHide() {
    const resolve = this.props.dialog.data.resolve;
    resolve(false);
    this.props.dispatch((0, _actions.HideDialog)());
  }

};
var _default = EventHandlers;
exports.default = _default;
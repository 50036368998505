"use strict";

exports.__esModule = true;
exports.default = void 0;

var _validator = _interopRequireDefault(require("validator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Validations() {
  const fieldValues = this.state.fieldValues;
  const errors = {};

  if (_validator.default.isEmpty(fieldValues.password.trim())) {
    errors.password = 'Password required';
  }

  if (!_validator.default.equals(fieldValues.password2.trim(), fieldValues.password.trim()) && !errors.password) {
    errors.password2 = 'Password do not match';
  }

  if (_validator.default.isEmpty(fieldValues.password2.trim())) {
    errors.password2 = 'Confirm your password';
  }

  if (!errors.password && !_validator.default.matches(fieldValues.password.trim(), '(?=.*[0-9])(?=.*[A-Z]).{6,}$')) {
    errors.password = 'Password help info';
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0
  };
}

var _default = Validations;
exports.default = _default;
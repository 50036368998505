"use strict";

exports.__esModule = true;
exports.default = void 0;

var _tools = require("@23point5/core/controls/utils/tools");

var _default = {
  SHOP_LIMITED: [{
    title: 'Limited Products',
    text: 'Products marked as limited are only available up to a certain number of orders. Avail one now!',
    selector: '.limited-edition-warning',
    position: 'bottom',
    adjustTop: '4.2rem',
    adjustLeft: () => -(document.querySelector('.limited-edition-warning').getBoundingClientRect().width / 2),
    onClose: showQuickTip => {
      showQuickTip('');
      (0, _tools.debouncer)(() => showQuickTip('SHOP_CUSTOMIZE'));
    }
  }],
  SHOP_CUSTOMIZE: [{
    title: 'Customizability',
    text: 'Products marked with this label can be customized, allowing your own personalization to an already great design!',
    selector: '.customize-btn',
    position: 'bottom',
    adjustTop: '4.2rem',
    adjustLeft: '-1rem',
    onClose: showQuickTip => {
      showQuickTip('');
    }
  }]
};
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@pages/DesignEditor/actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleYes() {
    const _this$props = this.props,
          dispatch = _this$props.dispatch,
          _this$props$dialog = _this$props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog;
    const _dialog$data = dialog.data,
          data = _dialog$data === void 0 ? {} : _dialog$data;
    const product_data = data.product_data;

    const payload = _objectSpread(_objectSpread({}, data), {}, {
      product_data: _objectSpread(_objectSpread({}, product_data), {}, {
        status: 'New'
      }),
      visibility: 'Private'
    });

    dispatch((0, _actions.CreateUpdateProductVisibility)(payload));
  },

  handleNo() {
    this.handleHide();
  }

};
var _default = EventHandlers;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = props => {
  const _useState = (0, _react.useState)({
    value: 0,
    phase: ''
  }),
        progressDetails = _useState[0],
        setProgressDetails = _useState[1];

  const progressPhase = progressDetails.phase;
  (0, _react.useEffect)(() => {
    if (progressPhase === 'Finished') {
      setProgressDetails({
        value: 0,
        phase: ''
      });
    }
  }, [progressPhase]);

  const handleProgressCallback = (progress = {}) => {
    setProgressDetails(progress);
  };

  return [progressDetails, handleProgressCallback];
};

exports.default = _default;
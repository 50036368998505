"use strict";

exports.__esModule = true;
exports.default = void 0;

var _redux = require("redux");

var _dialog = _interopRequireDefault(require("./dialog"));

var _form = _interopRequireDefault(require("./form"));

var _error = _interopRequireDefault(require("./error"));

var _fullDialog = _interopRequireDefault(require("./fullDialog"));

var _session = _interopRequireDefault(require("./session"));

var _toast = _interopRequireDefault(require("./toast"));

var _socket = _interopRequireDefault(require("./socket"));

var _preview = _interopRequireDefault(require("./preview"));

var _editor = _interopRequireDefault(require("./editor"));

var _shared = _interopRequireDefault(require("./shared"));

var _quicktip = _interopRequireDefault(require("./quicktip"));

var _sessionCart = _interopRequireDefault(require("./sessionCart"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = (0, _redux.combineReducers)({
  dialog: _dialog.default,
  form: _form.default,
  error: _error.default,
  fullDialog: _fullDialog.default,
  session: _session.default,
  toast: _toast.default,
  socket: _socket.default,
  preview: _preview.default,
  editor: _editor.default,
  shared: _shared.default,
  quicktip: _quicktip.default,
  sessionCart: _sessionCart.default
});

exports.default = _default;
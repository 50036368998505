"use strict";

var _types = require("@App/types");

// eslint-disable-line
module.exports = {
  CreateGetCreateStylesList(data) {
    return {
      type: `CREATE_GET_EDITOR_STYLES_LIST${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetDesignEditorStyleComponents(data) {
    return {
      type: `CREATE_GET_EDITOR_STYLE_COMPONENTS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetEditorPatterns(data) {
    return {
      type: `CREATE_GET_EDITOR_PATTERNS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateSaveDesignChanges(data) {
    return {
      type: `CREATE_SAVE_DESIGN_CHANGES${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetArtistProductsCount(data) {
    return {
      type: `CREATE_GET_ALL_ARTIST_PRODUCTS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateProductModifiedFlag(data) {
    return {
      type: `CREATE_PRODUCT_MODIFIED_FLAG`,
      payload: data
    };
  },

  CreatePublishProduct(data) {
    return {
      type: `CREATE_PUBLISH_PRODUCT${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetProductCategory() {
    return {
      type: `CREATE_GET_PRODUCT_CATEGORY${_types.REQUESTED}`
    };
  },

  CreatePublishDialogOnmount(data) {
    return {
      type: `CREATE_PUBLISH_DIALOG_ONMOUNT${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetCollections(data) {
    return {
      type: `CREATE_GET_COLLECTIONS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetTags() {
    return {
      type: `CREATE_GET_TAGS${_types.REQUESTED}`
    };
  },

  CreateSearchTags(data) {
    return {
      type: `CREATE_SEARCH_TAGS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetProductDetails(data) {
    return {
      type: `CREATE_GET_PRODUCT_DETAILS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetProductByParams(data) {
    return {
      type: `CREATE_GET_PRODUCT_BY_PARAMS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateCheckProductNameExist(data) {
    return {
      type: `CREATE_CHECK_PRODUCT_NAME_EXIST${_types.REQUESTED}`,
      payload: data
    };
  },

  CreatePublishDialogOnmount(data) {
    return {
      type: `CREATE_PUBLISH_DIALOG_ONMOUNT${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateCancelApprovalSubmission(data) {
    return {
      type: `CREATE_CANCEL_APPROVAL_SUBMISSION${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetReadOnlyData(data) {
    return {
      type: `CREATE_GET_READY_ONLY_DATA${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateUpdateProductVisibility(data) {
    return {
      type: `CREATE_UPDATE_PRODUCT_VISIBILITY${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateReviseDesign(data) {
    return {
      type: `CREATE_REVISE_DESIGN${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetProductTemplates(data) {
    return {
      type: `CREATE_GET_PRODUCT_TEMPLATES${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateProductSetViewMode(data) {
    return {
      type: `CREATE_PRODUCT_SET_VIEW_MODE`,
      payload: data
    };
  },

  CreateGetStockPhotos(data) {
    return {
      type: `CREATE_GET_STOCK_PHOTOS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetDynamicImages(data) {
    return {
      type: `CREATE_GET_DYNAMIC_IMAGES${_types.REQUESTED}`,
      payload: data
    };
  },

  GetElementDialogOnMountData(data) {
    return {
      type: `GET_ELEMENT_DIALOG_ONMOUNT_DATA${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetProductCategories(data) {
    return {
      type: `CREATE_GET_PRODUCT_CATEGORIES${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetStyleSizes(data) {
    return {
      type: `CREATE_GET_STYLE_SIZES${_types.REQUESTED}`,
      payload: data
    };
  },

  ProcessArtistLibraryNewData(data) {
    return {
      type: `PROCESS_ARTIST_LIBRARY_NEW_DATA${_types.REQUESTED}`,
      payload: data
    };
  },

  ProcessArtistLibraryUploadApply(data) {
    return {
      type: `PROCESS_ARTIST_LIBRARY_UPLOAD_APPLY${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetLibraryImages(data) {
    return {
      type: `CREATE_GET_LIBRARY_IMAGES${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetFolders(data) {
    return {
      type: `CREATE_GET_FOLDERS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetRecentFolders(data) {
    return {
      type: `CREATE_GET_RECENT_FOLDERS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateGetFolderImages(data) {
    return {
      type: `CREATE_GET_FOLDER_IMAGES${_types.REQUESTED}`,
      payload: data
    };
  },

  DeleteLibraryImages(data) {
    return {
      type: `DELETE_LIBRARY_IMAGES${_types.REQUESTED}`,
      payload: data
    };
  },

  RemoveImageFromFolder(data) {
    return {
      type: `REMOVE_IMAGE_FROM_FOLDER${_types.REQUESTED}`,
      payload: data
    };
  },

  RenameFile(data) {
    return {
      type: `RENAME_FILE${_types.REQUESTED}`,
      payload: data
    };
  },

  MoveToFolder(data) {
    return {
      type: `MOVE_TO_FOLDER${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateNewFolder(data) {
    return {
      type: `CREATE_NEW_FOLDER${_types.REQUESTED}`,
      payload: data
    };
  },

  EditorSaveDesignChanges(data) {
    return {
      type: `EDITOR_SAVE_DESIGN_CHANGES${_types.REQUESTED}`,
      payload: data
    };
  },

  SetIsDesignDraftFlag(data) {
    return {
      type: `SET_IS_DRAFT_FLAG`,
      payload: data
    };
  },

  ClearEditorSavedDesign() {
    return {
      type: `CLEAR_EDITOR_SAVED_DESIGN`
    };
  },

  ConfirmUploadElement(data) {
    return {
      type: `CONFIRM_UPLOAD`,
      payload: data
    };
  },

  EditorSaveAsCopy(data) {
    return {
      type: `EDITOR_SAVE_AS_COPY${_types.REQUESTED}`,
      payload: data
    };
  },

  PublishDialogGetLatestStyle(data) {
    return {
      type: `PUBLISH_DIALOG_GET_LATEST_STYLE${_types.REQUESTED}`,
      payload: data
    };
  },

  GetProductHistory(data) {
    return {
      type: `GET_PRODUCT_HISTORY${_types.REQUESTED}`,
      payload: data
    };
  },

  ApplyProductHistoryData(data) {
    return {
      type: `GET_SELECTED_PRODUCT_HISTORY${_types.REQUESTED}`,
      payload: data
    };
  },

  GetColorSchemes(data) {
    return {
      type: `GET_COLOR_SCHEMES${_types.REQUESTED}`,
      payload: data
    };
  },

  GetCategoryGroups(data) {
    return {
      type: `GET_CATEGORY_GROUPS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetEditorCreatorShopifyStores(data) {
    return {
      type: `GET_EDITOR_CREATOR_SHOPIFY_STORES${_types.REQUESTED}`,
      payload: data
    };
  },

  EditorUpdateProductDetails(data) {
    return {
      type: `EDITOR_UPDATE_PRODUCT_DETAILS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetStyleMockups(data) {
    return {
      type: `GET_STYLE_MOCKUPS${_types.REQUESTED}`,
      payload: data
    };
  },

  GenerateDesignMockup(data) {
    return {
      type: `GENERATE_DESIGN_MOCKUP${_types.REQUESTED}`,
      payload: data
    };
  },

  GetProductTextureStatus(data) {
    return {
      type: `GET_PRODUCT_TEXTURE_STATUS${_types.REQUESTED}`,
      payload: data
    };
  },

  GetMockupGroupData(data) {
    return {
      type: `GET_MOCKUP_GROUP_DATA${_types.REQUESTED}`,
      payload: data
    };
  },

  DemographicTabItems(data) {
    return {
      type: `DEMOGRAPHIC_TAB_ITEMS${_types.REQUESTED}`,
      payload: data
    };
  },

  CreateCollection(data) {
    return {
      type: `CREATE_COLLECTION${_types.REQUESTED}`,
      payload: data
    };
  },

  SaveAndExit(data) {
    return {
      type: `SAVE_AND_EXIT${_types.REQUESTED}`,
      payload: data
    };
  }

};
"use strict";

exports.__esModule = true;
exports.withStyled = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styleVariables = _interopRequireWildcard(require("@23point5/controls/src/styleVariables"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const styleVariables = _styleVariables.default[0];
/*
===========================
 SELECTOR
===========================
*/

const wizard = '.wizard';
const wizardTab = `${wizard} > .md-tabs`;
const wizardTabItem = `${wizardTab} > .md-tab`;
const wizardContent = `${wizard} > .md-tabs-content`;
const wizardHeader = `${wizard} .wizard__content__title`;

const withStyled = Comp => (0, _styledComponents.default)(Comp)`
  width: 100%;
  margin: 0;

  .wizard {
    width: 100%;
    height: auto;
    font-size: 1rem;
    flex-direction: column;
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
  }

  .wizard > .md-tabs {
    margin: 0;
    padding: 0;
    width: auto;
    border: none;
    width: 100%;
    z-index: 100;
    overflow: visible;
    margin-bottom: 1.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    @media and (min-width: 375px) and (max-widtth: 736px) {
      padding: 1.5em !important;
    }
  }

  ${wizardTabItem} {
    z-index: 0;
    padding: 0;
    border: none;
    cursor: default;
    border-radius: 0;
    max-width: unset;
    min-width: unset;
    overflow: visible;
    margin-left: -0.5em;
    background: transparent;
    height: ${styleVariables.wizard_circle_size};
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:nth-child(2) {
      flex-shrink: 0;
      width: ${styleVariables.wizard_circle_size};
    }

    &:not(:nth-child(2)) {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .md-ink-container,
    .md-icon,
    .md-tab-label {
      position: relative;
      top: 0 !important;
      left: auto !important;
      right: 0 !important;
      bottom: 0 !important;
      width: ${styleVariables.wizard_circle_size};
      height: ${styleVariables.wizard_circle_size};
      margin: 0;
      padding: 0;
      font-size: 1.5em;
      transition: 0.3s ease-in-out;
      &::before {
        font-size: 2em;
      }
    }

    .md-tab-label {
      position: absolute !important;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: ${styleVariables.wizard_tab_bgcolor};
      border-radius: 50%;
      z-index: 10;

      cursor: pointer;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: ${styleVariables.wizard_circle_size};
        height: ${styleVariables.wizard_circle_size};
        border-width: 4px;
        border-style: dashed;
        border-color: ${styleVariables.dna_color_white};
        border-radius: 50%;
        opacity: 0;
        transform: scale(0);
        visibility: hidden;
      }
    }

    .md-ink-container {
      z-index: 5;
    }

    &::before {
      content: '';
      display: block;
      position: absolute !important;
      top: 50% !important;
      margin-top: calc(0 - (${styleVariables.wizard_line_size} / 2));
      left: 0;
      margin-left: 6px;
      width: calc(100% - ${styleVariables.wizard_circle_size});
      height: ${styleVariables.wizard_line_size} !important;
      background-color: ${styleVariables.wizard_tab_bgcolor};
      transition: 0.3s ease-in-out;
      transform: none;
      z-index: 1;
    }
  }

  ${wizardTabItem} {
    &.done {
      z-index: 2;

      &::before {
        background-color: ${styleVariables.wizard_tab_bgcolor_done};
      }

      .md-tab-label {
        background-color: ${styleVariables.dna_color_primary};
        box-shadow: 0px 0px 0px 5px rgba(${styleVariables.dna_color_primary}, 0.45);
      }
    }

    &.false {
      z-index: 1;
    }

    &.md-tab--active {
      &::before {
      }

      .md-tab-label {
        transform: scale(1);
        background-color: ${styleVariables.dna_color_primary};
        cursor: default;
      }
    }

    &.error {
      &::before {
        background-color: ${styleVariables.wizard_tab_bgcolor_error} !important;
      }

      .md-tab-label {
        background-color: ${styleVariables.wizard_tab_bgcolor_error};
        cursor: default;
      }
    }

    &.valid.done {
      &::before {
        background-color: ${styleVariables.wizard_tab_bgcolor_valid} !important;
      }

      .md-tab-label {
        background-color: ${styleVariables.wizard_tab_bgcolor_valid};
        cursor: default;
      }
    }

    &:nth-child(2):before {
      width: 0 !important;
      display: none !important;
      visibility: hidden;
    }

    &::after {
      display: none;
    }

    .md-tab-indicator {
      display: none;
    }
  }

  ${wizardHeader} {
    margin: 0;
    padding: 0.7rem 1rem;
    display: flex;
    background-color: ${styleVariables.dna_color_primary};
    font-weight: 600;

    span {
      display: block;
    }

    .step {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.4rem;
      color: ${styleVariables.dna_color_border};
      padding-right: 1rem;
      color: #fff;
    }

    .title {
      font-size: 1.2rem;
      font-weight: inherit;
      line-height: 1.4rem;
      color: #fff;
    }
  }

  ${wizardContent} {
    overflow: visible !important;
    overflow-x: hidden !important;
    overflow-y: visible !important;
    width: 100%;

    .wizard__content {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    .wizard-content {
      padding-top: 1em;
      position: relative;
      min-height: 250px;
    }

    .react-swipeable-view-container > div {
      height: auto;
      overflow: visible !important;

      &[aria-hidden='true'] {
        height: 0 !important;
        overflow: hidden !important;
        /* visibility: hidden; */
      }
    }
  }

  /*
  // =============================
  // wizard step tabs
  // =============================
  */
  .wizard.wizard--step .md-tabs {
    position: relative;
    z-index: 1;
    padding: 0;
    font-size: $step-size;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: nowrap;
    border-bottom: none;
    padding: 0 ${styleVariables.wizard_tabs_padding} !important;
    margin: 0.5em;

    @media (max-width: 1024px) {
      padding: 0.2em 2em !important;
    }
    @media ${styleVariables.responsive_phone} {
      padding: 0.2em 1em !important;
    }

    &:before {
      content: '';
      font-size: inherit;
      position: absolute;
      top: 50%;
      left: ${styleVariables.wizard_tabs_padding};
      z-index: 1;
      width: calc(100% - (${styleVariables.wizard_tabs_padding * 2}));
      height: ${styleVariables.wizard_step_line};
      transform: translateY(-50%);
      background-color: ${styleVariables.wizard_line};
    }
  }

  .wizard.wizard--step .md-tab {
    font-size: inherit;
    position: relative;
    z-index: 5;
    border: none !important;
    background-color: transparent !important;
    max-width: unset;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible !important;
    &:not(:nth-last-child(2)):not(:only-child) {
      flex-grow: 1;
      flex-shrink: 1;
    }

    &:nth-last-child(2) {
      flex-shrink: 0;
    }

    &:before {
      content: '';
      font-size: inherit;
      position: relative !important;
      width: 1em !important;
      height: 1em !important;
      border-radius: 50% !important;
      background-color: ${styleVariables.wizard_inactive_step_bg} !important;
      flex-shrink: 0;
      transform: unset !important;
      box-shadow: 0px 0px 0px 0px rgba(${styleVariables.dna_color_primary}, 0.25);
      transition: box-shadow 300ms ease-in-out;
    }

    &:after {
      content: '';
      font-size: inherit !important;
      position: relative !important;
      top: unset !important;
      left: unset !important;
      height: ${styleVariables.wizard_step_line} !important;
      width: 100% !important;
      background-color: ${styleVariables.dna_color_primary} !important;
      flex-grow: 1;
      flex-shrink: 1;
      height: ${styleVariables.wizard_step_line};
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 300ms ease-in-out;
    }

    .md-icon,
    .md-tab-label {
      position: absolute !important;
      top: 50%;
      left: 0.35em;
      z-index: auto;
      width: 1em !important;
      height: 1em !important;
      padding: 0 !important;
      font-size: inherit;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      transform: translateY(-50%);
      transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

      @media ${styleVariables.responsive_phone} {
        display: block !important;
        margin-top: 3px;
      }
    }

    .md-icon {
      color: #fff !important;
      opacity: 0;
      visibility: hidden;
      opacity: 0;
    }

    .md-tab-label {
      color: #fff;
      font-size: 0.5em;
      width: 1.3em !important;
      height: 1.3em !important;
    }
  }

  .wizard.wizard--step .md-tab.md-tab--active {
    &:before {
      background-color: ${styleVariables.dna_color_primary} !important;
    }
  }

  .wizard.wizard--step .md-tab.done {
    &:before {
      background-color: ${styleVariables.dna_color_primary} !important;
      box-shadow: 0px 0px 0px 5px rgba(${styleVariables.dna_color_primary}, 0.45);
    }

    &.md-tab--inactive {
      &:after {
        transform: scaleX(1);
      }

      &:not(.md-tab--active) {
        .md-icon {
          visibility: visible;
          opacity: 1;
        }
        .md-tab-label {
        }
      }
    }
  }
`;

exports.withStyled = withStyled;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _createReducer = require("@23point5/controls/lib/controls/utils/createReducer");

var _actionHandlers = _interopRequireDefault(require("@common/reducers/actionHandlers"));

var _initialState = _interopRequireDefault(require("@common/reducers/initialState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const customState = _objectSpread(_objectSpread({}, _initialState.default), {}, {
  isLoading: false,
  // list: {
  //   data: [],
  //   count: []
  // }
  list: []
});

const uniqueEntity = 'EDITOR_TUTORIAL_VIDEOS';

const actionHandlers = _objectSpread(_objectSpread({}, (0, _actionHandlers.default)(uniqueEntity)), {}, {
  GET_EDITOR_TUTORIAL_VIDEOS_LOADING: state => _objectSpread(_objectSpread({}, state), {}, {
    isLoading: true
  }),
  GET_EDITOR_TUTORIAL_VIDEOS_LOADING_DONE: state => _objectSpread(_objectSpread({}, state), {}, {
    isLoading: false
  }),
  GET_EDITOR_TUTORIAL_VIDEOS: (state, {
    payload
  }) => _objectSpread(_objectSpread({}, state), {}, {
    list: payload
  })
});

var _default = (0, _createReducer.buildReducer)(customState, actionHandlers);

exports.default = _default;
"use strict";

var _effects = require("redux-saga/effects");

var _axios = _interopRequireDefault(require("@utils/axios"));

var _replace = _interopRequireDefault(require("lodash/replace"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _uniq = _interopRequireDefault(require("lodash/uniq"));

var _tools = require("@common/utils/tools");

var _tools2 = require("@23point5/core/controls/utils/tools");

var _actions = require("@App/actions");

var _types = require("../types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

const FIELDS = ['method', 'index', 'index_value', 'collection', 'sort_column', 'sort_direction', 'fields', 'offset', 'limit', 'search', ':contains', ':eq', ':ne', ':gt', ':ge', ':lt', ':le'];

function* SetListFetching(_ref) {
  let source = _ref.source,
      payload = _objectWithoutPropertiesLoose(_ref, ["source"]);

  const _Object$keys = Object.keys(payload),
        key = _Object$keys[0];

  const action = source === 'full_dialog' ? _types.SET_FULL_DIALOG_DATA : _types.SET_DIALOG_DATA;
  yield (0, _effects.put)({
    type: action,
    payload: {
      [`${key}IsFetching`]: payload[key]
    }
  });
}

function* RequestList(payload) {
  const _payload = payload,
        source = _payload.source,
        key = _payload.key,
        type = _payload.type,
        url = _payload.url,
        reloadDialog = _payload.reloadDialog,
        postDispatches = _payload.postDispatches;
  payload = (0, _omit.default)(payload, ['postDispatches']);
  yield (0, _effects.call)(() => SetListFetching({
    source,
    [key]: true
  })); // exclude status in get

  const shapedPayload = (0, _tools.contains)(payload.exclude_status ? payload : (0, _tools.setStatus)(payload), FIELDS);
  const query = (0, _tools.toQueryString)(shapedPayload);
  const config = {
    method: 'get',
    url: `/${url}?${query}`
  };
  const actionType = (0, _replace.default)(type, '_REQUESTED');
  const result = yield (0, _effects.call)(_axios.default, config, actionType);

  if (result.type === actionType) {
    const action = source === 'full_dialog' ? _types.SET_FULL_DIALOG_DATA : _types.SET_DIALOG_DATA;
    const putResult = {
      type: action,
      payload: {
        [key]: result.payload
      }
    };
    const promises = reloadDialog ? [(0, _effects.put)(putResult), (0, _effects.put)({
      type: _types.RELOAD_DIALOG
    })] : [(0, _effects.put)(putResult)];

    if (postDispatches) {
      const chainFunctions = (0, _tools2.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }

    yield (0, _effects.all)(promises);
  } else {
    yield (0, _effects.put)(result);
  }

  yield (0, _effects.call)(() => SetListFetching({
    source,
    [key]: false
  }));
}

function* RequestDetails(payload) {
  const _payload2 = payload,
        id = _payload2.id,
        _payload2$source = _payload2.source,
        source = _payload2$source === void 0 ? 'dialog' : _payload2$source,
        key = _payload2.key,
        type = _payload2.type,
        url = _payload2.url,
        _payload2$reloadDialo = _payload2.reloadDialog,
        reloadDialog = _payload2$reloadDialo === void 0 ? true : _payload2$reloadDialo,
        postDispatches = _payload2.postDispatches;
  payload = (0, _omit.default)(payload, ['postDispatches']);
  const config = {
    method: 'get',
    url: `/${url}/${id}`
  };
  const actionType = (0, _replace.default)(type, '_REQUESTED');
  const result = yield (0, _effects.call)(_axios.default, config, actionType);

  if (result.type === actionType) {
    const action = source === 'full_dialog' ? _types.SET_FULL_DIALOG_DATA : _types.SET_DIALOG_DATA;
    const putResult = {
      type: action,
      payload: {
        [key]: result.payload
      }
    };
    const promises = reloadDialog ? [(0, _effects.put)(putResult), (0, _effects.put)({
      type: _types.RELOAD_DIALOG
    })] : [(0, _effects.put)(putResult)];

    if (postDispatches) {
      const chainFunctions = (0, _tools2.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }

    yield (0, _effects.all)(promises);
  } else {
    yield (0, _effects.put)(result);
  }
}

function* GetOptionsList(_ref2) {
  let payload = _ref2.payload,
      restPayload = _objectWithoutPropertiesLoose(_ref2, ["payload"]);

  yield (0, _effects.call)(() => RequestList(_objectSpread(_objectSpread({}, payload), restPayload)));
}

function* GetResourceDetails(_ref3) {
  let payload = _ref3.payload,
      restPayload = _objectWithoutPropertiesLoose(_ref3, ["payload"]);

  yield (0, _effects.call)(() => RequestDetails(_objectSpread(_objectSpread({}, payload), restPayload)));
}

function* GetProductCategoryList({
  payload
}) {
  const config = {
    method: 'GET',
    url: `/product-category?index_value=${payload}&status:ne=Deleted`
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'GET_PRODUCT_CATEGORY_LIST');

  if (result.type === 'GET_PRODUCT_CATEGORY_LIST') {
    yield (0, _effects.put)({
      type: 'SET_FULL_DIALOG_DATA',
      payload: {
        product_categories: result.payload
      }
    });
    return;
  }

  yield (0, _effects.put)(result);
}

function* LikeDislikeDesign({
  payload
}) {
  const postDispatches = payload.postDispatches,
        _payload$updateListSt = payload.updateListState,
        updateListState = _payload$updateListSt === void 0 ? true : _payload$updateListSt;
  const fullDialog = yield (0, _effects.select)(_tools.getState.bind('global.fullDialog'));
  const dialog = yield (0, _effects.select)(_tools.getState.bind('global.dialog'));
  yield (0, _effects.put)({
    type: 'LIKES_UPDATING'
  });

  if (fullDialog.isVisible) {
    yield (0, _effects.put)((0, _actions.LikesUpdating)());
  }

  if (dialog.isVisible) {
    yield (0, _effects.put)((0, _actions.DialogUpdating)());
  }

  const omitted_payload = (0, _omit.default)(payload, ['postDispatches', 'updateListState']);
  const session_id = yield (0, _effects.select)(_tools.getState.bind('global.session.data.id'));
  const with_metadata = (0, _tools.setMetadata)(omitted_payload, session_id);
  const config = {
    method: 'PUT',
    url: `/product`,
    data: (0, _pick.default)(with_metadata, ['id', 'artist_id', 'likes', 'metadata'])
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'LIKE_DISLIKE_DESIGN');
  let promises = [];

  if (result.type === 'LIKE_DISLIKE_DESIGN') {
    // CHECK IF REDUCER IS MOUNTED
    const collection_details = yield (0, _effects.select)(_tools.getState.bind('collection_details'));
    const home = yield (0, _effects.select)(_tools.getState.bind('home'));
    const search = yield (0, _effects.select)(_tools.getState.bind('search'));
    const profile = yield (0, _effects.select)(_tools.getState.bind('profile'));
    const landing_page = yield (0, _effects.select)(_tools.getState.bind('landing_page'));

    if (collection_details) {
      yield (0, _effects.put)({
        type: 'COLLECTION_DETAILS_PRODUCT_LIKES_UPDATED_REQUESTED',
        payload: _objectSpread(_objectSpread({}, result.payload), {}, {
          updateListState
        })
      });
    }

    if (home) {
      yield (0, _effects.put)({
        type: 'HOME_PRODUCT_LIKES_UPDATED_REQUESTED',
        payload: _objectSpread(_objectSpread({}, result.payload), {}, {
          updateListState
        })
      });
    }

    if (search) {
      yield (0, _effects.put)({
        type: 'SEARCH_RESULTS_PRODUCT_LIKES_UPDATED_REQUESTED',
        payload: _objectSpread(_objectSpread({}, result.payload), {}, {
          updateListState
        })
      });
    }

    if (profile) {
      yield (0, _effects.put)({
        type: 'PROFILE_PRODUCT_LIKES_UPDATED_REQUESTED',
        payload: _objectSpread(_objectSpread({}, result.payload), {}, {
          updateListState
        })
      });
    }

    if (landing_page) {
      yield (0, _effects.put)({
        type: 'LANDING_PAGE_PRODUCT_LIKES_UPDATED_REQUESTED',
        payload: _objectSpread(_objectSpread({}, result.payload), {}, {
          updateListState
        })
      });
    }

    if (postDispatches) {
      const chainFunctions = (0, _tools2.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e)); // promises.push(...chainDispatches)

      promises = [...promises, ...chainDispatches];
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }
  }

  yield (0, _effects.all)(promises);
  yield (0, _effects.put)({
    type: 'LIKES_UPDATED'
  });

  if (fullDialog.isVisible) {
    yield (0, _effects.put)((0, _actions.LikesUpdated)());
  }

  if (dialog.isVisible) {
    yield (0, _effects.put)((0, _actions.DialogUpdated)());
  }
}

function* GetSimiarDesigns(payload) {
  const design_tags = payload.design_tags,
        artist_id = payload.artist_id,
        product_category_id = payload.product_category_id;
  const config = {
    method: 'post',
    url: `/product/grid`,
    data: {
      must_not: [{
        key: 'hidden_product',
        value: [true]
      }, {
        key: 'artist_id',
        value: [artist_id]
      }],
      filter: [{
        key: 'status',
        value: ['Approved']
      }, {
        key: 'visibility',
        value: ['Public']
      }, {
        key: 'design_tags',
        value: design_tags
      }, {
        key: 'product_category_id',
        value: [product_category_id]
      }, {
        key: 'is_locked',
        value: [false]
      }],
      sort_column: 'created_date',
      sort_direction: 'desc'
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'GET_MORE_DESIGNS_FROM_ARTIST');

  if (result.type === 'GET_MORE_DESIGNS_FROM_ARTIST') {
    const _result$payload$data = result.payload.data,
          data = _result$payload$data === void 0 ? [] : _result$payload$data;
    return data;
  }

  return [];
}

function* GetMoreDesignsFromArtist(payload) {
  const id = payload.id,
        artist_id = payload.artist_id;
  const config = {
    method: 'post',
    url: `/product/grid`,
    data: {
      must_not: [{
        key: 'id',
        value: [id]
      }, {
        key: 'hidden_product',
        value: [true]
      }],
      filter: [{
        key: 'status',
        value: ['Approved']
      }, {
        key: 'visibility',
        value: ['Public']
      }, {
        key: 'artist_id',
        value: [artist_id]
      }, {
        key: 'is_locked',
        value: [false]
      }],
      sort_column: 'created_date',
      sort_direction: 'desc'
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'GET_MORE_DESIGNS_FROM_ARTIST');

  if (result.type === 'GET_MORE_DESIGNS_FROM_ARTIST') {
    const _result$payload$data2 = result.payload.data,
          data = _result$payload$data2 === void 0 ? [] : _result$payload$data2;
    return data;
  }

  return [];
}

const shapedQueryPayload = payloadQuery => (0, _tools.contains)(payloadQuery.exclude_status ? payloadQuery : (0, _tools.setStatus)(payloadQuery), FIELDS);

function* DesignFormOnmountRequests({
  payload
}) {
  const fabric_id = payload.fabric_id,
        style_id = payload.style_id,
        product_id = payload.product_id,
        artist_id = payload.artist_id,
        product_category_id = payload.product_category_id,
        design_tags = payload.design_tags,
        postDispatches = payload.postDispatches; // --------------------------------------------------------------------

  const fabricDetailsConfig = {
    method: 'GET',
    url: `/fabric/${fabric_id}`
  };
  const fabricDetailsResult = yield (0, _effects.call)(_axios.default, fabricDetailsConfig, 'FABRIC_DETAILS_RESULT');
  const fabric_details = fabricDetailsResult.type === 'FABRIC_DETAILS_RESULT' ? fabricDetailsResult.payload : {}; // --------------------------------------------------------------------

  const fabricDetailsQueryPayload = {
    'style_id:eq': style_id,
    'status:eq': 'Active',
    sort_column: 'order',
    sort_direction: 'asc',
    'weight:gt': 0,
    fields: 'weight,id,name,code,attributes,order'
  };
  const productSizesQuery = (0, _tools.toQueryString)(shapedQueryPayload(fabricDetailsQueryPayload));
  const productSizesConfig = {
    method: 'GET',
    url: `/size?${productSizesQuery}`
  };
  const productSizesResult = yield (0, _effects.call)(_axios.default, productSizesConfig, 'PRODUCT_SIZES_RESULT');
  const product_sizes = productSizesResult.type === 'PRODUCT_SIZES_RESULT' ? productSizesResult.payload : []; // --------------------------------------------------------------------

  const productCategoriesQueryPayload = {
    url: 'product-category',
    source: 'full_dialog',
    'status:ne': 'Deleted',
    fields: 'id,name'
  };
  const productCategoriesQuery = (0, _tools.toQueryString)(shapedQueryPayload(productCategoriesQueryPayload));
  const productCategoriesConfig = {
    method: 'GET',
    url: `/product-category?${productCategoriesQuery}`
  };
  const productCategoriesResult = yield (0, _effects.call)(_axios.default, productCategoriesConfig, 'PRODUCT_CATEGORIES_RESULT');
  const product_categories = productCategoriesResult.type === 'PRODUCT_CATEGORIES_RESULT' ? productCategoriesResult.payload : []; // --------------------------------------------------------------------

  const similar_designs = yield (0, _effects.call)(() => GetSimiarDesigns({
    design_tags,
    artist_id,
    product_category_id
  })); // --------------------------------------------------------------------

  const artist_designs = yield (0, _effects.call)(() => GetMoreDesignsFromArtist({
    id: product_id,
    artist_id
  }));
  const fullDialogData = {
    fabric_details,
    product_sizes,
    product_categories,
    artist_designs,
    similar_designs
  };
  const promises = [(0, _effects.put)({
    type: 'SET_FULL_DIALOG_DATA',
    payload: fullDialogData
  })];

  if (postDispatches) {
    const chainFunctions = (0, _tools2.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
    const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
    promises.push(...chainDispatches);
    yield (0, _effects.call)(() => chainFunctions(fullDialogData));
  }

  yield (0, _effects.all)(promises);
}

function* GetSharedLatestStyles({
  payload
}) {
  const _payload$callback = payload.callback,
        callback = _payload$callback === void 0 ? () => {} : _payload$callback,
        _payload$isNodeList = payload.isNodeList,
        isNodeList = _payload$isNodeList === void 0 ? false : _payload$isNodeList;
  const config = {
    method: 'POST',
    url: `/styles/grid`,
    data: {
      sort_column: 'created_date',
      sort_direction: 'desc',
      filter: [{
        key: 'has_sizes',
        value: [true]
      }, {
        key: 'status',
        value: ['Active']
      }] // fields: ['id','name','price']

    }
  };
  const nodeListQueryPayload = {
    'status:eq': 'Active',
    // 'has_sizes:eq': true,
    fields: 'id,name,price,make_price,status,default_markup'
  };
  const nodeListQuery = (0, _tools.toQueryString)(shapedQueryPayload(nodeListQueryPayload));
  const nodeListConfig = {
    method: 'GET',
    url: `/styles?${nodeListQuery}`
  };
  const resultType = 'GET_SHARED_LATEST_STYLES';
  const result = isNodeList ? yield (0, _effects.call)(_axios.default, nodeListConfig, resultType) : yield (0, _effects.call)(_axios.default, config, resultType);

  if (result.type === resultType) {
    const _result$payload = result.payload,
          _result$payload$data3 = _result$payload.data,
          data = _result$payload$data3 === void 0 ? [] : _result$payload$data3,
          server_time = _result$payload.server_time;
    const result_data = isNodeList ? result.payload : data; // const staticOrdering =  {
    //   'T-Shirt': 0,
    //   TShirt: 0,
    //   'T hirt': 0,
    //   Hoodie: 1,
    //   Jogger: 2
    // }

    const staticOrdering = (style_type_value = '') => {
      const lowecase_style_type_value = (style_type_value || '').toLocaleLowerCase();

      if (lowecase_style_type_value.includes('hoodie')) {
        return 0;
      }

      if (lowecase_style_type_value.includes('tshirt') || lowecase_style_type_value.includes('t-shirt') || lowecase_style_type_value.includes('t shirt') || lowecase_style_type_value.includes('tshirt')) {
        return 1;
      }

      if (lowecase_style_type_value.includes('jogger')) {
        return 2;
      }

      return result_data.length;
    };

    const static_ordered_data = result_data.sort((prev = {}, cur = {}) => {
      return staticOrdering(prev.name) - staticOrdering(cur.name);
    }); // set sessionStorage server time for general purpose use

    if (server_time) {
      sessionStorage.setItem('server_time', server_time);
    }

    callback(static_ordered_data);
    yield (0, _effects.put)({
      type: 'SET_SHARED_LATEST_GLOBAL_STYLES',
      payload: static_ordered_data
    });
  }
}

function* GetGlobalProductListing({
  payload = {}
}) {
  const localStorageParams = localStorage('products-params') || {};
  const params = {
    entities: ['product'],
    filter: {
      product: []
    },
    limit: 25,
    sortValue: localStorageParams.sortValue || 'trending',
    sort_column: localStorageParams.sort_column || 'likes_count',
    start: 0,
    sort_direction: localStorageParams.sort_direction || 'desc'
  };
  const config = {
    method: "post",
    url: `/search`,
    data: params
  };
  const result = yield (0, _effects.call)(_axios.default, config, "GET_GLOBAL_PRODUCT_LISTING");

  if (result.type === "GET_GLOBAL_PRODUCT_LISTING") {
    yield (0, _effects.put)({
      type: 'GET_GLOBAL_PRODUCT_LISTING',
      payload: result.payload.product || {
        data: [],
        count: 0
      }
    });
  }
}

function* GenerateProductName({
  payload = {}
}) {
  const artist_id = payload.artist_id,
        postDispatches = payload.postDispatches;
  const config = {
    method: 'POST',
    url: `/generaete-product-name`,
    data: {
      artist_id
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, "GENERATE_PRODUCT_NAME");
  const promises = [];

  if (result.type === "GENERATE_PRODUCT_NAME") {
    if (postDispatches) {
      const chainFunctions = (0, _tools2.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }
  }
}

module.exports = function* shared() {
  // GET
  yield (0, _effects.takeEvery)('GET_OPTIONS_LIST_REQUESTED', GetOptionsList);
  yield (0, _effects.takeEvery)('GET_RESOURCE_DETAILS_REQUESTED', GetResourceDetails);
  yield (0, _effects.takeLatest)(`GET_PRODUCT_CATEGORY_LIST_REQUESTED`, GetProductCategoryList);
  yield (0, _effects.takeEvery)(`GET_SHARED_LATEST_STYLES_REQUESTED`, GetSharedLatestStyles); // PUT

  yield (0, _effects.takeEvery)('LIKE_DISLIKE_DESIGN_REQUESTED', LikeDislikeDesign); // CUSTOM

  yield (0, _effects.takeLatest)(`DESIGN_FORM_ONMOUNT_REQUESTED`, DesignFormOnmountRequests);
  yield (0, _effects.takeLatest)(`GET_GLOBAL_PRODUCT_LISTING_REQUESTED`, GetGlobalProductListing);
  yield (0, _effects.takeEvery)(`GENERATE_PRODUCT_NAME_REQUESTED`, GenerateProductName);
};
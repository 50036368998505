"use strict";

exports.__esModule = true;
exports.default = _default;

var _styleDesciptions = _interopRequireDefault(require("@utils/styleDesciptions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const check_id = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

function _default() {
  const _this$props = this.props,
        dialog = _this$props.dialog,
        session = _this$props.session;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  const _data$collection_list = data.collection_list,
        collection_list = _data$collection_list === void 0 ? [] : _data$collection_list,
        _data$tags = data.tags,
        tags = _data$tags === void 0 ? [] : _data$tags,
        _data$product_data = data.product_data,
        product_data = _data$product_data === void 0 ? {} : _data$product_data,
        _data$locationSearchP = data.locationSearchParams,
        locationSearchParams = _data$locationSearchP === void 0 ? {} : _data$locationSearchP;
  const _product_data$collect = product_data.collections,
        collections = _product_data$collect === void 0 ? [] : _product_data$collect;
  const tags_options = tags.map(({
    name
  }) => ({
    label: name,
    value: name
  }));
  const collections_options = collection_list.map(e => {
    if (e.name === 'Default Collection') {
      return {
        value: e.id,
        label: e.name,
        data: _objectSpread(_objectSpread({}, e), {}, {
          isFixed: true
        }),
        isFixed: true
      };
    }

    return {
      value: e.id,
      label: e.name,
      data: e,
      isFixed: false
    };
  });
  const defaultCollection = collection_list.find(opt => opt.name === 'Default Collection') || {};
  const hasDefaultCollection = !!collections.length && collections.find(e => e.id === defaultCollection.id);
  const defaultCollectionValue = !!Object.keys(defaultCollection).length && hasDefaultCollection ? [...collections] : [defaultCollection.id].filter(Boolean);
  const product_fields_collection = collections.length ? collections.filter(e => {
    const collection = collection_list.find(col => {
      if (check_id.test(e) && col.id === e) return col;
      if (!check_id.test(e) && col.name === e) return col;
    });
    return !!collection && collection.id;
  }) : defaultCollectionValue;
  const staticMakePrice = product_data.style_type_name.toLowerCase() === 'hoodie' || product_data.style_type_name.toLowerCase() === 'jogger' || product_data.style_type_name.toLowerCase() === 'zip hoodie' ? 45 : 28;
  const initial_retail = Number(product_data.base_price) + Number(product_data.default_markup);

  const description = product_data.description || _styleDesciptions.default[product_data.style_type_name.toLowerCase()].content;

  const fieldValues = _objectSpread(_objectSpread({}, product_data), {}, {
    id: product_data.id,
    product_name: product_data.product_name,
    artist_id: product_data.artist_id,
    collections: product_fields_collection,
    product_category_id: product_data.product_category_id || '',
    description,
    default_markup: product_data.default_markup || '0.00',
    design_tags: product_data.design_tags || [],
    availability: product_data.availability || 'For Everyone',
    stock: 0,
    make_price: product_data.make_price || staticMakePrice,
    pricing: {
      markup: product_data.default_markup,
      retail: initial_retail
    },
    is_agree_to_terms: false,
    is_publish_to_marketplace: true,
    include_size_guide: false
  });

  return {
    fieldValues,
    tags_options,
    collections_options,
    errors: {},
    shopify_stores: [],
    selectedExternalStores: []
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _v = _interopRequireDefault(require("uuid/v4"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const solidColorsData = {
  schemes: [{
    color_groups: [{
      description: '',
      family: [{
        description: '',
        hex_code: '#E7D083',
        id: 'abf6eeb6-8dfe-4d4a-b96b-5fdf77aa8550',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E8C871',
        id: '7600f9f7-8ebd-4f0f-8f69-58a230a4a01e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1B74C',
        id: 'a03f0a4e-f312-4652-a325-40a62cad929d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDAD33',
        id: '876a123c-796a-4ded-8e66-aeee09ce0b65',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9A51B',
        id: 'a36b5294-9fba-44ca-b51e-91a3894361bc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF9616',
        id: '3e195ceb-79bb-45aa-b3be-0cb5d35fd0df',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BC7B28',
        id: '0c26b6f9-644c-431f-9f34-1469bb255d2c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BC9E7A',
        id: '467cb252-9de9-4c7e-8251-61e86552b618',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF8C62',
        id: '57058ebe-f408-4fc6-b898-19ba7ab211f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A47F54',
        id: 'e9a1b9b1-6bea-47d7-8be1-8d627bd6e362',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D7748',
        id: 'd3fb809a-acd2-4a31-8bce-6aa8667705db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#875F32',
        id: '01fb4c0e-ca0a-47da-91cb-d5e5ccd15d62',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B5127',
        id: 'b975d0dd-4187-4e52-8143-39264aa71b51',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#67502D',
        id: '8b34576c-316b-45f7-92ee-98ed1ebd6fae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0B285',
        id: '0fbbf9eb-7c02-4435-8f4d-3abd444b09ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F49D61',
        id: '520ff42d-dd2b-4621-a595-135581a87772',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FB914A',
        id: 'd1103329-5ecf-4b81-bac6-3e03079a731f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F58132',
        id: 'f34ed14f-eb53-4d44-86eb-7112fef3d32d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF6B0D',
        id: '9fe32004-d73b-480d-a90f-52f3b9019852',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E25800',
        id: 'ba11ca2c-18d9-498f-971e-bc47293fc891',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D75A1B',
        id: '558a3a59-09a0-4d93-841c-462dc6981a85',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F5C0B5',
        id: 'ae4387b4-5ff9-40b9-babe-723feaf8f024',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAB19E',
        id: '6ea252e4-02de-4a80-b438-d4bf648775b1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E69684',
        id: '59639920-122f-4636-a49b-fb6a3b767349',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DC826B',
        id: '23e6b4d5-3762-4226-8583-2406bcef29a7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D57559',
        id: '99daf284-70b2-40ee-b338-a62261022043',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C3623F',
        id: '6dee417b-2a4e-490f-b7e1-5de0cab1e85e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BA5B3F',
        id: '1210e117-5a68-4917-b29c-6b50a989d3d6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D1AFAD',
        id: '5bfae82a-4ed1-41cf-90cb-16f76a266cc5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C79E99',
        id: 'edcce380-bc11-46b3-b586-3175c458d2b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BA8F85',
        id: '0c3f4549-e389-4b43-9316-c8f9d999b669',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B18278',
        id: 'f445e6ee-e5f7-40aa-a5ba-98107f976898',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A97669',
        id: '77f1f3e4-345b-4673-99e7-acd351f07e66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A06A5A',
        id: '90408e4e-9c34-47e6-b1e5-2cf9b59bf7a4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#945E4B',
        id: 'f8ff027a-b730-453f-99f4-d9c3e89307ff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE8E98',
        id: '90f2deb3-f109-4959-a7b1-449978e37908',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC767E',
        id: 'a47066e0-112d-4a4c-bc88-3cefbae5ad6d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2666E',
        id: '84969050-d4ff-491d-bd60-3bf3315dc796',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93565C',
        id: '2c43c1c9-8b0c-4a89-bf5a-7ce313d9e318',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#864549',
        id: '1a929320-f169-49c6-ac22-209d6212d244',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B393C',
        id: 'e1b9afc9-9636-47dc-9feb-fd8dee787359',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#612E2E',
        id: '7ad9c4cf-e157-4bcf-b07f-f7f5afee1233',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3909E',
        id: 'c44708aa-a532-4b17-b371-416d328d7290',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F08592',
        id: 'ed961f00-a612-45f1-afc9-c4e13d325515',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ED7B86',
        id: '6d9801ed-0de0-4e83-8b2f-6a389fa3d1d0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E56C73',
        id: 'bc0f09b1-bc96-4b94-a007-f606c3427a44',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D04D46',
        id: '84502bc1-9539-4418-9d16-b79fc8545119',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D24F4E',
        id: '14b24813-26c5-47ea-b5ed-133eec51435c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BB452E',
        id: '00e03669-b8aa-4eb8-837b-a603bf30f4ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FBA8D0',
        id: '9eaad186-f1f0-427e-94d3-adbb8443510d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FB83AD',
        id: '75df14b6-b350-47d6-8cdc-d6daf9069e83',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E76E93',
        id: 'ed11522c-eb0f-4015-b877-3d37fd248c18',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F65C87',
        id: '4cbe7af3-626c-4720-a2ad-c9f021841523',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DD5681',
        id: '15fab193-94b3-4cf4-9191-52a22274d305',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D34368',
        id: '272bed2d-cab1-4dad-b3d9-cb541b32c733',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CA3A57',
        id: '941a524f-850c-4a1b-84ba-20c648af74ac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D25EA1',
        id: 'a429b1db-9dd5-4c47-bad6-ee6f939b265f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C94C91',
        id: '837aa446-987f-4e37-a9e1-41b2b881bb7a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4448A',
        id: '84c6c1f8-a457-4657-9349-4c5f480a06e4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE3C80',
        id: '5e669d3e-e910-4c0a-9a6e-a68b5e3a1438',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B43174',
        id: 'dd689dfb-b5f6-4a63-8629-2605494ccd42',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B22E6D',
        id: 'a7344f7c-f2fc-4e7e-a4c7-b783e5900194',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A42B5F',
        id: 'ae16cfd7-b05a-43e1-8c4f-dedd7b8bae89',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0AEC6',
        id: '9b7ebe1e-d2d5-4db1-9c92-1e324b40621d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C89BB2',
        id: '73a129eb-2870-4970-a17c-e6bd24e5efb9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C08EA7',
        id: '6517cb21-098e-4c72-997d-cd6b09f255d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B48098',
        id: '3cec60d1-aa6e-4767-ad63-78c03362a263',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB758F',
        id: '9192a191-ce1f-4226-b32e-3c9d899142b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9C637D',
        id: '94ea10c6-d8a7-493a-b6a9-ca1e54c7cf9c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#98627A',
        id: '370e3690-6261-4a98-8b05-9fe4bee34ccf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#937898',
        id: '9ca1dd6c-6500-4bdb-9a08-5a1581f26339',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#745276',
        id: '9f649b66-73bf-465e-a767-8ca94457a3c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#674568',
        id: '637f38ea-9b77-4200-b9ad-9143f46535b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#613E5F',
        id: 'ef4c2788-255c-41fc-bf8a-0ede49f4e484',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#583756',
        id: '5ac7e667-b282-4f60-b704-c5b30cfe5b67',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#462A42',
        id: '0a7e2eb3-9890-4d3c-b15b-714552662174',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#392734',
        id: '4325fbde-95e9-4672-9fdf-f2e42fffc1d6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C094CE',
        id: '18e3527c-2035-405a-ad30-205b9689648b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B37EC0',
        id: 'b147c94a-1ae5-4aed-b7ec-b5f21d95d9e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A369B1',
        id: 'e7c6d544-7977-495f-aa17-57b180370d28',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E60AC',
        id: 'c2506900-bce0-4864-86eb-9087d3841566',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#90509B',
        id: '5c25981b-d631-4c6a-aa0e-7d2ab6dc4239',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#833E8D',
        id: '70f23d49-1b5e-455f-94c6-d6f40ec5eb30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#773580',
        id: '0f52d205-e5a3-40a8-986e-c782880019f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8CEEA',
        id: 'f3eebb9c-23b5-451f-8dc0-cfc53f088101',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AEB5D8',
        id: '33442d15-406f-420d-81b6-efd3006ed133',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EA7CD',
        id: '7df36d05-6f89-4768-99ba-04398d570d17',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B96C2',
        id: 'eb32c570-b6c0-4dff-8a56-d28560ff60b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C89C1',
        id: '5a5339d1-baf2-4849-88b1-4af79e531111',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B74AC',
        id: 'b2deb1a0-17c6-431c-bb0d-2f0f5a18887f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#54609A',
        id: '2469d415-1a21-4f8a-ad98-7962ebcd4e13',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C8FC7',
        id: '4116ac20-dd47-4143-9626-aaeee4315bd3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8488C2',
        id: '11762617-147d-47f0-813a-7d6c7498b425',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7779B7',
        id: 'fafd9a47-50c1-489e-bdd0-c5ef2f3b008c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F72B4',
        id: '416f856a-3cd3-4928-b7c7-d567071faac5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F5FA5',
        id: 'a48ab308-d471-4337-8234-c5deffde483b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F529B',
        id: '10f99f42-5506-4140-a8a0-600694d27142',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#42458E',
        id: '3e89c354-01da-4b3c-9d33-495d2febac50',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#90A5DB',
        id: '4be5af05-a94c-4b24-bfe0-a8d864eaf629',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7992CB',
        id: '19b51e82-c8d1-4057-b88e-ff8279c0ece3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6680C3',
        id: 'c3bd7ea8-00df-4a8e-b0cc-735983ab2437',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C77B6',
        id: '705de393-5e37-4673-bf20-8dd617287351',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#405397',
        id: '255644e2-5e43-4d7a-a2ef-59ac6c1a82b2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#213B8B',
        id: '906f9faa-0028-4c90-a2dc-92040eb3c24b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#283883',
        id: 'a41e9539-34f9-44c7-b517-bbc3cd833daa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B5BFD5',
        id: '86bb40c9-e989-4856-b390-739b3604882f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A5B3CE',
        id: 'f1c574b1-9fd3-4efc-af8e-d654a06f2606',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8FA4C0',
        id: '81ec0b21-90a4-4965-bc38-366be3932d6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7890B0',
        id: '27d6f437-d5b7-4a26-b387-48846e42ff76',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7188A8',
        id: 'cb90a0ac-25f6-45af-b03f-fa9f550b5184',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#627A9D',
        id: 'cfa76e37-6f8a-427a-986d-2797cef256ae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3B5C7F',
        id: '1ade3e89-c4a5-4b8f-826e-b094ca2745c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6C8797',
        id: '3a3e2f17-f6be-4bd1-821f-bb9788e0389e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#668090',
        id: 'e786efbe-b334-4a0d-ba02-f2e682fb39c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#597482',
        id: '69cbc06b-2b1c-4633-9788-d3c8a1ad5ede',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B6775',
        id: '8d57b9ea-e3f7-446e-bdfd-b559a11d94fb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#46616F',
        id: '7024b44e-39c5-455b-9eaf-9f583a776bb2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#32464D',
        id: 'c0e47dbf-147c-4b47-865d-2d116535524a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E3C41',
        id: '66d8b67c-85f3-4292-b32c-452109dc8812',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3AA5D5',
        id: 'cce57fe8-686c-404e-a27d-6de484c90324',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009CCF',
        id: '69726bd1-c1e7-4452-be7a-f2fedf584fd8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008BC4',
        id: 'da383785-9fc9-4882-a067-e8bbf38bec9e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0085BF',
        id: '52e6323d-fb37-4ab1-8f71-bc877991cf4a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007CB7',
        id: 'e56cce55-6f1f-47fb-85c0-f0e57b1d2186',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0074AA',
        id: '7d2486c8-5cd2-4fc9-9806-3965f47699d3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005D9C',
        id: '28ca6910-e3f0-4fbb-b231-6310284616b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#63B3CA',
        id: '2763c9a5-c414-433f-9ce5-e55395635b16',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#36A2BC',
        id: '55d9e6f7-3503-429d-a354-6b1adafd6e46',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0093AD',
        id: 'c975164b-032f-46ed-a2f5-71d03d181fb4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00859D',
        id: '7fdaf660-2be3-4995-b982-dacac119324c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00728B',
        id: '6de03a93-5827-49e8-b2e0-0b6c68363ac4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007A99',
        id: 'a277511c-faa9-4079-ab38-1445c7e97f3f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00657F',
        id: '7f2d7374-edc7-4901-980d-06dc1587a6b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#82CAD3',
        id: '48409060-0003-4aac-8f3d-0b21ef2713ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71B9BF',
        id: '67dfcec3-2039-448b-89da-e95c7a59fcc3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4BA6AA',
        id: 'a32d5d41-f0c7-4b21-85e2-0498681f9241',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#369DA0',
        id: '46d38160-00fe-42ca-b7cc-c99290fec001',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#049193',
        id: '11265bdb-2044-41e8-adea-0f27cd436d78',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008587',
        id: '4ad227a0-7acc-4898-a428-b8ef75f5b372',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007D7E',
        id: '58474b66-9523-464f-81f6-7016e125772d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#84CEC3',
        id: '9b751835-2c43-44dd-b8be-7caa1591f80f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#63C0AD',
        id: 'db1a34c6-69ec-41b3-bcb1-6b2cd9b9eaee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#27B093',
        id: 'd73e46c9-6352-4bf9-b056-2c5c6e2f389e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A481',
        id: '24d90b2c-b4cd-4a17-8529-988fb6b268e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00966E',
        id: 'e874c003-26a0-4fb0-a242-c5774446f925',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008A60',
        id: '65a50c7b-ba23-41be-9cac-1c3a906b1418',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008153',
        id: '746848f8-072f-45f3-a92d-02ba7f1847a1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D3E0EA',
        id: '35bc3b59-cf57-4654-9b14-50ba7253e2bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BCD5DA',
        id: 'ce5f314d-4631-4bf0-9c1d-15350a9d1a88',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EC6C6',
        id: '070cde8a-6b71-4759-9bd1-8dce2f396bd2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#81B8B6',
        id: 'a46cec52-fe30-4ae4-9491-737402d04338',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#63A193',
        id: '9c3ae2ce-88d2-435c-9864-a7eb6204be2e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#458B77',
        id: '254ba182-f42d-4af0-a4d3-c72facc8a020',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#267859',
        id: 'd124e52c-127e-4cc0-a12d-81cc211a0d42',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#72A694',
        id: '2a50441b-c77e-42a0-a4a3-d70e156ee286',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5A9680',
        id: 'a050c8df-faab-4e54-8db6-979d9605efbc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#48896F',
        id: '13a34d99-4b28-415a-bda6-822baf5d5f2a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#337F60',
        id: '636e6a11-640c-4c2d-a216-0c982e7ddd58',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#167254',
        id: '76c62b39-b5bd-46cd-9795-657967072421',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0D5D3F',
        id: 'a521f227-4069-4e26-ac75-1594f00f5a28',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#174C38',
        id: 'c44edfb5-51bb-4ef5-a3eb-a93812d337be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3AAC79',
        id: 'a85f5015-974d-4e8d-b355-a3d2d4144c19',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A26C',
        id: '7d4085bb-7254-40ef-8dd6-1973c367d4bf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009F64',
        id: 'a588b304-273b-4e2b-90eb-92e297724b66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009353',
        id: 'ea9f1f3d-6f8d-40d1-93cf-0b1416df9f1e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008C48',
        id: 'dcbb5aff-bae6-4296-a8fd-d46737aeac09',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00753A',
        id: '03e13560-7dd8-4cea-a44e-51f505a70546',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007742',
        id: '146343e1-f2b3-4b3c-b334-922f61a676e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#74BB7D',
        id: 'f63c0303-62cd-4f35-a8c4-2512cab9f823',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#67B171',
        id: 'a27711eb-949d-43d6-9318-0e347452c9db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5BA763',
        id: 'dcdfa064-5207-4047-8fe8-a1ff0438dae3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#509659',
        id: '58adeb85-a433-4674-902f-43df5321cb7b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E8840',
        id: 'bd55b917-20b7-4c60-be67-9b7401363e12',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2B7E3D',
        id: '89c429c8-2441-43f1-a0dc-20c6b0c66905',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2F723E',
        id: '250c7b36-4923-464c-8abc-b39c3df17e9e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9AC0A5',
        id: 'a9e13e68-0e82-4c62-a77a-91b11fd02851',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#87B18F',
        id: '1da5de00-695f-4376-ae41-18598edb4f8f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71A37B',
        id: 'aa9fc948-0984-4e2c-8348-db3247171e5a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B9768',
        id: '957a3e12-26ef-43dc-993c-81e51c5e70a6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#498759',
        id: 'cd0ab62f-2557-4770-b23d-4d848cc7a89a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#40814E',
        id: '60de4eaa-5d9b-453c-a1a8-c5b5a6e85870',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#367542',
        id: '63180fd9-acdd-4d70-b549-baab1a6bd284',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#798772',
        id: '0c99e7bb-385e-4a01-a80b-9cbedd8ad758',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F8067',
        id: 'd242f54a-dc5b-4ed0-a0f1-1908394fcfd5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#637158',
        id: '791234fb-9618-44b0-a569-619d612ebdaa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#57664D',
        id: '3aa3ea7c-bf24-421b-89ef-865d9082cca1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F5C43',
        id: 'dc14025f-ab59-4697-8943-cc5f36d79a83',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#43523B',
        id: 'ba926e33-b003-4f02-bcc6-3a765796094e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2D3D32',
        id: '8663c003-0483-4cc9-90cd-8cc7ee657aac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3EABA',
        id: 'a62e47c5-e0f6-4f24-860c-1b1cf25de939',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E2E9A6',
        id: 'f2742587-b9fe-47ea-ad5c-a5fbc834e6b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D2E177',
        id: '2e271478-f4a2-4206-8afa-45840ac0d92a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6D843',
        id: '8595374b-e9c6-462d-a296-ccab8b524631',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B5C900',
        id: '3b5c1a1e-ee95-41f1-b086-fcbdb1665305',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#97B300',
        id: '926720c9-ee52-42a5-870c-18ee1f3ce31f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94B000',
        id: '4f81a399-50a9-4b11-a3f9-3bfdaaeee177',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7D9D2',
        id: '88b6bc9d-3f68-4897-b43d-339771429790',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C9CCC0',
        id: 'edbc6a79-2160-4afd-b55d-3c7fdb5485e2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BDBEAE',
        id: '3c58c507-1029-4d7e-99e7-0cf8f528be5d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B5B5A0',
        id: '184e3680-414e-427e-bf98-4bf59e3ebc66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A1A288',
        id: '14c5eaeb-0ea0-4f64-aa5f-607f7a871385',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#949778',
        id: 'ab495c33-d43f-448d-b8c2-1596b1a6f2f7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#90906F',
        id: '32c3674a-40f9-4730-93b2-16b34f3935dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7A392',
        id: 'eb4cac6f-3c3d-4055-8e0b-1a9866cdfa91',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#999179',
        id: 'b3e3830a-cc5b-4c3c-b24f-fa47cda3f814',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F866E',
        id: '9b89ebb2-4942-4b07-9a7e-f1b9ae1752e9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#898067',
        id: 'b096b3fe-bfa3-437f-b9cb-e8ed0066e927',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#786F55',
        id: '7130f3f5-0a38-44a3-8789-d2b9ba9bba31',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6D6448',
        id: '58fb45d9-1e18-41c0-b869-2134ddcade5a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#60583D',
        id: '04dbb669-6d50-48ce-ba17-a87a48474692',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8D949B',
        id: 'f3b68962-6790-4cba-bd88-dede21301342',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E8389',
        id: 'f2c862e0-f48b-4d45-b6b6-ee2ddd6298b2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#72787D',
        id: '076b6d73-419c-4b32-a9e7-ba28b37dfcf3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B7073',
        id: '5f95706a-9cfc-466b-bc03-3f1408710cf6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#606667',
        id: '8139d339-d3e7-4bbc-a16f-7c6924a69ce9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#505250',
        id: '5f4fdd09-7796-463a-8a5a-0ed2efc6f576',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#464946',
        id: '487167e2-26be-402d-92f9-ad5e840229c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#949FAC',
        id: '3bad57b2-f8c4-4e49-9da8-3cf1f4585a7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#83909B',
        id: 'cb259017-1ab7-4af3-9663-ec4c78f753cf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#76828D',
        id: '27c53ded-9ff8-475e-a380-8fa67a141233',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A7883',
        id: 'a5e4abda-4dfd-4539-9875-6d65314a30cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F6E77',
        id: 'b9bcd4b3-6e55-4833-bf59-491804fd7c45',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F5E67',
        id: 'bc72fc64-6667-4322-97c0-df23de38438c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#47555D',
        id: '77716ab0-5346-4d25-92fe-b73ec1cca4ff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F6EB61',
        id: '14530c68-10b1-4541-8ae0-bedb025a49fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F7EA48',
        id: '1b1f570d-0819-4e10-93db-490cbc010eb6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FCE300',
        id: '057bb7df-a353-4329-9d6c-6483f1ad5ff3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FEDD00',
        id: '573fd8af-21ec-4ec1-9c12-db95b56c7bc1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5A900',
        id: 'ff8917f5-74fe-41b8-8cea-f8e31e640ea9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF9800',
        id: '92414c68-f91c-43d5-91b8-69cc2152794f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#897A27',
        id: '94bef6fd-a559-4a76-9e93-79f3449c46a0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F5E1A4',
        id: 'f78b6b65-30b1-422f-afe1-459b2bc773b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECD898',
        id: 'ffc44718-1596-4a67-a272-17eb903edb2e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EED484',
        id: 'f407af28-6b68-468c-84f6-552b2d4a29c5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4DA40',
        id: '8ffdad9b-16a1-4977-8495-6296076d45bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2CD00',
        id: 'c8955e41-297d-4033-8e4e-0f1ffe7989c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1C400',
        id: 'd76425ed-1d55-4e80-b7c6-aca1f97da8ba',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CBA052',
        id: '0575530b-0992-4730-a1a1-9fcd98934687',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8E59A',
        id: '3adbd77b-c5f2-4881-9122-5dd02457767e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F9E27D',
        id: '4dc73e84-46b4-4b06-b631-6c0affca4e16',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F9E267',
        id: 'f9bff763-8c99-4475-b568-488701517abd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FBDD7A',
        id: '31aaf2a0-884a-4447-b433-560db3a4cc9e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FED880',
        id: '3df513e7-e7d5-4cd1-8f7f-01d393ddee9b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EBBC4E',
        id: '6e303b5a-ede5-4953-bf61-60b9c3f16c73',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0A526',
        id: '32bd7489-6992-4886-b1e5-2283ccd54c8f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F9E547',
        id: '7e308015-f374-467d-afa7-c355e1740220',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FBE122',
        id: 'a3294e08-5c23-4fe6-a504-e7111eb4f041',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FEDB00',
        id: 'b2446534-3a7d-4899-81fb-acafe1a62f04',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFD100',
        id: '34b704b9-c3ea-49fc-8cbf-abe033d2593d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DAAA00',
        id: '453ec862-9b2a-4707-ae45-4c2305a35a78',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AA8A00',
        id: 'e571fe3a-fa80-4403-a663-8c5d8fa0c4dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9C8412',
        id: '7a19cdae-c21a-4856-8787-a13b000df9fb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FAE053',
        id: 'efb156ba-034f-4266-a0cc-f541b1daefe6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FBDE40',
        id: '1d1e60c0-80fd-4b3a-ba92-7640c900dd30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FDDA25',
        id: '02677167-f273-41d3-b1da-2cd398c4f0f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFCD00',
        id: 'd31ca46f-97f9-4ed3-a744-333ac9a20963',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C99700',
        id: 'b367d3bb-cc39-43fc-83c8-d0456b8d8eb1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC8400',
        id: 'f1142c01-94b0-4464-9b22-ad8033b4f835',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#897322',
        id: 'c579fae5-41e2-46ef-acb4-01d92c3effe3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3DD6D',
        id: '59d6b3d4-b9ba-4c3e-9008-0c2090055f8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3D54E',
        id: 'd0dbf713-2c61-43eb-ae21-f0b00116b932',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3D03E',
        id: '5134c702-1d1b-4b0b-b228-bc9ccf43ff34',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2A900',
        id: '8d9f1f36-9654-4dd5-b134-052b4f18f276',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CC8A00',
        id: 'ddf6b6f7-7609-4071-8b09-3c8854a2df49',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A07400',
        id: 'cc1ff7e1-a354-4c24-bc19-321f2d7eb677',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6C571B',
        id: '93691610-f97f-4b4e-b4b6-56f4294aa97d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9DB9C',
        id: '4a3d7695-2f73-4f9f-9c32-9506bebb843a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DCC681',
        id: '5831ee78-f4e0-4299-8a04-a5258ba9e05e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5C897',
        id: '290fcff3-d02f-4fd4-a770-5a2dfea61a7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C9B777',
        id: '9ef35b56-9015-4dc1-804c-f991dbab8be6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C0AD7B',
        id: 'c65420ba-e7a3-4f5d-99af-ce102123c1d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBA370',
        id: 'cb0eae51-cc70-4b02-98e9-7522e43311e1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD965F',
        id: 'f1e4e169-58d1-4f01-998e-9e2bed3c8e1d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8E08E',
        id: 'c4698d73-b719-41a7-ac5c-3b2e0d9ddf61',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FBD872',
        id: '58a2f876-d212-415d-990c-18a64c3f7331',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFC845',
        id: 'd8e0cc36-1917-42a1-92eb-cbd2a5c3eed0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB81C',
        id: '9e5ca73d-9ca9-4df6-96f8-1c3ef2bf35bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C69214',
        id: '00b3b718-1c53-4d6a-92f0-4e88ece79046',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD841F',
        id: 'd6d093e7-697d-4fa7-ad31-906a02e9b776',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#886B25',
        id: 'a103e0ea-5573-4323-a210-d9a3ed6c8c88',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FBDB65',
        id: '31963c0f-1e91-4318-a214-32ffa0b754a5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FCD757',
        id: '30a25f3d-53a8-4e66-9f26-2c0b92f9cdbc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FED141',
        id: '28d5673d-0810-4333-a366-154eada0abfb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFC72C',
        id: '210b90d2-c7dd-4411-af3d-5e468d330773',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAAA00',
        id: '88cc932c-30d7-484e-ba4c-ff75e75bad00',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B58500',
        id: 'd4879f2b-4e9e-41d2-8694-f065190c3b48',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A7611',
        id: 'f064a3ab-1cd7-41bb-85b9-c9f447b2b6ac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFC600',
        id: '3bbc51d3-1532-40a2-8600-3a1affefc427',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB600',
        id: 'ed9344ea-85d5-4aa5-ad76-a7926d17a438',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D19000',
        id: '95ebd16d-ac47-4c40-9c2c-81cb24eac489',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B47E00',
        id: '4873aa99-2c85-4cea-a438-e8a2e4186039',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#73531D',
        id: 'a6ddf283-e47d-4985-a5d8-ac085cc12aa1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5A4522',
        id: '8a9f57a1-47ca-4a6f-9473-816960e5f40e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B3D2A',
        id: '1c7b2a7b-9c2e-4066-8952-785f5f0685ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D29F13',
        id: '18f8da4b-1207-4764-b5ff-9e2360cb4844',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B78B20',
        id: '7bb155f0-1435-43b1-b3dc-1d807e22068d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9F7D23',
        id: '7f680d81-db12-4acd-9aa3-e5e7d4a6d831',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#967126',
        id: '5dd1a54a-f9cc-4e28-8e7b-e200a130ada1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F6A2A',
        id: 'eaa3eafa-917e-4bc0-bd60-ef5a3a063466',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7D622E',
        id: '10be052b-b32f-400b-96b4-0babb82d19bc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6C5D34',
        id: 'a524dd78-a90d-4765-905b-c33f75281a1b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FDD26E',
        id: '6602f656-fef8-4cf3-9294-48f668768c8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFC658',
        id: 'f0a6f5a2-8512-4628-8264-d8f20f6c8f27',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFBF3F',
        id: 'e8c77732-719f-47e9-9868-f45bdb1d9cf7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFA400',
        id: '07fc670b-e189-4443-8cb7-f327589d8c53',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DE7C00',
        id: 'a26b6fa3-7d24-4b9d-bb83-8879daa136bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF6D04',
        id: 'cca0ffbc-4711-4797-bf35-3bfda735058f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#74531C',
        id: '0968e07b-5f23-414b-9da3-3a320c3b9da1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFC06E',
        id: 'a405f5d9-46e3-4e1f-8f2a-611922774f60',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFB661',
        id: 'd6634024-da5f-46fc-8ea0-96daec596a80',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFAD00',
        id: 'd2bb2de0-45d3-4ff0-983c-929fcfa9c8e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ED9B33',
        id: '0d201954-5d27-44d5-bae7-bc763570b4d7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF9600',
        id: '5aa11f5d-6062-4a82-9a92-1a1a896a99b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF9800',
        id: '926a85a2-1737-44c1-b436-25d95bd733f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B97000',
        id: '8dfd52e9-7711-4ebf-a392-891cb6696cd9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FDD086',
        id: '0a3df7f4-26e8-4c5a-ac0a-94af46dd0c64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFC56E',
        id: '5994c9e1-cccc-4aca-8c32-5c3da4247a8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB549',
        id: '83b143a5-e6de-460a-83cb-5f5f58de1f6a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF9E1B',
        id: '962f27f3-6855-4ac2-a281-1ab20dd9c1c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D57800',
        id: 'bad1f2b1-9ed2-4117-8598-ac01fe92b153',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#996017',
        id: '7554655d-b496-48cd-ad95-59be0387f85a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E4C1E',
        id: '22fc0012-e9aa-46d3-80c7-1732baa6734c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2C75C',
        id: '0b17f0de-07db-4846-82f2-ad8aaaf67b8c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1BE48',
        id: '1e9dad0c-da21-4547-8a5c-1f7d0481c2b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1B434',
        id: '47c48f14-a4bf-43c5-a429-413392ed4439',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ED8B00',
        id: '8fc955df-c97f-40e5-83b7-4819ef4b41c3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF7F00',
        id: '8027bbec-b310-4297-b20a-84b238006e14',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A76D11',
        id: '7861efdc-06ff-48cd-8894-633750062678',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#715C2A',
        id: '9933270c-8e5b-4e3b-a4b6-1493851e9f07',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4AF23',
        id: '4242a9b2-c555-4100-958c-bdbe15bac3fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F19C49',
        id: 'd099acad-217d-4bcd-9801-d5b3b4883697',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E56910',
        id: '86a4aef7-678b-4be0-890a-6534d906b4cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DE754C',
        id: '20ceefd3-42f5-441a-ac5e-dc1e5d510604',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EA733D',
        id: '88abee63-0321-47ab-a75f-616577b2be79',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CC6C27',
        id: '0f5dc67d-37b7-4bf1-9a26-890cf000b30f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B86748',
        id: '15043de1-fd34-4574-a3cd-6829e907b1a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F6B700',
        id: '6bab9cf0-cf8c-4456-bff3-36f944ef2fbb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DEBC8B',
        id: 'ffbfc543-529a-46d2-8d8a-c2ee170f7d8d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C59014',
        id: 'faa6ab28-650b-4999-875b-2006aebef34e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF9425',
        id: '725aab6e-0305-4e8f-815c-1126985da1e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF6A00',
        id: 'a80cf75d-93fd-4066-9ed3-6a784e03bc5e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DE882D',
        id: '2ecf91ae-db2c-440c-a942-ea5b75b572bc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C25E1C',
        id: '3b62d8e4-0b49-49cf-a010-6fae802ebcf0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D3C763',
        id: 'e1096986-99e5-42d6-b1b9-0289d0c9535a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5BF39',
        id: 'a268a7c6-4ee8-46e9-b48e-ce028f62dba4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DEB83B',
        id: 'c1497b6d-b7f7-43dc-92b0-7512d81e249a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D1B249',
        id: 'c3af9962-7865-4905-b571-974c0d612001',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C2A84D',
        id: 'ea47f51d-60e9-483b-9c93-6ccb97e78562',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BF9F00',
        id: 'e27e0db9-d306-4e11-a656-bdee65b0bbe5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF9335',
        id: '72aec83c-d4db-4f8a-9a50-ece536aa9997',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F6BE00',
        id: 'e5713cc4-8c02-452a-8c3b-6bf3e24bc9dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0B323',
        id: '8ba2da34-8ef6-49e9-bc8b-194901b83140',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FEAD77',
        id: '6c191614-6a1e-49c5-9379-fe2d720b5871',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E6A65D',
        id: 'b6240102-bfe7-4449-9011-7ced6fdd98eb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D38235',
        id: '9521cff5-35bf-4d19-8823-7f799d4f8b08',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DC8633',
        id: '1a67c5fa-6e87-4874-bacd-682535849e1a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C16C18',
        id: '7c8db470-1bc5-4343-825e-72e2f271fad1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BD9B60',
        id: 'a74b005f-664a-41d7-8212-4fc82648e04e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D69A2D',
        id: '1dadf0b9-d9f1-4fc1-a7ca-de29bd8c1c13',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DB8A06',
        id: '88e07ecf-b0ab-4ef2-8df1-86e57fef83e9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CD7925',
        id: '024c3e9d-6b07-4fb1-aa57-54ca293c051d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD6433',
        id: '31748309-e846-4053-8cb2-e33dfa2f4d1c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#89532F',
        id: 'e160a026-68e2-4912-963e-7a27eaae4c92',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#785135',
        id: '4d7a3517-2511-47b4-b0ef-ca05291d153e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D78825',
        id: 'b9a9fc44-b8de-45d8-a44c-bfe7afbc9257',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D3832B',
        id: '318520a4-713d-4bfe-a9b8-06ebe64e9c45',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C67D30',
        id: '68725982-5f5d-46e7-8a24-a1c67b960689',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B67233',
        id: '43672856-053a-4ec7-9956-bdf9f95f47d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7662B',
        id: 'a5fd8ea1-4e65-4fc3-a10e-a024b177fdb9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E6A37',
        id: '31855e33-c333-4193-a82d-456c611b34ed',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#835D32',
        id: '857ba4e3-8265-4a64-9627-a059a62186ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CEB47E',
        id: 'd6784a61-0493-43d8-85da-ee8620f21e88',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D2B467',
        id: '206a1882-8fa4-423f-9f82-d0fac3a70cd1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D4AD67',
        id: '913147d4-acba-47da-9c1f-3318bdef7be4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7A048',
        id: 'c0942b5f-d487-4158-99cd-e561105b997a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BC8C3D',
        id: '6acc1280-9174-456d-a174-584eac8cf3f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E6320',
        id: '1389fb33-fb77-44c7-8059-adbab0c00d30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#83602B',
        id: '546f77b9-4513-43df-b812-a77eec16274d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9CDA5',
        id: '0480c551-86f3-4729-83c2-fed06b45b5ae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1CAAD',
        id: 'e5674ad3-59ff-48e9-87cc-555d708c794e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EACBBB',
        id: '9ff68903-7870-4bf6-8138-4e2910114d30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F5CABF',
        id: 'e0044390-eaa9-4c70-97da-6234695fb762',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F7BCAC',
        id: '943d4415-c793-4a4f-ad4f-90f7ba1db4da',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CB9576',
        id: 'ab578a33-85d0-4dac-9689-c5a8262c3131',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C79674',
        id: '582edbd9-a031-4c56-a111-766521c05149',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FECB8B',
        id: '12a9bbf8-1463-4a22-bc8d-288ce66c7be1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFC27B',
        id: '77fb3483-a0e6-41d2-8b03-04b1d57ad142',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB25B',
        id: '67d97297-2ff9-4d0d-b364-0006c102df33',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF8200',
        id: 'dd12a1bd-c80d-43c5-99ac-2fcbb2d53071',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E57200',
        id: 'fa94a356-b475-4ab2-880f-1a2190656ac7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE6A14',
        id: '87f19977-1bcd-424f-b3c6-6c1765811e7d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B5A1A',
        id: '72a5d612-11d7-498b-8b32-0d1b196385b5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFD19F',
        id: 'fde8fbf3-4578-423b-868e-6037477fb346',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFBE7D',
        id: 'dc80eb32-66fe-4087-be44-c41103e20f91',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECA154',
        id: '0bf5e999-4b26-441c-a040-b6132502c295',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E87722',
        id: '8ebf1859-6404-4829-a046-1770dc21c3e7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CB6015',
        id: '95636b89-f9a0-41fd-b2de-6ad42adc3054',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A1561C',
        id: '90489a53-929f-4e26-92bb-07681c742dcc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#603D20',
        id: '3faf4054-8632-4f75-8302-b5ba15a4354a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFAE62',
        id: '3faf2f68-d4a9-43f8-9e55-ced6a81250f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF8F1C',
        id: 'ef9c5fb7-ed2d-4273-9051-d2ac97516371',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF6900',
        id: '42386eac-fba4-4fe2-b077-05a2a68166d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FE5000',
        id: '4fa37bbc-e8f5-4297-b8ff-a9f71da42212',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B94700',
        id: '4c174a15-7229-4285-82ca-df32a92f8815',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94450B',
        id: '2a164772-c7fc-41cb-a03b-cb57dc12fd24',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#653818',
        id: 'e123d251-d9f9-49cc-8c8a-30738c9cb8d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB990',
        id: 'a1ca1ab0-da9f-46d0-9546-aece1b953728',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFA06A',
        id: '23f767fb-7389-435f-99fb-bdeaf270488d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF7F32',
        id: '88ad1575-77b0-4ebc-8ac3-eb5ef4c18b8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF6A14',
        id: 'f169c6ab-d4f6-408b-893c-3e8f1dec92b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D86018',
        id: 'a43452c3-e751-4500-94fb-0f1cf032b07d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A65523',
        id: '7963b593-ceb5-4a70-94f8-b0b5c369d0ee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B4720',
        id: '32399137-56c4-4e23-a381-a77e291f6ec7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FCC89B',
        id: 'ef7694d9-89a2-4639-b22f-161969af1271',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FDBE87',
        id: 'f9663d65-3c0a-4c63-a318-de4587595a88',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FDAA63',
        id: 'caf1121c-0b9a-4b99-81e3-07c45711649f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F68D2E',
        id: '8374c6e0-4dc3-4438-837f-eecab52e2773',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EA7600',
        id: '31110edd-639a-46a8-8bad-421786421ffc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D45D00',
        id: '6083695b-ea73-448a-81b7-f7e0beb9b75f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE4D00',
        id: 'a4d479c0-ece2-46ac-94b0-a3ebc3bc14ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8CFA9',
        id: '4afa3db1-e573-4011-9d39-f84100ba98e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB673',
        id: '3c5e5ee0-fd54-4436-a336-a38428fbc58b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8AD6D',
        id: 'a18b30d9-1f0a-48b3-985a-6870555e6862',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF7500',
        id: 'e9a31eea-f826-484e-b7a1-fd108455fe87',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D76B00',
        id: 'e087ab2d-b592-4b4d-8a2c-eea01510e844',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE5400',
        id: '215fca89-cf0c-457e-b9d0-5e049dad101d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D4815',
        id: '76026eec-c1bf-455d-8f12-1425f74378cd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FAAA8D',
        id: 'ec47f505-aa37-4052-8727-ec0a791bee6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FA9370',
        id: 'f9046159-b851-4ef7-9f84-aefa8ae07917',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F87C56',
        id: '963ef2f2-0e05-45b4-82f3-b892c2bc57d1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF8A3D',
        id: '3d2a2ef4-a2f1-4920-933f-693276be7867',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4633A',
        id: '55d0a4d5-4766-44c5-9274-3b0d35f13cbe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EE5340',
        id: '50491202-0c6a-4226-ba56-b3120613d235',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EB3300',
        id: '805963a8-7321-453b-8029-6d7e30c6dbf4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFBE9F',
        id: '15e70f04-d03a-42b1-a75b-ac1ec99b8202',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF9D6E',
        id: '1325d456-83c4-4380-b73e-d3ef02db4cd5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF7F41',
        id: '20c6be92-0ccc-49ae-b37b-f509dfc004a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF6720',
        id: '9ea52a69-5c33-400e-a15c-34a46429da53',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E35205',
        id: 'eb942f2c-d079-4605-b9e7-12334df03537',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE531C',
        id: 'a5d31d0c-3d19-43bf-942c-120d8eb29da8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#73391D',
        id: '17831cec-d1ff-4bdb-adb0-b2c9c4fc4a3b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DB864E',
        id: 'ca4ca5c6-2653-42b8-b629-79eb3f7592a0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E07E3C',
        id: '8ba99230-8612-4711-a856-d2b91e35ec39',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DB6B30',
        id: 'cbd2db49-4fae-44d3-9668-810353ce9393',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DC582A',
        id: 'ac0fe88c-c2a1-45c4-8e5e-d92e3ff55154',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C05131',
        id: '642dc4c8-a3f3-4419-866c-29854a635645',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#864A33',
        id: 'b9d9127e-5b1f-4523-a89f-3daef90aa7ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#674736',
        id: 'c4478a7c-5e13-4423-8a4b-1a1d76a718ac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7A179',
        id: '14fa86f5-df25-4c83-a63b-d403dc4bbf8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DE9353',
        id: 'e262ecb2-f612-4a22-9538-f6f107ad021a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DE8C59',
        id: 'e7da3d62-e107-464c-8528-d7a25c66c4d8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DB7B51',
        id: '76a423e9-91c4-4504-86cf-b7ef93dbcf7d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3785E',
        id: '0c1330f5-5e76-47bd-8bfb-10682c5935f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C17059',
        id: '57cd0b74-212e-454d-a404-0391b196aa6c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6533E',
        id: '3266eaa5-43a1-4d78-be7a-14d8f69f1290',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFA38B',
        id: '2ba626df-ca8c-4282-ad10-20c6c1509885',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF8D6D',
        id: 'a3563cad-b396-4333-bff3-29b533733b09',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF6A39',
        id: 'd045b64f-e595-4794-b90e-5ac4d1866eda',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FC4C02',
        id: 'c22c6db4-92a5-4df5-9d09-10286b1d2e06',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DC4405',
        id: '31b521cb-2ead-4f4b-bdca-db4cb438e1f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A9431E',
        id: 'c4bed3a4-ab7e-4236-a0bd-ebbca2acb286',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#833921',
        id: '3b20182b-76d8-47a2-a9e5-6a9406256452',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB3AB',
        id: '33a796ad-992b-4267-8511-3193f74657b3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF8674',
        id: '7915542d-f448-4068-a2b8-a0e1b8064de4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF5C39',
        id: 'a05b36c9-de69-484e-9a55-67b573c52090',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FA4616',
        id: '9db8bac1-a78d-4901-88c5-391024f3daff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF4520',
        id: 'abaa32e8-2c9d-4844-bb9d-3ea98201aec5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#963821',
        id: 'dfdbfca1-ee5c-4006-9768-ef1f8725958b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B3529',
        id: '89635ed9-499d-45cf-9bff-851852818e5a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4622D',
        id: '64b53b9f-96fe-447f-8b9c-41da4fb0914b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BA5826',
        id: '374591d7-44ec-44a6-9cba-35bc416396ee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B05C37',
        id: '15b7316d-1cb5-468f-8451-df1916f139d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E5330',
        id: '4a9e1f38-8d26-4224-9ac5-7407bbe5646e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#924C2E',
        id: 'ffb87c8c-a816-4a87-a567-142ad482224c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B4D35',
        id: '2cd52970-93f8-4a75-8fe9-8af926a356cd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C4738',
        id: '5fec7040-626c-4a95-978c-95fccfc29930',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D4B59E',
        id: '5e8329d0-98db-4c2b-98de-edd6ac78f744',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C07D59',
        id: '16a99475-7312-4c08-9213-2b0544267087',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B15533',
        id: '9ccd8966-0bd7-4a8b-8c56-c9ad5599f31a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D432C',
        id: '83d55ae3-1886-4427-a533-78fe64db4e99',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C3A2D',
        id: 'ed9373a1-e55e-4782-94b6-b8a4a447eb9e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B3D2E',
        id: '449225b2-4d01-4315-ac5c-8413f316e369',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C3D31',
        id: 'd1542256-c21a-4f61-bee1-ff95e45163a0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D14124',
        id: 'dc9d3c2e-653c-470c-af60-96896eef29be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BD472A',
        id: 'fc38cb25-bb8c-4bd6-9965-973e232d0124',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B33D26',
        id: 'e1e154a8-d36b-4f6c-bb79-900eaa49e0ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8D3F2B',
        id: 'b694340d-ffb6-4006-bf40-37c71a211bca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#83412C',
        id: 'cfca0826-375b-4f4d-8cb7-60ff7a6e022d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B4931',
        id: '0f07ca3c-6f88-4069-93d8-b5494ac0dbbe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#674230',
        id: '888a6732-7a2e-4870-becc-50ea1dbc31db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E4D5D3',
        id: '02e65d4a-f0e3-4f02-b4e6-710df50d1c8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1BBB4',
        id: '1b8a4dc4-6fdc-41d6-86d9-d570737507db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6938A',
        id: '6a160677-b738-4c5e-b74a-63fa81776cf5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C26E60',
        id: '82858053-753f-46c7-b363-725eb032c40b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4493D',
        id: '7b3a851d-c276-4231-9a40-03c3f3c510e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#823B34',
        id: '272faa9d-b908-4760-b0a9-68f750afee30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#683431',
        id: '5c450b46-94e3-448d-8e5c-011964184300',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DFB8A7',
        id: '4469ab91-a598-437c-9e23-4dc9a3ad2c9f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDA69D',
        id: 'c1a2618b-dc27-40d0-b63d-3c93019a33c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1A6AD',
        id: '8d84ed14-d5a6-45cc-b9ad-f0e151729e94',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF9DA2',
        id: '39a51bfe-1cda-45ef-950a-1e5154bb12b1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DD878D',
        id: '3167b4a9-0eea-41a5-a493-3fd2935c457c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0858B',
        id: '16df9ddf-92b7-4645-8866-df9fa304d5fb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B06F76',
        id: '36659c8e-149d-45f5-8248-ee2eaa2134c2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8C1B8',
        id: '64adb8e3-d5f0-4166-9974-fcbb116058c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DEA39C',
        id: '2d31f775-3f30-4d2c-bdf7-543d8c456dcc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F09491',
        id: '5bfbc960-6a57-4510-9d27-f1731d767a8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF6F77',
        id: '252a921c-98e3-4d22-9566-06ae46196a19',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B66C6D',
        id: 'a2a572a0-2dbc-4a71-8d67-a99fcd9f9aff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B65A65',
        id: '36b151af-72ac-4bd1-9ae0-a538accf4e4f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A85566',
        id: '72550df8-65d1-494d-982b-0b07e41f7d67',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C09A97',
        id: 'd56e4d21-277b-4798-8d2b-90299ff59e97',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B47D75',
        id: 'f80a98e1-a827-4a74-95b3-045769b762b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B17368',
        id: 'b709d16c-354d-4be9-bc49-269abd469656',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD6960',
        id: 'd47afe72-b86d-47b6-a9ab-f7ed32961e0d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#956A64',
        id: '4de6209c-54ab-4cd3-b1a7-891bb4e8237e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#815E58',
        id: 'c56814a6-29e2-4c68-b9e7-6e8b41b06c8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A5D53',
        id: 'd0d9919d-8499-4ed8-a214-1b28d726ee22',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDBCB0',
        id: '6e215d8a-b870-48ce-98b6-3f134e4470a2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CA9A8E',
        id: 'd3528062-d0e1-4f56-95d2-4213f55cc85e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BC8A7E',
        id: 'eb1f8470-e079-460d-9b11-24eccebc389f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A47F74',
        id: 'c7a0a63f-44e7-4468-986a-6746ff0c3dce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#866761',
        id: '3cc679ed-be06-4cd6-b8ba-4e3d6c09d2f6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B4C4C',
        id: '9a089a9f-4f72-4539-815a-b25c2c4557b9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#583D3E',
        id: '5f44840d-d7ba-4cff-96da-7c64139e07e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EABEB0',
        id: 'c225f5c6-8b64-487f-9f4d-57462f66589c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C09C83',
        id: 'e3def4a6-b6d3-4d10-9564-76063211330a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B46A55',
        id: 'deabb6e4-4a50-4313-ad5f-bca26ad019a2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB5C57',
        id: 'ed670903-14f3-4ab5-9b56-f2bebfc10863',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A45248',
        id: '2e02464f-a076-45ae-a52c-b1a606553d81',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A6A4F',
        id: 'bff385c7-2fb8-476b-b736-25d186bf8260',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A391B',
        id: '91a25697-9c27-4348-b964-a8c55009001b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2BFA0',
        id: 'ef0945cd-a208-4e4f-bd61-9f404839f247',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3A184',
        id: '65a95f60-3371-4a2c-97f7-16b91e73a3f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C48672',
        id: 'aa2e933a-5a76-456b-a29b-1f1603ef34dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B59289',
        id: 'da4b3e17-9b2c-453d-8277-6b33731304a0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A86F5F',
        id: '0c21c7db-edd8-4ddb-917d-2bd5b60daefb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9F5F54',
        id: '6930d82e-ea7a-4fa9-9a9e-f96b0fb9c498',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E3A22',
        id: 'b39c5d9e-09c6-4ab2-8768-1abc8cda2ed4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1C4AE',
        id: '96e20c39-4e3e-4341-b931-a5ba9c5bb482',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4A28A',
        id: 'a2d12e1a-bbe9-4da0-ba1b-bbedcaf773c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BA9682',
        id: '6a08b2e4-2ff5-4443-8bde-841e978666a1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B29780',
        id: '6ea1bfd8-8dc8-433d-afe5-8c6b1d720d55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB8C75',
        id: '37fdc1f6-d6aa-45ca-a458-c51c7d8664b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A18269',
        id: '6f6b517d-7e5d-4172-b516-72a7d1ff7aa7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#96826D',
        id: '22fc006f-8d27-4cc3-9b8d-c8bdd9dba7de',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECC3B2',
        id: 'e64260e4-148a-4b3e-a8eb-35e652737323',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECBAA8',
        id: '7406c3f4-5d18-40d1-a496-26e30605e566',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAA794',
        id: 'eb378700-1d92-4e0f-9988-322ecdaabcb1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E8927C',
        id: '36074f67-2f29-4f3e-bae5-6f27acef203f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DA291C',
        id: 'b5a7a0cc-981f-4315-882a-b0bb8258b367',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A3324',
        id: '7922a861-907d-48b4-ba9a-d37cf45bbb5e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#653024',
        id: '392016eb-1fe8-4f14-b928-5ad249ed80f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2827F',
        id: '353c7fb0-2854-4b71-9f26-793722d93131',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DD7975',
        id: 'e2aae256-1b8c-4a7a-b4cb-149ac7387088',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D66965',
        id: 'b0943af3-3276-43e9-bb7d-368172af409c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D05A57',
        id: 'd5d6719c-0494-4305-afe0-204d3be64b68',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C54644',
        id: 'c90c15ac-7639-4f85-91c2-145147330cc0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E73C3E',
        id: 'c50c8d8b-aae4-4888-bb87-29117811d5b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6001C',
        id: 'ac99e03e-a2a6-4e61-bb4c-d414c5e612d7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F18070',
        id: '0bd840d6-8eff-4aa4-9601-3fd597d748c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF6D6A',
        id: '9bba107b-0e39-45b5-b03d-4d0250629225',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF5869',
        id: '53826f83-a90d-488b-ab44-ce07979d888e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E10600',
        id: '17791666-859c-4723-8392-fd9c1605afcd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E5554F',
        id: 'b0cb1a50-46d7-4ad4-b50b-d05acf0dda1d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CA3604',
        id: 'e92aada7-a5b1-4548-bf16-07f7ba8fa666',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF231C',
        id: 'e17a3d9e-4e0a-4b01-a491-3d41a5302838',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9AC95',
        id: '470a368f-6d62-4c49-8a2d-926e62d4a1cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DF8877',
        id: '2cc57013-8427-4356-8b76-46d78b5b5d36',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DA8879',
        id: '5a517fc8-e864-4aad-8803-345c76623771',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF7B69',
        id: 'f1654530-2b6d-478e-af75-b3a84f854e5e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A9533F',
        id: '40fc330c-fc0f-4493-a9c9-5935bfce993a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A55343',
        id: '50dc6b2d-663d-4c91-89bf-a24791041715',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#874F47',
        id: 'c26d8e4b-e4f3-49f6-8eda-9484eaf62c5d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E2665C',
        id: 'bf99ea82-3e59-44e0-a854-5e44ac848a3c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E44B55',
        id: '7ec1efed-36cc-457c-b2f7-d126921b678e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C12F41',
        id: '28e559af-07ed-46c5-9b2d-55d0ffee88e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BD3742',
        id: 'd6784c16-ce7d-431a-921b-c9ac27b9630e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BD625E',
        id: '46946f04-0828-432b-bb42-7cb0d62e9b1d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A84544',
        id: 'df0d848c-866f-4988-a4a4-6980955da378',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B3F34',
        id: '676d8e7a-8215-4c71-b678-af38952ac4d8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFB1BB',
        id: '2e1e0e32-540b-4af8-8cc9-3ab768ff10c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF808B',
        id: '5e0e7e8a-13f2-43d0-9208-89ae5eeb3c5c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF585D',
        id: '181dc4f3-45f8-4100-8e22-6e6488ee462a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F9423A',
        id: '7fda142b-b732-4334-9f5b-fdf7f7f8b3ae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E03C31',
        id: 'ddba95e8-1ce1-4c0f-b3f6-0c66a3ac9516',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE3A34',
        id: '8a62d432-3076-42b7-813a-124cc5bd3eb2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#81312F',
        id: 'ea7982d7-080c-4335-b75d-9abb808b74cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FFA3B5',
        id: 'c7cd32a7-96be-4a26-a702-4efa7e494571',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FF8DA1',
        id: 'af0015ef-4d4b-4d9f-9791-033718194dfa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8485E',
        id: '0bc4b624-7e03-49c4-9ec2-41e6fdbf13be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EE2737',
        id: 'b779d06d-84b1-4109-9539-c4c24009f3ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D22730',
        id: '5f738516-d394-41cb-bf48-54a472694a2a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF272F',
        id: '48ece505-d0c0-43a4-9cee-1ac364d89c3a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C2629',
        id: '3e77c8af-4e41-4ed0-9ca1-d2df689be8ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FCAFC0',
        id: '9b28936e-06c0-416d-b7e3-f575fbdab4a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FB637E',
        id: 'eb0d3a4d-960a-438f-ae97-05968e16f377',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4364C',
        id: 'a070c817-b443-413f-bf9f-b87293873d72',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF3340',
        id: '082c16f3-c351-42c8-84ce-01ea57f5b38c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CB333B',
        id: '11e202f4-f7b9-401b-a333-9af26d181133',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4343A',
        id: '7f37085c-805e-4daa-b7eb-00ff5e6e2345',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#643335',
        id: '642ed3d7-332a-4bd6-9956-e8bb0f25bfb4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DA6254',
        id: 'c9224490-aa9b-474c-a686-3c05797e1dbf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EE3831',
        id: 'ea56268a-8685-4aee-9504-87acbd9b9f2d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DC3513',
        id: '2fc6acac-9ae7-4ca6-ba51-762f35f26036',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CD001A',
        id: '98f6b70d-9cae-4019-952b-2f331faa699e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C10016',
        id: '3f1d5d78-333f-4e22-b232-0772d8114f11',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#742E34',
        id: 'cdc27407-c387-4617-8867-729f612b7eaf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#472426',
        id: 'f08dfb97-8c21-4721-a173-66ab9c481ff1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C66E4E',
        id: 'e3587131-bb3a-4e5e-a0a0-4c0ed171633b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C04C36',
        id: 'a469edca-59e0-49fd-86eb-52271e1cf964',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7312C',
        id: 'eef31600-2d1a-48d9-9ee4-59559685c7de',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB2328',
        id: '4a645a5e-d5d4-454d-bb38-507e49ecedd7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93282C',
        id: 'b23100a1-9259-4137-96ae-9c2162775e2e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A2B2B',
        id: 'db074857-fb30-4803-87f0-e0f8de1292f6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#802F2D',
        id: '7ffbf975-fe67-441e-b7c8-7f600522d995',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1523E',
        id: 'f3b4db5c-caa2-497d-9c2f-6b00a28efecf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C63527',
        id: '560d5624-9206-4be7-b8b7-99c83a903887',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A72B2A',
        id: '79208293-70a2-434a-9262-e4c27daef779',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E2A2B',
        id: 'b8f9ba6d-93e9-46f3-9e49-761211ea95e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6D3332',
        id: '8107e443-bbf9-492a-b9c0-d746f7d3ea3c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#633231',
        id: '36b7ffa6-cf62-4aca-b70d-d46f79a1d214',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#572D2D',
        id: '0f3af631-e4a0-4571-9b92-2f9f4a16059b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E6BAA8',
        id: 'ff7bfd96-4c27-4082-8df0-3ee5c831a8c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E56A54',
        id: '2c9ec430-95ba-47d0-b58a-f0b34b3d4cf2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E04F39',
        id: 'a795ea27-4976-4406-815f-4ce687de0232',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CD545B',
        id: 'e3c61b73-aa60-44a5-8b9a-e7bc386d58b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B04A5A',
        id: '0ccf1d44-4471-42bb-a444-be6a4465aff9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B2242',
        id: '4e0b674d-7392-4877-8a1e-018487543532',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#651C32',
        id: '3c695a3b-e7b4-44aa-b391-8da6361089d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FABBCB',
        id: '9c030bab-3303-4bc8-954d-8e93d459922b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FC9BB3',
        id: '8c200c06-3fb2-48fd-b253-cb55edbdf6e7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F65275',
        id: '51e90b76-205d-4036-ae85-7e757cac4370',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E4002B',
        id: '82a18c86-4f4c-43cb-b78b-f7f692936473',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8102E',
        id: '3fbc1869-52de-4b16-ad70-5f671a46771c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A6192E',
        id: 'f07560cb-faeb-4bdd-9f43-8000a244b7d6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#76232F',
        id: '732af56d-1ac1-431f-a435-445688a5b465',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EBB4BB',
        id: 'b1116d66-e689-4b0a-a6d5-5194ad459e7d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E4AEB1',
        id: 'ca0e8dec-e559-47c6-82f9-2ed464de2293',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAA09C',
        id: '678bd120-a458-45a1-9f48-5132a7eb3e4b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E89E9D',
        id: '85af0417-bb43-4f1b-9299-6017172e34cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E48F93',
        id: '3725aa45-c6ea-4416-be76-95ba25bbbcb9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C74E61',
        id: '73cfe43f-4154-4e71-aec9-30721e945124',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B53B50',
        id: '16fcfe12-0cce-4c6e-8f1d-19a648836253',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECC7CD',
        id: '1dd3bb3f-0225-41f2-9c16-45a9eb099779',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E89CAE',
        id: 'e894a7f5-26dc-48e8-943d-de152637938a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DF4661',
        id: '4324181b-b46b-45c0-bd09-1ca07024a9fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D50032',
        id: 'ec0d3fd0-b371-4d9f-9cb6-97b20c03b2c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BA0C2F',
        id: '77724aab-049a-4dda-95ca-d6459f71ebeb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D2235',
        id: '1387d2b6-cc43-451d-a548-d1833ef1b5b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#862633',
        id: 'ba230ec2-b931-44e4-9d48-e1c22993a9db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8A3BC',
        id: '27e0f30e-3e16-4529-b890-99b0ab1871d8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F67599',
        id: '6f55b92b-715c-4c42-b2c5-0052e57321a5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF426F',
        id: '241632bb-ba5e-45be-9556-ec2a4ae7d2ef',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E40046',
        id: '40e678c6-ddc0-4235-a350-06ea3823e931',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BF0D3E',
        id: 'f1703f8e-fa1f-42ed-980c-885f5205e2bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B2743',
        id: 'e765edfe-962f-4bc1-a40b-3ad0156e9849',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#782F40',
        id: '15246dc5-0a8e-48d8-aba8-598424aa76bc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F5B6CD',
        id: 'cea618ef-a9cb-4b3f-856e-e5b44b43cf55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F59BBB',
        id: '7b4575e3-01bb-4d7a-bf04-6c9066563a27',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF4B81',
        id: 'ed895dd2-caf5-4c6f-92cb-8bf9a5fb1455',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0004D',
        id: 'aa4a80fe-0cba-44ff-a5df-84d25c7f9e42',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5003E',
        id: 'e9e15cf5-6173-408d-b18c-983d0b1f259d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A60A3D',
        id: '81871121-2869-4e91-ba7c-7fe5777ef975',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A1538',
        id: 'fc830e2e-a293-4f46-a300-6b00f15784e4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F5DADF',
        id: 'c6bcbf96-f4dd-4a69-9c07-2d1ab7e0286a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F7CED7',
        id: 'c8426e80-5161-471b-9343-6ae3d79b63fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8B5C4',
        id: '068c647f-49e0-46fd-aac4-dde12b886692',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F891A5',
        id: 'e71b225c-118f-48d5-89ce-346e2d0e1b25',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF6079',
        id: 'bf6e9a6d-83b9-4a1e-be09-aff1d3f6bbd6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E03E52',
        id: 'b4ff22c6-3598-4da2-b266-4f29e3c0f9fa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CB2C30',
        id: '4aeaeccc-3a80-4236-93ed-0b305ebb2ccc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2D4D7',
        id: '7ae3576f-e9e5-4daa-9bd6-34bdcf807e15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4C3CC',
        id: '1b88c899-f211-4b22-91f8-88d2a1d2e6ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2ACB9',
        id: '7ff79db8-5169-4e53-ab09-0abe0eae094f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E68699',
        id: '7b622213-c3d4-4172-80ef-42e86ffc3cfa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D25B73',
        id: '6cfb9c50-6c52-444c-a627-3362c439a6d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B83A4B',
        id: '7c451dd2-7167-4173-bb13-b5c8c60a3814',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E2B2F',
        id: 'a4de95ab-5d6d-4f5b-8c10-ed3eaa364c61',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECB3CB',
        id: '7a225504-7cd8-44ce-ba67-6edb085aed6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E782A9',
        id: 'dbcf73e7-26c4-4342-9368-53b8b2731335',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0457B',
        id: 'eda55844-af0f-4c6e-a934-a77d47939f3b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CE0037',
        id: '29f212b7-7190-4dd1-a3db-f3fd63b7ba03',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A50034',
        id: 'e0137dbe-8738-4757-be4e-f9d5aab857cf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#862041',
        id: '90e20a52-d452-4b9f-be81-ec610623426b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F263D',
        id: '8ba1aa7c-b0e2-4974-b744-d685fe6a9d47',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F99FC9',
        id: '34ebb278-b954-483b-9f54-57b4cf254021',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F57EB6',
        id: 'dd7504ff-955f-4559-8ad1-620abacc99d6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F04E98',
        id: '7317da75-72d6-471c-bf63-3290ee6ec7fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E31C79',
        id: '15af2c46-daa1-4492-a30c-edb95cb9fc33',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CE0F69',
        id: '6fcb37c7-5a29-4e2a-a70c-2774a1df2911',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC145A',
        id: '1c3beac0-5d93-4187-b447-7541bb7d24a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7D2248',
        id: 'ec991d08-3a68-41bb-81ad-6cc79abf3475',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F8BED6',
        id: '83c39c0e-cddc-43f4-9f0e-5f0602c903de',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F395C7',
        id: '4f484d34-3677-411d-b3d7-617912f5f1c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF60A3',
        id: '0dc7a3da-5567-4389-bfaf-110ca158894f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E63888',
        id: '8eb0100f-97a0-4bc3-bd62-6fa84adc0c86',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DB0B5B',
        id: '03f511c6-1d23-4b65-98ab-eeadd6e85c40',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A1224E',
        id: 'c486239c-f9bc-43f0-9a3a-259971f04062',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#70273D',
        id: '5448c91d-68a4-4178-855b-de043652df1b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4CDD4',
        id: '88298baf-945c-4846-86c6-e386003cbbc6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E06287',
        id: 'dc0074c6-4861-4e37-bbd6-323e4532add9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E24585',
        id: '17d3c239-6064-4932-90ee-50979bb5943c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B52555',
        id: 'c99cfbba-a921-452b-ba6d-83b64b34a9c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4123F',
        id: '211b2b46-748f-468b-bc9f-4d0d4a402c06',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#971B2F',
        id: '3243a503-b7a6-4f46-bb51-30dbc877b50a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A2C3E',
        id: 'e76b1c9e-c62f-4133-aa8d-9710c949d89e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF879D',
        id: 'fdeab2c0-6b0f-40cf-954e-7663087aa383',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DF7A99',
        id: '9a889f87-2a17-437f-a569-419b2f85c3a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C64771',
        id: '7df7588b-cf5c-40a4-876d-404a193b1927',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7D1B49',
        id: '7e57a49d-7ece-4046-a34c-a5f62fbdbc32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#823550',
        id: 'a2403031-8989-4688-94bb-3fb32a5fe423',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#824259',
        id: 'e2f717d7-b36c-454f-acfe-3cbba49e3797',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#834553',
        id: '104eaf8c-3215-44ba-ad93-95a1f0f55e76',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6C9CA',
        id: '496aede6-c6a8-4d24-add3-c4e110e2ebca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4A4A7',
        id: '3785bf2f-2086-47ca-b877-0678973f6b32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C16784',
        id: '8d2c08a2-d693-48af-894d-0d69b5f6cd56',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C63663',
        id: '4c843d3c-c9b3-4d7e-a412-415e0c77e9c5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BC204B',
        id: '7ea60ba6-59d4-4e49-bdeb-cb0b54594d93',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#912F46',
        id: '09f935c7-6624-4ae8-b26b-dee6965a36a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E2D40',
        id: '18449c16-3e4d-4ac2-8192-c042c7b00e18',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EABEDB',
        id: 'a209c62b-b063-4322-9da7-c10239db5fa6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E56DB1',
        id: 'b510a924-c402-415e-97f7-91436606ec7f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DA1984',
        id: '3b06521a-a1e2-401b-a97b-0bdcc43e7c39',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CE0058',
        id: '53a557ef-1cf5-4045-a60b-df8bb6f682cf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A50050',
        id: 'ad49e45f-442f-4823-acaa-8df584e13231',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#910048',
        id: '9161b08a-61e6-45b5-bb93-32dc7b56a1b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6C1D45',
        id: 'b0d240a3-df2f-450e-aef3-039097579b99',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#936D73',
        id: '62e1dbee-c361-4198-bd97-7a694a3abe3b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#934054',
        id: '9a80b69c-adde-434a-b554-99dd7c5f63f3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E2C48',
        id: 'af41a239-441d-444e-abd0-661becf740b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#732E4A',
        id: 'd77b9554-3078-48a6-a038-140af5acf9ee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#672E45',
        id: '8d5f1376-7ea6-415f-80b8-9de67c87af3f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#582D40',
        id: '607e7586-2012-41a0-9379-241ea7d6930d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#502B3A',
        id: 'f414bd88-cb93-4355-b928-1a73b03123f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0C8D9',
        id: 'f800cc40-e2d3-4248-a19d-e41fe72e00a6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1BCD5',
        id: 'abf5009b-86df-4491-8f28-98d54c387f85',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D74388',
        id: '873ce8ef-9880-4071-882c-cda26e9ffd98',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5419E',
        id: 'd88087e5-5df8-42c7-a8f5-55ddef6f490f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D173A6',
        id: 'ce26c0c3-c9b1-4b6c-afb4-b7b6ec0038b3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B74271',
        id: '048688e9-c4f5-40f4-9cd4-6bb482edee16',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C3366F',
        id: '6f9c859e-b205-4141-863e-a7f3f7f79e10',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EF95CF',
        id: '6b2adc09-b33d-4d6f-b350-e38bf19ce191',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EB6FBD',
        id: '3c662e44-87c7-45e3-8299-19aee24d3c34',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DF1995',
        id: '610826f3-5ade-47fb-b392-2fb53ebcff47',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D00070',
        id: '5de34655-457e-4e61-90fc-0f0a41b6700a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AA0061',
        id: '811802c7-38b8-4455-94b6-f584b166db32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#890C58',
        id: 'f85fa985-ef0d-4d98-abc0-53fc35e42492',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#672146',
        id: 'b8c9dacd-4ccc-4a7c-a1e0-2d4769aeaae2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F4A6D7',
        id: 'adacd5d3-dd78-450d-a151-9c73f43a3791',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F277C6',
        id: 'd2d6d181-4e4f-4f74-bba6-11a905fd2a97',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E93CAC',
        id: '04d308f9-56a5-42f2-903f-f107995b5d3d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E10098',
        id: '2d7a0336-a5d8-45eb-8df1-c930359136b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6007E',
        id: 'b8cbe5f2-59ba-4e3d-88a7-6235e0cfdde7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A20067',
        id: 'e3283ad8-c9b2-4ad9-abd2-56aa2f28d3a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#840B55',
        id: '5a90d136-715a-46cc-bc82-5527eef64f69',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAD3E2',
        id: 'c32fcfea-e856-4e61-a324-c44515f5b54d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E6BCD7',
        id: '04688746-de53-4739-8155-5d823c7aadbe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DFA0C9',
        id: 'a6259b59-0815-4dc4-b9c4-ae611227ff27',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D986BA',
        id: '8e654b35-c2e5-4fa6-b3be-6f03205ac4c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C7579A',
        id: '785117d1-7de3-4b69-834b-8ff20471a4d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AE2573',
        id: 'a308f0cd-60b3-4841-bc2e-272d5e4efc59',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#960051',
        id: 'e79a2907-cf54-45f4-95e3-1a5d8c3c7b15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAC4D2',
        id: '367ef1d6-ff26-448d-89b0-eb6584585ec6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E793B7',
        id: 'd2cdda22-8c17-4036-94ef-f1bb85e227c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DD74A1',
        id: '0a4b6889-f34d-46ee-9669-6af448226344',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF578A',
        id: 'f703f251-525c-4c01-8c7c-8240cc3d2f64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A64570',
        id: '2114f9c4-fdf2-4db5-adab-0938f0b97504',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#973961',
        id: '1c2bfaea-2957-47a9-8aeb-9b0425ae1ecb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#852C4D',
        id: '643d7e58-cfe8-452a-af96-7c987d865f15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E5CEDB',
        id: '064a5004-2e26-40da-b653-f911db8cac8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3C8D8',
        id: '90f7d374-e9da-4f27-9b2a-a2b01cda98a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DEBED2',
        id: '8d189da0-448a-409f-afff-79491dc3bcfa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C996B6',
        id: 'a892cd12-a27d-4cf6-964d-72a8d4d79ccd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B06C95',
        id: 'e1b95f0b-6d73-4436-a966-5f0f4e360236',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#994878',
        id: '385df800-4b40-4691-95cc-0af1fcf6b21e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C2855',
        id: '7d516a3f-a0a7-4698-a247-4f626db33db9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFD7E5',
        id: '6c986f95-c1cc-4d05-b2ea-7d4af3e27dd5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDC2CF',
        id: 'd8d331f0-8c84-46dd-9321-af0662bc7f9f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C89FB6',
        id: '67565e60-0ac4-415f-9a77-c5f1973b39f3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B3809D',
        id: 'b6d0dc24-903a-465d-b8c7-b11e585738a4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E708B',
        id: 'b50e4d24-b6e7-4b42-a218-d55864ceb0f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#905E7B',
        id: '3290bc1b-d245-48d9-9543-68d8313f1fd3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E4966',
        id: '4669c252-7a8e-4aab-82fa-b388f265e949',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E7C1D5',
        id: 'c4ca21f2-4da9-47ba-8feb-04aab6764d19',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDA7C1',
        id: '9afec0cf-4a47-4c70-8c34-d8236fa52e7d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0B0CA',
        id: '8556f65b-b55d-4f24-a675-97edc3bc987a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF90A7',
        id: 'd42803f4-9493-4db3-ae91-137009a4332a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A86590',
        id: '23ad8edb-8c1b-4d64-966a-3110289ef83a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#865991',
        id: '3c731199-b10d-4fc5-8291-cacf09c25017',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#713C62',
        id: 'fb437f55-84d0-429d-b15a-b93190d219b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E4C6D4',
        id: 'faff8493-4325-425a-8099-2fa42655308a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DCB6C9',
        id: 'd67b1950-1bb9-4694-8694-f512f0a786fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0A1BA',
        id: 'e96eb0d6-70d4-4c89-9401-9c10651e7d43',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BE84A3',
        id: 'c4627265-2abb-41cd-8af2-7e72b717d19f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A76389',
        id: '864c3b7b-f5da-4f04-894e-9237d67ed057',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#893B67',
        id: 'dd23ed51-452e-4315-b25f-fae8a47d802a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#612141',
        id: '340b4336-2de3-40c3-83ab-bc20425e6308',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EBBECB',
        id: '1487676f-ccfc-4fc2-b8f7-afb442258e00',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E8B3C3',
        id: 'cf604276-1cf2-46d2-977a-c698168bf090',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E4A9BB',
        id: 'cbb50276-16d9-410c-953f-c739374a8419',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D592AA',
        id: '5d38d371-17ca-4c79-b365-2d7bc7297efc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#84344E',
        id: '79aef54e-b3ee-4403-9b53-088eb46441cd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F2C3F',
        id: '39f6fda9-4a07-4847-aaaf-3001dc881700',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#572932',
        id: '181779ab-b8b3-4481-8a22-1439bd195fc1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E2BCCB',
        id: 'fde03319-02b6-43c3-a85d-d6e48d9a48e8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DCA9BF',
        id: 'cd2161e7-fa19-492a-8739-bdba42f354dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C9809E',
        id: '7bb49404-8bdd-41a5-b265-1c3702e91afc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B55C80',
        id: 'f3d0ad1f-07d3-42fd-9e77-b5803491cf3c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A73A64',
        id: '9fa8e371-a0ec-416c-801e-0ec0695a41e7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B3359',
        id: '15c24230-df05-401b-96da-0303ba264a61',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#872651',
        id: 'debe03f2-5902-482e-ba37-b561012df704',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9CDD0',
        id: 'eef502e5-374d-4a38-a85d-655bf6262659',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3BEC3',
        id: '5078d600-278c-4d1f-a55c-0e1b7c802613',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7A3AB',
        id: 'c318615f-e5f3-4e5f-9aea-8acf0ef99e10',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C48490',
        id: 'c21f7fa4-3647-4042-9540-b717073e01a7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B46B7A',
        id: '8c2bac8b-b09d-478a-b1b2-2b8d8e8a0818',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#984857',
        id: '7e61d237-c984-42ed-8773-27b608ee3867',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#893C47',
        id: '1cd63347-da4b-43b1-9554-1ef923412a6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F2C6CF',
        id: '01f9d136-3520-4b20-b7be-cbde29e4815a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1BDC8',
        id: 'c9889f4c-62ec-411e-9ee9-6c295f37af3c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9A2B2',
        id: '2fd9f09b-df90-407c-a113-f41b045e3f93',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DC8699',
        id: '54614843-2b2e-46a3-9b54-995805db2d0d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F3237',
        id: '0eedb5f2-dfac-44ad-860e-723b059d2fce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7F3035',
        id: 'fc95b3c7-52eb-4164-93e0-df532463b7d9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D2A2C',
        id: 'b468fa18-5b13-489d-8d36-48eaecf99c2a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9C5C7',
        id: 'edf05542-0b71-4375-9e03-452c77df3e7f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E5BAC1',
        id: '4ba235df-867f-4218-8cfc-c07f501947db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DAA5AD',
        id: 'edd3bede-c30e-4a5a-a837-5c3138c8bbe1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6858F',
        id: '28042baf-4370-4b76-b12b-70f8297c8471',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A3E3A',
        id: '76868526-8be9-4bc3-8c5a-f7f8ab36deb8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A3735',
        id: '75c04bdd-2641-4338-9e69-2479f4f747f6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#512F2E',
        id: '1c5b85ff-7324-4be6-85da-eb0171ab64f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8B7AD',
        id: 'f302f5f3-9b4a-4f81-bd78-14f9cc770dd4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BCA5A5',
        id: '40156ed7-1c73-491f-8097-e9bf043979bf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B79F97',
        id: '41f6d3cb-74c2-4334-b2cd-0c84ceb1747e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A88B80',
        id: '369e4b36-268c-41bb-a369-0f9cc2e9f253',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A48C88',
        id: '363b0c8d-7461-4e52-908e-eab8cecd8d81',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F7368',
        id: '15d21a12-c283-4e8e-a3d5-72c6ffa2f71a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B605E',
        id: 'e9399e83-15ac-41ed-a65e-362f90ab63a7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CAA7B5',
        id: '9b913cf5-a21c-499a-a5c4-9235fe26a437',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AE9093',
        id: 'bec487e0-bdd9-484c-b75b-a04ff6f5b240',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#916976',
        id: '2efedc5e-16b4-41f7-88df-e4374ef7c172',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B5A6B',
        id: '71fa5197-0fad-4242-bb25-be0019de01d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#78494D',
        id: 'fd0b9048-3b74-4393-b1b3-9dedfa2a634e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#744D51',
        id: '21d08692-ec5b-4373-bbd0-02bd324da9f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#65443D',
        id: '6388dc99-801a-4929-a770-e271afa79b4c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DFC2C3',
        id: '4fba4cf2-e81b-4ccc-bba0-770b03f408a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DBB7BB',
        id: '98708b64-0a5d-4f45-82a4-3d8fdc7d488d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CCA1A6',
        id: '23f2a82d-6b9c-48d0-8834-60b7e1d154d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B07C83',
        id: 'b3be7bc1-fcf3-427f-a4db-551d0380fefe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9C6169',
        id: 'b22b1607-57df-4749-a72e-0eda316e39ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#874B52',
        id: 'e8e414cc-bcc7-4778-ada1-83738ca0f542',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3F2021',
        id: 'cc777fbb-375d-4c74-a104-d9c10b19a08a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC8584',
        id: '49e7f93e-0047-4a8e-b8ed-9d5f03c84238',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E4D4D',
        id: 'de54a943-15ad-475f-9245-27fc4a83544b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#622128',
        id: 'd5e7ec1e-189a-495a-93a2-fde7091e71c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#551C25',
        id: '03897a93-e884-456c-84cb-8bf93b85479f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#51252F',
        id: 'b17a835e-2751-470c-bec3-8db58697976e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#543A3B',
        id: '04d4956a-dbb2-437c-b66a-b7c1924cb4d1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#663F34',
        id: '0c44d577-30ee-4753-b034-6c879e9b25b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1A7DC',
        id: '8c868b34-4862-475e-a038-741b9dd765df',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EC86D0',
        id: '5fe6fcfb-48f9-49b9-b3e0-122b500401b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E45DBF',
        id: '08dbe1f1-033c-4706-83a5-faa812501c92',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DB3EB1',
        id: '95b34b9c-0573-4ae4-b8ec-75eebfda33c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5299B',
        id: 'bafa0b06-be93-4710-825a-c020402884b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF1685',
        id: '3d71a195-b739-4d90-94b3-d36970a99db1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#80225F',
        id: 'dd920660-e8c4-46cc-8bc4-2b1adf842404',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CF92B7',
        id: 'b2928b61-aed4-4b45-8afc-55301895aec9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BB85AB',
        id: 'fc48298f-44a6-4575-965b-29e439810564',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B87BA1',
        id: '0e6a3e42-f8f0-4601-9c42-ed11c6902150',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C86BA8',
        id: 'fd72236a-3dc1-4964-a34c-906123cc1443',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD5389',
        id: '99b7f7aa-697d-4aad-aded-0a5c89211f43',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B4458D',
        id: '541a42cd-3bbe-4b38-86d1-697330efe2fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A5307C',
        id: '69a85fd2-bf99-4cf9-b49a-c20539dc028f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFBAE1',
        id: 'fbe01a62-daa3-487c-99fe-a8c125c51fc2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E277CD',
        id: '920fd5b1-d969-47e7-832e-97f3047c23b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D539B5',
        id: '70159e33-e36f-43c7-a5a6-c1b674446ccb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C800A1',
        id: 'fda3e08e-3378-4ff7-b0c4-2223d0000ca1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0008E',
        id: '415f978a-b838-4f96-a8a2-0fc5b4becd4f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E007E',
        id: '4d5b1c45-7a19-479d-908e-299ea5ebd081',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#830065',
        id: 'a696f774-08d3-4ac1-ba26-a9ff781d1c7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EAB8E4',
        id: '771a9130-cf03-4d9c-9ec3-cee79ea6881b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E59BDC',
        id: 'c3ba84ea-85a8-487b-ba04-70aa1f95a750',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DD7FD3',
        id: '25a77fa3-e2b7-4d9b-9388-fd1f6649d48e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C724B1',
        id: 'c884ee87-1ec5-4bed-bdc6-e82507ce85ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BB16A3',
        id: '3dc04997-52c1-455e-8b83-751e2775c74c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A51890',
        id: '796edfb9-14eb-42b7-a3e3-01bd7da36c66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#80286C',
        id: '31dc0bcf-4794-4732-aaa2-1e8b37fffd11',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A56E87',
        id: 'b3918719-ce58-499e-97ad-5d1a8f6c4b50',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A83D72',
        id: '8a272271-6afb-4d6a-a571-75d0e83b14fa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#991E66',
        id: '24d3d06a-ea19-4644-b447-94d5e859851e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A1B61',
        id: 'cbeb949a-c216-44b3-a393-0e72d5aebb73',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#722257',
        id: 'aa9edb33-b02b-42c4-9431-f00b1c816931',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A2A5B',
        id: '42bfe207-e921-469b-a6cb-7fc18ccf109a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E2751',
        id: '8d55571d-2358-49af-ab1c-c556ffaac117',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E7BAE4',
        id: 'f80538fa-15ef-4be7-adba-bf6c661cf035',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DD9CDF',
        id: 'd3b4105d-1a8d-46f6-bc54-5c2f59a7acc5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C964CF',
        id: '7866afd1-24be-4490-bd6b-4b573766aba7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BB29BB',
        id: 'c12929b9-226c-414a-a1a3-53b8d89c90dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD1AAC',
        id: 'af59d18a-ec36-4732-ab90-ad4f1574c557',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#981E97',
        id: '98625d57-0c59-45fe-aea4-76a15a64ce9d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#72246C',
        id: 'f35bc4fd-bf6d-4e26-bcb6-adb60d0c2141',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0A2D4',
        id: '67b6ac38-bfec-4398-8cce-9643e3b38435',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DEA8DD',
        id: '1776906c-e1fd-43dd-bc9a-f52b22b509f6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D28DD3',
        id: '547f55d9-23d7-42ea-a188-bdcb8ae5ed78',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C069C2',
        id: '732d6c4e-3b68-43c9-ae4b-925528293afe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B14EB5',
        id: '760e66d3-b130-4c52-a2b1-bd9b62124f23',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A438A8',
        id: '44cfd1b6-5422-4c4c-9f18-177cf074cfc8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F1A95',
        id: '4c63f3a5-ca93-4f6a-85de-52a34e89c837',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EBC6DF',
        id: 'b4d7bc1c-3be5-476f-ad9a-4e13a42d52f3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E6BEDD',
        id: 'ecd2e127-4bf0-40a0-996a-7f2c75c07a4f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E2ACD7',
        id: 'f6cba554-cfad-4dd7-b06b-46626225e2c4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D48BC8',
        id: 'c1c89423-c03f-4864-a6b0-d01b97a2b674',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93328E',
        id: '9110502b-f665-4b68-897a-f374460462c5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#833177',
        id: 'e75ba5ce-46d8-4d37-88ea-d3714be4e20a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#612C51',
        id: '397e0f2a-0f2f-4322-b1d0-dbd743bccbd6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BD7AB3',
        id: 'dc86f4ef-ff29-48bf-a620-80c6b0d02be5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B565A7',
        id: '40eab3dc-37fd-4a7c-8691-57e4ce975b95',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BF4DA5',
        id: '15e7cfb6-a1bf-4e1c-9366-4178db7a1153',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#924E8C',
        id: 'c962f51e-e32b-4967-88fb-fdb1c42a87a0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#87037B',
        id: '2c8a88b4-15b5-4cf0-a9e6-1ba993d3f721',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#720062',
        id: 'f9e4f802-07ca-4391-8516-d93e7bd564d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#621244',
        id: 'ca052647-e752-41c9-a228-3e7447bbe25f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#948794',
        id: '12d4522c-41a3-4bf4-86e3-24121ecb2666',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2789C',
        id: 'c9dd2d7a-9d49-408f-8b24-f2185fe0dde4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A15A95',
        id: '4541e0f4-4e76-4733-86e4-a98733a2fe28',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E3A80',
        id: '282f2539-0283-45cc-bc83-5affb4543497',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E2B62',
        id: 'a49bd5f8-f571-4d41-a6b9-21698ac0281d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A3460',
        id: '821bbad2-4caa-4c24-ad11-8b3e7952d98c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D3754',
        id: '813d670f-f2a8-412e-9e88-b835f22adef2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D8C8D1',
        id: '75a5861f-4b62-4776-a20e-0db5ec58cf7d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D3C0CD',
        id: '2a8542cd-b6fa-4ecf-b508-21834fb5a66a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFA5B8',
        id: '3113c7aa-d2f7-44ac-afe1-e374ac51ac73',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B7793',
        id: '892e5d2a-b3cb-497f-9bbe-39288588f205',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E5475',
        id: '839a625a-7883-4995-8fd8-36a6f0d019c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#693C5E',
        id: '39af3c19-3692-4a9e-b562-8190e839a644',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#512A44',
        id: 'd01a27e2-3331-4db9-a053-d092da1edfe1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4AAB2',
        id: 'e7fe4888-94f8-4e48-94b3-6fe707099a16',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD92A4',
        id: '381d7279-4428-4491-881c-d5ecf72f8d43',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A58496',
        id: 'c4a4c51a-48aa-4d6b-a933-af05a30018c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E7889',
        id: 'a1424d1e-dff8-4169-a485-cedf2a93f7ff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A6D7F',
        id: 'dbd24807-dad7-48c6-b7e4-7f026658a2c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C576A',
        id: 'af8e95e2-634f-4acc-ac85-f58f99eeac6d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C6D73',
        id: '5b87a2c8-580e-4a33-a087-b9fdb7a449dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DBCDD3',
        id: '0d7201ab-1924-43e0-b649-f5f221bc2426',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0BEC7',
        id: '740564ba-5337-466f-ae78-67aedf0cb13c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6B0BC',
        id: '994027a8-3a5a-4ec2-83ef-44dfd54d4b17',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF95A6',
        id: 'e31b4f44-cb2a-4f98-ab5f-190d574b01ef',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#86647A',
        id: 'ca795685-db60-4fdb-8b7a-db8d2df5dc5f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#66435A',
        id: '7c95c7b2-09fa-4aa8-b86c-35caa27ee3c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A3041',
        id: '834cf1e0-3736-460c-85f3-70de783f9172',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A38D91',
        id: 'e9906578-8ad0-4b36-989e-5ec47b1ae311',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B8C92',
        id: 'ef35ed51-74c4-4168-8d7c-7ce6f9f05db0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#948082',
        id: '2b1c59b2-13f0-4732-b99d-d0b28fa64dd4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#988383',
        id: '776c4fee-21fc-4456-ab1e-9d4098f2e60f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#726477',
        id: '43650add-5137-4141-97ac-8832b4bd8398',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#46394E',
        id: 'f3a76d59-8889-415f-942e-53f53155a732',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#403845',
        id: '1e109d11-abaa-4c76-8a74-ec2894043368',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5C2D8',
        id: '8018e2f3-7b88-4b9b-999f-3148ed1927ab',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C9B1D0',
        id: 'fdbf86b8-8a14-41b7-985a-07ddb1ebdd23',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BA9CC5',
        id: '9df1ca16-f0e6-4794-b098-049ca1834dbe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A57FB2',
        id: '963c1258-ca66-4ec5-87ab-7e1790f0e0af',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#642F6C',
        id: '6d1124b1-d12b-4779-af04-177708f14f30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#59315F',
        id: 'd16b8349-a493-4cd5-9297-8187a4fb5ae7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B3048',
        id: '2b21ffab-f459-4037-b547-6758ad868cac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EEDAEA',
        id: '0fbd8f5b-50a7-4830-aa89-d09b57208b25',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CCAED0',
        id: '7b9eef8e-e049-4fc5-b077-365aa345dbd6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D59ED7',
        id: 'd8ed4183-f9d4-4b25-b4eb-522011f0948b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B288B9',
        id: 'f5aef2f1-8ee9-456d-b17d-e0cedc855a40',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A277A6',
        id: '7c5d41f3-8703-45f1-9e2c-5493dc15333a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9F5CC0',
        id: '8ceb1d41-760b-40f9-8bb8-aa0222cf89ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#963CBD',
        id: '333faff0-a011-4cab-aede-5296da604fbd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7A9E3',
        id: 'b623fd4c-5825-402c-aaef-f467620aca0d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C98BDB',
        id: 'c95b9b4e-b589-4f12-9efd-29a76605b70c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC4FC6',
        id: 'c151ff21-5022-4895-b82d-c31dcc1fb7d0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B26B6',
        id: '783e0ac5-2930-4161-9466-ea80476abc0b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#87189D',
        id: '82d2e795-44e2-487b-ba6b-4bd1953aea32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#772583',
        id: 'c38fb791-0eea-4702-974c-fabcc9fbc8eb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#653165',
        id: 'cc261be9-978f-4bcd-a2b8-2eb6a7a2163d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DFC8E7',
        id: '66831236-6793-47d9-8ec2-ecd61da3f49b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7B9E4',
        id: '2392a78e-ea4b-4983-8539-7210dfb35343',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CAA2DD',
        id: '95325ede-68c3-4eb7-8429-d4e326e63770',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B580D1',
        id: 'ffc922b3-f140-47bd-8c45-cef14d1055cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8031A7',
        id: '8b94d918-551c-428d-8113-102c47ff3e11',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#702F8A',
        id: 'c5d9ebc6-a40d-42f0-8610-0a1693683afe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#572C5F',
        id: '04be1b87-a8e6-44b2-ad96-b7b3a3590df2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6BFDD',
        id: '40a28e94-6e36-4f2c-b491-66e12aadf80c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6A1CF',
        id: '79a3ae2a-5ec8-4027-a251-dde042157604',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C4799',
        id: 'ca49d08e-6041-4517-8447-00effb8e1011',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6D2077',
        id: 'f8aa7de9-79d8-430b-a3f5-7c4fe5990490',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#642667',
        id: '04d87167-3d43-408c-8e23-ed6f2cee0f89',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D295F',
        id: '8a051950-51f1-4a80-beb6-fbd17a1d1c9a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#51284F',
        id: 'bb0b3396-9efd-4832-be37-69979fc05875',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CBA3D8',
        id: 'dbffcd8d-6f3e-4a65-a5de-d12ba5153451',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B884CB',
        id: '4d5003a7-ff86-4310-b7be-3c7c42efc2fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A05EB5',
        id: '78431550-8fc4-4dbc-b954-cebfd011c2ae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#84329B',
        id: '3244a435-0e20-42ed-be3b-490eaf964c5e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#702082',
        id: '161a8467-d91f-4455-8076-4921f162a3f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#671E75',
        id: 'fe7454cd-24a6-4a98-a950-2497ed56b870',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F2167',
        id: 'd815bcb4-943e-45a4-bc6d-377082cd71b2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B59DBB',
        id: 'e83ab70d-e31a-4798-8244-1c4f3be877f7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B7EA4',
        id: '685f65cc-98fb-451b-8e86-12bf0aac7402',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9873AC',
        id: 'f2b5fa1a-331c-4adf-9a25-a4169c7e0056',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#865E9C',
        id: '37cca87f-8166-46d8-b268-96629c6d8f0f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#784E90',
        id: '75e9bb1c-f710-4d01-8ab8-6f51082eda58',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#965EC7',
        id: '2ee080d1-1007-4a10-8a2c-44d4d5bba636',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7F35B2',
        id: '0efa4256-1d7b-44fc-996c-e8d6afc39abf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B996C3',
        id: 'f53b56d3-4ca2-4e4b-b38e-b8e0707d5da9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B27BC4',
        id: 'ab581e43-7f06-4ca9-a30d-d6ff28fe2d62',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E51A8',
        id: 'bdbba83e-270b-496f-8932-6813cb690ef8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B6EB6',
        id: '021ecb81-8ba4-4685-bf0c-1ab89886713f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9672C4',
        id: '905f3f02-76fe-4227-b366-21609ea0741b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A48DD1',
        id: 'ab82eaf4-6927-49dd-bf06-e3541dfc3982',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#61007D',
        id: '3657f035-72d7-465b-804a-94fe773cd71f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C7B2DE',
        id: 'ab541a95-1a8d-449d-b946-a642a6b698c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B896D4',
        id: '975438c3-48bb-4084-a1cb-6806e776d895',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AF95D3',
        id: '4a86b3c8-d6f6-49d8-b2cf-18c9e8ae5db2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9569BE',
        id: '65b7d1df-22c4-477b-b72a-36786f253905',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8866BC',
        id: 'ba51502e-7746-4dfe-bc2f-ffcd6306b89b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8659B5',
        id: 'a6571017-122c-47dd-a004-5e57be435f96',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E3FA3',
        id: 'd3d55e5f-7242-4220-b1db-db324a104ba8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9991A4',
        id: '4af11431-8003-4142-8821-de1e99f4ea29',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8D6E97',
        id: '62c7a8c8-6de6-4d2b-ae71-87080e938b05',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A4282',
        id: 'e44bcbc3-ffb3-41f2-89e5-0abaeb36b896',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B3077',
        id: '550fb6e6-aabe-4212-a753-937686174742',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#653379',
        id: 'f4a884df-089b-494b-b75f-df33c5e2a503',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E366E',
        id: '3f6b9714-560f-4a49-b264-6bcd5433511e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C4E63',
        id: 'b3acfbad-eaf6-40fe-916a-2914e413d6f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1A0DA',
        id: '22c57051-0e72-45f3-af65-f3c666765803',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A77BCA',
        id: '064db6d5-c4c5-4d7f-a106-91c66d63d1e8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8246AF',
        id: '1c07839a-c588-499f-be13-4239d4d2afd7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C068C',
        id: '07b21405-bd1d-4b9b-ba71-bb4a3b542552',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#500878',
        id: '9ca37d8c-94f4-41a0-be5e-79efee476dc2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#470A68',
        id: '47a7a43a-b0da-4ef9-ac0d-0971e8d126da',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3C1053',
        id: 'b75458b3-c6c3-4b2a-92af-dc8124c41fc1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7C6E6',
        id: '44ce9405-84e4-4516-9a79-e027b387ee38',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1A7E2',
        id: '5721647e-688e-4907-ad57-81e37a71462c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9063CD',
        id: '81e19b22-21f0-4c98-a95c-72d906d8cc3a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#753BBD',
        id: 'c397438a-d432-48b2-8435-162eb0587128',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F249F',
        id: '89543ced-4ca2-4392-a370-7e2eb8454977',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#582C83',
        id: '8a8e7f78-bfce-4e68-98f0-ba50fa549303',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#512D6D',
        id: 'a6034d6f-5b9b-4370-bb52-28cbc92979d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DECDE7',
        id: '29efeb08-baf0-4317-a90c-a05780937579',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A08BCB',
        id: '4d65daca-53da-4226-97c8-7441004c609a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B7DD4',
        id: 'c693b93e-e0bc-4e1e-aabc-00b3c463be53',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#825DC7',
        id: '588fc602-648b-46f0-a88b-d81b646374a0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7248BD',
        id: '7ce8f814-fed7-4b62-86bf-974aaa194ee9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6638B6',
        id: '32545c6a-2526-436d-8ede-68347d2fb1a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C12A1',
        id: '7ceb50c8-53b3-4350-a3bc-6fdde3ec403c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5B4E3',
        id: 'cf699e94-a032-4557-a76c-0925dc53c441',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD96DC',
        id: '425ee6ca-c5e9-40e4-bbc2-7541a6aeddb1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9678D3',
        id: '75c33888-7a0d-4535-a1b1-e64173955b75',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7D55C7',
        id: '56512dcd-6276-47d9-9dc6-5d2fe4c676c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#440099',
        id: '68a32d1c-e9b7-4df0-81b1-ddce2e73d5c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#330072',
        id: '3f2c161a-a3ef-4507-a8c5-8ea81a6ffcf5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E1A47',
        id: '467eb255-cf22-4838-a50b-3ad4d95b8f7a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B8ACD6',
        id: '2b8f30ec-ceb6-43fa-9f83-27b46879e40b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A699C1',
        id: '1801e8bd-d93c-442a-983d-9012b3fd8c7d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E7FAE',
        id: 'c6d9a237-2026-4e00-ba41-69d06442ef9b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7965B2',
        id: '7f9d248f-50dd-41e8-bf03-e2a898e1777c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#654EA3',
        id: '491c0089-a362-4a35-9a34-9873284df5c3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6244BB',
        id: '50d91fc6-f81d-441a-9aa4-d01a72ee4dd2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A25AA',
        id: '8c697a4e-3f67-411e-870a-26df13e9ff4d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1A2CA',
        id: '2b86e241-03e7-479d-97b8-3128927d0288',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9F8FCA',
        id: '645353da-8955-4a60-af55-6cbd9650cb28',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A75D1',
        id: '664f57d9-aca7-4e05-9ae3-093538c3741c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6558B1',
        id: '70532e28-b988-4791-a971-29d08034f327',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5949A7',
        id: 'a082aebc-aa8d-4349-8189-a20d4c1c67e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#483698',
        id: '8a86a2a4-3d6a-4b80-94ef-a5ed35094a9d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#39207C',
        id: '991af94e-d9f1-4945-96a3-4c0fc5f8511f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B8ACD0',
        id: '503aaf48-34c1-418a-a086-0c07f3bb31aa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B8BB5',
        id: '0b940046-f1ca-4f06-9485-7f7f5b0eacfd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#776CB1',
        id: '997bca7c-e8a0-4647-8655-7122b4139986',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A5A7BC',
        id: '932eee3b-db32-4076-8695-8829516a8987',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F91B0',
        id: '2ed0cdb9-a456-4581-9b0d-1ef6f596bcc0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F5E77',
        id: '11bb74e3-b2e3-4afe-b241-59e523849270',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#514163',
        id: 'd5d11a60-24f8-4c4a-8a8e-6b452e7579b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B4B5DF',
        id: '307c4659-4218-4cf8-84a9-e0daa66c2014',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9595D2',
        id: 'ad57d82d-087a-43a9-a688-c71465075b04',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7474C1',
        id: 'c4e09500-f818-49b9-924c-aa0c3a742dc6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#24125F',
        id: 'd6bfddec-3aca-4ddb-b00c-e66cc1240913',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#221551',
        id: '82715b55-72c9-4d3a-8c9d-6393a0381f79',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#211747',
        id: 'cdef4022-b21a-4a06-b3db-75d61413d0aa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#221C35',
        id: '29286f77-8716-443a-82c7-2d3e8ad4c6e2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#919CD4',
        id: '22799050-918d-42c3-894b-3eb8131a1189',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#512179',
        id: '472b815e-9b0a-4073-b44b-a07de4899a38',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#511E84',
        id: 'adee1c09-663f-41eb-8aa9-111f38ad0d73',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C4580',
        id: 'ab6fa7a4-e51f-4bed-a38b-30abf3b599cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#472B74',
        id: 'f3346277-309d-4004-b016-17749c2aed13',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#400186',
        id: '1d84d53d-5a6b-497a-9edb-d9deca16118b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#31137C',
        id: '34cebc6b-3c6b-4e09-90ce-20d1d73ccde5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7A4E0',
        id: '4e2b601e-9838-4f83-b75b-fd293dbcde32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B84D7',
        id: '14f1f20a-d2a7-4107-8c3e-9dcae34cb32c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#685BC7',
        id: '22986fb2-461b-4660-b990-455b8b3f1f6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E008B',
        id: '69cfcefc-2b09-4529-afe7-9018ce54353a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#280071',
        id: '60250e15-ca10-4eea-bfd8-dcae8c2d14d7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#250E62',
        id: 'cee805a2-ffa1-4d10-bf21-688b2eb3ce35',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#201547',
        id: 'f4bae9a1-155b-430c-89f6-2df6b0926b15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E7CA0',
        id: '9c33d33d-1318-427a-91de-1d8666ea8114',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#676E9F',
        id: 'ae99ca89-2c2b-498c-879c-a2730735ddaa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#615E9B',
        id: 'e3933eb8-0599-411c-a27a-69a6f1b42874',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#565294',
        id: '0f3c30da-766d-426a-bec2-43fdbe7fb0fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#514689',
        id: '7d74046d-6420-43bf-bac1-2522ddc8fe55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C4084',
        id: '8562e2bb-91e0-45a5-8800-b0938daeca03',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#535486',
        id: '4fba41db-4ed0-436a-bce4-446fecbb79d9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDDAE8',
        id: '7ac26468-c805-4671-89e0-2f48b34ddb7b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6B8DC',
        id: '639d1a12-5844-4f6a-84f9-760696b5894b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7A2C3',
        id: 'a275d30d-8b2a-4510-a673-615b7035e96b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8986CA',
        id: '6935e3a8-6e13-4bcc-b0a3-e2652703d2d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D4777',
        id: 'f6938425-1510-4d40-84bd-cffa7f5a5707',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B384C',
        id: '3f8443d6-e330-4873-91c9-a99d72b96764',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#41273B',
        id: 'f71dfa23-6c7d-495a-b8d5-b0c8603a3b2b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#878CB4',
        id: '5a136b5c-3706-4292-8b20-bb4af56beae1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C7FAB',
        id: '39b92ae3-64d2-42a9-9f99-2ba02e8f27c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7566A0',
        id: '43c2f4c9-fd3d-4190-8642-5c8d06d96720',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F5091',
        id: '1410a3c9-e920-4e73-9396-74bd73c0d243',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#68478D',
        id: '08e74c05-c3e9-4d36-8413-f446d50a16ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#563D82',
        id: '82df332a-36ad-498d-af11-72196588a850',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#523178',
        id: 'fa4dd4ca-2cf5-492f-ac77-1aa6ae20d652',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E5E1E6',
        id: 'd8cfeb94-fa4c-4383-af40-ed61f1cb2d21',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0DBE3',
        id: 'e3e6ee02-fbeb-4a1d-ac42-960748a74ce2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6BCD0',
        id: '055e540b-7ae4-4e37-ba94-bca11e6989db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A192B2',
        id: '51a551c1-7ae7-42c2-ab56-655ebea09460',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C6992',
        id: '1ee07f55-42dd-4145-8aaa-f3c046f97fb1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#614B79',
        id: 'bfe3c6f7-e047-4ae6-8c05-a4a5f6c58cc3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3F2A56',
        id: '2c1701b1-84ae-4d5d-9473-57f402b7a456',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D8D7DF',
        id: '02944ddb-d1b9-469f-aedb-7d568a18cc93',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6C4D2',
        id: '1375a488-9e0a-4a10-9525-55b21fa70137',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B3B0C4',
        id: '09218fb9-d31e-479d-a7bb-c53ed7c9e17d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8D89A5',
        id: '3049e804-518b-4c7b-9ac9-ab5df443ec34',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#595478',
        id: '72025d74-1819-4f4b-88c6-5dd67dd4944c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#403A60',
        id: '5d314a51-4cf3-4f80-8ad7-3805b78113ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1E1A34',
        id: '79c3fbd2-af3c-4cd3-98ce-42e017715471',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ADA4AE',
        id: '3d9a3432-cd16-4783-9733-468aca38fe98',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7F7384',
        id: 'c585b239-3440-4810-b540-8b6a5d9b7675',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B798E',
        id: 'a4fb8e0f-6f16-4bca-8897-2794cf180286',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#70708D',
        id: '765a95b2-bc60-4abf-ba99-3b29af77d6d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6C6A81',
        id: '48a60391-f6f3-4df4-a197-2b125c3718c3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#605D75',
        id: 'a3a909d2-66a3-486d-ba19-2cfd6b029107',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#66596C',
        id: 'b0ffb018-2f8f-4158-b047-8bdcc8d1add0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#808194',
        id: 'da2f8c5d-5724-4968-b9c3-c49eb6a74704',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E7185',
        id: '8fac6218-ed69-4376-a624-065b6facdbbd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#676F7F',
        id: '82c57ff5-cee0-4ddf-bab5-3e06c3b1ab84',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#515A6C',
        id: '220feeac-c664-4bfe-9db8-41babc38d11a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#484B5D',
        id: '7bd74ec7-2a5d-4534-b5ec-93b55832712e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3B3A4A',
        id: 'e03bd3fa-e68f-4959-a59b-2d77644ee463',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3B3B46',
        id: '8b9243c9-de17-4a03-b7a6-694cfab02427',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1B5CE',
        id: 'ab9d1b39-9be1-4e36-915d-95edcc86039f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9099BA',
        id: 'ea885775-4c7d-4f15-8e8d-cdb01983d07c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#777FA8',
        id: '34cc3ab3-fceb-47e7-8618-3b4ccd74df4c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#676D99',
        id: '96a5f3bc-5c92-488c-a57d-12222ee5f42a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B618F',
        id: 'e11e7de3-8672-4171-b471-de55f701b040',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#464E7E',
        id: '177b6dfc-e1b2-4ad5-97ad-317ef2150a2a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#351F65',
        id: 'c3b0322a-06e9-4a24-aec5-0b0ed8f7e323',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A5B0E3',
        id: '7ab92dbd-9367-4322-98a7-e49c626b2d11',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#838DC8',
        id: '6e9be580-45c6-4137-871e-d78e98296ae4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F79BD',
        id: 'f7521fc2-3558-47d1-95ba-a55517aaa928',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6068B2',
        id: '505f603a-bf5b-4a06-bc48-338c69174520',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#44499C',
        id: '5219cddb-1ed4-4282-8fbd-ff7f28293772',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#343579',
        id: '30d6833e-4ffa-438a-9e99-db9b96072c4c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2C2E65',
        id: 'f72d2684-c77a-4487-8d36-48c832b5ac04',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A6DCD',
        id: 'dc5c7216-409d-4f2c-b65b-ca657f534d88',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F4CB1',
        id: 'dad0372b-b609-4e0f-a607-0bebadcb601d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4D49BE',
        id: '897a82b7-9b25-4a6e-9db7-d93214a12e65',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3B3FB6',
        id: 'b3396184-7fef-4eaa-ae88-46117c3d763f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2F2A95',
        id: 'c4c30e0c-24ce-438b-8319-3774f4288bec',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#33058D',
        id: '5ba5f03e-820b-4da4-ba98-d60b2081e7e9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#281E78',
        id: '2b04c963-dd7d-487d-add4-ed8f00b3fa86',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5CFDA',
        id: 'bf217919-733d-458b-b3db-c1b53c52b37c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBC7D6',
        id: 'b364b099-3fde-4fd1-add9-fcee5dddd5c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2B2C8',
        id: 'bee690ef-93cc-481a-bc6f-799d46a9d87a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E9FBC',
        id: 'b9c24ba0-d96c-42a4-b740-5858270ea115',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1B365D',
        id: 'cbbb5ca6-3a3e-47ea-abc0-81c456124d9a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#202A44',
        id: '68ae9e07-2be7-48d3-9a8c-0d94f0e91dce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D1F2A',
        id: 'b4655d86-90d6-43f6-ba07-4f6f93d1e075',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9E1E2',
        id: '75c6a373-d36d-4fb2-a3cd-18d5e1a34ebb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4BCC2',
        id: '3a3458be-f35c-47a3-9313-cfdab655b7bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#98A4AE',
        id: '7fe963be-80f5-4af0-bf58-381b7a21b097',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#768692',
        id: 'ecfb0d19-08d5-491c-b01f-43b5476209b1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#425563',
        id: '395e250d-ce6a-4abd-a2fa-97317268c383',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#253746',
        id: '8fa193cf-cea9-43b9-b359-df452ca045d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#131E29',
        id: 'b87967b9-13e3-429f-a477-88855b83c8dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EA6B4',
        id: 'fec67466-d48d-49c6-8a15-4444f787037c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#869CAE',
        id: 'c9fd085c-b329-4656-97af-ea8988dbf33b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E93A7',
        id: 'f093bbb1-cb57-4624-8daf-0c00f7c5b68f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#688197',
        id: 'c25c6456-71e6-42b8-98f7-1e489966dc5c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#57728B',
        id: 'a217c888-ad24-4bc7-adaa-769aac95adf6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#506D85',
        id: '86a1b886-ec3e-436c-8286-6a590abed19a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#254A5D',
        id: '3e1c0304-866a-4d3c-a9ea-d94a2ca7730c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A0A2B0',
        id: 'da467277-3583-4dd0-8ce1-a7dd987f6c76',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B859B',
        id: '6c44b099-00d4-4142-8fc5-78e8bd888359',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#606E81',
        id: 'f05efd7c-02b9-4b64-9f9f-76013d0772e2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#475D76',
        id: '75b06b6f-33cd-4456-8c43-6df281176461',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C5F71',
        id: '963b3866-86e5-4950-a885-b6bf05cbe1fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F5A6B',
        id: 'dbb9ebc8-0b31-44a6-9d71-87d1605406d7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#393D47',
        id: 'c9fa3287-a6cc-4135-9f84-bc432e1e7550',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#73829F',
        id: '52f8c514-b511-4587-b3b4-917f77a1d3db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4D5F80',
        id: 'db406989-df8d-4f44-80ce-24d3ce5776c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E5B73',
        id: 'a8cb66a6-d0cb-46e0-86ff-26a088638c49',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#315470',
        id: '49e1326e-831f-4b31-a468-91e99fc79137',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#384967',
        id: 'ff68e9ae-cc55-4ce2-acdd-b9d277d6b298',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3B4559',
        id: '51dfcb02-9718-4f6c-8240-8f713b272c9b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#28334A',
        id: 'e87f28db-7365-4dd0-87b8-d1150340c7ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B9D3DC',
        id: '886f36f1-7049-4559-b2ee-dffc132ca137',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A3C7D2',
        id: '091ab8bc-0103-44c7-abcf-9c70914cf4be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8DB9CA',
        id: 'e8b524e4-45f8-40b1-87fa-f9e039c20e90',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6BA4B8',
        id: 'b3a75bb2-94d4-4ace-b4d3-c71f9cb73272',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003D4C',
        id: '81cceb20-3e1a-45d7-b0a0-c59434dd112c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00313C',
        id: '906f8aaa-937b-45cb-90d7-ba3e1cf8e53f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#072B31',
        id: '4a7c86b3-6913-4dee-8250-af5316c311ec',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFCED6',
        id: '46c6cf25-264e-40c7-97ff-1299b6903075',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7C9D3',
        id: '60674be7-aec8-4092-99e4-04aa4e2eaceb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A6BBC8',
        id: 'bfacf1b2-305b-49dd-9ce6-f63b32f76541',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A99AC',
        id: '2664ee7d-dbfe-4a66-b84e-82965431918c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B7F95',
        id: '91620e2b-ae36-4c52-a958-16aca83077c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F758B',
        id: '53b86dcb-cb9b-4cee-b18a-e6de27c03a18',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#091F2C',
        id: '60c3ab33-0c50-420d-ac6a-163b5d2b4d30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EB5CB',
        id: '5290b350-363f-486d-9ed8-074dba07e379',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8BA6C1',
        id: 'd9b5a940-7f0c-4673-8830-67fdd56561e2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7594B3',
        id: 'c63e6405-d61e-4f8b-9a72-bfc275b61d1d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C82A5',
        id: '996c729e-a68d-4be4-aedb-4e78a70207bf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E769B',
        id: '20c481ce-a1c5-463b-a45f-a213b77ccd2c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E6991',
        id: '1b64ea58-8c5e-4474-aff3-784226feffa0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#285780',
        id: '7bed5b19-2179-4726-b962-023283bcce04',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93A5CF',
        id: '0d54432a-d9b4-426f-8a63-9a8d45e581fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#758CC0',
        id: 'd8fbf430-6c23-41c6-98d3-1adce13e87c5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8394B8',
        id: 'cf42025b-a2b7-4900-9c0e-66b93d32ed74',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E80A9',
        id: '914f2005-e416-46bd-9774-2b61db5483a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#647692',
        id: 'c230d85d-1c43-4a67-adb1-7375b62969e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4D6995',
        id: 'a3374c39-e946-4b38-8416-048ba84b5136',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#395382',
        id: '7dc0330b-9cb0-4e16-a74c-d3c407a254bb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D1DDE6',
        id: '3293cb84-9d71-4ed2-8859-4bbf9d2fec25',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6D6E3',
        id: '8ad9cb6a-cd25-4df6-9c69-6e7635ae2590',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BB8D3',
        id: '25fa22b1-12a2-4256-aaa2-a9025e25d61a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7DA1C4',
        id: '4a976ade-5fde-494c-aa75-ebc2439e5170',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E8AB4',
        id: 'a1515c1e-155f-4002-a9c1-767c89d570c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#236192',
        id: 'd9e141ed-6e89-413a-84e0-cdb119264a8d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002E5D',
        id: '9cc43570-cced-471f-a82b-21bc072cd61b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DBE2E9',
        id: '4a5c491d-fffc-4672-9e90-ab5131ca6c9f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CED9E5',
        id: '0b559272-18d9-4d66-82c6-329608dc9496',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7BCD6',
        id: '88b246ac-236f-492d-8802-c9595b14ef30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7D9CC0',
        id: 'b95be603-2d61-4ef4-92b0-5d73e350a547',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#326295',
        id: '55175661-a3f6-4dd0-a1c6-b39be6f27f49',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003A70',
        id: 'dfce1434-9547-4f7d-9183-4c14477c0e0e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002554',
        id: '5959f98a-0a93-45c4-bb83-ae59a19a2074',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDE5ED',
        id: 'eb2342ab-4827-4c87-a0c9-72f0aa0bb11b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8D8EB',
        id: 'b55ef671-4883-4a66-b5ae-e77a732d7966',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1C9E8',
        id: '2cbfabda-1d43-47cd-b30d-3d361bef515b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7BA4DB',
        id: '926a32b9-67d5-4687-b831-66f3402fa1dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#407EC9',
        id: '0f175d43-56b8-48a8-b2ac-7961855740c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003594',
        id: 'f04755cd-d30a-4175-b9ff-51698e55c801',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#001A70',
        id: '2a0f7646-3c80-4894-b9c6-1f2921367fde',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B8C9E1',
        id: 'dbcb949e-725b-455a-8e0b-483436bc1df8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#98B6E4',
        id: 'e8a58007-d78b-4603-80ef-bd8532954102',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F8DDA',
        id: 'a48483df-c7b5-4126-bb6c-a11f49fcd83b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5576D1',
        id: 'fcc28599-b125-4949-b656-5b9b5ce6529f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#365ABD',
        id: 'df0bbd14-4016-4c97-b69a-f8527db78c45',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0857C3',
        id: '0d960fa2-a0d2-4d0e-85bc-d42f67042314',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D57A5',
        id: 'a00e7484-7f88-4a54-b338-b812b33e8754',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BDC5DB',
        id: '1217135a-9c87-4d60-823d-224b983c681a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#89ABE3',
        id: '3cb2f9c8-e216-4489-b5a8-0c374ff07151',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8194DD',
        id: '28db75e1-ae98-4cbb-8a55-879f2280ad38',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7CA6DE',
        id: '6ccb1787-831e-4c24-9a44-6bc67bfc7292',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F8FB4',
        id: '41ba8941-3bbb-4669-81af-5e76e7b3cc8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3A5DAE',
        id: '1d797985-f29b-4b71-a893-3366b12be330',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#606EB2',
        id: '087e3a76-fe6d-4479-a6ea-4994efedb225',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BECAE9',
        id: '965e458b-74ab-4ad2-8107-abcf9c92a599',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8FACD8',
        id: 'd75f8625-6a18-493c-bd52-bb88ac6aa222',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BAEE4',
        id: '677ae832-06e1-4aab-8261-59ec45834d55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A93DC',
        id: 'c7266230-ecea-4fa7-b5ac-2d98d484597b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F7BD4',
        id: '89597f55-8eb0-471c-bca1-e9585466996d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5461C8',
        id: '060b7d06-1e34-4d02-84a1-ea965a32890a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#303AB2',
        id: 'bedca766-79fa-46b7-aed3-042e39a0502c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CBD3EA',
        id: '6bd5a7a6-c092-4af2-b810-a55b456f6022',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9FAEE5',
        id: 'ab334e4c-9158-4cac-8b9d-78ea8940bb75',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#485CC7',
        id: 'c71e09ee-0800-4cf6-93aa-a321a86270f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1E22AA',
        id: '91eb04bc-d11d-4270-8d6f-ca1e1196eec0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#171C8F',
        id: 'fa9a17bf-c4be-4fae-86e5-88d8fdc0f1bf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#151F6D',
        id: '0e54e371-970a-40ff-adc5-48d306517bac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#141B4D',
        id: 'df63cb85-5a7d-419c-ac0e-b5829a110833',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B8CCEA',
        id: 'dc5c283e-e764-40cd-8e6c-d6fc25d544bf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C88DA',
        id: '8890d423-d1aa-497a-9edc-f6a172e9dc83',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0047BB',
        id: '9a5334a3-80f2-439d-ad43-ed2c29d5119d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#06038D',
        id: 'e6a8d2bc-1c2c-4451-a627-5b8fa6825c7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#001871',
        id: '84b99b65-6d62-4e32-a732-81cbe6adf922',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#001E62',
        id: 'ae588d0c-c25a-4d7d-87c6-73896b22c8b9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#071D49',
        id: '2735667f-64f1-410a-b8f2-b1bd9df0bb74',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C3D7EE',
        id: 'c6ea33c0-07bb-4325-92c3-b86578cbd094',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7C6ED',
        id: 'bef95761-0008-42e5-b9e8-cd619da5eea2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#307FE2',
        id: 'e9985ae5-9639-44f4-8373-c439f67b84ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#10069F',
        id: 'd162f375-9fc9-40a4-8aa1-0bb1739b0eee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#001A72',
        id: '2a4d6475-061a-4ea9-8c4d-88099314d79d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#001E60',
        id: 'bf5c289c-e984-4de2-8ae8-f0fbe890d539',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#13294B',
        id: '755a5fb8-9511-4287-b387-e9e9a46e53db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ABCAE9',
        id: '6e35d4bb-9b50-4e15-86ea-184965701a59',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8BB8E8',
        id: 'ab1f1d11-6824-4fff-b05f-4d3e00f69f3b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#418FDE',
        id: 'a3bc12d1-23e5-47bb-8826-0cec3ec776d3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#001489',
        id: '6a55d841-1ec7-4922-9d78-cc8a07502ed8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#012169',
        id: 'a5ea0c8c-b815-439f-9043-c85b2ad032c3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00205B',
        id: 'a5ced19c-f885-4557-96e3-cb62ed266e84',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#041E42',
        id: '4a18a732-f0d8-4d97-a9ed-cfcdd3a7c1ae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#92C1E9',
        id: 'd8262b7a-1334-4a86-bcb9-6dbdfff7a4a7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6CACE4',
        id: 'a0a19323-63db-48a7-8ccb-f0ab93cc9fcf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0072CE',
        id: '646e5afc-0934-4fdf-931e-6cb32ce06578',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0032A0',
        id: '48ae58ef-d419-4550-844c-a8c16ae90591',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003087',
        id: '727c684f-4183-4fd4-bf4c-c9b32384efa9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002D72',
        id: 'a26bc01b-4fdc-45ef-9020-3bcd3f36f62f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0C2340',
        id: 'baee7691-1cdd-4605-bc2e-a4f305f4344d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1961AE',
        id: 'aae2c820-a22b-496d-a558-22b302adcc1c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3C57A1',
        id: 'e399c0f8-1937-4bfc-91fa-72eeb8bb19b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E3E80',
        id: '3d956974-ae0d-4ebc-b811-46e1ed67cd4b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#03257E',
        id: '6e25187d-7b54-487f-9025-3228592811f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1C3775',
        id: 'a38f425c-2bfd-4587-9694-b77d3a9730e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#22326E',
        id: 'a7a81bce-e9a0-45d3-9c71-13ddc126990c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2F2951',
        id: '10c4d1e8-fe94-4f99-abb0-95509ea022c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4964A2',
        id: '165115ba-94a4-4715-a4e7-0f411c29b0f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3D4E80',
        id: '0aab45d4-dcbe-48d2-956d-042a8b11db15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#435570',
        id: '5f4ff7de-76ef-4792-984a-a4ded4ee6c43',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3A3A59',
        id: '4764b632-463c-498c-8c4b-33c016e055a1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E334E',
        id: '96980520-9c04-4899-a03c-b6a56041c141',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1B1D36',
        id: '710497e5-a340-4cd9-97a5-7da77b532bd0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#26213F',
        id: '025a714c-8272-49cc-84cd-73e60618e2a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94A9CB',
        id: '15492511-a685-4655-83dd-6d41b5dfab21',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6787B7',
        id: '89672ba3-c272-4af2-b3fd-69ed933b2dd8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#426DA9',
        id: 'bbb07712-d34b-4938-8399-74d9dce7e86a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#385E9D',
        id: '9c242851-581c-4e10-a5b3-3287628cb18b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2C5697',
        id: 'f8490333-af93-46b7-8232-0fd39776ca37',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D4F91',
        id: 'f5011fd8-6494-4c95-8713-b456c4c61da4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D4289',
        id: 'f0012e94-50ef-4642-afdb-4065080329ac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8BBEE8',
        id: 'a4ab500e-246f-4b71-adaa-522fcf54a5cf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7FADE3',
        id: '29b33884-f729-4049-bbb7-50f5fbca4304',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3D87CB',
        id: 'cbe0f7d2-fcf6-49d3-8d3d-54c0336c0cb8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0067B9',
        id: 'dc8110cd-0b46-45d3-9a88-b74ef416a820',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004EA8',
        id: 'b5d7e131-d389-4317-9352-2100dac073d0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00358E',
        id: '0c1c72ab-d3eb-415c-8e1a-25a3a3a0a05f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002677',
        id: '0cc1e8eb-aedd-4bde-b564-ea8c62044e55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6DAE7',
        id: 'e1c6ab10-56d8-4116-9034-12e3967d4d0e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BDD6E6',
        id: '5c67d1cf-8183-4142-8298-839b9f076d98',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4C8E1',
        id: 'c5885dc7-352a-48c2-b1b1-d455d7b8dd12',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7BAFD4',
        id: '772b90b9-9e8a-4df7-87e1-a05c9b62d4e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003C71',
        id: 'a6b5e8b9-43ea-4e6a-b549-e06ffc47ed48',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003057',
        id: '91a1218b-eb44-43ed-aac5-6a92b73c1f86',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00263A',
        id: 'be38597e-d3bf-473f-bf18-ea1f6ceb7a5c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B9D9EB',
        id: '17c83062-6d4b-4fdd-a7dd-fec1d8ade1a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BCBEB',
        id: 'fe923092-d923-4095-b838-e3a51e591700',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#69B3E7',
        id: 'a18838a1-b068-4add-abde-74bfdf3666aa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003DA5',
        id: '1450d79b-b509-423d-bd88-bb77a6d32612',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002F6C',
        id: 'd4375cb4-8422-4d2e-9b7c-a395b378ff4c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002855',
        id: '4fca73ce-fd00-415d-ba17-5e5e7a915200',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#051C2C',
        id: '165760e8-47fd-4d95-86ca-cf1a1031a954',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8DC8E8',
        id: 'dc55a127-bbff-44ec-b5c8-1cf59e1727c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#62B5E5',
        id: 'f531b571-0752-4b17-b6f0-abbe5d4cce03',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009CDE',
        id: '641f014c-096b-4e7b-9efc-900a3e7562a5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0057B7',
        id: '4ff40a43-71b4-4763-a3a0-c96fc3d31d78',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004C97',
        id: '6e1dd7d8-7a3a-44ae-9168-f9493da9184b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003865',
        id: '0b247db2-892a-4943-88ff-340bbddea3fb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00263E',
        id: 'cd2180fe-2023-484d-8955-388b1d52545e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#81B0D2',
        id: 'd9673009-bf95-401c-bcd4-ac9385caea9a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F9BC6',
        id: '3bf82694-f7f4-4a2a-a312-a169b6381281',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#489FDF',
        id: '5165493c-9da6-4ed7-8a91-6f3d552ff44a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#147BD1',
        id: 'f25777b1-856c-4b64-bb28-14dadf434656',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0084D5',
        id: '1164309d-5b08-432f-a093-5c40a5b6c4dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0076CE',
        id: '05e0ec1e-6f6d-4bda-894d-b0aa41e4fc64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006AC6',
        id: '1003dbab-1dcf-421d-8c1b-da5f97e91cc7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E93DB',
        id: '9e44adb1-49c0-493a-8da9-25c65987b2bf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008AD8',
        id: 'f572617c-7fb5-43df-83a0-cfd1927b1338',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2774AE',
        id: '1345e3dd-4c88-40c6-bdb7-5fc126878f72',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0061A0',
        id: '9d4c281a-6bbf-4cbb-b290-eb4ae89209df',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2D68C4',
        id: '61eb1393-5b3c-4fce-afa1-526c0a8b7a6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0762C8',
        id: '1eaf2afa-cf6b-495c-b6ba-457824217300',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0050B5',
        id: '2410818c-7910-425f-9d46-d7de34dbd608',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6197B0',
        id: 'abbde0f3-548e-4477-92fd-714c34b198c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8AAAC5',
        id: '34e01757-3527-40f6-95e2-ee9c478421a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#517DB9',
        id: 'c115dea9-8206-48ac-b365-8726a5b7ef9f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0067AC',
        id: '41736aa9-5219-4e93-86d4-d981b528bf60',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#135193',
        id: 'b8f806c6-4597-468e-87f3-9255a7b15db2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1A4E8A',
        id: '385893ee-2ffe-49a9-b9ac-120db608d6ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0E4174',
        id: 'dd9b7c11-e200-49dc-a4ea-78861a1c678a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71C5E8',
        id: 'b715162f-4c35-494d-ac5c-cf125448df37',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#41B6E6',
        id: '24d05801-b5dc-4636-b250-da5b10faf482',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A3E0',
        id: '5e007f8b-6c02-44e4-a3df-8c75d94f77f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005EB8',
        id: '1d37d6e0-b06e-4312-a620-1199c2d0daeb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004B87',
        id: '2195dcd7-dd12-4ce0-bad1-a8d5631af900',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003B5C',
        id: '3bb1593b-ba21-4b83-b775-43702ff9ac58',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002A3A',
        id: '2b955d30-b632-406b-a99f-e736aabe88f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4698CA',
        id: 'e5743018-c15e-4a80-b1ff-f4794cb4d5f8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#298FC2',
        id: '203f5a9a-6a83-4573-a259-50ae6b7e2d26',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0076A8',
        id: 'bff33e81-8dad-46f6-b1cd-a12901196106',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006298',
        id: 'b5402b61-93de-4b9e-bdfa-e12c1fe137e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005687',
        id: '14654e95-a861-410e-bbac-1a3763cba62a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004976',
        id: '8d3a4562-6748-4a13-92fc-c5e9a2bbd99d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#01426A',
        id: 'db48aa1e-0cc0-49ca-8918-141da0f22c4c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6191B4',
        id: 'c7c4b5cb-c1d1-4132-b9d6-8da1e6e77736',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C82A8',
        id: 'b70a2465-956c-4aec-b965-72d316a7e2ec',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#36749D',
        id: '50c5bdbb-2230-4d1d-81a2-4a9ebc5f8560',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#256BA2',
        id: 'cf04e109-578f-4dae-8d91-5019f095ca8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1A658F',
        id: '71a5041d-47c0-4915-a6df-c5acf96da0ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00567E',
        id: '87b6f4be-b7e6-437f-87f1-ff313f2b8cf3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004680',
        id: 'b7b445c4-6917-43dd-8ef0-e12699fa7456',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0092BD',
        id: '80e4ba9b-6bb5-484f-9833-be87567d067c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0084D4',
        id: 'e2c9af99-3a83-4567-b54e-6ecc627afa6b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0076A5',
        id: '329ee806-8984-4c33-964d-2dc1a85d9389',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004986',
        id: '7fb900d2-5bf3-4e64-9bfe-519d766cab7f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004677',
        id: 'd9c708ab-3270-4a27-b145-2a4a93ae6dd2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00426A',
        id: 'cd56ccf0-c4b6-4a1a-92ab-d82cbf50693c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003349',
        id: '6fe74c52-142f-42a9-b113-a48d0295cb7f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#99D6EA',
        id: 'e6f8eb87-e2b2-4106-a735-75cb0f3b1c5c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5BC2E7',
        id: '6dfa8d87-a3aa-43d7-952c-686a44a87881',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A9E0',
        id: '1d23709c-ffc1-459f-a6b9-48f918f62065',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0077C8',
        id: '10a4fea5-5078-4757-b29c-10b7c168ee3b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00629B',
        id: '1206067e-9918-4c2c-bb45-8e82458beaf8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004F71',
        id: '8059a869-9199-4c74-b51a-5d4d09664d1d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003E51',
        id: '139637d3-d1e8-479d-aaf7-75e99a3169af',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#56B7E6',
        id: 'dac89eec-f610-4099-bb0c-85e447d4c749',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A3E1',
        id: 'c51c8299-f7fa-4c57-9257-2ea0013ad2b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0091DA',
        id: 'a7c43cf3-d5d5-4147-96d3-b7878387a680',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0090DA',
        id: '9ab512f6-e454-4a69-9953-42f73937c327',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0086D6',
        id: '3852b02f-e11c-4818-8471-d6564f926347',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0077CF',
        id: '2a804782-3234-47c6-b03a-6f8338f10adf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0069B1',
        id: '025c30cd-6695-438e-a944-8bb1a3b7553e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7BA7BC',
        id: '9cdf9074-e601-45f1-817d-2fb6d23dd0af',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6399AE',
        id: '20b0a0c7-004b-4387-a7b2-d0bfab4d5eae',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E87A0',
        id: '77366f00-fb8d-46eb-8669-f4138d4a3a41',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#41748D',
        id: '7a2a784c-f5a1-482e-980c-30cb250d2a48',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#34657F',
        id: '722c8056-91f5-432e-a800-c02a5523bdc4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#165C7D',
        id: 'ae70406a-c96d-41aa-bdda-6a2562935641',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005776',
        id: 'c02b7058-3b00-4933-b194-e8b8af4d53f8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A6B8C1',
        id: 'c57166c3-3847-4dc9-a064-4a1f40864ed8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7FA0AC',
        id: 'a20377ee-b817-4778-93e3-a5ed5b1ca6c5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B8F9C',
        id: 'e8c752a3-dd00-4f0f-9472-729e4f3ba964',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#527A8A',
        id: '86c187a3-4f30-454d-a069-2bed5a5ddce8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3F6C7D',
        id: '6ca96a67-24f3-4518-b267-315682d03592',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2B5B6C',
        id: 'de06ce83-2d86-4738-aef8-01ed10fbd28d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#174A5B',
        id: 'e6ae9d9f-9726-4bba-a9f8-ca6b52a78239',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AFCDD7',
        id: 'fbd85bdd-94e5-40d8-bb24-684c59f08fcd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#89B2C4',
        id: '30739303-ac8f-4ea6-adba-c0a2362de597',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#779FB5',
        id: '41c70b13-21e4-4f32-b48d-3f83042418b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#659AB1',
        id: 'c1453fbe-0da2-4ccd-a915-b445067e8169',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5489A3',
        id: 'b6f4437f-98a6-4b95-93b6-0e2d0d4cf359',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A86A0',
        id: '762b28c0-0bcb-4055-b6cb-e477f0b52502',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#074F71',
        id: 'f4338286-a04c-479c-bca6-b56aa2aaee48',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B9C9CC',
        id: '9b072f41-93cb-44e3-9896-fbecd0aa6632',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#97BFCB',
        id: '94c9fb9d-b804-4d1e-af7a-b10e05282a2b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#65AEC1',
        id: '65b28922-b91c-463e-a14e-87907e1ff06d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#387F97',
        id: 'b2394651-3444-435e-a907-1bd68ee0216d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005683',
        id: 'd5cb733b-2f29-4ccc-8f50-d2890bed596f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004860',
        id: 'c055137b-f445-47c1-b472-dbc19c02d6a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#304C5A',
        id: '1d6a33ea-f20f-449b-8c24-4e5f098ece4d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBDDE6',
        id: 'ab478e3b-53f6-4329-9b74-63ddbf72853e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71B2C9',
        id: '9a4d6c82-ef52-47bf-bdfd-2a8748d95d47',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4298B5',
        id: '5894de4a-aef5-4a45-b32b-dda0e5680763',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0086BF',
        id: 'cdbe25f5-b80c-4c01-8b2b-602710164260',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007DBA',
        id: '03177750-9f41-4d73-b611-c80d6cb7c6cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00558C',
        id: '69157975-a8a6-456c-913f-9f0e508c4c7a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#002B49',
        id: '4e33c32c-cfcb-4515-a390-6480869e16ea',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9ADBE8',
        id: 'fbd97125-7d22-4247-b269-4d5fbd961317',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#59CBE8',
        id: '4553ad67-50a5-407b-959a-d7d6e42cdeaf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B5E2',
        id: '81442c62-4698-425f-8a32-b42305e75e56',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0085CA',
        id: '6435551c-6000-4ec7-ae4f-b853de0f1aa8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006BA6',
        id: '781d5824-aed7-44a6-a0da-25bc7af93d0a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00587C',
        id: '5376eb0f-1e11-416f-97b3-61139fe85f6d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003B49',
        id: '9eeed502-923d-409b-95e1-3f3f1f5f42d1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4DBE8',
        id: '6df5de7f-2200-4b83-aab6-d8156679b893',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8BD3E6',
        id: 'ac165668-2426-47ef-aea5-e75b84adbd2c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4EC3E0',
        id: '3dc3a378-6a55-4636-bd9e-07f40c53def9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AFD7',
        id: 'a3ec2e29-efab-443d-a08f-15ead7a77baf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0095C8',
        id: '7cc7c028-ad66-4a10-81b0-344bb432bdf7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0082BA',
        id: 'd89e1be0-489b-4464-b041-11293c041e47',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0067A0',
        id: '8e6827ba-60d0-442b-9d65-78d07857666f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8ABADD',
        id: 'b7866e6b-ca6b-40bf-aeda-d3e7debd062f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BCE1',
        id: 'bcbc8131-252a-4c91-9b7d-b71717e814fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A6C8',
        id: 'f780223c-e343-4e3e-a0ab-3cd9720f02e1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0094D5',
        id: 'c7e49273-cdb2-4b86-a03a-7e1c9a9b78fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006EB3',
        id: '6bb094d6-fda0-4c75-a023-c735037a2b33',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#287DA1',
        id: 'e80260d5-0684-4917-9396-8aa7eea4a6b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A939C',
        id: '5b2d62ea-4156-465e-adcd-2fd54f79fcd5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C9FC8',
        id: '5cf5a7cf-6a84-4538-906d-b6ce76bfc7c3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#077CAB',
        id: 'ebdf56dc-e96b-41e9-8992-cee5c346e20b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0081A6',
        id: '4025956c-d945-4fe7-bfa2-3b3584072045',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#166886',
        id: '925156ba-4296-4395-9032-45dd528ea89e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0092CB',
        id: '3696bedb-772d-4fff-9e39-7273fbcd6afa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008BCE',
        id: 'e5fb92ae-2989-461c-96f2-f470b4f0ff64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0099B2',
        id: 'd4b48419-2461-4ac4-ab45-9368d1f41e2f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#48A9C5',
        id: 'e5588504-5716-4f7a-9c19-17ff55a552fa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009CBD',
        id: '05893232-e9b5-495f-930b-e374797ce0f0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0085AD',
        id: '67842ec4-63a2-460e-8e5f-e454ce144482',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007096',
        id: '2fc7635f-c53f-491e-8cd6-b088f4cb652a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006A8E',
        id: 'cde2d9f2-9a2d-4cb6-a440-6a92d29b9723',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00617F',
        id: '0639c26e-a24d-4b49-9ada-0dfd93b7eb85',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005670',
        id: '794570ad-c23f-4993-bd38-57d98828fcba',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#74D2E7',
        id: '5bbaa597-4410-462c-9716-8be866f77c0b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4AC9E3',
        id: '7f6d18e6-479b-4090-8b85-5f0688323b83',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BBDC',
        id: '141d207b-7a3f-4d9e-b999-67b69e106dfb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#05A9C7',
        id: '4e09167d-8140-43e6-b1b4-23022d271838',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AED6',
        id: '696bf1c1-269c-4cc5-9a56-03efd6adecd3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A5DF',
        id: '899ee889-6e26-4c33-ae9e-a8fd88154cbe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0087AE',
        id: '9f2cbea2-ee7e-4434-9ed6-68e4a1cfb70f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7DDE1',
        id: '4d1b16ce-f5a9-4312-9a21-01d4ac882cd4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BD3DD',
        id: 'bfa00b34-99b3-4b5e-ae4a-5c24c7782770',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#77C5D5',
        id: 'a2511fc4-8a5f-4402-81a2-14c67c94ba8f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3EB1C8',
        id: 'd53a1073-476e-47cd-8c66-0375cfb23f6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0093B2',
        id: 'b4bad955-824c-4b23-a283-dfbe91cadd42',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007396',
        id: '03b6e490-05da-4673-b828-7d61bf37a920',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005F83',
        id: 'b6d91b0a-95ec-4e0e-8f82-08c056ea2b85',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6AD1E3',
        id: '5bacb703-2aa3-4fbd-a849-ad4284b7d0b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#05C3DD',
        id: 'b17b195a-4cf5-4ff8-87f3-6a9622008a2b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A9CE',
        id: 'bd76c008-2280-47ac-b214-3fac5f7f053f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0092BC',
        id: '4c646dc2-dd29-4621-b29d-23ba26b241bd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007FA3',
        id: 'e5df0a4d-f519-4372-a06a-dbe29b8bea8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00677F',
        id: 'ac3e4561-c5b0-45eb-9b7e-cd29836fc619',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004851',
        id: 'e9202fd5-8dd6-400d-b14b-355caecb344d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#67D2DF',
        id: 'a5fa4ad1-3a87-4805-8f8b-682650f6f02c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00C1D4',
        id: '849714eb-7b5a-425e-94b8-ba44e032a960',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AEC7',
        id: 'e5f97887-2428-4d54-86b0-6caa2ec1b0bb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008EAA',
        id: 'ec9b5f88-1d04-4907-8c80-30b4a4ad7275',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00778B',
        id: 'c9e3dbdc-2ea7-4341-9ed8-1fc86a44a279',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006271',
        id: 'eba8fe08-1149-4035-a671-9efc7aaa8729',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004F59',
        id: 'ca52cceb-9b63-4784-923c-e7d8603fcc0e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#63B1BC',
        id: '76a2d412-0e00-4803-9d98-81d88cbbf576',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A7B5',
        id: '1f4be0b9-bc34-46a7-a565-b8a466ec481c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0097A9',
        id: 'c81d2b99-4f7e-46ca-b0b9-c7fd9c656a53',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00859B',
        id: 'cbd8d4f4-f10e-4309-9f61-209355994802',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007D8A',
        id: 'e788ed1f-826a-4596-9b5d-07b05ab293cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007680',
        id: '6f170d83-1e06-4625-ae2a-54f5ceb84e8c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006269',
        id: '605f4c8c-6d28-4ad1-bb1e-26c8691975c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#72B0BD',
        id: 'c8ec377e-0258-4c2d-8a46-b637d0c5c0d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5DA3B2',
        id: '0b3c9961-74fa-45a2-94c4-7e56732dfc25',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4797A8',
        id: 'e4222902-0cea-454e-a3ff-11161b3e7a79',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2D8C9E',
        id: '80c46be7-3a5b-4255-92fa-2fbfc2c7ec13',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D8296',
        id: '2a3643cb-c45f-485c-8cd0-0d579d2ae46c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00788D',
        id: '6ad9556b-4904-42d8-b926-a532479a517a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006980',
        id: '7214544b-ada4-4978-97fc-9c026d68abd6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1E4E3',
        id: '6124bf45-4e8e-408c-932a-a9069bada634',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#88DBDF',
        id: '763df848-de57-4864-a009-4cfcf07f0648',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2CCCD3',
        id: 'c70e6cbc-85ac-417f-a2a2-0ccd59bfbe30',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009CA6',
        id: '50af6269-5967-46aa-8442-4f105b96fec1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008C95',
        id: '084f18e9-d499-420a-819a-b16fc0c947e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007377',
        id: 'ab638e80-2ff9-494a-ac9b-413c1248841d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005F61',
        id: 'd3650b2a-cb4d-42ec-9d8e-7cefa385e38f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#78D5E1',
        id: '4297679b-64e9-427d-8520-a92dba000e37',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3CCBDA',
        id: '7eeddecc-9ea7-488d-9f43-40148b4a886c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#59BEC9',
        id: 'c8e018ac-9b88-4ccf-a130-04118f4811b1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A5BD',
        id: '511ade1a-f00d-408f-bbe0-172b4bd9de00',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009CB6',
        id: '9c005f89-93c7-4d89-a0db-de31a969a4f9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008CA0',
        id: 'd347f249-bf41-4ba3-8230-bec8069b4058',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007C91',
        id: '7762506a-c287-4032-93af-babf34ca17b1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A8D3C4',
        id: 'b308892b-6923-488e-8741-5f80c2b40699',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8FCAB9',
        id: '9b9554d8-c388-476b-8ea2-bfe627c09e4a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1C8172',
        id: '92114e41-ebfd-4b54-a9cf-c719e0381c0e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D6960',
        id: 'dd94724c-d04f-4b3c-bf70-ebff4d6ccc7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#417571',
        id: '955f5f4d-289c-46b7-bb30-f33507136325',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#32645C',
        id: 'a78c4455-61d6-4c2e-b7fe-8d0437a3e610',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#244545',
        id: '7bd4f4d6-1e92-4531-b58d-18dcb1c941ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A0D1CA',
        id: '6762185e-6165-48fb-920d-83febf81cba0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#40C1AC',
        id: 'e2ddf718-391e-4784-ac49-80aa886acd1c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B0B9',
        id: '99323f5d-dad2-4294-8536-138b4dad594a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A3AD',
        id: 'f4c0a412-baa3-4f0a-a7e7-72088d5384c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007398',
        id: '519c079b-2060-4c1d-9349-3f9c535486c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005F86',
        id: 'e474fbe4-f2b5-4698-87e4-f0b89110ba74',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005A6F',
        id: 'b614f0db-b818-461c-b2bd-6971e9f95157',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7EDDD3',
        id: '7997eca5-ba70-46b5-8858-763e49b554f5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5CB8B2',
        id: 'dd10ff98-c3de-4904-b0a3-873230247086',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#279989',
        id: 'f13f958d-7900-435e-8f24-bd4f36cb71ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007681',
        id: '93e68533-d676-4047-b6ef-6f04bf2b28da',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#477A7B',
        id: 'e93da694-8291-49b7-8fe9-c2f2db348c34',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#0D5257',
        id: '0c2100e4-47f7-400e-a3de-d891ccf60225',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#244C5A',
        id: 'b233b724-a59b-4580-a5c1-1ce39a3aa5ee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F9BA4',
        id: '55485542-fe31-4af7-9e98-93639174879b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#508590',
        id: '210ec0b7-f0e1-4c50-8c96-8ae7e14e83da',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#337179',
        id: 'be550bff-fac9-425e-8a8c-182420c3325f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2F6F7A',
        id: 'a01c2948-1f6b-4ddf-a41e-8e2488e70931',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E5665',
        id: '18826ed5-870d-4666-a435-20b8b104ca2f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2D4D58',
        id: '670b785e-7ce0-4550-afba-d4460d07ca64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#003A40',
        id: '04b931a9-5877-461a-b32d-2bde3ecca90d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6CFD0',
        id: '8545acbd-7d12-47c4-b765-cd8f69495b1f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ABC7C9',
        id: '6687c099-e994-4be6-b678-cf63fe794351',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94B7BB',
        id: 'dacb27b0-47cc-4cbd-911b-d87c04303fde',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7FA9AE',
        id: 'd5248ad5-eda1-48e6-931a-79f11104976d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F868E',
        id: '5086afc8-9555-451a-adac-3036e521b690',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#115E67',
        id: '39dad3ee-06f8-416e-965a-3ad05e97f9a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#07272D',
        id: '33285d26-dcbd-492c-a654-53058e35aab7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71B0B4',
        id: '71660962-a0bb-47f2-b572-e20460522150',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#58A7AF',
        id: '0a696a4d-7751-48a8-b419-e27eb77764b2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F9FA6',
        id: '24769307-c196-4eda-8324-3a524546d017',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#28939C',
        id: '1881e594-a262-432c-a92a-e15326640d0a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2C9199',
        id: '71ed7293-1689-49c6-a728-b1d46549d8f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#05868E',
        id: 'f7874e00-b491-4b1b-90fa-fa3c29216df5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006975',
        id: '2e65fb25-36f9-4300-b5f5-1c8efde709b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00968F',
        id: '8f704f62-db79-4f0a-9884-832df9bc3706',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00857D',
        id: 'e234d191-40e4-4b08-8594-f3e7ebf5e35c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007672',
        id: '5871cfd2-bdde-426e-b081-b9c9cb2434ac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006D68',
        id: '2c28ef2c-e143-4314-8aa5-ae5387a61ccc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00635B',
        id: '0c0e0a64-a6b6-44e9-ab35-e5744ffc0209',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005E5D',
        id: '293a58dc-f097-4510-9fbb-6ab537c0ce05',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005151',
        id: 'dab9ea1a-7dae-4de6-9da8-2fcae415b868',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9CDBD9',
        id: 'e5b8a237-4c1d-46a5-b9ce-fa166855ce2d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#64CCC9',
        id: '8a91ea35-3db1-4758-84e2-9fcc56218708',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B2A9',
        id: '6f1b690f-ed95-472b-893e-a9403ad41d0e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008675',
        id: 'f6e1b5dc-0cfa-4af2-9955-9594483296fe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007367',
        id: 'f2b6141e-16d9-42a1-b2ce-03e566f351a1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00685E',
        id: '56776403-fa68-442c-a6ab-b73c55f17841',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00534C',
        id: '2d90b961-0a91-40a9-9dd6-f50049323284',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93BDB4',
        id: '6b782a45-f95e-452d-940c-384d5a8e3d2d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#69DBC8',
        id: '13e3671a-a107-4686-8ca8-d39d2ea3c6b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B2A2',
        id: 'ffe27e15-36a5-42ad-aa05-cbdc3c14f70a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#319C8A',
        id: '058912ab-9201-4081-930f-c479e4226d84',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A497',
        id: '95447c10-d612-4e88-af8f-5b03479e1d86',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007C80',
        id: 'f45773a5-75c7-437f-abad-60138e6b29fc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00939D',
        id: '260bd89b-3b90-42d3-b1ef-78b93975aa4d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71DBD4',
        id: '1b354621-d99c-4a2c-8c28-2ba17fff1904',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2AD2C9',
        id: 'b48df268-1c66-4302-9de3-0ecf20fac79c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BFB2',
        id: '498eb1e5-ef6b-49e9-bf2c-aed52da3b7b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A499',
        id: '7c38ce31-4e1a-447b-b585-0389471aff7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008578',
        id: '5e321e52-5182-4485-bb06-eb3f6ecad2c5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00594F',
        id: '486b2f7a-4023-42ec-8eb0-0d12452feefb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004C45',
        id: '8739a4e2-3966-44bb-b9c9-ed9d5d548bf4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B7BD',
        id: 'c94509cb-b64e-4c9f-bc9d-99e99e43652d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BAB3',
        id: 'a8c14eae-bded-48ee-b5c8-8c413a1e7944',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00ACA0',
        id: '7aa9aaa4-d8db-4ed7-9a17-4b37df35b24a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B097',
        id: '037afb64-7619-4052-a447-514d23ef5d6e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#34A798',
        id: '430d6b10-4a7d-4a7d-9b5d-2e623290757e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009D85',
        id: '903ecf03-ddc4-47e0-ac44-bb3106d183c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00897B',
        id: '21159059-3506-4f7f-8e4b-3f6af40e59da',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8BAA99',
        id: '531149e7-43e6-40d4-b6c1-8a6b4fce4853',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#689881',
        id: '16f379f6-7e4b-4188-9127-610f764e0c32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#56B093',
        id: 'ea2ee49b-5706-4dce-a1f5-2d8bb1e032b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6AAFAA',
        id: '10c30951-3235-4d63-8053-bcb5707974c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#259591',
        id: 'eddbb2b7-8cda-474a-b1c4-fa54950a9e9b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5A8D84',
        id: '1e66ca26-29ca-4bf9-825c-eea99620cf97',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B7671',
        id: '5374428f-f2b8-45f8-b3bd-75d6c894caec',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6C8C2',
        id: '8bc55470-e548-4a5d-bd52-85d5715dbe40',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4BDBA',
        id: 'fda1c2ee-6493-41c4-8061-954c7414466b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BB9B5',
        id: 'd5bc9b22-38aa-4261-a6ea-df21737a1b9c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#81B0AA',
        id: '6b4b3053-c6e7-4002-89f5-c6ef62f0e104',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#84C4C0',
        id: '5b1ade53-3a49-4795-a7e4-2df90e3348b2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#83C1C0',
        id: 'da153b5b-5a9f-4d03-9a57-deba7795534a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93BBC2',
        id: '09991201-51ed-4b59-9883-237d9813fb07',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7CE0D3',
        id: '7d9e9dcb-3377-4d90-9301-e2ff5c0911c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2CD5C4',
        id: 'c203decc-a103-48ba-8b65-a7431d147f19',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00C7B1',
        id: 'ecb821cd-9f0f-4cc4-92bb-9ec08305e4b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B398',
        id: 'd4d87103-6e04-49ac-b218-29158a35ebcb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009681',
        id: '78852326-351f-4a84-be46-ccb652772154',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007864',
        id: '895088d1-f8a1-491d-bba3-d315bed2b34c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#004E42',
        id: '4cdbeb43-7437-4761-bcbc-7956d8dba2f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6DCDB8',
        id: '9a04ebf4-481e-4b7a-91fb-30d910b043b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#49C5B1',
        id: 'a47d27ad-7da4-4e97-888a-903c51b72a52',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AB8E',
        id: '7ce665d8-e3f7-4e78-805a-5fdf4f5e2afb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009B77',
        id: '75a2afd7-097f-4ed5-81d1-5431ccc6d652',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008264',
        id: 'bcfbbd40-e00b-4844-b17b-ea400ef82ccb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006A52',
        id: 'c4304626-7bdd-47d1-8737-7d6be6d877ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#024638',
        id: 'c3e563e0-9a93-44af-8c5b-7416cd30feff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B9DCD2',
        id: '6b0326cd-3ba4-4f2a-b555-d116074601f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A1D6CA',
        id: '875fbee3-15a6-46f7-97df-9139ea2ed967',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#86C8BC',
        id: '675756b6-a8d5-40ed-8a17-b67de972e006',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6BBBAE',
        id: '90b18e1a-93c5-45bb-8a19-715a921cbb0f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006F62',
        id: '0ceb49b7-e73b-4754-8a73-20b8f7c61642',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00594C',
        id: '1fa16dde-e0a8-42aa-a068-f639c31d84bb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D3C34',
        id: 'e464d59f-59b7-4e44-8d8c-55d2671c00ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B5E3D8',
        id: '5593a6a1-3892-438e-aeba-e4606d28dfc6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A5DFD3',
        id: '1a78a53e-e0f2-4d1d-b746-4c048eb974fe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#98DBCE',
        id: '5af41936-34bf-40a8-aa85-fd76240e00b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6BCABA',
        id: '73a56e08-d87f-4c19-8889-7d1ef0ea5972',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00816D',
        id: '8b6faf68-791f-4933-a9fb-4fbdec04745e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006C5B',
        id: '9b52ad72-5c84-495a-842f-73fd0c357466',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#173F35',
        id: 'a476c448-165c-49af-a0ef-e422e7e332cd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ADCAB8',
        id: '45425c3e-3867-4d3e-8c62-d0c04e564ce9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9ABEAA',
        id: 'c01ef099-5e92-4987-80f7-8c75493608b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#85B09A',
        id: '6d5643fc-b4b3-49a0-9b90-211592b13e54',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6FA287',
        id: '4d45ba3b-d290-467f-9744-27837f92c705',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#28724F',
        id: 'aa161b7d-5e3e-4060-8d85-957280d2111f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#205C40',
        id: '167bafe9-74b2-4395-84a8-181cc3b1ea6a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#284734',
        id: '1c8065db-b31a-4edd-8709-5778fadd3523',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A5B99C',
        id: '477cd173-620b-4739-a24e-5dc285d92fff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#819E87',
        id: '2bea0bef-da4e-4d04-86c4-ac6a40521f84',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B8D73',
        id: 'c0e0c22e-eedb-42b4-97bb-c88cc980f559',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#497B55',
        id: 'd6afcd22-2c7f-4d18-b872-c3a3d7fd64e9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E6548',
        id: '3ceeae61-9267-42a7-bdb0-88b2209e1f1f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#445A3E',
        id: '844998b2-96e0-4cd4-9667-d7b57fdef9b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1C4220',
        id: '664dea6c-08eb-4e44-a7ff-d4f797f49eb4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFCEC2',
        id: '61c034f3-73f7-46d0-bbef-7cdbd5828da6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7BDB1',
        id: '83d978e5-4c62-485a-990f-1a72931be847',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#92ACA0',
        id: '8adf3359-552c-4412-9410-403ab9ca9465',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7F9C90',
        id: 'a3000b84-402b-46c7-bc98-62d5f8c6fbbb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C7F71',
        id: '539f4a9a-6490-4685-88a1-eff0a9a4cbe3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#43695B',
        id: '92541818-d98d-4fa2-be88-517943ec6830',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#183029',
        id: '278f1d05-39a0-43b1-9f17-8d83a8c12e32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BAC5B9',
        id: '598ea7d2-7aac-41a2-8e18-88b3cdaf3b93',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0BDB0',
        id: '31d90110-0f82-4a01-809a-4f8bd2b64a8d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A3B2A4',
        id: '76397edd-b4f6-4e1e-8676-4d37a4098c9a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94A596',
        id: '2a74ef2b-a8e9-4e8b-ab39-7d69c60c0b80',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#708573',
        id: 'eab858f5-292c-4101-911d-27190baf1922',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E7461',
        id: '29d74731-ddd1-4569-b2a0-e0ba74384587',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#22372B',
        id: 'fdc17627-c681-4de5-a2a2-ff5d800dbc4b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4CEC2',
        id: 'e0cd933d-dffa-4625-a71a-5643dcff6407',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBBEAE',
        id: '3ceaf5b2-fe39-49eb-a032-280ab96467df',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A8AF99',
        id: '4bd25750-ea5c-403b-bab7-1719e024eddc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#96A480',
        id: 'd33cb11c-eeaf-408c-ba76-60a5e678bc2e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F9980',
        id: 'eda17d43-c7c3-40f7-a7b0-2b4a4c59dec0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#738670',
        id: '397ba8be-0145-45a7-b2ef-8300b3ddaf16',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A7866',
        id: '7f172f65-6617-487b-93be-e860390186eb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B9C1B4',
        id: '94133f89-9ff5-4da5-b266-363fd570b136',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9AAB9E',
        id: 'd0286262-1bf0-458a-9395-e7f5d282cfbc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#88A29A',
        id: '07926f62-4b52-4ccd-a6c3-453c6d3606cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A8E89',
        id: '5d6a21b1-5e87-475a-8ce4-78ca0570a520',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#687C7B',
        id: 'bf9a0641-b960-448d-bef4-aa695835c4f8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#445D5C',
        id: 'f4d987aa-2326-4ff5-a545-0fa800bd3578',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1C4A50',
        id: '417935af-e581-4973-a038-b620e4bcfdf7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BCC9C5',
        id: '091d71a6-c2ae-4acf-9845-7435f7577895',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1C0BC',
        id: '2735f874-8823-406a-9f9a-9d76efe82c39',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9DB0AC',
        id: '89134cb6-a087-48b5-995c-1dbb9b66f0f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#829995',
        id: '1dabee60-6dca-459b-8ae9-be9a86dcb13b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E7975',
        id: 'd417d058-dad3-4aa7-b547-b8630229c6c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E5D58',
        id: 'e6036353-3ace-40b2-96d5-bed73332ce15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#18332F',
        id: 'ea5a064b-8959-494f-b85b-f5ebc4f15f90',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7B2A3',
        id: 'c21d750b-2a1c-4c29-8c2d-267c2417baa4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94A59E',
        id: 'f9a7b7f1-0676-46a7-9c52-0ad36ac299aa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#859587',
        id: 'cf76fcff-9ad0-4365-a586-69395df4748c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#62685A',
        id: '82a23217-92aa-4819-a71a-ad2c500b9f79',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#566361',
        id: 'b8789756-c79a-43a4-ad88-e9599c5645a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#646566',
        id: '71027df1-aeab-414a-b294-604adb6677d0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#47565A',
        id: '766f0b44-3f56-4edf-9211-96d79d18d026',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A9B8E',
        id: '8948674e-835d-499c-87c4-0e2c074b2d46',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#697E70',
        id: '9a4d15c1-8565-415f-9e07-d53a9fcbaed1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#59695C',
        id: 'aff7b172-ab14-4f63-a737-ad827f23e149',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#596C62',
        id: '8c99ee75-3d80-4089-b303-052bbd0630b0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F685D',
        id: '2b2ce91a-8158-45c0-bbca-d228d1e99149',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#384E46',
        id: '69bf4ef7-bb20-4aed-aee9-0007f2e656dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2E483E',
        id: '8d75537f-00b3-4c24-83d3-20065f824585',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D1E0D7',
        id: '9a595fac-c8e0-4b8c-9450-9c031bfec214',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7CDC2',
        id: 'e057aba3-a95c-4baa-9f22-ed5ef4b14e13',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9AB9AD',
        id: '7f108fcf-c159-4e58-a5a0-ac418c3c5497',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#789F90',
        id: 'd4767bbe-1146-42a2-9380-5b7a4935a5ea',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#507F70',
        id: '53a13306-9410-4941-901c-52fb34131de6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#285C4D',
        id: '66ca1c3e-f375-4743-8f1a-b5d1f1c07b6d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#13322B',
        id: '223cd777-7ffb-434a-a5fd-c83aea779dfc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7E6D7',
        id: 'e7a2e639-d4b4-4a66-8ddc-5bc0c45fc0fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8CE2D0',
        id: '552500a0-5027-46a3-88b6-a6cd096c639f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3CDBC0',
        id: 'cdb02f5e-cee2-4800-9bd5-2e98d31c8aff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AB84',
        id: '922dd7aa-5b96-4a99-9c78-cbd74c78c025',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009775',
        id: '76607f8b-4ed8-4394-b99a-465d92c8495b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007B5F',
        id: '20f347db-c08e-46d3-93d1-eb64f65f7276',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00664F',
        id: '25a574be-ba2f-4b79-af12-480b5e8ae784',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00CFB4',
        id: '0cf80033-c4c4-421d-b946-def16e37dad7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00C19F',
        id: '4f938a7c-640e-4f15-a279-2bec9af3043a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#58A291',
        id: '8b4824ba-d088-4ad7-b200-dda4c3ec9837',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A376',
        id: '270656c5-0578-43e2-8b50-5438011dfdf6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00957A',
        id: '7f4abcf2-af5f-4f14-85ed-e7249f29f25c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1B806D',
        id: 'cad13c0f-0975-42a6-9998-b52486fba45a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008655',
        id: '237bed5f-a133-4cc7-8002-1bc89ab12657',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8FD6BD',
        id: '992781b4-afdb-4541-8541-541ada846497',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6ECEB2',
        id: '608545f5-e1e1-4fd0-9dad-cd47a55a53c4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B388',
        id: '034bd1bd-6f13-4796-b77c-9be29b0f7f5b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00965E',
        id: '4535c44d-0772-4ccd-9b75-1ff642405d36',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007A53',
        id: '438bf939-4237-4902-a635-dc1a996c6a80',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006747',
        id: 'e0fbc028-2368-43aa-aec4-2450723770a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#115740',
        id: 'ca3ad329-ac8c-4fcf-acfd-420768258fbf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#50A684',
        id: 'f838c9ad-a93f-4267-b1fa-dd1688751217',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00966C',
        id: '3bb0a776-ac9c-4fd0-8d0f-865a21d2ee20',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008755',
        id: '1b701964-57b2-4ec3-9dff-ae2b53736918',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007B4B',
        id: 'a1c4b797-e04f-4d41-b929-14aaa4f974df',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006F44',
        id: '8de237aa-1b52-4974-a376-8a90a05fa13d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006845',
        id: '3b47ef8f-1711-41c7-a37c-a55b084df411',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#005844',
        id: '83b942fd-7bd8-485d-ab0c-6aa9dae1e510',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7AE1BF',
        id: '4ad6f3ae-b321-4e89-8b06-cbc19eee6e93',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#47D7AC',
        id: '9ecb8b1e-ed33-40cd-895a-c3be3c087012',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00C389',
        id: 'd8260cd1-9b2e-4158-8d6e-4833c3c35186',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AF66',
        id: '95f19968-39d8-432a-8d00-fb49069706b5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007749',
        id: 'd2eae166-9032-4832-b366-0a1247876117',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006341',
        id: 'd9a42ca5-ec3e-4e92-8459-1ccb3ae69772',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#154734',
        id: 'a63da9c3-32e4-411e-81fa-2bb81e101fda',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A8D5BA',
        id: '1a808dbc-b95e-4903-8672-3129272bf193',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#77C19A',
        id: 'b6bac5c1-8801-4ce2-b57a-cd7b21dd54e4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71B790',
        id: 'a54965c0-6b0f-4961-b314-bb67a1863a0a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5CAA7F',
        id: 'f995f68d-c29a-4131-a48d-3523c66cbab2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B373',
        id: '8e7bf182-641d-4daf-8e03-d9efec52318e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AD6F',
        id: '0831206f-fba5-420f-ba36-a508e6dff5b9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A74A',
        id: '9a1448bd-c130-46b0-a93f-e13164c5b410',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#48D597',
        id: '860d49b4-0c46-48d9-9c56-0e778bbcae1c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#34B78F',
        id: '0875bf57-d111-4e93-bf30-f2d589283fce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BB7E',
        id: '7a94af0e-8702-42fe-a166-1790cc7a9510',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B176',
        id: 'c6605414-4c9f-4d6b-b94a-6fb370e3c3b5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#249E6B',
        id: 'fe6e819e-1a6a-4683-b8f0-ad86764021fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00873E',
        id: '89b525ad-4aa3-4d8a-abaa-d9b3e0cc5ec5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007C58',
        id: '51c36e40-ef1b-4dbd-9d4b-baa0bbe925b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A0DAB3',
        id: 'f2d6db83-25e7-4484-842b-4214766284cf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#91D6AC',
        id: 'd9aa57c5-1d06-4e37-a3a8-86f6c9e8bea6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#71CC98',
        id: 'd646b708-246d-494a-9df6-cbde1510af19',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009A44',
        id: '5bec3e15-af21-44cf-9d45-426e2f5b05c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00843D',
        id: '0df9fee6-8a50-4dd6-b196-ed8398a70489',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#046A38',
        id: 'e1e850e9-2da7-4b12-849e-c59973ab2208',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2C5234',
        id: '159e7f13-c841-4bd8-b731-7fea47259ef4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2E4B8',
        id: '22957b38-5d77-4eaa-a544-34192d4c68cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8FE2B0',
        id: '8916e0f4-8b16-44d6-b3b0-4d162ce87db6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#80E0A7',
        id: 'd539b49a-5ab4-43cf-aa62-96de8a8e9415',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B140',
        id: 'bdd14cfb-a882-4070-b614-224a41341941',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009739',
        id: '63d9a582-287c-471f-8653-9e5322f05536',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007A33',
        id: '22b845d5-c7c1-470e-bb07-f8985177355d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#215732',
        id: 'd38936f2-0021-42eb-96d9-b4cc875cf863',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BE3BF',
        id: '06019ac9-8771-4aee-9002-b346f9dd450a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#26D07C',
        id: '8aa4707a-54a9-49cf-a94b-c384cb74ebb0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BF6F',
        id: '9ceb90db-a84f-40ff-96c4-793b8846e678',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00B74F',
        id: '6b88393c-1c9d-4c3e-8861-2bd63058aa28',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009F4D',
        id: '0bbffd91-8c48-4e30-8ddb-ff494f95830f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#275D38',
        id: 'dd3f569b-0f7a-488d-94b2-37107508c82c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00573F',
        id: '18b3244b-2b9c-4b8b-94b1-b32e975c4b39',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BDE9C9',
        id: '35c33645-db9d-41b4-98ed-8274f0eae115',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ADDFB3',
        id: 'cb2d553a-ff4f-4522-ac98-df368feef8e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#86D295',
        id: '628ece54-55db-4e7b-a9ec-0cabfe31d7c8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#56C271',
        id: 'bbf1ed27-d6fc-4f62-b358-82538b7e626f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AD50',
        id: '7a40ceb9-44f2-4938-8ee6-0bd0838c2c24',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008522',
        id: '417084b3-1319-4996-b29d-6dc340e9b775',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#027223',
        id: 'cdbf4695-8b64-44d1-8e6f-fbe3af99706b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#79BE70',
        id: '7ce2591d-81c2-4a21-996d-81ddcb5c99aa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1E6F30',
        id: 'eb662c50-aa0e-48ac-b5f6-5f834e57190c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00892F',
        id: 'b7cbeae6-996c-4934-8f8d-f0521c44f188',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007737',
        id: '34371b16-92c9-44d2-9c17-f6efb4cfba01',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#006E33',
        id: 'ce384639-1409-4c1a-8992-9260143e1d1f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00491E',
        id: '5fdc1fbf-7ba2-4d61-97e4-8556f9c157f5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2A514D',
        id: '1d2f365d-dc91-4dd1-b3c7-79bcfc3e93e9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B9560',
        id: 'd9d33780-a842-4a9e-8579-cedb546ed474',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#228848',
        id: 'df478c3c-711b-4e64-b71c-f1a969ff8050',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007A3E',
        id: 'a1b0d236-2bbe-474c-a640-a8d7846ce494',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#007041',
        id: 'bb8ad627-5ff7-4919-9a97-fa84717fc647',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#286140',
        id: '4a823cb5-3ac3-4df8-b381-a63cecac2349',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#36573B',
        id: '1f6a87c3-cc71-4e9b-919b-dcbca503529e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#385542',
        id: '1f520276-a9c8-4dc2-8e76-2fca7d4adddf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6BA539',
        id: '5e5695e0-8b76-4068-a951-06d3f2330ea7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#47A23F',
        id: 'b41b4ca1-8faf-4cdf-af4f-e8252d609f93',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#309B42',
        id: '39757af9-8643-475b-b0cc-1b7630cd0994',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3A913F',
        id: '05c5f9b3-504d-4c46-aa18-7e2ae6c6e34c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#44883E',
        id: '790a2802-f0bf-4921-a4d9-738673346ccb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A783C',
        id: '32882ee0-49a8-410b-9ce9-fdcb73c2634e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#44693D',
        id: 'a7a59a70-84d3-4e30-877f-938792155b64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1D1A6',
        id: '4163d025-4a1c-4eea-ac82-2bf3c6bc7fcc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7C186',
        id: '04e25e22-77be-4dab-810c-8d7ab0ed2feb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8DAE81',
        id: '709faa03-e703-4f12-889b-2b49cbf44f26',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7DA177',
        id: '9bcad4d8-4c5e-4e82-9add-550f4e161d47',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E7054',
        id: '791fd016-2f2a-4761-9af1-103a51b23b32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#38584B',
        id: '72e3e3b0-b5a3-47a8-984d-4e705d3063d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#304F42',
        id: 'f935a80e-ba41-45d8-a759-22e9f7fd704d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6CFAE',
        id: 'de947c2c-63d3-4900-b85e-e2c1daa33c11',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9ABB91',
        id: 'c0eb4bbc-16ea-4689-bc1c-a0bdaa451a52',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8CAC89',
        id: '1eba71b4-bbf3-4b1e-9b20-04eb528e33e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#74956C',
        id: '6e9b1fe7-b64b-492f-a4f0-c8abfd218e06',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6B9560',
        id: '5ab73036-1e31-4bd3-90fc-1c95c8f2c6f3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C7E51',
        id: '1d4409c5-e2aa-4d08-af44-e2dd0c0f3e67',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#335525',
        id: '65c44d4d-e285-498a-ad26-af4caffc754b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ADDC91',
        id: '55e3b8ec-866e-45a8-9aaf-236c2bbea373',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A1D884',
        id: '0e22f4f4-0ad8-41d9-8d35-db5264263104',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6CC24A',
        id: '14a9850c-efeb-4494-9a3d-1a9667731869',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#43B02A',
        id: 'a4bd117f-f587-4af4-ad33-b7f914f25392',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#509E2F',
        id: 'e55a6415-2a7d-435d-add9-8c1e7a5e75a5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C8D2B',
        id: 'f2c76992-8ff5-41b5-9eaf-e7f0305b2ff6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A7729',
        id: '37109b03-3d34-4412-a97b-e3a99b068a32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9BE198',
        id: 'd02228aa-084d-41d2-9b7b-36e924eef5ac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#79D97C',
        id: 'edb4f58b-2515-41a0-badf-06125e74c99c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7CCC6C',
        id: 'f63a01cf-69fd-477a-8286-46f9cc8d1efb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2CC84D',
        id: '1b9c25cf-f010-4266-bf75-bf37cf90ae34',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00BB31',
        id: '64fcb0bc-85d2-4c8b-a34c-0c387c587a36',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#009A17',
        id: '22b40931-63f2-46d8-a08e-8cab5134ee8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#257226',
        id: '19428b8c-132c-4745-9510-d572a7d752bb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00C65E',
        id: '266d6e65-58f8-4cf5-9bd9-ec534cf297ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#30B700',
        id: 'd3f31e70-c620-4655-8d53-c70ba6bc5c66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00A82D',
        id: 'd07886e0-ae7b-470c-8691-0d24c57c4321',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#00AA13',
        id: '3388bc6f-cd88-4cee-8d42-c4cb47250e50',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#279F00',
        id: '49217891-9f24-43fa-9478-89f234a6ce66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#008C15',
        id: '31c84de6-b10a-4573-bc20-0163be170f7b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#025F1D',
        id: 'aa515893-26b2-4827-b8de-0e9e54e2cb9a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0DEBB',
        id: 'e90fb2ac-9047-4c34-8605-d710796537e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BCE194',
        id: '79eb9d69-7167-4b2a-a9c9-43f0990e92e8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8EDD65',
        id: 'bc51aee2-aa5f-43a8-bd3b-15521e407e53',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#78D64B',
        id: '23786c9a-c05f-47f5-bfd5-4c6ea52c5703',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#74AA50',
        id: '074d91c0-17a3-4535-81ee-094b008fa76e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#719949',
        id: 'bc7a47f9-bd38-45e9-9da0-7525f601e4f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#79863C',
        id: '78f9b6a5-5dec-4323-a1c1-51bc9ea06af4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CFDCB1',
        id: 'f32b74a8-9638-4ac8-a75f-c3c068a9fa92',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C3DC93',
        id: '3613080c-409c-4c85-ad75-906a4b4ec27e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#89A84F',
        id: '6a49ebd2-5fb7-4cb1-9f13-0bb858a128d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#55951B',
        id: '15346b96-d503-4f21-ad33-c48676ab017e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E801F',
        id: '6df0dd11-aec2-45b4-88a9-5e322364af6f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E7E29',
        id: 'f9e5b355-d984-4e84-b5cb-fca2e02ebe06',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A6A1D',
        id: '5f546499-05b3-4f8b-86b6-40a74a6d2b01',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C2E189',
        id: 'd860ccc7-6a9a-4d46-bbb8-b7fd6d427b7e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7DD79',
        id: '9ae9d0de-86d9-4438-ab06-6855dcebf6be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4D65E',
        id: '0204b2fd-e7c0-42f1-a0ce-0c86212d2524',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#78BE21',
        id: 'a3ab7eb2-4c6b-4881-a86a-e95582f4bf2b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#64A70B',
        id: '63f4850e-3048-4538-81ec-416bbfb748c9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#658D1B',
        id: 'd8f3ad7c-1ae6-4c9f-b5aa-98f58044ad2a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#546223',
        id: 'd4409b44-c5ca-4455-8b20-b782c05d49dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1D600',
        id: '0d590548-acf3-4010-a7a9-2a48905d2090',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AED000',
        id: '6086509b-4747-4dd4-9999-07bac24c1645',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#79C300',
        id: '31d55682-e7dd-4fa2-aff9-7d090c5227df',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4CAE04',
        id: '85980f1d-6404-461e-9f15-04412590d0fb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1FA824',
        id: '521606d0-23fc-4fb7-b34c-9af967ce736b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E9A2C',
        id: '64018b95-174d-4e2d-962e-f458de0dd6f8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E7930',
        id: '7698beab-50da-47d2-bb7a-7a3bd3fc4244',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9EA9A',
        id: 'fb2a8a1b-6a33-48d7-9afe-82418bb7f53f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C7E995',
        id: '08e928a8-5ee4-411a-b584-25641289dd6c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7E163',
        id: '414d4381-aa9b-41a8-88a0-838d6a9e54ed',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B3D57C',
        id: 'cdd10856-8329-4c01-8b05-5d216f5b726e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93DA49',
        id: 'cf800260-b395-48b8-a003-ee353e1dd9a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7ACC00',
        id: 'd2924b2c-7617-437f-9795-10de7ec80355',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5BC500',
        id: 'b089b28e-ed78-4a2b-8c9e-879939c4a4d3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D4EC8E',
        id: '10448cb5-4d9b-475d-8674-705842838399',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDEA80',
        id: '8d203cbb-004a-4f0f-8cf5-e9ee4c454206',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5E86C',
        id: '6882ff33-433e-4469-ac2a-722a81296e81',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#97D700',
        id: 'b7f07a7d-2d4c-4b15-b829-4b3a5aa342be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#84BD00',
        id: '32ccc0ef-c384-492c-9e3b-f38ca34247fa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A9A01',
        id: 'a062cf45-03e8-43b0-8c1f-208699d4fa04',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#59621D',
        id: 'eed5b4c3-11cf-4000-99e7-1ca0fcf0e1e4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8E379',
        id: 'eeb105b9-2171-46bc-a0e0-92693a7fda96',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5D97A',
        id: '43b05003-89d2-4c67-bd8c-efa2a7cdf6b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AADB1E',
        id: '8fdac91e-1d1d-42e5-87b0-2f4c634cd5d9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7D500',
        id: '33b17f44-b0e6-4b07-bdb9-9e9ff21466d7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#93C90F',
        id: '7d17a84a-8b4e-4996-ab5e-d4e6bb72ae55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8BC400',
        id: 'b7c637bb-eb8c-4655-947c-c5748682c6b1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#78AA00',
        id: '8a76c20a-aff4-46f5-b1a0-20d39d1e25f7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0EC89',
        id: '9170f1e3-2e91-48e5-b09a-59f867dca968',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6E865',
        id: '1d853b5b-3676-4292-b0f9-9995ec34785e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C0DF16',
        id: 'afbfb85d-585b-4016-95d7-e0c49ba910e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7DB57',
        id: 'f29797fe-49f0-4563-8e18-e9a8e7c21904',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4D233',
        id: '7f5c2606-3184-47d2-96b6-2945cd6d9c65',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A9C23F',
        id: '54f5352a-7868-497a-ba66-1d3aad979ff5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8FAD15',
        id: '4cedfdf9-55b5-4dd2-adae-40af6dcbdbd7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4D6A4',
        id: 'db6c0ece-fac0-4123-8f1e-b914adbb8b69',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BCD19B',
        id: 'a706a7cb-6ab9-4a05-84ec-ad45f6c45819',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7CE95',
        id: '87ed1c20-5eaa-4fbe-83d6-967c97b5ebac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A9C47F',
        id: 'f7bf6ebe-a4d2-4d02-ae82-a92c0e6728b5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#789D4A',
        id: '1094f4ad-a138-44b4-8060-64afab38641d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#67823A',
        id: 'de2df87c-00be-45c8-b53c-27ea102b6917',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E5B31',
        id: '0ef85622-7c65-40d8-8898-1940efd3d5c4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CFD993',
        id: '43350b27-92c4-4589-af7b-96b052171d0b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#86AD3F',
        id: 'c0c183e2-b7ce-4d0a-bcc4-acc32df13554',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A9033',
        id: 'ec03bb0f-92a5-4b15-b90f-4df9004d8202',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B7954',
        id: '9817be9d-cec5-405c-a154-d02f4ddc998c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#526E3E',
        id: '31619829-f6d2-46bf-9c64-0f880c439ad8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E5824',
        id: 'd38a1ac3-53d4-4076-a608-c38cf6ec71f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B5E23',
        id: 'f194a565-cde7-478e-a5de-d4eff7ae1358',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0D1AB',
        id: '0a1a8944-7272-4fe1-9d62-e1a5d1046835',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6C89B',
        id: 'fc4b4db0-d9f2-4844-b905-55ab07a957d2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BABD8B',
        id: '5ea8856c-846b-4ee7-8f8e-99e14c2ee365',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2A568',
        id: '8da12604-2492-4f12-9a02-1f9d89cbfc37',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A8D4A',
        id: '0ca10028-498a-4750-a927-52e7703c6dd4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6D712E',
        id: 'f8586c41-48f1-413e-b0e7-6f29ea8796d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3D441E',
        id: 'cc972395-1756-4332-a4c9-e4a2a81203d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D2CE9E',
        id: 'a7646da8-1f2a-462a-b4d7-74bbf81d5346',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CBC793',
        id: '9dff6d4f-52a8-493c-afc7-f4949d67c37a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C0BB87',
        id: '596bb65c-a2b0-43d6-8e35-13ec1a45aa00',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AFA96E',
        id: '3bd945d9-9b37-40f7-a5f2-932f4b32c8c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A09958',
        id: 'e5c5f08e-33a1-4102-8d6f-2cefd4683a08',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#89813D',
        id: 'e23c56d6-01fb-408d-8fe5-ebcfb424cc58',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#555025',
        id: '57a6d5e0-ee46-4b82-86da-bbd260efcc38',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AFB59D',
        id: 'f2a6c735-a4dd-460b-b7cc-7cbe9c1254b5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A4AA96',
        id: 'fb132788-756b-4312-bacd-792cb88e2b2c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#888A77',
        id: '40d095bf-2716-409d-9794-c1ed3cbad3a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B7D60',
        id: '464c6c23-76f2-4943-911d-8f48a1f4dad0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#727563',
        id: '4cfb4c26-fc9d-4284-8dd2-38d1d38eeafa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#626951',
        id: 'bf67e974-a364-4476-ba40-7ebddf66ae3f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E4938',
        id: '5e961721-9f20-4c09-9c65-fcc5444b3f50',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C3C6A8',
        id: 'c3eb37c3-9218-4f73-8032-5b2cfc71b789',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B3B995',
        id: '37354760-3fe8-420a-9435-449611948a56',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A3AA83',
        id: '2f1e4b96-7e8b-4ae6-8e12-f322a899f556',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#899064',
        id: '236c870d-9165-452b-835e-81ebbec01b17',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#737B4C',
        id: 'b09d27ba-7712-4812-bf8c-3a6a0a68de97',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E6738',
        id: 'db201c2d-4f2d-4220-baae-b22b73d3cfa1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E4827',
        id: 'e6001431-1a59-480f-96bb-dd8ca1feabee',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B8372',
        id: 'c27c1796-f3ce-4939-a49b-066b1e94ed1a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#868165',
        id: 'b919f35b-33eb-4380-9dc3-8bf5a7f7805d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6A6851',
        id: '88974dfb-d3e2-4b8a-9d71-44a92f45bc5e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#59563E',
        id: '205a0559-72bf-4e1a-a791-39dcc8374be7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B5C4A',
        id: 'cdd0ebf1-7880-4f65-a4b0-731c0208daca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#58534B',
        id: '8b56a2f8-2134-4bf3-b26d-275bc921b89a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#635C4F',
        id: 'c30e21e7-5e4d-4ae4-8ba2-2ad0c6ddeb5f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFCC80',
        id: 'bd1d5461-8fc8-468e-a1e1-ea7d74a24332',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBC592',
        id: 'bb6898ed-75ef-4550-a1a7-8a5b59c98c8b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9CAF88',
        id: '61ef5822-fc31-499d-a59a-fa3743081742',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F993E',
        id: '3b2436dd-4f78-4084-8b4e-8689be1d8359',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#76881D',
        id: 'a4aa5707-9410-43cf-be5d-0fe5c192996b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A7256',
        id: '210fca99-789d-44d5-813a-835007be62d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B6236',
        id: '7e14e302-b97d-4cac-9cc2-90ec8150e306',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AEB862',
        id: '15035fca-72b5-4a59-b687-7c8897fbf1db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EB356',
        id: '23a944e5-f231-43bc-86ba-9e3641cfc41e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A0AB4D',
        id: 'f0a0ca4d-ca85-4ef8-8137-ff80577d2c89',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EA700',
        id: 'c567f426-927f-4969-85bd-a3db40361357',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#808C24',
        id: 'bd33b8f4-04a9-492a-84b7-ced50589e7fe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#686F12',
        id: '162d4686-49e7-4062-bf9c-76ac6031926b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D551D',
        id: '6ba071b5-f3c2-4021-b3cc-96f227d99a47',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BABC16',
        id: 'a91e68f6-c6b6-419c-85a6-8441d3ae31e8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ABAD23',
        id: 'e2a6a672-0f2a-4dc9-ac76-c4e00bba100f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#999B30',
        id: '6a59203b-55ee-4777-a368-1459553e31e2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#888D30',
        id: '2622c9d1-3994-44fc-97fd-fb55aa473d3d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C8034',
        id: 'd1a62bb0-d5fc-4e20-9ac4-149680087be2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#727337',
        id: '0bd619b5-3a1c-4cea-bf54-36be7e54ca7b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#656635',
        id: 'df165345-4102-42c6-9ee8-0f89959eca63',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E2E868',
        id: '392e842b-6c0f-43e1-816e-a953068becd0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DBE442',
        id: '18e5fe58-3e50-4f50-9fb6-a2ecf704f9bb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CEDC00',
        id: '6ce9a4c3-def5-4bb8-a44a-91173dd4be5a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4D600',
        id: '32bb40ee-0687-4255-8bba-8fdd9e71e24b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A8AD00',
        id: '8e68ffa7-dcc5-45f7-9ea2-203869609ed1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#949300',
        id: '9d110460-e1e5-4c60-bd7f-591eb8461a76',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#787121',
        id: 'bcbfe5bd-6dc7-41ce-9f9e-615c016880b8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9EC6B',
        id: '365704ac-e05f-4323-9884-56d60e57371d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3E935',
        id: '9e0cb06c-eaef-40cf-90e4-eb213ae66831',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0E722',
        id: '2e7907ee-6e3d-4796-97a4-76d7b3b2f8c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0DF00',
        id: 'e058ef97-06b9-4788-bae2-4443d7ce8d5d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B5BD00',
        id: '3a783804-63e5-4274-a1dd-1500f26d728d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A9500',
        id: '6b12027e-659c-4c20-9b6f-b97210b28776',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#827A04',
        id: 'c7b57a8a-b071-43d2-9259-049409b8baac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E3E48D',
        id: '21520c44-2162-4252-856b-91630c22abde',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0E27C',
        id: '542b9c5d-1c31-4074-a358-2da7458ce37c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DBDE70',
        id: '052608ee-46be-491d-908e-24f633aa861c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D2D755',
        id: 'fdb527b2-f908-4905-9723-8476539aa5f1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7BF10',
        id: 'dda8a9ce-e3df-4695-8f7b-f0b750ecf4a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E8C13',
        id: 'fd428153-fb13-45ac-8ac3-b096f3248e44',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#625D20',
        id: '289c10ff-62a4-4c7b-b425-642ed56ce082',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D2CF05',
        id: '0ab9b132-5449-42d5-ad83-7674e96e2c9e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C0BE00',
        id: 'd34f3a05-ff17-4bb2-8102-ba59f40096ad',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBC25C',
        id: '35958d47-571e-45ce-aedc-ad35a7492790',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0B07C',
        id: '83ff870f-7720-4b46-ab1a-076227a8f6a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFBE69',
        id: 'bb40b7be-d82e-400e-b643-16475d6858cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ABA360',
        id: '523f97b5-21b9-4848-bddc-dab49c0f0417',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8D8B53',
        id: 'c89b67b5-0055-4cbc-8302-161ed3370c02',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0EC74',
        id: '58860201-3826-4bf8-98d2-2c11e3ac6431',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EDE939',
        id: 'b63cf377-cf43-4090-b362-a31a20b91046',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ECE81A',
        id: '73a0410e-ba9b-4e99-a196-a686fbb480b7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1E000',
        id: '970be365-cf3c-43c6-a2af-cbdf39c61797',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFB800',
        id: 'f4d7c6a8-e9c5-4944-96ae-3e4c15dc4eb4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ADA400',
        id: 'c2f603c2-598b-4d87-b2c7-45233e230eaf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A09200',
        id: 'c69e047d-003c-4bf8-8e45-1d05e7d5fa39',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3EA5D',
        id: '96d93e5c-93cb-43d7-8e29-2d116663f56d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3E600',
        id: '6da043aa-411c-4033-9882-37abc86af9e1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFDF00',
        id: '60b5f90b-d617-43ae-b9b1-f4a3e0a23be1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EEDC00',
        id: '6d7a5bad-edc1-42f7-84ee-dde4a6d96b9b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBA600',
        id: 'f66f3752-a6c6-4c29-b531-f0a3ad5dd69c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A8700',
        id: 'a41d76b3-0af2-4a7a-98ef-d2a9b84a63d0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#685C20',
        id: '7d9739dc-a815-4af4-bf9a-acf5d2047a60',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1EB9C',
        id: 'e7f3ddf3-bae5-4df2-8189-d6f29c86b956',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0E991',
        id: '6666e31c-b85e-4f5d-b863-acf93cdbd240',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0E87B',
        id: '357c5c65-41d4-4489-8c70-6f294fbc08b3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EDE04B',
        id: '0335f9b8-39b6-4529-973c-6c96ba71daff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EADA24',
        id: '761805a5-3bb2-4dea-bc1d-bf025ce50ba8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1CD00',
        id: '50d88f76-cb54-4753-b507-9fd12b14ae41',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CFB500',
        id: 'b1195516-b28f-4ae2-b46c-e66a46f01328',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EBE49A',
        id: '433bdb67-cc37-475f-b0b4-3d0a072522f6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9E186',
        id: 'e69ef0cd-f33f-40c5-aef0-4c38bb80798c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E6DE77',
        id: 'd31ea981-483f-471b-a09f-b7064587a4e6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1D555',
        id: 'dd37642b-8dd7-46be-b7bf-14d1a7650271',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7C826',
        id: 'a5f33479-d1e5-4a98-b030-796472e13ba5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4B000',
        id: '870eda09-6140-49d9-8abb-054ae4223895',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B39B00',
        id: '0a37f4e9-4dd9-420d-a0b0-1c982b50ffa6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E9DF97',
        id: 'a9226c2a-5b5e-458e-ab56-ea3d49c42e90',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E4D77E',
        id: 'e088ed0f-ee67-4f93-92f4-d6e4088422cd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DECD63',
        id: '6134b2dd-49a4-4161-9af1-22354942d7f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9C756',
        id: 'aaf7ec18-3107-4746-a86d-33ff163db446',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B89D18',
        id: 'a5a51838-a936-4683-9843-c0f7df038aef',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A28E2A',
        id: '08bf2add-8b0a-42c1-96d1-dfa5528ec577',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#695B24',
        id: 'b40dede5-b913-410c-ba39-9919fce1e67e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DCD59A',
        id: '8015d4be-6ba8-4139-9d6b-17bf1b56f1ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6CF8D',
        id: 'bd94b938-70ab-44e5-b8f1-a3f02e8d364d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0C883',
        id: 'd2fdc706-0860-41b0-8db9-f77bbb99c229',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C0B661',
        id: 'f7b89f14-5195-480a-9991-f809c28c531f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC9F3C',
        id: 'b9ae590a-2cd4-481a-98ec-d91de68de9cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9F912A',
        id: '7335b94f-138f-44af-a523-fe46ef1af71b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8A7B19',
        id: '0315b4aa-8745-475f-aa51-34369866fcb4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CAB64B',
        id: '78337bde-4572-4f2a-9251-65ff96be3111',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CFB023',
        id: 'f63e2989-7330-4976-8199-f635ca2c7e70',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1A01F',
        id: '64ac35f2-3471-4641-b7fa-adfe8bc3e64a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A08629',
        id: '6769d18e-1008-46c7-a6de-0305243cfa53',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#897630',
        id: 'ab3f897e-18db-4228-89c7-7c7062d60228',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#736635',
        id: '1ad55efc-66ec-4c64-aa0f-f4ca807bb01e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#675E33',
        id: 'b0aa0978-4531-4c3c-b543-2956a77c9df1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D4C304',
        id: '69fb4941-1898-48a9-aa47-7c227ef05f7c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4B200',
        id: 'e8ee3d87-b9da-4032-bfed-6ac032bebbc5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#91852C',
        id: 'ad834895-35aa-4cf9-861a-d14b41097e07',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#747136',
        id: '6f704e2d-20fc-483e-bf39-d9a3338a1df2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D6439',
        id: '0d102b3a-5e5d-40dd-bca5-08cdf205f305',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#585C3B',
        id: 'feb0f10e-0687-47be-b8fd-783d48fab8eb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#535435',
        id: '1e865921-769c-41e6-89e8-d0498ae83b72',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBB323',
        id: '77805951-f44b-4ec0-8cc1-a0c280be849b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B4A91F',
        id: '6a5ac1be-1841-488d-af87-6ff66b79c63d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AA9D2E',
        id: 'a557d8ec-6da6-4894-be21-49934dc38328',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F7E35',
        id: 'e0a27e2c-b045-47ca-a558-95d2df92492c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#716135',
        id: '49be3e10-dc6f-4e6f-b19e-18054ccff85f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#635939',
        id: '90678485-213f-4609-9c85-746a58c11eac',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E4934',
        id: 'e3ee890a-1ae9-4b9b-a41a-3cf3852f40f5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5CB9F',
        id: '67b99025-4ed6-4d95-a2eb-dee69ae5a5c2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CFC493',
        id: 'cde78d84-5d63-438a-927c-1c08ed9aa7c2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5B783',
        id: 'a37e9ffc-ecc0-4e4f-87ca-983b74871ff6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B3A369',
        id: '709879c1-dd6f-452f-a0ea-b14c7648df06',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#998542',
        id: '3b0e88a5-47be-454f-a329-c9d484584e5d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C7732',
        id: 'e2d24a0e-b1e6-49e1-8c2a-c82cf11bb6c1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#614F25',
        id: '98f8b0a7-02a1-401d-8d26-5ff9d0f8f2eb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B8B298',
        id: '2c589d3e-4201-4586-ad92-9ae80e76d45d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B2A384',
        id: '2c045e4d-81a2-4951-afdb-92c4cbff973c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A09074',
        id: 'c8cf99b9-335f-4fe0-a53e-dec57128df92',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B7C5E',
        id: 'fe8e3aca-d52a-4eb4-bd70-05ecd65ec763',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#837A58',
        id: '45e85df0-7241-4cfe-8ebc-44f626c04484',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#786748',
        id: '40500f20-7bac-4405-9369-3f04cc4e298d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#68593C',
        id: '8f6c755c-c100-4740-aea7-d8e4163df47a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8C3B3',
        id: '7c8577f8-266b-44c6-9a38-87207c000f55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CABFA5',
        id: 'd79ed631-4d61-49d5-b987-02ca4b0ead5a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BAAC88',
        id: '3d97b9cf-52b7-4bc9-9b9c-94558e1c06f5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#88764F',
        id: '36ccedbc-7baf-4b32-b1eb-8488221cecd9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#836A4D',
        id: '008db62a-c769-4014-915a-4508386a0a09',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E694F',
        id: '2287a0f7-2acb-4d35-9a2a-0d3e9beadaf4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5A5040',
        id: 'c6cf4cb9-a5f4-42c0-9783-f9d01a593e1d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CAC7A7',
        id: '84e8a9a2-8760-402b-89e5-dd6b857cb908',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFBB98',
        id: 'b75f889a-4a39-44ab-8914-ea8d1c24fd12',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0AA7E',
        id: 'fec5ab42-910b-4002-ad32-5a6b6218fba8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B945F',
        id: 'b405f36b-47a0-42bb-8caa-12f973ef4a52',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#594A25',
        id: 'f0b47212-30de-465a-85a4-2f2d00c4d919',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#524627',
        id: 'fc867ddc-1b08-40fd-adae-a70b2787715a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4A412A',
        id: 'bd5167d5-745a-4833-ac87-d3dcfa710fd2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1E6B2',
        id: 'db88e8d3-e6ab-4abb-9626-bbaea293e4e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DFD1A7',
        id: '9cfcb99f-92c6-44ec-8ca1-4d2c8bdbb483',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9C89D',
        id: '5df2869c-c506-49dc-97b8-f83d0d0fe05e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CEB888',
        id: 'b15ea478-53be-41a8-aebe-32c7ac17a387',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A89968',
        id: 'f3924384-a44c-4db9-bd0b-0a6781370c41',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#94795D',
        id: '4a2e629c-b6a7-490a-8104-b7ebdd7a42d7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#816040',
        id: '33a38317-086d-4675-bd7b-e76e4ba260dd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDC4AA',
        id: '9af209ff-dee6-494f-9857-cb51b3eedc9d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D1C1A8',
        id: '4b20fa20-ffa9-48dd-9c11-596ffa326362',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5C2A5',
        id: 'baec9b01-c50b-4c36-9a3a-22a0c6d771a6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C3AD8B',
        id: '9802025e-983a-4928-9757-5634449253a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBA887',
        id: '869b361d-dd4d-4579-be95-72e6d756e5fe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B8A279',
        id: '1e32dc79-1bd4-4190-af66-4deadb4d74d4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6A284',
        id: '3547c693-9212-4cd9-ac40-a088b6a6cc4e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDCBA4',
        id: '75235b46-4674-47f5-95e9-3449ea11a14e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D3BC8D',
        id: '3df88ed9-08be-411b-b6df-4ac8db7635f2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6AA76',
        id: 'c3a24615-19f1-45ed-942e-f1fbf6368e5e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B9975B',
        id: '1bd4d8ac-fc99-4b7f-9298-19722b208e38',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8B5B29',
        id: '968ce7aa-250d-4c2d-9785-edafa6b793be',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#744F28',
        id: 'e4a72808-a078-4479-9c96-e039099b1bbf',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5C462B',
        id: 'bca13986-54a5-412a-ac1c-7ef873e5ce20',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BAA58D',
        id: '7c1c288a-d2e2-4dfc-955d-f10d9b865277',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BF9972',
        id: '68b9eec0-d855-4686-8176-3d3c3af7a7d1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD9677',
        id: 'de09c36f-64c1-47ff-9999-90cd1d96c8cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AA9071',
        id: '9a663757-0a74-4449-a749-a4689f47c090',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#947D6F',
        id: '08dcbe2b-455b-4e20-8a04-3b66a89c9215',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F5D58',
        id: 'c5cb729a-4838-47c1-bda1-fe78d0ce7548',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#322923',
        id: 'f5dbeaa9-ebd8-407c-beeb-747dd9201c6f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DAC287',
        id: '86477187-cdc9-48d8-bb83-aeacf524e105',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7926C',
        id: '495a628c-21dd-49ef-a5a5-bdc538a174f8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#986A4C',
        id: '2fb4777f-611e-4328-ae70-4b6741cdef62',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F583B',
        id: '638e2c0e-a07f-43d8-91be-d87ad3a1b007',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A7F62',
        id: '43561b08-89f5-4d9d-9142-766ee3fa5249',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A58877',
        id: 'aeda52cc-aeb9-4e70-8d6f-fde2d5602eff',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F4029',
        id: 'dc2d1d70-3f5b-45d0-81a9-207cd628f8e7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EFDBB2',
        id: 'c358d788-182b-4a7c-ba50-6a820cabb9f7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#FCD299',
        id: 'abb66f81-b2f3-4482-9bb3-b9586bd53542',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1B87F',
        id: '6b87c1d9-21db-4fe6-aee0-61422796a39a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6A461',
        id: 'aed9b9f0-b432-4295-9fe4-5e96c567bfce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6893F',
        id: 'f0c6fa79-183b-4ffa-a9b8-0a8b46a4f165',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B77729',
        id: 'a406d984-26fe-472a-bf70-21be652bbbfe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A6631B',
        id: 'b0bf2b4f-96b8-4a4e-b3f0-929a40ff3637',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#EDC8A3',
        id: '9da5fd05-014d-41b1-83e1-952508399a3b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E7B78A',
        id: 'a37a48b1-10b6-464c-936a-fa1fb93ed310',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DDA46F',
        id: '7b6487a4-b830-4bfc-b0c6-2101d377a94e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C88242',
        id: '0aa3dc2b-779e-4bd2-b7e0-16ffeb78ffd3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B36924',
        id: 'e45a8228-30e8-4b52-ba1d-1de5a7ea72ec',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#934D11',
        id: 'a75fe4b5-f495-46c5-b0eb-7f50d28cc768',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7D4016',
        id: 'fba3bbbb-29c3-47c5-a26c-82abca1ed235',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F3CFB3',
        id: '957c73a3-361e-4929-9344-3c7f1c8a0ddb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F1C6A6',
        id: 'f9d1b521-24ae-476c-b9d1-727ee45b5d3d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#F0BF9B',
        id: '6bea798b-fa40-4820-84ff-9b6c7820e03f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E59E6D',
        id: '97fa552e-0f8c-43ac-be29-645c5e907b9e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B86125',
        id: '92275c5a-5aaf-4d99-8348-a88e56e643ea',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A45A2A',
        id: '9a13e55a-1a3f-4445-b44d-0f78c81a5c3d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#693F23',
        id: '4c3dba52-f16b-42ac-9580-efaa98897248',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0C09F',
        id: 'db250837-a8ea-4e5e-a410-06a9e7e3df0a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9B48F',
        id: 'd712180c-2e34-4116-86a0-2aa641fac75a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDA177',
        id: '582a73bd-93e4-46f8-99a9-b058c002b3a7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B58150',
        id: 'd0521ad3-0ece-422b-b301-59fc365d082b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9E652E',
        id: '1cde63e6-d304-405b-bb53-ae30b432c898',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#774212',
        id: 'a8250885-18a5-45bd-ad10-2ba3974905a2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#623412',
        id: '2d5fc9bc-fa5d-4202-a75b-de49bfc4c46a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BD9A7A',
        id: 'ad770573-bd66-40fb-a6b2-835f21b13ead',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B4875E',
        id: '3206be42-9899-4b00-90b6-879f7e788a71',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A47449',
        id: '395a0edb-bda0-45d4-9a38-4f64b26e78bb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C5C30',
        id: '778bfe68-a474-4eca-ae25-98a40d7f9faa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#714623',
        id: '5a488ef3-9a95-464a-af69-1402d519a4d5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6F5138',
        id: 'd4d8ae1d-5ae9-4e98-8ca4-562d60af239d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E3524',
        id: '8b45ddf1-3404-40d4-b1ad-70b56b6fc24e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E7CEB5',
        id: '2965fd5b-065e-489b-bc9c-b36799e29add',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9C0A9',
        id: '7341aa8c-fcf7-4204-ac77-733eddcb64d9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDA786',
        id: '63f1a8f7-8e88-4b68-9e3a-610b5dda7444',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B79A81',
        id: '7abc1330-cab2-44d5-b8a8-0d240ff9e38d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C39367',
        id: '343cb5c8-8edf-45bb-bf3d-65d57ed00177',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC7644',
        id: 'fd2013f5-37e4-4363-a90f-7a712484bff5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#914910',
        id: '0fe12c1f-5ed8-46e7-8e61-1fbb8d03f79b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E0C6AD',
        id: 'c4a6f901-d2c2-4840-8f14-d83a73c4ade7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DCBFA6',
        id: 'e76fccbf-21c4-4325-a45c-76d761f4b896',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDA788',
        id: 'e9309eb6-4b2f-4c8f-9d0f-90a6bfdc48b5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BF9474',
        id: 'd7c9e3af-413b-487d-b32b-c3c18462c6ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AD7C59',
        id: '29273491-9a3c-4940-b1cd-9efadf7decb5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#946037',
        id: '11d5d8e5-6997-47e8-821b-a69181dc1cf2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4F2C1D',
        id: '3a7fad6b-15e3-4779-acf1-328987bd9e9f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDA890',
        id: '71967034-37fd-4f73-9099-cf69bb98ad55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D6E46',
        id: '737581d4-a633-4b4e-9c3a-f695ba1fc622',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A8714E',
        id: 'ceb40d82-b47b-44b1-858b-5a0e3d830027',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB6E49',
        id: '960af366-93ee-4068-b846-90ed740f2a4d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AC7052',
        id: '197a484c-fd3f-4a20-b357-79fa0a6d3867',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9B6448',
        id: '3beb489c-2a7e-479d-b83a-f78477345dfe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9A5F3A',
        id: 'da75d01b-d8f4-4b80-9604-de67250ddb03',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#E1B7A7',
        id: 'afee3c81-832c-4af7-9a14-ac78d73b181a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D5A286',
        id: '8d0c715f-9a13-4ed7-ad02-2b0d299e12c7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C58B68',
        id: '34206cdc-8ed5-40a6-b6d6-6f025b8b300f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#99552B',
        id: '31b9b77a-f089-41ed-b5d2-c93a6744fda7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#85431E',
        id: 'f0eea6d4-f398-4fd7-9c4f-bfae25b4f4a2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6D4F47',
        id: '275daefe-517a-4122-a0b6-00c94b6e2137',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5D4B3C',
        id: 'f8ec7d6c-dc63-4e45-aedf-a1913e80cfd5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7C4B7',
        id: 'e6c6577c-fc91-4204-a83e-56bb50063d90',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CDB5A7',
        id: '4ba168c7-17bf-46b3-be89-373b81a60645',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C0A392',
        id: 'ccacd8ff-dd6e-4ac7-a2b3-db494e832e12',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AE8A79',
        id: 'fdaa3297-998b-4569-9f60-2dfe56bcd315',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#956C58',
        id: 'd6e7e40a-c4ae-429c-9639-b325f16b9f66',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C4D3A',
        id: '8858904d-e958-42e7-858c-b29cb3e5f8f5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B3427',
        id: '64a2accf-76e8-4380-8c59-3c0c26693ae5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#DBC8B6',
        id: 'f6cfe93e-44c9-4d54-be0c-f8ec6d151769',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D3BBA8',
        id: 'b1817a25-1b33-4d42-a2c3-996c639d07e0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C6A992',
        id: '3826bd28-fa1e-4968-b71e-73b0c9571bd8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AA8066',
        id: 'bf621133-88f0-47f7-9abe-5744be79e3b9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#713F2A',
        id: 'eb1dd379-30ee-4d13-b099-4ff3339f0d08',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#623B2A',
        id: '972dd72e-6458-484d-ae54-8429937b3289',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E3629',
        id: 'd5075ec5-ae1a-4aab-bcf8-32329d4cbe3f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1B2A2',
        id: '68178bb5-605b-4a26-8251-599cd93fd645',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B3A08E',
        id: 'ea447cd5-9c8a-4678-bc7e-8c732ab2f659',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB9278',
        id: '370fc0a3-2055-4984-8508-bffcd9390d8e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#95866F',
        id: 'b0c3a894-7df2-4aa6-983b-85b8ca300af6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E7A6B',
        id: 'e4961a8f-acb5-4538-a6c2-6c35f1aaad27',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E6A5D',
        id: 'f0fbad89-d20f-4fa0-bbfb-f25e20d40ae2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E4544',
        id: '982f20e6-f40f-4763-bd7f-44da4d3d2f33',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D6D2C4',
        id: '7bf5e4c5-d476-4872-b2f2-453a11716205',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C5B9AC',
        id: '29a0c776-3208-416c-81b8-a464af1d610e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7A99A',
        id: 'ec66e52f-54fa-4d27-820f-7539c25bed2d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A39382',
        id: '902f04f9-507f-4935-822f-8bf2c3863c55',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7A6855',
        id: '3f06e96d-bfa4-41ac-b40d-9ced0f55813d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#63513D',
        id: '0543af82-68b1-4352-ab17-5642fcc0145d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#473729',
        id: '608702fc-3aa3-438f-a93a-bf42351b2910',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D1CCBD',
        id: 'a810354a-fe83-46ec-8bd2-2414698ca2dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7B09C',
        id: 'c629381d-26eb-487d-be27-00e8486047dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A69F88',
        id: 'dc8590e1-6d93-4a48-a6e2-7926aff6c1a4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7ACA2',
        id: 'bdf89ca8-cf2a-4c24-a3f7-c5b1fbb8d41c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#949A90',
        id: 'b8bdc766-d759-4140-961b-89c507e77b31',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8E9089',
        id: '3444c3d3-7d57-4296-946b-a5494c505efe',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4B4F54',
        id: '16bfae47-a6a1-432f-9393-fc0516932480',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0D3D4',
        id: '56cba665-38b5-48f0-a7a3-18bd82630663',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1C6C8',
        id: '72f958b5-aa32-4d6b-b366-e2c60c99304c',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2AAAD',
        id: 'a57a5cc1-5338-43ab-884d-306aadab67ba',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7C878E',
        id: '093bc96a-2885-4ec3-abd5-230d11a776a1',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5B6770',
        id: 'e73097f2-a464-4c95-868c-f527111d22b3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#333F48',
        id: '40ff9056-2021-4467-942f-47aac22aef48',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#1D252D',
        id: 'a40b63c4-f922-4286-b5c0-e59b0c4fdd96',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0B7B9',
        id: '184c631f-008c-41fc-9680-eb0aede3e5a9',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ABAEAB',
        id: 'e02240f0-c320-4a82-8c80-eebb93967075',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D9C9E',
        id: '4822b75c-6c61-41f8-b268-8661bc506d15',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8F8B90',
        id: '842003fd-aa80-462d-b131-669679c5c98f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E7E82',
        id: '64b31272-3c8c-4247-90af-65b9c871f874',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5A5357',
        id: 'bd08196d-97ef-4e06-a2fb-f5d3d1b89a32',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#252A36',
        id: 'c9b292d0-ca75-43b0-b72a-a5f923fead64',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C7C9C7',
        id: 'f5d41e8a-fd1f-4d83-b551-46b965a174f5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B2B4B2',
        id: '1e325b06-0756-4972-a3a5-90f02ac114e3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9EA2A2',
        id: '92dde911-d5b2-4bea-b703-364812ab9183',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#898D8D',
        id: 'f67bd320-aadc-421a-b336-1e5307fb768f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#707372',
        id: '01dfac94-71d9-4bf1-919c-4024a0a8b9e5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#545859',
        id: '107c2029-8d81-4554-b9da-045705685960',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#25282A',
        id: 'ea4eab5e-54e2-48cc-9da1-71084074fe04',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BEC6C4',
        id: 'a19c7a19-8064-4db6-8733-a9868117ec88',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A2ACAB',
        id: '8eab8657-586f-477b-8ae6-d92d27e9dfe5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#919D9D',
        id: 'cea1c1ad-c7a8-48aa-8f3a-8022be653030',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#717C7D',
        id: 'ed2707fb-0c4e-491a-9d00-9d49f363c9ce',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#505759',
        id: 'c5a80cbd-0561-4a55-b4d8-7a8453176973',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3F4443',
        id: '9b132c18-d40b-4e3a-a788-6396f07cf608',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#373A36',
        id: '6adcea79-995d-4ee6-a4e1-32664f95fda2',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BABBB1',
        id: 'f23d9d9d-7298-47ab-8d34-b764aad8687a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A8A99E',
        id: '88ae0493-65cb-4ab7-aa5b-cdb86a72cfa8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#919388',
        id: '48c03840-eee7-4f57-9bb4-49b7327074c0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7E7F74',
        id: 'b51baff6-63a7-4596-b8dd-3960040973d3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#65665C',
        id: '2cd1e898-6f7f-47a9-bf52-ad24bb4f60cc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#51534A',
        id: 'cec9da5c-8362-4faa-9b02-fce6cf41a556',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#212322',
        id: '12fac3b6-7e78-4b25-b971-58cde7f84f85',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B2B1B2',
        id: '35090b7a-5fe5-4a69-81ab-f8d62ebb0f90',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0AAAB',
        id: 'e844b688-45a6-4af2-be4f-995a38955b8d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A9A2A1',
        id: '196fb04d-7044-4dc8-a96c-79c6d940c54d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#736B74',
        id: 'db9066be-b1d2-4cbc-8b8e-a6a52a33d933',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#69616C',
        id: '19a800aa-b692-4941-8de6-39e4a37cf14e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5F555D',
        id: 'a1d5733b-acad-4920-8612-fda4be3f6881',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#454142',
        id: 'd7d66439-18ab-4788-8d73-8442d7ca8663',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CFCDC9',
        id: 'f93fc40d-851a-47b3-8bcc-22b1639bb729',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AEA8A5',
        id: 'e4bbbb2d-de3e-42c1-aaea-62fe6fdb09a3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C8985',
        id: 'eb60833a-1bdb-45e6-9c02-0613f2eff202',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#716E6A',
        id: '8049e479-f6c8-4f66-91a8-b1019eb013dc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6C6463',
        id: '37c4d6ec-cd98-4c40-bbce-52c884dec73f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E5047',
        id: 'bd777712-35fc-43e9-b26e-290ed699224e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4E4B48',
        id: '1078287c-1be4-4e01-a653-3bb3c2ba992f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4BFB6',
        id: '9d356d94-5dd1-4594-b66b-12230ba9acfa',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AFA9A0',
        id: '4e9d2ad7-54b5-4088-89ae-073139fea731',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#9D968D',
        id: '2ecc5f19-c17b-4669-bb06-92d3bc5318d8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C857B',
        id: 'd8665928-1508-4429-872b-6ff4453069c6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#776E64',
        id: '172de5ab-d55e-43f1-bb22-51d9640455af',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#696158',
        id: '14278109-0358-4528-beab-976cb582ce46',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#2D2926',
        id: '71f9eac6-252b-425d-940f-890d7b4f81a8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B7BCB4',
        id: 'dc031958-9ed2-49b8-af45-12fa24126821',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#999998',
        id: '6727bfe4-7087-4339-a75c-989fd6223770',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#928B8A',
        id: 'a69e9729-b064-4da0-99c5-60942f8cbbeb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#746E6E',
        id: '32ddc0ec-c901-43e0-a14a-6b6f0e06c510',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#716D6E',
        id: 'a5e26029-7bf9-4c77-9890-3fd4911508cb',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#645B64',
        id: 'd72b8a13-d6c6-4856-b8dc-e8dc839472db',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#575257',
        id: 'fb81d5e1-68ee-4c22-b245-4090c2289785',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4BCB7',
        id: '77641cba-1273-4648-ae90-dfe712ea7080',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B2A8A2',
        id: '3397bd34-776d-41b0-85b7-210a517665a7',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#978C87',
        id: '8b32edc3-259d-4415-8779-c07d242e395a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#857874',
        id: 'c0265f36-ed0b-4529-be09-3f8fd58061f8',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#746661',
        id: '6903a504-f358-4efc-99d8-113c1665deca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#5E514D',
        id: '8e6ec348-77d2-430c-88de-0065386209ca',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#382F2D',
        id: 'ee2ff747-6b88-413a-beb6-870958f991f3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0C4C5',
        id: 'f70b337f-e3ab-4eb2-b54b-22c940ec3293',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C1B2B6',
        id: 'bec5d5b2-48a3-477c-8c20-6d1c906bee92',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#AB989D',
        id: '26787ee0-16a4-4587-99d6-6e3244a7e441',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#7B6469',
        id: 'cdae2377-11cd-4c2e-bd4f-f661dbd450b6',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#584446',
        id: 'd0f16307-1da5-4e65-b83c-07c83ee08614',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#453536',
        id: 'c251607c-fe3c-44f0-ac17-07a6ac2fac99',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#382E2C',
        id: 'd6177c0c-8b95-4a2d-a13d-722e968e275a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C4B8BD',
        id: 'c06de527-144f-4267-83d3-50389d81c133',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B0A198',
        id: '75e1be48-823f-4047-b72a-70d5a9bf025b',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A1877F',
        id: '1be3fa65-7e88-4c00-a8b2-a57d7fef9bd0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#826B6A',
        id: 'bd083d51-d272-4d75-9535-b43b1c80b078',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#705854',
        id: 'f8d0b57f-c8eb-46ed-8a78-d8e1b65f6460',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#4C363A',
        id: '9a3053cf-ca90-4641-b79c-bcd98792165e',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E342F',
        id: '64ee0c32-293e-44b2-b577-7c4732e89987',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D7D2CB',
        id: 'c3e1f194-07b1-4353-9a1a-8c0874a8234a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#CBC4BC',
        id: '4bbb3ba1-aad6-4b42-9553-feac36f49275',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BFB8AF',
        id: '3b8b3162-28bd-4b92-a4a8-3ec684cef2f4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B6ADA5',
        id: '5cdc1013-dfe0-4a48-9d2c-bc95750c7ed0',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#ACA39A',
        id: '8cd5a098-8bb1-4eee-973c-8fc413539828',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A59C94',
        id: '3a66623f-ea4d-4a16-9de1-c6012e28c52a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#968C83',
        id: '396463c1-0f44-417a-9251-0efd91add4fd',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#8C8279',
        id: '59bb6a6e-78a8-4239-acad-ed0eda708bf3',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#83786F',
        id: '5701f968-6df6-4868-b8be-fffa5b207044',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#796E65',
        id: '7a750639-9bfc-4de3-b2c3-bc2ba6ff562a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#6E6259',
        id: 'b7ab5ffe-40d0-4131-8d0e-dfe6106e7581',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D9D9D6',
        id: 'a68714ef-dcb3-475f-8945-5b8915b19692',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#D0D0CE',
        id: 'f3a25596-79f7-4774-b82c-ad192e3beb6f',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#C8C9C7',
        id: 'cdd15f8d-621c-4202-bf95-0b56012b8250',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#BBBCBC',
        id: '6896940a-4b1b-4cbb-9b12-113b6208f24d',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#B1B3B3',
        id: '9944bbad-f6b0-41c7-ab49-87d323c389e5',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#A7A8A9',
        id: '76ce8ec7-39d0-4202-9f9a-a911e06a1c29',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#97999B',
        id: '7a662bf3-d622-40b7-971f-89223f0d9027',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#888B8D',
        id: 'e75a4738-fce4-448b-8d85-55abb23ce55a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#75787B',
        id: 'c4911a3f-8167-4517-9d64-6e8eac2dbcfc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#63666A',
        id: '3ee3e0ea-e78c-457e-9875-c8435aa9a8b4',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#53565A',
        id: '4079f449-2b1f-4544-8bd2-47668a85deec',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#332F21',
        id: '7d6054d7-c16e-48f1-b93c-7df37332b583',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#212721',
        id: '5c34ec92-bafb-4b41-9e31-c9de5e9a1482',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#31261D',
        id: '0eda8c7d-9414-4e7e-a5d4-4e8a9f565e90',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3E2B2E',
        id: '7e06d199-4ea2-48ac-b172-42cb8bece0bc',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#101820',
        id: '9ea7f81d-ac51-4d10-9871-78f86f09cf6a',
        name: '',
        rgb_code: ''
      }, {
        description: '',
        hex_code: '#3D3935',
        id: '1c170ac1-3ce9-4441-becd-c5475ed78783',
        name: '',
        rgb_code: ''
      }],
      hex_code: '#FFD700',
      id: '90148477-b01a-4ffa-98f0-1390fb175386',
      name: '',
      rgb_code: ''
    }],
    id: 'f1b60b28-a73c-4ccf-b65b-e76b428c4617',
    scheme: 'Default Colors'
  }]
};
var _default = solidColorsData;
exports.default = _default;
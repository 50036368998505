"use strict";

exports.__esModule = true;
exports.default = _default;

var _omit = _interopRequireDefault(require("lodash/omit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  return {
    fieldValues: {},
    errors: {}
  };
}
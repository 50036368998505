"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@pages/DesignEditor/actions");

var _actions2 = require("@App/actions");

var _omit = _interopRequireDefault(require("lodash/omit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleInputChange(key, value) {
    const _this$state$errors = this.state.errors,
          errors = _this$state$errors === void 0 ? {} : _this$state$errors;
    const new_errors = (0, _omit.default)(errors, key);
    this.setState(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      errors: new_errors,
      fieldValues: _objectSpread(_objectSpread({}, prevState.fieldValues), {}, {
        [key]: value
      })
    }));
  },

  handleSave() {
    const _this$props = this.props,
          dispatch = _this$props.dispatch,
          _this$props$dialog = _this$props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog;
    const _this$state$fieldValu = this.state.fieldValues,
          fieldValues = _this$state$fieldValu === void 0 ? {} : _this$state$fieldValu;

    const _ref = dialog.data || {},
          session_data = _ref.session_data,
          threed_data = _ref.threed_data,
          product_data = _ref.product_data,
          method = _ref.method,
          postDispatches = _ref.postDispatches,
          progressHandlerCallback = _ref.progressHandlerCallback,
          setShowRedirectDialog = _ref.setShowRedirectDialog;

    const payload = {
      session_data,
      threed_data,
      product_data: _objectSpread(_objectSpread({}, product_data), {}, {
        product_name: fieldValues.product_name,
        is_draft: false
      }),
      method,
      postDispatches,
      progressHandlerCallback,
      showQuickTip: this.props.dialog.data.showQuickTip,
      setShowRedirectDialog
    };

    if (this.isValid()) {
      dispatch((0, _actions.EditorSaveDesignChanges)(payload));
      dispatch((0, _actions2.HideDialog)());
    }
  },

  handleSaveAsCopy() {
    const _this$props2 = this.props,
          dispatch = _this$props2.dispatch,
          _this$props2$dialog = _this$props2.dialog,
          dialog = _this$props2$dialog === void 0 ? {} : _this$props2$dialog;
    const _this$state$fieldValu2 = this.state.fieldValues,
          fieldValues = _this$state$fieldValu2 === void 0 ? {} : _this$state$fieldValu2;

    const _ref2 = dialog.data || {},
          session_data = _ref2.session_data,
          threed_data = _ref2.threed_data,
          product_data = _ref2.product_data,
          method = _ref2.method,
          postDispatches = _ref2.postDispatches,
          progressHandlerCallback = _ref2.progressHandlerCallback,
          setShowRedirectDialog = _ref2.setShowRedirectDialog;

    const payload = {
      data: _objectSpread(_objectSpread(_objectSpread({}, (0, _omit.default)(threed_data, ['id', 'shopify_product_id'])), (0, _omit.default)(product_data, ['id', 'shopify_product_id'])), {}, {
        product_name: fieldValues.product_name,
        is_draft: false,
        metadata: {
          initiated_by: threed_data.artist_id
        },
        texture_status: 'pending',
        texture_id: '',
        likes: []
      }),
      postDispatches
    };

    if (this.isValid()) {
      dispatch((0, _actions.EditorSaveAsCopy)(payload));
    }
  },

  handleCancel() {
    this.handleHide();
    const dispatch = this.props.dispatch;
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES_DONE'
    });
  },

  handleKeyUp(evt) {
    if (evt.key === 'Enter') {
      this.EventHandlers.handleSave();
    }
  }

};
var _default = EventHandlers;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.REMOVE_FROM_SESSION_CART = exports.UPDATE_SESSION_CART = exports.ADD_TO_SESSION_CART_REQUESTED = exports.GET_SEARCH_RESULT = exports.UPLOAD_COVER_PHOTOS = exports.COVER_PHOTOS_UPLOADING = exports.TEMP_PROFILE_PICTURE_UPDATED = exports.TEMP_PROFILE_PICTURE_UPDATING = exports.UPLOAD_TEMP_PROFILE_PICTURE = exports.PROFILE_PICTURE_UPDATING = exports.UPLOAD_PROFILE_PICTURE = exports.UPDATE_SESSION_DATA = exports.PROCESSING_USER_DONE = exports.PROCESSING_USER = exports.UPDATING_USER = exports.CLEAR_USER = exports.SEND_INVITE_EMAIL = exports.SEND_RESET_PASSWORD_EMAIL = exports.UPDATE_USED_TOKEN = exports.REGISTER_USER = exports.VALIDATE_TOKEN = exports.CHANGE_USER_PASSWORD = exports.UPDATE_COMPANY_SESSION = exports.UPDATE_USER_SESSION = exports.UPDATE_USER_PREFERENCES = exports.LOGOUT_USER = exports.LOGIN_USER = exports.SET_ACTION_RESPONSE = exports.SET_FILTER_TAGS = exports.GET_SWITCHED_ACCOUNT = exports.GET_SWITCH_USER_LIST = exports.CLEAR_USER_SESSION = exports.GET_SESSION_COMPANY_DETAILS = exports.GET_LANGUAGES = exports.GET_USER_SESSION = void 0;
const GET_USER_SESSION = 'GET_USER_SESSION';
exports.GET_USER_SESSION = GET_USER_SESSION;
const GET_LANGUAGES = 'GET_LANGUAGES';
exports.GET_LANGUAGES = GET_LANGUAGES;
const GET_SESSION_COMPANY_DETAILS = 'GET_SESSION_COMPANY_DETAILS';
exports.GET_SESSION_COMPANY_DETAILS = GET_SESSION_COMPANY_DETAILS;
const CLEAR_USER_SESSION = 'CLEAR_USER_SESSION';
exports.CLEAR_USER_SESSION = CLEAR_USER_SESSION;
const GET_SWITCH_USER_LIST = 'GET_SWITCH_USER_LIST';
exports.GET_SWITCH_USER_LIST = GET_SWITCH_USER_LIST;
const GET_SWITCHED_ACCOUNT = 'GET_SWITCHED_ACCOUNT';
exports.GET_SWITCHED_ACCOUNT = GET_SWITCHED_ACCOUNT;
const SET_FILTER_TAGS = 'SET_FILTER_TAGS';
exports.SET_FILTER_TAGS = SET_FILTER_TAGS;
const SET_ACTION_RESPONSE = 'SET_ACTION_RESPONSE';
exports.SET_ACTION_RESPONSE = SET_ACTION_RESPONSE;
const LOGIN_USER = 'LOGIN_USER';
exports.LOGIN_USER = LOGIN_USER;
const LOGOUT_USER = 'LOGOUT_USER';
exports.LOGOUT_USER = LOGOUT_USER;
const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
exports.UPDATE_USER_PREFERENCES = UPDATE_USER_PREFERENCES;
const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';
exports.UPDATE_USER_SESSION = UPDATE_USER_SESSION;
const UPDATE_COMPANY_SESSION = 'UPDATE_COMPANY_SESSION';
exports.UPDATE_COMPANY_SESSION = UPDATE_COMPANY_SESSION;
const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
exports.CHANGE_USER_PASSWORD = CHANGE_USER_PASSWORD;
const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
exports.VALIDATE_TOKEN = VALIDATE_TOKEN;
const REGISTER_USER = 'REGISTER_USER';
exports.REGISTER_USER = REGISTER_USER;
const UPDATE_USED_TOKEN = 'UPDATE_USED_TOKEN';
exports.UPDATE_USED_TOKEN = UPDATE_USED_TOKEN;
const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL';
exports.SEND_RESET_PASSWORD_EMAIL = SEND_RESET_PASSWORD_EMAIL;
const SEND_INVITE_EMAIL = 'SEND_INVITE_EMAIL';
exports.SEND_INVITE_EMAIL = SEND_INVITE_EMAIL;
const CLEAR_USER = 'CLEAR_USER';
exports.CLEAR_USER = CLEAR_USER;
const UPDATING_USER = 'UPDATING_USER';
exports.UPDATING_USER = UPDATING_USER;
const PROCESSING_USER = 'PROCESSING_USER';
exports.PROCESSING_USER = PROCESSING_USER;
const PROCESSING_USER_DONE = 'PROCESSING_USER_DONE';
exports.PROCESSING_USER_DONE = PROCESSING_USER_DONE;
const UPDATE_SESSION_DATA = 'UPDATE_SESSION_DATA';
exports.UPDATE_SESSION_DATA = UPDATE_SESSION_DATA;
const UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE';
exports.UPLOAD_PROFILE_PICTURE = UPLOAD_PROFILE_PICTURE;
const PROFILE_PICTURE_UPDATING = 'PROFILE_PICTURE_UPDATING';
exports.PROFILE_PICTURE_UPDATING = PROFILE_PICTURE_UPDATING;
const UPLOAD_TEMP_PROFILE_PICTURE = 'UPLOAD_TEMP_PROFILE_PICTURE';
exports.UPLOAD_TEMP_PROFILE_PICTURE = UPLOAD_TEMP_PROFILE_PICTURE;
const TEMP_PROFILE_PICTURE_UPDATING = 'TEMP_PROFILE_PICTURE_UPDATING';
exports.TEMP_PROFILE_PICTURE_UPDATING = TEMP_PROFILE_PICTURE_UPDATING;
const TEMP_PROFILE_PICTURE_UPDATED = 'TEMP_PROFILE_PICTURE_UPDATED';
exports.TEMP_PROFILE_PICTURE_UPDATED = TEMP_PROFILE_PICTURE_UPDATED;
const COVER_PHOTOS_UPLOADING = 'COVER_PHOTOS_UPLOADING';
exports.COVER_PHOTOS_UPLOADING = COVER_PHOTOS_UPLOADING;
const UPLOAD_COVER_PHOTOS = 'UPLOAD_COVER_PHOTOS';
exports.UPLOAD_COVER_PHOTOS = UPLOAD_COVER_PHOTOS;
const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
exports.GET_SEARCH_RESULT = GET_SEARCH_RESULT;
const ADD_TO_SESSION_CART_REQUESTED = 'ADD_TO_SESSION_CART_REQUESTED';
exports.ADD_TO_SESSION_CART_REQUESTED = ADD_TO_SESSION_CART_REQUESTED;
const UPDATE_SESSION_CART = 'UPDATE_SESSION_CART';
exports.UPDATE_SESSION_CART = UPDATE_SESSION_CART;
const REMOVE_FROM_SESSION_CART = 'REMOVE_FROM_SESSION_CART';
exports.REMOVE_FROM_SESSION_CART = REMOVE_FROM_SESSION_CART;
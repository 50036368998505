"use strict";

exports.__esModule = true;
exports.default = void 0;

const convertToThumbURL = (path, {
  type = '',
  dimension = 400,
  width,
  height
} = {}) => {
  if (!path) return '';
  const newPath = 'file/webp?path=';

  if (path.includes('https://') || path.includes('http://')) {
    const awsURL = 'artist-library.s3.amazonaws.com';
    const cloudfrontURL = 'd39123yu1efled.cloudfront.net';

    if (path.includes(awsURL)) {
      return path.replace(awsURL, cloudfrontURL);
    }

    return path;
  }

  if (path.includes('file?path=')) {
    if (type === 'webp') {
      return path.replace('file?path=', newPath);
    }

    return path;
  }

  if (path.includes('imgs/app')) {
    return path;
  }

  if (type === 'webp') {
    return `${window.config.upload}/${newPath}${path}`;
  }

  if (type === 'thumb' && width && height) {
    return `${window.config.upload}/file/thumb?path=${path}&type=thumbnail&height=${width}&width=${height}`;
  }

  if (type === 'thumb') {
    return `${window.config.upload}/file/thumb?path=${path}&type=thumbnail&height=${dimension}&width=${dimension}`;
  }

  return `${window.config.upload}/file/thumb?path=${path}&type=thumbnail&height=${dimension}&width=${dimension}`;
};

var _default = convertToThumbURL;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _validator = _interopRequireDefault(require("validator"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function Validations() {
  const fieldValues = this.state.fieldValues;
  const errors = {};

  if (_validator.default.isEmpty(fieldValues.product_name.trim())) {
    errors.product_name = 'Product Name is required.';
  }

  const trimmed_errors = Object.entries(errors).reduce((acc, [key, value]) => {
    if (!!value) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        [key]: value
      });
    }

    return acc;
  }, {});
  return {
    errors: trimmed_errors,
    isValid: (0, _isEmpty.default)(trimmed_errors)
  };
}

var _default = Validations;
exports.default = _default;
"use strict";

module.exports = {
  dialogContext: {
    // ### GENERATE ASSIGN CONTEXTS ###
    backgroundDialog: require.context('./components/dialog/backgroundDialog', false, /\.js$/),
    elementDialog: require.context('./components/dialog/elementDialog', false, /\.js$/),
    publishDialog: require.context('./components/dialog/publishDialog', false, /\.js$/),
    confirmNavigateDialog: require.context('./components/dialog/confirmNavigateDialog', false, /\.js$/),
    saveProductDialog: require.context('./components/dialog/saveProductDialog', false, /\.js$/),
    confirmCancelSubmissionDialog: require.context('./components/dialog/confirmCancelSubmissionDialog', false, /\.js$/),
    confirmUnpublishDialog: require.context('./components/dialog/confirmUnpublishDialog', false, /\.js$/),
    confirmPublishDialog: require.context('./components/dialog/confirmPublishDialog', false, /\.js$/),
    addToCartDialog: require.context('./components/dialog/addToCartDialog', false, /\.js$/),
    tutorialsDialog: require.context('./components/dialog/tutorialsDialog', false, /\.js$/),
    latestStyleNotification: require.context('./components/dialog/latestStyleNotification', false, /\.js$/),
    emailVerificationRequiredDialog: require.context('./components/dialog/emailVerificationRequiredDialog', false, /\.js$/),
    safariWarningDialog: require.context('./components/dialog/safariWarningDialog', false, /\.js$/),
    multipleStorePublishFailed: require.context('./components/dialog/multipleStorePublishFailed', false, /\.js$/)
  }
};
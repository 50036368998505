"use strict";

exports.__esModule = true;
exports.default = void 0;

function Title() {
  return 'Are you sure you want to log out?';
}

var _default = Title;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _useUpload = _interopRequireDefault(require("@common/components/FineUploaderHooks/useUpload"));

var _react = require("react");

var _tools = require("@23point5/core/controls/utils/tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = args => {
  const _args$uploaderProps = args.uploaderProps,
        uploaderProps = _args$uploaderProps === void 0 ? {} : _args$uploaderProps,
        _args$callbacks = args.callbacks,
        callbacks = _args$callbacks === void 0 ? {} : _args$callbacks,
        _args$fileMd = args.fileMd5,
        fileMd5 = _args$fileMd === void 0 ? {} : _args$fileMd;
  const uploaderRef = (0, _react.useRef)(null);
  const md5Ref = (0, _react.useRef)(fileMd5);
  const uploaderHandlersAndState = (0, _useUpload.default)(_objectSpread(_objectSpread({}, uploaderProps), {}, {
    autoUpload: true,
    onFileUploaded: (...params) => {
      const id = params[0],
            file_name = params[1],
            response = params[2],
            uploader = params[3],
            handleRemoveFileRef = params[4];
      const _response$file_path = response.file_path,
            file_path = _response$file_path === void 0 ? '' : _response$file_path,
            _response$thumbnail_p = response.thumbnail_path,
            thumbnail_path = _response$thumbnail_p === void 0 ? '' : _response$thumbnail_p,
            _response$raw_file_pa = response.raw_file_path,
            raw_file_path = _response$raw_file_pa === void 0 ? '' : _response$raw_file_pa,
            fileID = response.id,
            _response$height = response.height,
            height = _response$height === void 0 ? 0 : _response$height,
            _response$width = response.width,
            width = _response$width === void 0 ? 0 : _response$width;
      const fileType = file_path.split('.').pop();
      const isOptimalResolution = Number(height) > 2160 && Number(width) > 3840;

      const shaped_file_data = _objectSpread({
        key: fileID,
        name: file_name,
        texture: file_path,
        originalTexture: file_path,
        thumb: thumbnail_path,
        raw: raw_file_path,
        type: fileType,
        is_uploaded: true,
        is_low_res: isOptimalResolution,
        width,
        height,
        file_hash: md5Ref.current.current
      }, 'adjusted_width' in response && 'adjusted_height' in response && {
        adjusted_width: response.adjusted_width,
        adjusted_height: response.adjusted_height
      });

      if (callbacks.handleOnUploaded) {
        callbacks.handleOnUploaded(shaped_file_data, id, handleRemoveFileRef);
      }

      (0, _tools.debouncer)(() => {
        handleRemoveFileRef(id);
      }, 200);
    },
    onAllFilesUploaded: () => true,
    onError: errorArgs => {
      if (callbacks.handleOnUploadError) {
        callbacks.handleOnUploadError(errorArgs);
      }
    }
  }));
  return [uploaderHandlersAndState, uploaderRef];
};

exports.default = _default;
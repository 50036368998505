"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _v = _interopRequireDefault(require("uuid/v4"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _tools = require("@23point5/core/controls/utils/tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const defaultAllCategory = {
  id: 'all',
  name: 'All',
  isSelected: true
};

var _default = props => {
  const currentTab = props.currentTab,
        propsCategories = props.categories,
        _props$onSearch = props.onSearch,
        onSearch = _props$onSearch === void 0 ? () => {} : _props$onSearch,
        _props$onReset = props.onReset,
        onReset = _props$onReset === void 0 ? () => {} : _props$onReset,
        _props$isMultiple = props.isMultiple,
        isMultiple = _props$isMultiple === void 0 ? true : _props$isMultiple,
        _props$searchTagsLmim = props.searchTagsLmimit,
        searchTagsLmimit = _props$searchTagsLmim === void 0 ? 0 : _props$searchTagsLmim,
        _props$isLoading = props.isLoading,
        isLoading = _props$isLoading === void 0 ? false : _props$isLoading,
        _props$isAutoSearch = props.isAutoSearch,
        isAutoSearch = _props$isAutoSearch === void 0 ? false : _props$isAutoSearch,
        _props$isSearchBarOnl = props.isSearchBarOnly,
        isSearchBarOnly = _props$isSearchBarOnl === void 0 ? false : _props$isSearchBarOnl,
        _props$isCategoriesDi = props.isCategoriesDisabled,
        isCategoriesDisabled = _props$isCategoriesDi === void 0 ? false : _props$isCategoriesDi,
        _props$debounceDurati = props.debounceDuration,
        debounceDuration = _props$debounceDurati === void 0 ? 300 : _props$debounceDurati,
        folderSearchValue = props.folderSearchValue;

  const _useState = (0, _react.useState)(''),
        inputValue = _useState[0],
        setInputValue = _useState[1];

  const _useState2 = (0, _react.useState)([]),
        searchTags = _useState2[0],
        setSearchTags = _useState2[1];

  const _useState3 = (0, _react.useState)([defaultAllCategory]),
        categories = _useState3[0],
        setCategories = _useState3[1];

  const _useState4 = (0, _react.useState)(false),
        isInputFocused = _useState4[0],
        setIsInputFocused = _useState4[1]; // ---------------------------------------------------------------------------
  // SIDE EFFECTS


  (0, _react.useEffect)(() => {
    setCategories([defaultAllCategory, ...propsCategories] || [defaultAllCategory]);
  }, [propsCategories]);

  const handleOnSearch = passedParams => {
    const params = _objectSpread({
      searchTags
    }, passedParams);

    (0, _tools.debouncer)(() => {
      onSearch(params);
    }, debounceDuration);
  };

  (0, _react.useEffect)(() => {
    if (currentTab) {
      setInputValue('');
    }
  }, [currentTab]);
  (0, _react.useEffect)(() => {
    if (isSearchBarOnly) {
      handleOnSearch({
        value: inputValue,
        searchTags,
        categories: categories.filter(e => e.id !== 'all' && e.isSelected)
      });
    }
  }, []);
  (0, _react.useEffect)(() => {
    if (!['stock_images_seamless', 'stock_images_single_part', 'dynamic_image_templates', 'dynamic_image', 'patterns'].includes(currentTab)) {
      setInputValue(folderSearchValue);
    }
  }, [folderSearchValue, currentTab]); // ---------------------------------------------------------------------------
  // HAMDLERS

  const handleOnInputFocusChange = value => {
    setIsInputFocused(value);
  };

  const handleInputChange = value => {
    if (value.length >= 30) return; // const regex = /^[a-zA-Z0-9 ]*$/gm
    // if (!value.match(regex)) {
    //   return
    // }

    setInputValue(value);
    handleOnSearch({
      value,
      categories: categories.filter(e => e.id !== 'all' && e.isSelected)
    });
  };

  const handleAddSearchTag = value => {
    if (!value) {
      return;
    }

    const existing = searchTags.find(tag => tag.text === value);
    if (existing) return;
    if (!!searchTagsLmimit && searchTags.length === searchTagsLmimit) return;
    const newTag = {
      id: (0, _v.default)(),
      text: value
    };
    const newTags = [...searchTags, newTag];
    setSearchTags(newTags);
    setInputValue('');
  };

  const handleRemoveSearchTag = value => {
    if (!value) {
      return;
    }

    setSearchTags(prev => prev.filter(tag => tag.id !== value.id));
  };

  const handleKeyDown = e => {
    if (e.keyCode === 9) {
      e.preventDefault();
    }

    if (e.keyCode === 9 && inputValue) {
      handleAddSearchTag(inputValue);
      return;
    } // if (e.keyCode === 13 && !inputValue && !!searchTags.length) {
    //   // handleSearch(tags.map(e => e.text))
    //   if (!isAutoSearch) {
    //     onSearch({
    //       value: inputValue,
    //       searchTags,
    //       categories: categories.filter(category => category.id !== 'all' && category.isSelected)
    //     })
    //   }
    // }


    if (e.keyCode === 13 && inputValue) {
      handleAddSearchTag(inputValue);
    }
  };

  const handleClearSearchTags = () => {
    setSearchTags([]);
  };

  const handleClearSelectedCategories = () => {
    setCategories(prev => prev.map(e => _objectSpread(_objectSpread({}, e), {}, {
      isSelected: e.id === 'all'
    })));
  };

  const selectAndDispatch = newCategories => handleOnSearch({
    value: inputValue,
    categories: newCategories.filter(e => e.id !== 'all')
  });

  const handleOnCategorySelect = (selected = {}) => {
    if (selected.id === 'all') {
      setCategories(prev => {
        const newCategories = prev.map(e => {
          if (e.id !== 'all') {
            return _objectSpread(_objectSpread({}, e), {}, {
              isSelected: false
            });
          }

          return _objectSpread(_objectSpread({}, e), {}, {
            isSelected: true
          });
        });
        selectAndDispatch(newCategories);
        return newCategories;
      });
    } else {
      setCategories(prev => {
        const newCategories = prev.map(e => {
          if (e.id === 'all' && selected.id !== 'all') {
            return _objectSpread(_objectSpread({}, e), {}, {
              isSelected: false
            });
          }

          if (e.id === selected.id) {
            return _objectSpread(_objectSpread({}, e), {}, {
              isSelected: !e.isSelected
            });
          }

          return e;
        }).filter(Boolean);
        selectAndDispatch(newCategories);
        return newCategories;
      });
    }
  };

  const handleResetButtonClick = () => {
    // if (isInputFocused) {
    //   setInputValue('')
    //   setSearchTags([])
    //   setCategories(prev => prev.map(e => ({ ...e, isSelected: e.id === 'all' })))
    //   setIsInputFocused(false)
    //   onReset()
    // } else {
    //   setIsInputFocused(true)
    // }
    setInputValue('');
    setSearchTags([]);
    setCategories(prev => prev.map(e => _objectSpread(_objectSpread({}, e), {}, {
      isSelected: e.id === 'all'
    })));
    setIsInputFocused(false);
    onReset();
  };

  return {
    values: {
      inputValue,
      searchTags,
      categories,
      isInputFocused
    },
    handlers: {
      handleKeyDown,
      handleInputChange,
      handleAddSearchTag,
      handleRemoveSearchTag,
      handleClearSearchTags,
      handleResetButtonClick,
      handleOnCategorySelect,
      handleOnInputFocusChange,
      handleClearSelectedCategories
    }
  };
};

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.GetNodeDetails = GetNodeDetails;
exports.GetMultipleNodeDetails = GetMultipleNodeDetails;
exports.GetPreviewDetails = GetPreviewDetails;
exports.GetNodeList = GetNodeList;
exports.GetNodeFilter = GetNodeFilter;
exports.CreateNode = CreateNode;
exports.UpdateNode = UpdateNode;
exports.DeleteNode = DeleteNode;
exports.ExportCSVNode = ExportCSVNode;
exports.UploadImage = UploadImage;
exports.RemoveImage = RemoveImage;
exports.ReOrderGrid = ReOrderGrid;
exports.ClearNode = ClearNode;

var _types = require("../types");

function GetNodeDetails(id) {
  return {
    type: `${_types.GET_NODE_DETAILS}${_types.REQUESTED}`,
    payload: id
  };
}

function GetMultipleNodeDetails(id) {
  return {
    type: `${_types.GET_MULTIPLE_NODE_DETAILS}${_types.REQUESTED}`,
    payload: id
  };
}

function GetPreviewDetails(id) {
  return {
    type: `${_types.GET_PREVIEW_DETAILS}${_types.REQUESTED}`,
    payload: id
  };
}

function GetNodeList(data) {
  return {
    type: _types.GET_NODE_GRID + _types.REQUESTED,
    payload: data
  };
}

function GetNodeFilter(data) {
  return {
    type: `${_types.GET_NODE_FILTER}${_types.REQUESTED}`,
    payload: data
  };
}

function CreateNode(data) {
  return {
    type: `${_types.CREATE_NODE}${_types.REQUESTED}`,
    payload: data
  };
}

function UpdateNode(data) {
  return {
    type: `${_types.UPDATE_NODE}${_types.REQUESTED}`,
    payload: data
  };
}

function DeleteNode(id) {
  return {
    type: `${_types.DELETE_NODE}${_types.REQUESTED}`,
    payload: id
  };
}

function ExportCSVNode(data) {
  return {
    type: `${_types.EXPORT_CSV_NODE}${_types.REQUESTED}`,
    payload: data
  };
}

function UploadImage(data) {
  return {
    type: `${_types.UPLOAD_IMAGE}${_types.REQUESTED}`,
    payload: data
  };
}

function RemoveImage(data) {
  return {
    type: `${_types.REMOVE_IMAGE}${_types.REQUESTED}`,
    payload: data
  };
}

function ReOrderGrid(data) {
  return {
    type: `${_types.REORDER_GRID}${_types.REQUESTED}`,
    payload: data
  };
}

function ClearNode() {
  return {
    type: _types.CLEAR_NODE
  };
}
"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  return {
    dialogClassName: 'dnaDialog__container',
    className: `dnaDialog mainPage size-md dnaDialog--globalCss designEditorBackgroundUpload`
  };
}
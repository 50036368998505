"use strict";

exports.__esModule = true;
exports.default = void 0;

function Title() {
  return 'Element Selection';
}

var _default = Title;
exports.default = _default;
"use strict";

var _effects = require("redux-saga/effects");

var _isEqual = _interopRequireDefault(require("lodash/isEqual"));

var _validator = _interopRequireDefault(require("validator"));

var _queryString = _interopRequireDefault(require("query-string"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _toUpper = _interopRequireDefault(require("lodash/toUpper"));

var _startCase = _interopRequireDefault(require("lodash/startCase"));

var _times = _interopRequireDefault(require("lodash/times"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _fill = _interopRequireDefault(require("lodash/fill"));

var _get = _interopRequireDefault(require("lodash/get"));

var _set = _interopRequireDefault(require("lodash/set"));

var _last = _interopRequireDefault(require("lodash/last"));

var _template = _interopRequireDefault(require("lodash/template"));

var _pullAll = _interopRequireDefault(require("lodash/pullAll"));

var _moment = _interopRequireDefault(require("moment"));

var _pluralize = _interopRequireDefault(require("pluralize"));

var _history = _interopRequireDefault(require("@23point5/core/controls/history"));

var _tools = require("@23point5/core/controls/utils/tools");

var _artistPlaceholders = _interopRequireDefault(require("@common/statics/artistPlaceholders"));

var _constants = _interopRequireDefault(require("@App/constants"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

module.exports = {
  getState(state) {
    return (0, _get.default)(state, this);
  },

  getEntityReducer(uniqueEntity) {
    return _constants.default[uniqueEntity].reducer || (0, _toUpper.default)(uniqueEntity);
  },

  getEntitySocket(uniqueEntity) {
    return _constants.default[uniqueEntity].socket || (0, _toUpper.default)(uniqueEntity);
  },

  getEntityEndpoint(uniqueEntity) {
    return _constants.default[uniqueEntity].endpoint || uniqueEntity;
  },

  getEntityLabel(uniqueEntity) {
    return _constants.default[uniqueEntity].label || uniqueEntity;
  },

  getEntityRoute(uniqueEntity) {
    // return CONSTANTS[uniqueEntity].route || uniqueEntity
    return _constants.default[uniqueEntity] && _constants.default[uniqueEntity].route ? _constants.default[uniqueEntity].route : uniqueEntity;
  },

  getEntityAction(nodeAction, uniqueEntity) {
    return nodeAction.replace('NODE', module.exports.getEntityReducer(uniqueEntity));
  },

  getUploadEntity(uniqueEntity) {
    return _constants.default[uniqueEntity].upload || _constants.default[uniqueEntity].endpoint || uniqueEntity;
  },

  getEntityMainField(uniqueEntity, data = {}) {
    const mainField = _constants.default[uniqueEntity].main_field;

    if (mainField) {
      if (mainField.includes('template:')) {
        try {
          const fieldTemplate = (0, _template.default)(mainField.replace('template:', ''));
          return fieldTemplate(data).trim();
        } catch (e) {
          return '';
        }
      }

      return data[mainField];
    }

    return '';
  },

  getLocationHash() {
    const _getHistory = (0, _history.default)(),
          location = _getHistory.location;

    return location && location.hash ? location.hash.replace('#', '') : '';
  },

  getEntityFromRoute(route) {
    const uniqueEntity = Object.keys(_constants.default).find(key => _constants.default[key].route === route);
    return uniqueEntity || route;
  },

  getDeleteUrl(payload) {
    const id = payload.id,
          ids = payload.ids,
          _payload$method = payload.method,
          method = _payload$method === void 0 ? 'put' : _payload$method,
          uniqueEntity = payload.uniqueEntity,
          setUrl = payload.setUrl;
    const endpoint = module.exports.getEntityEndpoint(uniqueEntity); // handles custom url: returned value should be string with "/" as first character

    if (setUrl && typeof setUrl === 'function') {
      const url = setUrl(_objectSpread(_objectSpread({}, payload), {}, {
        endpoint
      }));
      const pre = url[0];
      return pre === '/' ? url : `/${url}`;
    } // handles (put or delete method) & (single or multiple records)


    const isBatch = Array.isArray(ids) && ids.length > 1;
    const idParams = method.toLowerCase() === 'delete' ? `/${isBatch ? ids.join(',') : id}` : '';
    const params = `${idParams}${isBatch ? '/batch' : ''}`;
    return `/${endpoint}${params}`;
  },

  pushHistory() {
    (0, _history.default)().push(this);
  },

  setMetadata(payload, initiated_by) {
    return _objectSpread(_objectSpread({}, payload), {}, {
      metadata: {
        initiated_by
      }
    });
  },

  setFilter(filter) {
    if (!filter.map(e => e.key).includes('status')) {
      return [...filter, {
        key: 'status',
        value: ['Active']
      }];
    }

    return filter;
  },

  setStatus(payload) {
    const hasStatus = Object.keys(payload).some(key => key.includes('status'));
    return hasStatus ? payload : _objectSpread(_objectSpread({}, payload), {}, {
      [`status:ne`]: 'Deleted'
    });
  },

  setButtonLoading(action, updating, reference) {
    const isSame = Array.isArray(reference) ? reference.includes(action) : reference === action;
    return isSame && updating;
  },

  getLocale() {
    return _jsCookie.default.get('locale') || 'en';
  },

  checkForNestedRoutes({
    uniqueEntity,
    match,
    location
  }) {
    const nestedRoutes = location.pathname.replace(match.url, '').split('/').filter(Boolean);
    const baseRoute = match.params.id ? [module.exports.getEntityFromRoute(uniqueEntity), match.params.id] : [];

    const _reduce = [...baseRoute, ...nestedRoutes].reduce(([accLeft, accRight], curr, index) => {
      if (index % 2 === 0) {
        return [curr, accRight];
      }

      return [accLeft, _objectSpread(_objectSpread({}, accRight), {}, {
        [module.exports.getEntityFromRoute(accLeft)]: curr
      })];
    }, [undefined, {}]),
          nestedRouteParams = _reduce[1];

    return [Boolean(Object.keys(nestedRouteParams).length), nestedRouteParams];
  },

  takeLatestPerEntity(patternOrChannel, worker, entitySelector, ...args) {
    return (0, _effects.fork)(function* runner() {
      const tasks = {};

      while (true) {
        const action = yield (0, _effects.take)(patternOrChannel);
        const key = yield (0, _effects.call)(entitySelector, action);

        if (tasks[key]) {
          yield (0, _effects.cancel)(tasks[key]);
        }

        tasks[key] = yield (0, _effects.fork)(worker, ...args, action);
      }
    });
  },

  // --- Unofficial Flat Assign Utils
  getAssignFlatCollection(entity) {
    return (0, _pluralize.default)(module.exports.getEntityLabel(entity));
  },

  getAssignToastHeader(str, {
    entity,
    isMultiple
  }) {
    const label = module.exports.getEntityLabel(entity);
    return `${str[0]}${isMultiple ? (0, _pluralize.default)(label) : label}`;
  },

  getAssignToastPostFix(str, {
    entity,
    action,
    isUnassign
  }) {
    const preposition = isUnassign ? 'from' : 'to';
    return `${str[0]}${action}ed_${preposition}_${entity}`;
  },

  getAssignType(nodeEntity, entity, assign) {
    const _ref = assign ? ['ADD', 'TO'] : ['REMOVE', 'FROM'],
          action = _ref[0],
          prepos = _ref[1];

    const node = module.exports.getEntityReducer(nodeEntity);
    return (0, _toUpper.default)(`${action}_${node}_${prepos}_${entity}`);
  },

  insertToFilter(filter, toInsert = [], override = false) {
    const keys = filter.map(e => e.key);
    return toInsert.reduce((acc, curr) => {
      // if (!acc.map(e => e.key).includes(curr.key)) {
      if (override || !keys.includes(curr.key)) {
        return [...acc, curr];
      }

      return acc;
    }, filter);
  },

  isEqualArray(arr1, arr2) {
    // accepts only plain arrays, like: [string] or [number]
    return (0, _isEqual.default)(arr1.sort(), arr2.sort());
  },

  resetErrorObject(error, field, listID) {
    if (listID && error[field] && error[field].length > 1) {
      const errors = error[field].filter(e => e.id !== listID);
      return _objectSpread(_objectSpread({}, error), {}, {
        [field]: errors
      });
    }

    return (0, _omit.default)(error, [field]);
  },

  getWeekday(day, formatter) {
    const result = (0, _moment.default)().day(day);
    return formatter ? formatter(result) : Number(result.format('d'));
  },

  getWeekdays(starting = 'Sunday', format, ordered) {
    const offset = module.exports.getWeekday(starting);
    return (0, _times.default)(7, ind => {
      const day = ordered ? ind + offset : ind;
      return (0, _moment.default)().day(day).format(format);
    });
  },

  injectTranslationProperty(value, translateFn, prefix) {
    return {
      label: translateFn(`${prefix || 'txt_'}${value}`),
      value
    };
  },

  injectOptions(_ref2) {
    let values = _ref2.values,
        labelFn = _ref2.labelFn,
        disabledFn = _ref2.disabledFn,
        other = _objectWithoutPropertiesLoose(_ref2, ["values", "labelFn", "disabledFn"]);

    return values.map(value => _objectSpread(_objectSpread({}, other), {}, {
      key: value,
      label: labelFn ? labelFn(value) : value,
      disabled: disabledFn ? disabledFn(value) : false,
      value
    }));
  },

  buildOptions(_ref3) {
    let _ref3$values = _ref3.values,
        values = _ref3$values === void 0 ? [] : _ref3$values,
        defaultFormat = _ref3.defaultFormat,
        rest = _objectWithoutPropertiesLoose(_ref3, ["values", "defaultFormat"]);

    const _ref4 = rest.keys || {},
          _ref4$labelKey = _ref4.labelKey,
          labelKey = _ref4$labelKey === void 0 ? 'label' : _ref4$labelKey,
          valueKey = _ref4.valueKey;

    const labelFn = val => {
      if (defaultFormat) {
        return defaultFormat(val);
      }

      if (rest.getTranslation) {
        const getTranslation = rest.getTranslation,
              _rest$translationPref = rest.translationPref,
              translationPref = _rest$translationPref === void 0 ? 'txt_' : _rest$translationPref;
        return getTranslation(translationPref + val.toLowerCase());
      }

      return val;
    };

    return values.reduce((acc, val) => {
      if (typeof val === 'string') {
        return [...acc, {
          value: val,
          label: labelFn(val)
        }];
      }

      if (typeof val === 'object') {
        const update = {
          value: valueKey ? val[valueKey] : val
        };

        if (val.labelOnly) {
          update.label = val[labelKey];
        } else {
          update.label = labelFn(labelKey ? val[labelKey] : val);
        }

        return [...acc, update];
      }

      return acc;
    }, []);
  },

  formatListOptions(list = [], valueKey = 'id', labelKey, pickKeys = []) {
    return list.map(e => _objectSpread({
      value: e[valueKey],
      label: labelKey ? e[labelKey] : (0, _tools.getDisplayName)(e)
    }, (0, _pick.default)(e, pickKeys)));
  },

  extractContactDetails(list = [], inner, formatter) {
    return list.map(({
      type,
      [inner]: field
    }) => ({
      value: field,
      label: `${type ? `${type}: ` : ''}${formatter ? formatter(field) : field}`
    }));
  },

  cleanListData(fieldValues, fields) {
    return fields.reduce((acc, {
      field,
      innerField,
      extractPrimary
    }) => {
      const fieldArray = (fieldValues[field] || []).filter(e => e[innerField] !== '');

      const update = _objectSpread(_objectSpread({}, acc), {}, {
        [field]: fieldArray
      });

      if (extractPrimary) {
        update[innerField] = fieldArray.length ? fieldArray[0][innerField] : '';
      }

      return update;
    }, _objectSpread({}, fieldValues));
  },

  buildParams(item, keys) {
    switch (typeof item) {
      case 'string':
        return {
          [keys[0]]: item
        };

      case 'object':
        return Array.isArray(item) ? keys.reduce((acc, curr, ind) => _objectSpread(_objectSpread({}, acc), {}, {
          [curr]: item[ind]
        }), {}) : item;

      default:
        return item;
    }
  },

  regularValidation(value, getTranslation, fields, error = {}) {
    const errors = error;
    const arrParamKeys = ['field', 'label'];
    fields.forEach(curr => {
      const _module$exports$build = module.exports.buildParams(curr, arrParamKeys),
            field = _module$exports$build.field,
            label = _module$exports$build.label,
            custom = _module$exports$build.custom,
            _module$exports$build2 = _module$exports$build.emptyCheck,
            emptyCheck = _module$exports$build2 === void 0 ? true : _module$exports$build2;

      const prefix = label || field;
      const toCheck = (0, _get.default)(value, field);

      if (emptyCheck && (!toCheck || _validator.default.isEmpty(toCheck))) {
        (0, _set.default)(errors, field, `${label || field} is required.`);
      } else if (custom) {
        const invalid = custom(toCheck, prefix, value);

        if (invalid) {
          (0, _set.default)(errors, field, invalid);
        }
      }
    });
    return errors;
  },

  listValidation(fieldValues, getTranslation, fields) {
    const errors = {};

    const setError = (field, fieldError) => {
      errors[field] = errors[field] ? errors[field].concat(fieldError) : [].concat(fieldError);
    };

    fields.forEach(fieldItem => {
      const field = fieldItem.field,
            innerField = fieldItem.innerField,
            validator = fieldItem.validator;
      (fieldValues[field] || []).forEach(fieldValueItem => {
        const id = fieldValueItem.id;
        const error = validator(fieldValueItem);

        if (error) {
          const fieldValueItemError = {
            id,
            [innerField]: getTranslation(error)
          };
          setError(field, fieldValueItemError);
        } // check for duplicates


        if (!error) {
          const duplicates = (fieldValues[field] || []).filter(item => item.id !== fieldValueItem.id && item[innerField] === fieldValueItem[innerField]);

          if (duplicates.length) {
            const lastItem = (0, _last.default)(duplicates);
            const fieldValueItemError = {
              id: lastItem.id,
              [innerField]: getTranslation('validation_duplicate_item')
            };
            setError(field, fieldValueItemError);
          }
        }
      });
    });
    return errors;
  },

  getHostOrigin() {
    return window.location.origin;
  },

  updateListItem(list, comparatorFn, updaterFn) {
    return list.map(item => {
      if (comparatorFn(item)) {
        item = _objectSpread(_objectSpread({}, item), updaterFn(item));
      }

      return item;
    });
  },

  toJSDate(date, format = 'MMMM DD Y') {
    return (0, _moment.default)(date, format).toDate();
  },

  toQueryString(queryObject) {
    return _queryString.default.stringify(queryObject);
  },

  contains(object, keys) {
    return Object.entries(object).reduce((acc, [oKey, oValue]) => keys.some(filter => oKey.includes(filter)) ? _objectSpread(_objectSpread({}, acc), {}, {
      [oKey]: oValue
    }) : acc, {});
  },

  getEndOfPeriod(date, period = 'day') {
    const result = (0, _moment.default)(date).endOf(period);
    return (0, _moment.default)(result).isValid() ? result : (0, _moment.default)();
  },

  getDay(date = new Date(), format = 'DD') {
    return (0, _moment.default)(date).startOf('day').format(format);
  },

  getMonth(date = new Date(), format = 'MMMM') {
    return (0, _moment.default)(date).startOf('month').format(format);
  },

  getYear(date = new Date(), format = 'Y') {
    return (0, _moment.default)(date).startOf('year').format(format);
  },

  getMonths(formatterFn) {
    return _moment.default.months().map(formatterFn || (e => ({
      label: e,
      value: e
    })));
  },

  getYears(formatterFn, pastCount = 20, futureCount = 5) {
    const presentYear = (0, _moment.default)().year();
    const totalYears = pastCount + futureCount + 1;
    const startingYear = presentYear - pastCount;
    return (0, _fill.default)(Array(totalYears), startingYear).map((e, i) => (e + i).toString()).map(formatterFn || (e => ({
      label: e,
      value: e
    })));
  },

  getListItemField(list, compareKey, compareValue, fieldValue) {
    return (0, _get.default)(list.find(e => (e[compareKey] || '').toLowerCase() === compareValue), fieldValue) || '';
  },

  mergeDateTime(fieldValues, fields) {
    return fields.reduce((acc, curr) => {
      const _curr$dateField = curr.dateField,
            dateField = _curr$dateField === void 0 ? 'date' : _curr$dateField,
            _curr$timeField = curr.timeField,
            timeField = _curr$timeField === void 0 ? 'time' : _curr$timeField,
            preserveTime = curr.preserveTime,
            outputField = curr.outputField;
      const timeMoment = (0, _moment.default)(acc[timeField]);
      const dateMoment = (0, _moment.default)(acc[dateField]).set({
        hour: timeMoment.get('h'),
        minute: timeMoment.get('m')
      }).toISOString();

      const update = _objectSpread(_objectSpread({}, !preserveTime ? (0, _omit.default)(acc, timeField) : acc), {}, {
        [outputField || dateField]: dateMoment
      });

      return update;
    }, _objectSpread({}, fieldValues));
  },

  formatCurrency(value, symbol = 'USD') {
    const numberFormat = {
      locale: 'en-US',
      options: {
        style: 'currency',
        currency: symbol,
        currencyDisplay: 'code'
      }
    };
    return new Intl.NumberFormat(numberFormat.locale, numberFormat.options).format(Number(value));
  },

  copyObjectValues(destination, source) {
    Object.keys(destination).forEach(key => {
      if (source && source[key] != null) {
        destination[key] = source[key];
      }
    });
    return destination;
  },

  textInputFormatter(value, format) {
    if (format === 'integer') {
      return /^-?\d*$/.test(value);
    }

    if (format === 'float') {
      return /^-?\d*[.,]?\d*$/.test(value);
    }

    if (format === 'alpabetsOnly') {
      // A-Z only
      return /^[a-z]*$/i.test(value);
    }

    return false;
  },

  urltoFile(url, filename, mimeType) {
    return _asyncToGenerator(function* () {
      const res = yield fetch(url);
      const buf = yield res.arrayBuffer();
      return new File([buf], filename, {
        type: mimeType
      });
    })();
  },

  capitalizeFirstLetter(data = '') {
    const str = data.replace(/[_-]+/g, ' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  capitalizeFirstLetterOfSentence(sentence) {
    if (typeof sentence !== 'string' || sentence.length === 0) {
      return sentence; // Return as it is if it's not a string or an empty string
    }

    return sentence.charAt(0).toUpperCase() + (sentence.slice(1) || '').toLowerCase();
  },

  formatPersonName(firstname = '', lastname = '') {
    // return startCase(`${firstname} ${lastname}`).trim() || '';
    const capitalizeFirstLetter = (data = '') => {
      const str = data.replace(/[_-]+/g, ' ');
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const first_name = firstname && firstname.split(' ').length ? firstname.split(' ').map(e => capitalizeFirstLetter(e)).join(' ') : capitalizeFirstLetter(firstname);
    const last_name = lastname && lastname.split(' ').length ? lastname.split(' ').map(e => capitalizeFirstLetter(e)).join(' ') : capitalizeFirstLetter(lastname);
    return `${first_name} ${last_name}`.trim() || '';
  },

  isValidUrl(toCheck, prefix, value) {
    if (!!toCheck && !_validator.default.isURL(toCheck)) {
      return `${prefix} is not a valid URL`;
    }

    return null;
  },

  generateAddressString(address_object = {}) {
    const _address_object$unit_ = address_object.unit_number,
          unit_number = _address_object$unit_ === void 0 ? '' : _address_object$unit_,
          _address_object$stree = address_object.street,
          street = _address_object$stree === void 0 ? '' : _address_object$stree,
          _address_object$city = address_object.city,
          city = _address_object$city === void 0 ? '' : _address_object$city,
          _address_object$state = address_object.state,
          state = _address_object$state === void 0 ? '' : _address_object$state,
          _address_object$count = address_object.country,
          country = _address_object$count === void 0 ? '' : _address_object$count,
          _address_object$zip_c = address_object.zip_code,
          zip_code = _address_object$zip_c === void 0 ? '' : _address_object$zip_c;
    return `${unit_number} ${street} ${city} ${state} ${country} ${zip_code}`;
  },

  isValueEmpty(value) {
    return value === undefined || value === null || typeof value === 'object' && Object.keys(value).length === 0 || typeof value === 'string' && value.trim().length === 0;
  },

  isFilesAllowed(files = [], allowedTypes = []) {
    const file_types = files.map(file => file.name.split('.').pop().toLowerCase());
    const valid_types = file_types.map(type => allowedTypes.find(e => e === type)).filter(Boolean);
    return valid_types.length === files.length;
  },

  formatPrice(value, prevValue, noDecimal = false) {
    if (!value) {
      return '';
    }

    let newValue = value;
    const newValueSplit = newValue.toString().split('.');
    const prevValueSplit = prevValue.toString().split('.');

    if (newValueSplit.length === 2 && newValueSplit[0] === '') {
      newValue = `0.${newValueSplit[1]}`;
    }

    if (newValueSplit[0].length > 10) {
      newValue = `${prevValueSplit[0]}${newValueSplit.length === 2 ? `.${newValueSplit[1]}` : ''}`;
    }

    if (newValueSplit.length === 2 && newValueSplit[1].length > 2) {
      newValue = `${newValueSplit[0]}.${prevValueSplit[1]}`;
    }

    if (newValueSplit.length > 2) {
      newValue = `${prevValueSplit[0]}.${prevValueSplit[1]}`;
    }

    if (noDecimal) {
      const noDecimalValueSplit = newValue.toString().split('.');
      newValue = noDecimalValueSplit[0];
    }

    return newValue.replace(/(\.\d\d)\d+|([\d.]*)[^\d.]/, '$1$2');
  },

  populateBackgrounds() {
    return [{
      key: 'white',
      name: 'white',
      thumb: `/imgs/app/temporary-image/white.png`,
      texture: `/imgs/app/temporary-image/white.png`
    }];
  },

  populateStockElementPhotos() {
    return [{
      key: 'stock1',
      name: 'stock1',
      texture: 'imgs/app/fulldialog/stock-placeholder-14.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-14.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-14.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-14.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock2',
      name: 'stock2',
      texture: 'imgs/app/fulldialog/stock-placeholder-13.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-13.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-13.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-13.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock3',
      name: 'stock3',
      texture: 'imgs/app/fulldialog/stock-placeholder-16.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-16.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-16.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-16.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock4',
      name: 'stock4',
      texture: 'imgs/app/fulldialog/stock-placeholder-5.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-5.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-5.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-5.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock5',
      name: 'stock5',
      texture: 'imgs/app/fulldialog/stock-placeholder-12.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-12.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-12.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-12.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock6',
      name: 'stock6',
      texture: 'imgs/app/fulldialog/stock-placeholder-2.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-2.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-2.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-2.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock7',
      name: 'stock7',
      texture: 'imgs/app/fulldialog/stock-placeholder-1.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-1.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-1.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-1.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock8',
      name: 'stock8',
      texture: 'imgs/app/fulldialog/stock-placeholder-15.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-15.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-15.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-15.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock9',
      name: 'stock9',
      texture: 'imgs/app/fulldialog/stock-placeholder-11.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-11.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-11.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-11.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock10',
      name: 'stock10',
      texture: 'imgs/app/fulldialog/stock-placeholder-9.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-9.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-9.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-9.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock11',
      name: 'stock11',
      texture: 'imgs/app/fulldialog/stock-placeholder-10.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-10.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-10.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-10.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock12',
      name: 'stock12',
      texture: 'imgs/app/fulldialog/stock-placeholder-8.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-8.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-8.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-8.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock13',
      name: 'stock13',
      texture: 'imgs/app/fulldialog/stock-placeholder-7.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-7.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-7.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-7.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock14',
      name: 'stock14',
      texture: 'imgs/app/fulldialog/stock-placeholder-4.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-4.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-4.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-4.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock15',
      name: 'stock15',
      texture: 'imgs/app/fulldialog/stock-placeholder-3.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-3.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-3.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-3.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }, {
      key: 'stock16',
      name: 'stock16',
      texture: 'imgs/app/fulldialog/stock-placeholder-6.jpg',
      originalTexture: 'imgs/app/fulldialog/stock-placeholder-6.jpg',
      thumb: 'imgs/app/fulldialog/stock-placeholder-6.jpg',
      raw: 'imgs/app/fulldialog/stock-placeholder-6.jpg',
      type: 'jpg',
      is_uploaded: false,
      default_scale: '5'
    }];
  },

  CapitalizeEveryFirstLetter(string = '') {
    // const capitalizeFirstLetter = (data = '') => {
    //   const str = data.replace(/[-]+/g, ' ');
    //   return str.charAt(0).toUpperCase() + str.slice(1);
    // }
    // const splitted = string.split(/\s+/);
    // const result = splitted.reduce(
    //   (acc, cur) => `${acc} ${capitalizeFirstLetter(cur)}`,
    //   ''
    // );
    // return result.trim();
    const transformedString = string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return transformedString || '';
  },

  TruncateString(str = '', num = 0) {
    if (str.length > num) {
      const subStr = str.substring(0, num);
      return `${subStr}...`;
    }

    return str;
  },

  padLeadingZeros(num, size) {
    let s = `${num}`;

    while (s.length < size) s = `0${s}`;

    return s;
  },

  numberFormatter(num) {
    if (num >= 1000000000) {
      return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
    }

    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
    }

    if (num >= 1000) {
      return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
    }

    return num;
  },

  formatPricing(value = '') {
    const stringValue = `${value}`;

    const _ref5 = stringValue.split('.') || [],
          _ref5$ = _ref5[1],
          decimalValue = _ref5$ === void 0 ? '' : _ref5$;

    return `$${Number(decimalValue) > 0 ? stringValue : Math.round(Number(stringValue))}`;
  },

  formatPricingV2(value = '') {
    const stringValue = `${value}`;

    const _ref6 = stringValue.split('.') || [],
          _ref6$ = _ref6[1],
          decimalValue = _ref6$ === void 0 ? '' : _ref6$;

    const formatStringValue = stringValue.replace(/\$\s?|(,*)/g, '').replace(/[^\d.,]/g, '').replace(/(\.\d{2})\d*/, '$1');
    const newvalue = `${Math.round(Number(formatStringValue)).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${Number(decimalValue) > 0 || !decimalValue ? stringValue : newvalue}`;
  },

  dateTimeIsoString(date = '') {
    return !!date ? `${new Date(date).toISOString()}` : `${new Date().toISOString()}`;
  },

  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k" : Math.sign(num) * Math.abs(num);
  },

  generateRandomProfileImage() {
    const placeholders_length = _artistPlaceholders.default.length;
    const random_index = Math.floor(Math.random() * (placeholders_length - 1));
    return _artistPlaceholders.default[random_index];
  },

  fileToBlob(url) {
    return new Promise(resolve => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
          resolve(blob);
        }, 'image/png', 0.95);
      };

      img.src = url;
    });
  },

  isStandardInputValue(value = '') {
    if (!value) return true;
    const regexPattern = /^[a-z\d\-_\s]+$/i;
    return regexPattern.test(value);
  }

};
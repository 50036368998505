"use strict";

exports.__esModule = true;
exports.default = useUploadProgress;

var _react = require("react");

function useUploadProgress(props) {
  const uploader = props.uploader,
        id = props.id;
  const isTotalProgress = !id ? id !== 0 : !id;

  const _useState = (0, _react.useState)({
    bytesUploaded: null,
    totalSize: null
  }),
        state = _useState[0],
        setState = _useState[1];

  (0, _react.useEffect)(() => {
    uploader.on('progress', onFileProgress);
    return () => {
      uploader.on('progress', onFileProgress);
    };
  }, []);
  return [state];

  function onFileProgress(fileId, name, bytesUploaded, totalSize) {
    if (fileId === props.id) {
      setState({
        bytesUploaded,
        totalSize,
        isTotalProgress
      });
    }
  }
}
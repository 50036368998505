"use strict";

var _types = require("@App/types");

// eslint-disable-line
module.exports = {
  GetEditorTutorialVideos(data) {
    return {
      type: `GET_EDITOR_TUTORIAL_VIDEOS${_types.REQUESTED}`,
      payload: data
    };
  }

};
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _set = _interopRequireDefault(require("lodash/set"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = args => {
  const entity = args.entity,
        entity_id = args.entity_id,
        key = args.key,
        _args$concurrent = args.concurrent,
        concurrent = _args$concurrent === void 0 ? true : _args$concurrent,
        _args$allowedFileType = args.allowedFileTypes,
        allowedFileTypes = _args$allowedFileType === void 0 ? [] : _args$allowedFileType;
  const uploaderProps = {
    allowedExtensions: allowedFileTypes,
    customHeaders: {},
    customParams: _objectSpread(_objectSpread({
      entity,
      entity_id
    }, key ? {
      key
    } : {}), {}, {
      concurrent
    }),
    uploadEndpoint: `${window.config.upload}/file/upload/entity`,
    uploaderParams: coreParams => {
      const updateParams = (0, _cloneDeep.default)(coreParams);
      (0, _set.default)(updateParams, 'options.chunking.partSize', 5000000);
      (0, _set.default)(updateParams, 'options.chunking.concurrent', {
        enabled: concurrent
      });
      (0, _set.default)(updateParams, 'options.maxConnections', 10);
      (0, _set.default)(updateParams, 'options.chunking.success', {
        endpoint: `${window.config.upload}/file/upload/combine`
      });
      (0, _set.default)(updateParams, 'options.deleteFile.endpoint', `${window.config.upload}/file`);
      return updateParams;
    }
  };
  return uploaderProps;
};

exports.default = _default;
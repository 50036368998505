"use strict";

exports.__esModule = true;
exports.default = _default;

var _isMobile = _interopRequireDefault(require("@utils/isMobile"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _default() {
  return {
    dialogClassName: 'dnaDialog__container',
    className: `dnaDialog size-sm warning mainPage dnaDialog--globalCss ${(0, _isMobile.default)() && 'mobile-dialog'}`
  };
}
"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  const isProceed = this.state.isProceed;
  return {
    dialogClassName: 'dnaDialog__container',
    className: `dnaDialog mainPage size-sm dnaDialog--globalCss editor-publish`
  };
}
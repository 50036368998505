"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = require("react");

var _contexts = require("@pages/EditorTutorials/contexts");

var _actions = require("@App/actions");

var _default = (props = {}) => {
  const dispatch = props.dispatch; // const [isVideoDialogVisible, setIsVideoDialogVisible] = useState(false)

  const _useState = (0, _react.useState)(),
        currentVideo = _useState[0],
        setCurrentVideo = _useState[1];

  (0, _react.useEffect)(() => {
    if (currentVideo) {// setIsVideoDialogVisible(true)
    }
  }, [currentVideo]);

  const handleVideoItemClick = ({
    video
  }) => {
    document.querySelector('.tutorials-container').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setCurrentVideo(video);
  }; // const handleCloseVideoDialog = () => {
  //   setIsVideoDialogVisible(false)
  // }


  return {
    values: {
      // isVideoDialogVisible,
      currentVideo
    },
    handlers: {
      handleVideoItemClick // handleCloseVideoDialog

    }
  };
};

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  const isProceed = this.state.isProceed;
  return {
    dialogClassName: 'dnaDialog__container',
    className: `dnaDialog mainPage ${isProceed ? ' size-md artistConfirmationDialog' : ' warning size-sm dnaDialog--globalCss'} `
  };
}
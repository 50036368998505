"use strict";

exports.__esModule = true;
exports.QUICK_TIP = exports.SET_PREVIEW_VISIBLE = exports.REGISTER_FORM_CONTEXT = exports.SET_FULL_DIALOG_DATA = exports.SET_DIALOG_DATA = exports.SET_LANGUAGE = exports.CLEAR_DIALOGS = exports.SHOW_FULL_DIALOG = exports.SHOW_DIALOG = exports.RELOAD_DIALOG = exports.HIDE_DIALOG = exports.UPDATE_OBJECT_URL = exports.SET_TAB_INDEX = exports.GET_PREVIOUS_NODE = exports.ADD_PAGING_LIST = exports.DIALOG_UPDATED = exports.DIALOG_UPDATING = exports.FORM_UPDATED = exports.FORM_UPDATING = exports.SET_ACTIVE_FORM = exports.CLEAR_NAVI = exports.DELETE_NAVIGATION_NODE = exports.UPDATE_NAVIGATION_NODE = exports.ADD_NAVIGATION_NODE = exports.FORM_CHANGED = exports.SUCCESS_TOASTS = exports.CLEAR_TOASTS = exports.FORM_CHANGE = exports.REQUESTED = exports.CLEAR_ERROR = exports.EXCEPTION = exports.ERROR_500 = exports.ERROR_401 = exports.ERROR_400 = void 0;
const ERROR_400 = 'ERROR_400';
exports.ERROR_400 = ERROR_400;
const ERROR_401 = 'ERROR_401';
exports.ERROR_401 = ERROR_401;
const ERROR_500 = 'ERROR_500';
exports.ERROR_500 = ERROR_500;
const EXCEPTION = 'EXCEPTION';
exports.EXCEPTION = EXCEPTION;
const CLEAR_ERROR = 'CLEAR_ERROR';
exports.CLEAR_ERROR = CLEAR_ERROR;
const REQUESTED = '_REQUESTED';
exports.REQUESTED = REQUESTED;
const FORM_CHANGE = 'FORM_CHANGE';
exports.FORM_CHANGE = FORM_CHANGE;
const CLEAR_TOASTS = 'CLEAR_TOASTS';
exports.CLEAR_TOASTS = CLEAR_TOASTS;
const SUCCESS_TOASTS = 'SUCCESS_TOASTS';
exports.SUCCESS_TOASTS = SUCCESS_TOASTS;
const FORM_CHANGED = 'FORM_CHANGED';
exports.FORM_CHANGED = FORM_CHANGED;
const ADD_NAVIGATION_NODE = 'ADD_NAVIGATION_NODE';
exports.ADD_NAVIGATION_NODE = ADD_NAVIGATION_NODE;
const UPDATE_NAVIGATION_NODE = 'UPDATE_NAVIGATION_NODE';
exports.UPDATE_NAVIGATION_NODE = UPDATE_NAVIGATION_NODE;
const DELETE_NAVIGATION_NODE = 'DELETE_NAVIGATION_NODE';
exports.DELETE_NAVIGATION_NODE = DELETE_NAVIGATION_NODE;
const CLEAR_NAVI = 'CLEAR_NAVI';
exports.CLEAR_NAVI = CLEAR_NAVI;
const SET_ACTIVE_FORM = 'SET_ACTIVE_FORM';
exports.SET_ACTIVE_FORM = SET_ACTIVE_FORM;
const FORM_UPDATING = 'FORM_UPDATING';
exports.FORM_UPDATING = FORM_UPDATING;
const FORM_UPDATED = 'FORM_UPDATED';
exports.FORM_UPDATED = FORM_UPDATED;
const DIALOG_UPDATING = 'DIALOG_UPDATING';
exports.DIALOG_UPDATING = DIALOG_UPDATING;
const DIALOG_UPDATED = 'DIALOG_UPDATED';
exports.DIALOG_UPDATED = DIALOG_UPDATED;
const ADD_PAGING_LIST = 'ADD_PAGING_LIST';
exports.ADD_PAGING_LIST = ADD_PAGING_LIST;
const GET_PREVIOUS_NODE = 'GET_PREVIOUS_NODE';
exports.GET_PREVIOUS_NODE = GET_PREVIOUS_NODE;
const SET_TAB_INDEX = 'SET_TAB_INDEX';
exports.SET_TAB_INDEX = SET_TAB_INDEX;
const UPDATE_OBJECT_URL = 'UPDATE_OBJECT_URL';
exports.UPDATE_OBJECT_URL = UPDATE_OBJECT_URL;
const HIDE_DIALOG = 'HIDE_DIALOG';
exports.HIDE_DIALOG = HIDE_DIALOG;
const RELOAD_DIALOG = 'RELOAD_DIALOG';
exports.RELOAD_DIALOG = RELOAD_DIALOG;
const SHOW_DIALOG = 'SHOW_DIALOG';
exports.SHOW_DIALOG = SHOW_DIALOG;
const SHOW_FULL_DIALOG = 'SHOW_FULL_DIALOG';
exports.SHOW_FULL_DIALOG = SHOW_FULL_DIALOG;
const CLEAR_DIALOGS = 'CLEAR_DIALOGS';
exports.CLEAR_DIALOGS = CLEAR_DIALOGS;
const SET_LANGUAGE = 'SET_LANGUAGE';
exports.SET_LANGUAGE = SET_LANGUAGE;
const SET_DIALOG_DATA = 'SET_DIALOG_DATA';
exports.SET_DIALOG_DATA = SET_DIALOG_DATA;
const SET_FULL_DIALOG_DATA = 'SET_FULL_DIALOG_DATA';
exports.SET_FULL_DIALOG_DATA = SET_FULL_DIALOG_DATA;
const REGISTER_FORM_CONTEXT = 'REGISTER_FORM_CONTEXT';
exports.REGISTER_FORM_CONTEXT = REGISTER_FORM_CONTEXT;
const SET_PREVIEW_VISIBLE = 'SET_PREVIEW_VISIBLE';
exports.SET_PREVIEW_VISIBLE = SET_PREVIEW_VISIBLE;
const QUICK_TIP = 'QUICK_TIP';
exports.QUICK_TIP = QUICK_TIP;
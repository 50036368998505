"use strict";

exports.__esModule = true;
exports.SetCartItemsCount = SetCartItemsCount;
exports.ShowSideCartPanel = ShowSideCartPanel;
exports.HideSideCartPanel = HideSideCartPanel;
exports.GetCartActualProducts = GetCartActualProducts;
exports.AddToSessionCart = AddToSessionCart;
exports.UpdateSessionCart = UpdateSessionCart;
exports.RemoveFromSessionCart = RemoveFromSessionCart;
exports.SetSessionCartProducts = SetSessionCartProducts;
exports.GetArtistCart = GetArtistCart;
exports.ProceedShopifyCheckout = ProceedShopifyCheckout;
exports.ProceedToShopifyCheckout = ProceedToShopifyCheckout;
exports.ChangeCartItemProductVariant = ChangeCartItemProductVariant;
exports.AddPendingProductToCart = AddPendingProductToCart;
exports.RemovePendingItemFromCart = RemovePendingItemFromCart;
exports.UpdatePendingCartItems = UpdatePendingCartItems;

var _types = require("@App/types");

function SetCartItemsCount(data) {
  return {
    type: `SET_CART_ITEMS_COUNT`,
    payload: data
  };
}

function ShowSideCartPanel(data) {
  return {
    type: `SHOW_SIDE_CART_PANEL`,
    payload: data
  };
}

function HideSideCartPanel(data) {
  return {
    type: `HIDE_SIDE_CART_PANEL`,
    payload: data
  };
}

function GetCartActualProducts(data) {
  return {
    type: `GET_CART_ACTUAL_PRODUCTS${_types.REQUESTED}`,
    payload: data
  };
}

function AddToSessionCart(data) {
  return {
    type: `ADD_TO_SESSION_CART${_types.REQUESTED}`,
    payload: data
  };
}

function UpdateSessionCart(data) {
  return {
    type: `UPDATE_SESSION_CART${_types.REQUESTED}`,
    payload: data
  };
}

function RemoveFromSessionCart(data) {
  return {
    type: `REMOVE_FROM_SESSION_CART${_types.REQUESTED}`,
    payload: data
  };
}

function SetSessionCartProducts(data) {
  return {
    type: `SET_SESSION_CART_PRODUCTS${_types.REQUESTED}`,
    payload: data
  };
}

function GetArtistCart(data) {
  return {
    type: `GET_ARTIST_CART${_types.REQUESTED}`,
    payload: data
  };
}

function ProceedShopifyCheckout(data) {
  return {
    type: `PROCEED_SHOPIFY_CHECKOUT${_types.REQUESTED}`,
    payload: data
  };
}

function ProceedToShopifyCheckout(data) {
  return {
    type: `PROCEED_TO_SHOPIFY_CHECKOUT${_types.REQUESTED}`,
    payload: data
  };
}

function ChangeCartItemProductVariant(data) {
  return {
    type: `CHANGE_CART_ITEM_PRODUCT_VARIANT${_types.REQUESTED}`,
    payload: data
  };
}

function AddPendingProductToCart(data) {
  return {
    type: `ADD_PENDING_PRODUCT_TO_CART${_types.REQUESTED}`,
    payload: data
  };
}

function RemovePendingItemFromCart(data) {
  return {
    type: `REMOVE_PENDING_ITEM_FROM_CART${_types.REQUESTED}`,
    payload: data
  };
}

function UpdatePendingCartItems(data) {
  return {
    type: `UPDATE_PENDING_CART_ITEMS${_types.REQUESTED}`,
    payload: data
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

const convertToURL = (path, {
  type = ''
} = {}) => {
  if (!path) return '';
  const newPath = 'file/webp?path=';

  if (path.includes('https://') || path.includes('http://')) {
    const awsURL = 'artist-library.s3.amazonaws.com';
    const cloudfrontURL = 'd39123yu1efled.cloudfront.net';

    if (path.includes(awsURL)) {
      return path.replace(awsURL, cloudfrontURL);
    }

    return path;
  }

  if (path.includes('file?path=') || path.includes('thumb?path=')) {
    if (type === 'webp') {
      return path.replace('file?path=', newPath);
    }

    return path;
  }

  if (path.includes('imgs/app')) {
    return path;
  }

  if (type === 'webp') {
    return `${window.config.upload}/${newPath}${path}`;
  }

  return `${window.config.upload}/file?path=${path}`;
};

var _default = convertToURL;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _toUpper = _interopRequireDefault(require("lodash/toUpper"));

var _toLower = _interopRequireDefault(require("lodash/toLower"));

var _tools = require("@common/utils/tools");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = uniqueEntity => {
  const socketEntity = (0, _tools.getEntitySocket)((0, _toLower.default)(uniqueEntity));
  return {
    [`GET_${uniqueEntity}_GRID`]: (state, action) => {
      const _action$payload = action.payload,
            list = _action$payload.list,
            properties = _action$payload.properties; // const data = list.data.reduce((acc, curr) => ({...acc, [curr.id]: curr }), {})

      return _objectSpread(_objectSpread({}, state), {}, {
        list,
        properties,
        resetSelectedRows: false,
        isGridFetching: false,
        refreshGrid: false,
        datetime: Date.now().toString()
      });
    },
    [`GET_${uniqueEntity}_FILTER`]: (state, action) => {
      const field = action.field,
            payload = action.payload;

      const filters = _objectSpread(_objectSpread({}, state.filters), {}, {
        [field]: payload
      });

      return _objectSpread(_objectSpread({}, state), {}, {
        filters
      });
    },
    [`${uniqueEntity}_GRID_EXPORTING`]: state => _objectSpread(_objectSpread({}, state), {}, {
      isExporting: true
    }),
    [`${uniqueEntity}_GRID_EXPORTED`]: state => _objectSpread(_objectSpread({}, state), {}, {
      isExporting: false
    }),
    [`SOCKET_MAIN_TBL_${socketEntity}`]: state => _objectSpread(_objectSpread({}, state), {}, {
      refreshGrid: true
    }),
    GET_NODE_GRID_REQUESTED: (state, action) => {
      const payload = action.payload,
            resetSelectedRows = action.resetSelectedRows;
      const gridEntity = payload.uniqueEntity,
            hideGridLoader = payload.hideGridLoader;
      return _objectSpread(_objectSpread({}, state), {}, {
        resetSelectedRows,
        isGridFetching: hideGridLoader !== true && (0, _toUpper.default)(gridEntity) === uniqueEntity
      });
    },
    EXCEPTION: state => _objectSpread(_objectSpread({}, state), {}, {
      isGridFetching: false,
      isListFetching: false
    }),
    // CUSTOM REDUCER FOR LIST FETCHING
    PAGE_PROCESSING: state => _objectSpread(_objectSpread({}, state), {}, {
      isProcessing: true
    }),
    PAGE_DONE_PROCESSING: state => _objectSpread(_objectSpread({}, state), {}, {
      isProcessing: false
    }),
    LIST_FETCHING: state => _objectSpread(_objectSpread({}, state), {}, {
      isListFetching: true
    }),
    [`GET_${uniqueEntity}_LIST`]: (state, {
      payload
    }) => _objectSpread(_objectSpread({}, state), {}, {
      list: {
        data: payload.list,
        count: payload.count
      },
      list_properties: payload.list_properties,
      isListFetching: false,
      isProcessing: false
    })
  };
};

exports.default = _default;
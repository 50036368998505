"use strict";

exports.__esModule = true;
exports.default = setBodyHeight;

function setBodyHeight(height = 'calc(100vh - 5rem)') {
  const pageBody = document.querySelector('body .app-container>.body');

  if (pageBody) {
    pageBody.style['height'] = height;
    pageBody.style['position'] = 'relative'; // pageBody.style['overflow-y'] = 'auto'

    if (height === '100%') {
      // pageBody.style['overflow-y'] = 'hidden'
      pageBody.style['position'] = 'fixed';
    }
  }
}
"use strict";

exports.__esModule = true;
exports.default = void 0;
const styleDescriptions = {
  'zip hoodie': {
    content: `Comfortable and stylish zip-up hoodie, perfect for any casual occasion. Made with high-quality materials, this hoodie is designed to keep you warm and cozy during cooler weather while also allowing you to move freely.

  Our hoodie features a full-length zipper, making it easy to put on and take off. The hood is adjustable, providing added protection from the wind and rain. The ribbed cuffs and hem provide a snug fit, keeping out the cold air.`
  },
  'zip-hoodie': {
    content: `Comfortable and stylish zip-up hoodie, perfect for any casual occasion. Made with high-quality materials, this hoodie is designed to keep you warm and cozy during cooler weather while also allowing you to move freely.

  Our hoodie features a full-length zipper, making it easy to put on and take off. The hood is adjustable, providing added protection from the wind and rain. The ribbed cuffs and hem provide a snug fit, keeping out the cold air.`
  },
  hoodie: {
    content: `Ultimate comfort essential for your wardrobe - our premium hoodie! Crafted with high-quality materials and meticulous attention to detail, this hoodie is designed to provide you with maximum comfort and style, making it a must-have for any fashion-forward individual.

  Made from a soft and cozy blend of cotton and polyester, our hoodie is perfect for keeping you warm and comfortable on those chilly days and nights. The interior is lined with a plush fleece material that is gentle on the skin, ensuring that you feel cozy and comfortable at all times. The exterior is durable and long-lasting, so you can wear it for years to come.`
  },
  't-shirt': {
    content: `The perfect blend of comfort, style, and sustainability. Made from premium quality, natural cotton fibers, this t-shirt is a must-have addition to your wardrobe.

  Crafted with utmost care, our t-shirt is incredibly soft to touch and comfortable to wear, making it ideal for everyday use. The breathable fabric wicks away moisture, keeping you cool and fresh all day long. The classic round neck and short sleeves add a timeless appeal to this versatile t-shirt that pairs effortlessly with any outfit.`
  },
  jogger: {
    content: `
      This comfortable and durable fabric combination provides the best of both worlds, making it perfect for all your athletic and leisure activities.

  The cotton in our Jogger provides softness, breathability, and moisture-wicking properties to keep you cool and dry, while the polyester adds strength, durability, and quick-drying capabilities for ultimate performance.

  Featuring a relaxed fit and an adjustable waistband, this Jogger is designed to move with you and provide maximum comfort during any activity. Whether you're running, working out at the gym, or simply lounging at home, our Jogger is the perfect choice.
    `
  }
};
var _default = styleDescriptions;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

/** Note: This is IMPORTANT and is used across the App.
 ** shapeOf CONSTANTS
  [uniqueEntity]: {
    endpoint: <string>    required: false       default: uniqueEntity               *Notes: API endpoint
    upload: <string>      required: false       default: uniqueEntity               *Notes: Upload API endpoint
    route: <string>       required: false       default: uniqueEntity               *Notes: Portal route, Browser location
    label: <string>       required: false       default: uniqueEntity               *Notes: Display label used for translation
    reducer: <string>     required: false       default: toUpper(uniqueEntity)      *Notes: Redux, ex: uniqueEntity = 'user', reducer = 'USER'
    socket: <string>      required: false       default: toUpper(uniqueEntity)      *Notes: Redux, ex: uniqueEntity = 'user', socket = 'USER'
    icon: <string>        required: false       default: toLower(uniqueEntity)      *Notes: Icon on Full Dialog Tree Navigation
    main_field: <string>  required: false       default: "getDisplayName" func      *Notes: Display the main field in the form title & navigation tree, complex ex: "template: <%= first_name %> <%= last_name %>"
  }
*/
const CONSTANTS = {
  // ### GENERATE APP CONSTANTS ###
  artist_order: {
    reducer: 'ARTIST_ORDER',
    route: 'sales',
    endpoint: 'order',
    main_entity: 'order'
  },
  sales_report_details: {
    reducer: 'SALES_REPORT_DETAILS',
    endpoint: 'order',
    main_entity: 'order'
  },
  style_type_products_grid: {
    reducer: 'STYLE_TYPE_PRODUCTS_GRID',
    endpoint: 'product',
    main_entity: 'product'
  },
  customer_order: {
    reducer: 'CUSTOMER_ORDER',
    route: 'customer-order',
    endpoint: 'order',
    main_entity: 'order'
  },
  merchant_order: {
    reducer: 'MERCHANT_ORDER',
    route: 'merchant_order',
    endpoint: 'order',
    main_entity: 'order'
  },
  orders_report_details: {
    reducer: 'ORDERS_REPORT_DETAILS',
    endpoint: 'order',
    main_entity: 'order'
  },
  design: {
    reducer: 'PRODUCT',
    route: 'shop-product',
    endpoint: 'product',
    main_entity: 'product'
  },
  product_details: {
    reducer: 'PRODUCT_DETAILS',
    main_entity: 'product'
  },
  collection: {
    reducer: 'COLLECTION',
    main_field: 'name',
    route: 'collection',
    endpoint: 'collection',
    main_entity: 'collection'
  },
  product: {
    reducer: 'PRODUCT',
    main_field: 'name',
    route: 'shop-product',
    endpoint: 'product',
    main_entity: 'product'
  },
  artist: {
    reducer: 'ARTIST',
    main_field: 'first_name',
    route: 'artist',
    endpoint: 'artist',
    main_entity: 'artist'
  },
  artist_collection: {
    reducer: 'ARTIST_COLLECTION',
    main_field: 'name',
    route: 'artist-collection',
    endpoint: 'collection',
    main_entity: 'collection'
  },
  collection_details: {
    reducer: 'COLLECTION_DETAILS',
    main_field: 'name',
    route: 'collection-details',
    main_entity: 'collection'
  },
  artist_details: {
    reducer: 'ARTIST_DETAILS',
    main_field: 'first_name',
    route: 'artist-details',
    main_entity: 'artist'
  },
  profile: {
    reducer: 'PROFILE',
    main_field: 'first_name',
    route: 'profile',
    main_entity: 'artist'
  },
  base_style: {
    reducer: 'BASE_STYLE',
    main_field: 'name',
    route: 'create',
    endpoint: 'style'
  },
  design_editor: {
    reducer: 'DESIGN_EDITOR',
    route: 'create'
  },
  artist_design: {
    reducer: 'ARTIST_DESIGN',
    route: 'artist-design'
  },
  assign_product: {
    endpoint: 'product',
    label: 'design'
  },
  look_up_product: {
    endpoint: 'product',
    label: 'product'
  },
  cart: {
    reducer: 'CART',
    main_field: 'name',
    route: 'cart',
    endpoint: 'cart',
    main_entity: 'artist'
  },
  search: {
    reducer: 'SEARCH',
    route: 'results-page',
    endpoint: 'search'
  },
  account_setting: {
    reducer: 'ACCOUNT_SETTING',
    route: 'account_setting',
    main_entity: 'artist'
  },
  product_listing: {
    reducer: 'PRODUCT_LISTING',
    route: 'product-listing',
    main_entity: 'product'
  },
  collection_listing: {
    reducer: 'COLLECTION_LISTING',
    route: 'collection-listing',
    main_entity: 'collection'
  },
  landing_page: {},
  home: {},
  payout: {},
  timeline: {},
  note: {},
  user: {},
  company: {},
  creators: {},
  creator_details: {},
  news_letter: {},
  creator_analytics: {},
  tutorials: {},
  product_preview: {},
  editor_tutorial_videos: {},
  page_not_found: {},
  support_page: {},
  shopify_store_details: {},
  connect_shopify_store: {}
};
var _default = CONSTANTS;
exports.default = _default;
"use strict";

var _effects = require("redux-saga/effects");

var _reduxSaga = require("redux-saga");

var _fileSaver = _interopRequireDefault(require("file-saver"));

var _jsonexport = _interopRequireDefault(require("jsonexport"));

var _moment = _interopRequireDefault(require("moment"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _find = _interopRequireDefault(require("lodash/find"));

var _startCase = _interopRequireDefault(require("lodash/startCase"));

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _axios = _interopRequireDefault(require("@utils/axios"));

var _uploadFile = _interopRequireDefault(require("@utils/uploadFile"));

var _tools = require("@23point5/core/controls/utils/tools");

var _tools2 = require("@common/utils/tools");

var _timeouts = require("@common/statics/timeouts");

var _actions = require("@App/actions");

var _types = require("../types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function* GetNodeGrid({
  payload,
  makeDelay
}) {
  const list = payload.list,
        uniqueEntity = payload.uniqueEntity,
        parent_node = payload.parent_node,
        makeNetworkRequest = payload.makeNetworkRequest,
        restPayload = _objectWithoutPropertiesLoose(payload, ["list", "uniqueEntity", "parent_node", "makeNetworkRequest"]);

  const cleanPayload = (0, _omit.default)(payload, ['hideGridLoader']);
  const resultType = (0, _tools2.getEntityAction)(_types.GET_NODE_GRID, uniqueEntity);

  if (makeDelay) {
    yield (0, _effects.call)(_reduxSaga.delay, _timeouts.REQUEST_GRID_ON_UPSERT);
  }

  if (makeNetworkRequest === false) {
    yield (0, _effects.put)({
      type: resultType,
      payload: {
        properties: (0, _cloneDeep.default)(cleanPayload),
        list: list || {
          count: 0,
          data: []
        }
      }
    });
    return;
  }

  const config = {
    method: 'post',
    url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/grid`,
    data: (0, _omit.default)(restPayload, ['parent_node', 'uniqueEntity'])
  };
  const activeForm = (0, _find.default)(yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.raw')), item => item.active);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);
  const start = cleanPayload.start,
        limit = cleanPayload.limit;

  if (result.type === resultType) {
    result.payload = {
      list: (0, _cloneDeep.default)(result.payload),
      properties: (0, _cloneDeep.default)(cleanPayload)
    };
    const entityRoute = (0, _tools2.getEntityRoute)(uniqueEntity);
    yield (0, _effects.all)([(0, _effects.put)(result), (0, _effects.put)({
      type: _types.ADD_PAGING_LIST,
      payload: _objectSpread(_objectSpread({}, result.payload.list), {}, {
        start,
        limit,
        uniqueEntity,
        parent: parent_node,
        type: (0, _tools2.getEntityReducer)(uniqueEntity),
        data: result.payload.list.data.map(e => _objectSpread(_objectSpread({}, e), {}, {
          route: parent_node && activeForm ? `${activeForm.route}/${entityRoute}/${e.id}` : `/${entityRoute}/${e.id}`
        }))
      })
    })]);
  } else {
    yield (0, _effects.put)(result);
  }
}

function* GetNodeFilter({
  payload
}) {
  const uniqueEntity = payload.uniqueEntity,
        field = payload.field;
  const config = {
    method: 'post',
    url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/grid`,
    data: (0, _omit.default)(payload, ['parent_node', 'uniqueEntity'])
  };
  const resultType = (0, _tools2.getEntityAction)(_types.GET_NODE_FILTER, uniqueEntity);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);

  if (result.type === resultType) {
    yield (0, _effects.put)(_objectSpread(_objectSpread({}, result), {}, {
      field
    }));
  } else {
    yield (0, _effects.put)(result);
  }
}

function* GetNodeDetails({
  payload
}) {
  if (payload.showFullDialog !== false) {
    yield (0, _effects.put)({
      type: _types.SHOW_FULL_DIALOG,
      payload: true
    });
  }

  const id = payload.id,
        imageUrl = payload.imageUrl,
        isCustomer = payload.isCustomer,
        parent_node = payload.parent_node,
        uniqueEntity = payload.uniqueEntity,
        customFullDialogDispatches = payload.customFullDialogDispatches;
  const config = {
    method: 'get',
    url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/${id}`
  };
  const parentForm = (0, _find.default)(yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.raw')), item => item.id === parent_node);
  const itemRoute = `${uniqueEntity}/${id}`;
  const result = yield (0, _effects.call)(_axios.default, config, (0, _tools2.getEntityAction)(_types.GET_NODE_DETAILS, uniqueEntity));
  const otherDispatches = (customFullDialogDispatches || []).map(e => (0, _effects.put)(e));
  yield (0, _effects.all)([(0, _effects.put)(result), (0, _effects.put)({
    type: _types.SET_ACTIVE_FORM,
    payload: {
      id,
      uniqueEntity,
      parent: parent_node,
      name: (0, _tools2.getEntityMainField)(uniqueEntity, result.payload) || (0, _tools.getDisplayName)(result.payload),
      type: (0, _tools2.getEntityReducer)(uniqueEntity),
      form_data: _objectSpread(_objectSpread({}, result.payload), {}, {
        isCustomer,
        imageUrl
      }),
      route: parentForm ? `${parentForm.route}/${itemRoute}` : `/${itemRoute}`
    }
  }), ...otherDispatches]);
}

function* GetMultipleNodeDetails({
  payload
}) {
  const isFullDialogVisible = yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.isVisible'));

  if (!isFullDialogVisible) {
    yield (0, _effects.put)({
      type: _types.SHOW_FULL_DIALOG,
      payload: true
    });
  }

  try {
    const _Object$keys$reduce = Object.keys(payload).reduce(([actions, mapping], uniqueEntity) => {
      const config = {
        method: 'get',
        url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/${payload[uniqueEntity]}`
      };
      const entityAction = (0, _tools2.getEntityAction)(_types.GET_NODE_DETAILS, uniqueEntity);
      return [[...actions, (0, _effects.call)(_axios.default, config, entityAction)], _objectSpread(_objectSpread({}, mapping), {}, {
        [entityAction]: uniqueEntity
      })];
    }, [[], {}]),
          requests = _Object$keys$reduce[0],
          actionMapping = _Object$keys$reduce[1];

    const results = yield (0, _effects.all)(requests);

    const _results$reduce = results.reduce(([acc, path], {
      type,
      payload: resultPayload
    }) => {
      const id = resultPayload.id;
      const uniqueEntity = actionMapping[type];
      const currentPath = `${path}/${(0, _tools2.getEntityRoute)(uniqueEntity)}/${id}`;
      return [[...acc, {
        id,
        uniqueEntity,
        name: (0, _tools2.getEntityMainField)(uniqueEntity, resultPayload) || (0, _tools.getDisplayName)(resultPayload),
        type: (0, _tools2.getEntityReducer)(uniqueEntity),
        form_data: resultPayload,
        route: currentPath
      }], currentPath];
    }, [[], '/']),
          data = _results$reduce[0];

    yield (0, _effects.put)({
      type: _types.GET_MULTIPLE_NODE_DETAILS,
      payload: data
    });
  } catch (e) {
    yield (0, _effects.all)([(0, _effects.put)({
      type: _types.SHOW_FULL_DIALOG,
      payload: false
    }), (0, _effects.call)(_tools2.pushHistory.bind('/page-error'))]);
  }
}

function* GetPreviewDetails({
  payload
}) {
  const data = payload.data;
  yield (0, _effects.put)({
    type: _types.SET_PREVIEW_VISIBLE,
    payload: true
  });

  if (!payload.id) {
    yield (0, _effects.put)({
      type: _types.GET_PREVIEW_DETAILS,
      payload: data
    });
    return;
  }

  const uniqueEntity = payload.uniqueEntity,
        customFullDialogDispatches = payload.customFullDialogDispatches;
  const config = {
    method: 'get',
    url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/${payload.id}`
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.GET_PREVIEW_DETAILS);
  const otherDispatches = (customFullDialogDispatches || []).map(e => (0, _effects.put)(e));
  yield (0, _effects.all)([(0, _effects.put)({
    type: _types.GET_PREVIEW_DETAILS,
    payload: _objectSpread({
      fieldValues: result.payload
    }, data)
  }), ...otherDispatches]);
}

function* AddNavigationNode({
  payload
}) {
  const _payload$customFullDi = payload.customFullDialogDispatches,
        customFullDialogDispatches = _payload$customFullDi === void 0 ? [] : _payload$customFullDi,
        restPayload = _objectWithoutPropertiesLoose(payload, ["customFullDialogDispatches"]);

  yield (0, _effects.all)([(0, _effects.put)({
    type: _types.ADD_NAVIGATION_NODE,
    payload: restPayload
  }), ...customFullDialogDispatches.map(e => (0, _effects.put)(e))]);
}

function* CreateNode({
  payload
}) {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const _payload = payload,
        action = _payload.action,
        source = _payload.source,
        imageUpdated = _payload.imageUpdated,
        imageUrl = _payload.imageUrl,
        imageFile = _payload.imageFile,
        parent_node_id = _payload.parent_node_id,
        uniqueEntity = _payload.uniqueEntity,
        postDispatches = _payload.postDispatches,
        customFullDialogDispatches = _payload.customFullDialogDispatches,
        _payload$requestMetho = _payload.requestMethod,
        requestMethod = _payload$requestMetho === void 0 ? 'post' : _payload$requestMetho,
        requestEndpoint = _payload.requestEndpoint,
        customMessage = _payload.customMessage,
        _payload$preventGridR = _payload.preventGridRequest,
        preventGridRequest = _payload$preventGridR === void 0 ? false : _payload$preventGridR; // --- inject metadata ---

  const endpoint = requestEndpoint || (0, _tools2.getEntityEndpoint)(uniqueEntity);
  const session_id = yield (0, _effects.select)(_tools2.getState.bind('global.session.data.id'));
  payload = (0, _tools2.setMetadata)(payload, session_id);
  const shapePayload = (0, _omit.default)(payload, ['source', 'action', 'uniqueEntity', 'postDispatches', 'customFullDialogDispatches', 'parent_node_id', 'fileType', 'imageUpdated', 'imageFile', 'imageUrl', 'requestMethod', 'requestEndpoint', 'customMessage']);
  const config = {
    method: requestMethod,
    url: `/${endpoint}`,
    data: shapePayload
  };
  const resultType = (0, _tools2.getEntityAction)(_types.CREATE_NODE, uniqueEntity);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);
  const promises = [(0, _effects.put)(result), (0, _effects.put)((0, _actions.DialogUpdated)())];

  if (result.type === resultType) {
    const isFullDialogVisible = yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.isVisible'));
    const id = result.payload.id;

    switch (action) {
      case 'save_close':
        promises.push((0, _effects.put)({
          type: _types.HIDE_DIALOG
        }));
        break;

      case 'save_continue':
        // --- if full dialog is open, add to navigation ---
        if (isFullDialogVisible) {
          const parentForm = (0, _find.default)(yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.raw')), item => item.id === parent_node_id);
          const itemRoute = `${(0, _tools2.getEntityRoute)(uniqueEntity)}/${id}`;
          const formRoute = parentForm ? `${parentForm.route}/${itemRoute}` : `/${itemRoute}`;
          promises.push((0, _effects.call)(_tools2.pushHistory.bind(formRoute)), (0, _effects.put)({
            type: _types.ADD_NAVIGATION_NODE + _types.REQUESTED,
            payload: {
              uniqueEntity,
              id: result.payload.id,
              parent: parent_node_id,
              name: (0, _tools2.getEntityMainField)(uniqueEntity, result.payload) || (0, _tools.getDisplayName)(result.payload),
              type: (0, _tools2.getEntityReducer)(uniqueEntity),
              form_data: _objectSpread(_objectSpread({}, result.payload), {}, {
                imageUrl
              }),
              route: formRoute,
              customFullDialogDispatches
            }
          }));
        } else {
          promises.push((0, _effects.call)(_tools2.pushHistory.bind(`/${(0, _tools2.getEntityRoute)(uniqueEntity)}/${id}`)));
        } // --- end of if full dialog is open, add to navigation ---


        promises.push((0, _effects.put)({
          type: _types.HIDE_DIALOG
        }));

        if (source === 'preview') {
          promises.push((0, _effects.put)({
            type: _types.SET_PREVIEW_VISIBLE,
            payload: false
          }));
        }

        break;

      case 'save_new':
        promises.push((0, _effects.put)({
          type: _types.RELOAD_DIALOG
        }));

        if (source === 'preview') {
          promises.push((0, _effects.put)({
            type: _types.SET_PREVIEW_DATA,
            payload: {
              fieldValues: result.payload
            }
          }), (0, _effects.put)({
            type: _types.RELOAD_PREVIEW
          }));
        }

        break;

      default:
        // do nothing
        break;
    }

    promises.push((0, _effects.put)((0, _actions.ShowSuccess)({
      message: !customMessage ? `${(0, _tools2.capitalizeFirstLetter)(uniqueEntity)} successfully created.` : customMessage,
      action: 'postfix_successfully_created'
    })));

    if (!preventGridRequest) {
      const gridProperties = yield (0, _effects.select)(_tools2.getState.bind(`${uniqueEntity}.properties`));
      promises.push((0, _effects.put)({
        type: _types.GET_NODE_GRID + _types.REQUESTED,
        payload: gridProperties,
        makeDelay: true
      }));
    }

    if (parent_node_id) {
      promises.push((0, _effects.put)({
        type: _types.ADD_PAGING_LIST,
        payload: {
          data: [_objectSpread(_objectSpread({}, result.payload), {}, {
            parent: parent_node_id
          })],
          parent: parent_node_id,
          type: (0, _tools2.getEntityReducer)(uniqueEntity),
          uniqueEntity,
          start: 0,
          count: 1,
          limit: 1
        }
      }));
    }

    if (postDispatches) {
      const chainFunctions = (0, _tools.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }
  }

  yield (0, _effects.all)(promises);

  if (result.type === resultType && imageUpdated) {
    const uploadEntity = (0, _tools2.getUploadEntity)(uniqueEntity);
    yield (0, _effects.put)({
      type: _types.UPLOAD_IMAGE + _types.REQUESTED,
      payload: {
        id: result.payload.id,
        uniqueEntity: uploadEntity,
        imageFile,
        resultType
      }
    });
  }
}

function* UpdateNode({
  payload
}) {
  const _payload2 = payload,
        id = _payload2.id,
        uniqueEntity = _payload2.uniqueEntity,
        action = _payload2.action,
        source = _payload2.source,
        postDispatches = _payload2.postDispatches,
        _payload2$requestMeth = _payload2.requestMethod,
        requestMethod = _payload2$requestMeth === void 0 ? 'put' : _payload2$requestMeth,
        _payload2$preventGrid = _payload2.preventGridRequest,
        preventGridRequest = _payload2$preventGrid === void 0 ? false : _payload2$preventGrid,
        customMessage = _payload2.customMessage,
        _payload2$isShowToast = _payload2.isShowToastAfter,
        isShowToastAfter = _payload2$isShowToast === void 0 ? true : _payload2$isShowToast; // --- inject metadata ---

  const session_id = yield (0, _effects.select)(_tools2.getState.bind('global.session.data.id'));
  const endpoint = (0, _tools2.getEntityEndpoint)(uniqueEntity);
  payload = (0, _tools2.setMetadata)(payload, session_id);

  if (source === 'dialog') {
    yield (0, _effects.put)((0, _actions.DialogUpdating)());
  } else {
    yield (0, _effects.put)((0, _actions.FormUpdating)());
  }

  const data = (0, _omit.default)(payload, ['uniqueEntity', 'created_date', 'updated_date', 'action', 'source', 'fileType', 'imageUpdated', 'imageFile', 'imageUrl', 'postDispatches', 'requestMethod', 'showFullDialog', 'requestEndpoint', 'preventGridRequest', 'customMessage', 'isShowToastAfter', 'access_token', 'authenticated', 'logged_in', 'menus']);
  const config = {
    method: requestMethod,
    url: `/${endpoint}`,
    data
  };
  const resultType = (0, _tools2.getEntityAction)(_types.UPDATE_NODE, uniqueEntity);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);
  const promises = [(0, _effects.put)(result)];

  if (source === 'dialog') {
    promises.push((0, _effects.put)((0, _actions.DialogUpdated)()));
  } else {
    promises.push((0, _effects.put)((0, _actions.FormUpdated)()));
  }

  let changeRoute;

  if (result.type === resultType) {
    const gridProperties = yield (0, _effects.select)(_tools2.getState.bind(`${uniqueEntity}.properties`));

    if (source !== 'dialog' && source !== 'page') {
      if (action === 'save_close') {
        const treeNodes = yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.raw'));

        const _ref = treeNodes.find(fi => fi.id === id) || {},
              parent = _ref.parent;

        if (parent) {
          // --- go up 1 level ---
          const _ref2 = treeNodes.find(fi => fi.id === parent) || {},
                parent_node_id = _ref2.id,
                parent_type = _ref2.type,
                route = _ref2.route;

          changeRoute = route;
          promises.push((0, _effects.put)({
            type: _types.UPDATE_NAVIGATION_NODE,
            payload: {
              id: payload && payload.id,
              name: (0, _tools2.getEntityMainField)(uniqueEntity, data) || (0, _tools.getDisplayName)(data)
            }
          }), (0, _effects.put)({
            type: _types.GET_NODE_DETAILS + _types.REQUESTED,
            payload: {
              uniqueEntity: parent_type.toLowerCase(),
              id: parent_node_id
            }
          })); // --- end of go up 1 level ---
        } else {
          // --- navigate to root ---
          changeRoute = `/${(0, _tools2.getEntityRoute)(uniqueEntity)}`;
          promises.push((0, _effects.put)({
            type: _types.SHOW_FULL_DIALOG,
            payload: false
          }), (0, _effects.put)({
            type: _types.CLEAR_NAVI
          }));
        }
      } else {
        promises.push((0, _effects.put)({
          type: _types.ADD_NAVIGATION_NODE,
          payload: {
            id: payload && payload.id,
            name: (0, _tools2.getEntityMainField)(uniqueEntity, data) || (0, _tools.getDisplayName)(data),
            form_data: payload,
            type: (0, _tools2.getEntityReducer)(uniqueEntity)
          }
        }));
      }

      promises.push((0, _effects.put)({
        type: _types.FORM_CHANGED,
        payload: false
      }));
    } else {
      // --- update is on dialog ---
      promises.push((0, _effects.put)({
        type: _types.HIDE_DIALOG
      }));
    }

    if (source !== 'page' && isShowToastAfter) {
      promises.push((0, _effects.put)((0, _actions.ShowSuccess)({
        message: !customMessage ? `${(0, _tools2.capitalizeFirstLetter)(uniqueEntity)} successfully updated.` : customMessage,
        action: 'postfix_successfully_updated'
      })));
    }

    if (gridProperties && !preventGridRequest) {
      promises.push((0, _effects.put)({
        type: _types.GET_NODE_GRID + _types.REQUESTED,
        payload: gridProperties,
        makeDelay: true
      }));
    }

    if (postDispatches) {
      const chainFunctions = (0, _tools.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }
  }

  yield (0, _effects.all)(promises);

  if (changeRoute && source !== 'dialog' && source !== 'page') {
    yield (0, _effects.call)(_tools2.pushHistory.bind(changeRoute));
  }
}

function* DeleteNode({
  payload
}) {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const _payload3 = payload,
        _payload3$preventChan = _payload3.preventChangeRoute,
        preventChangeRoute = _payload3$preventChan === void 0 ? false : _payload3$preventChan; // --- inject metadata ---

  const session_id = yield (0, _effects.select)(_tools2.getState.bind('global.session.data.id'));
  payload = (0, _tools2.setMetadata)(payload, session_id);
  const _payload4 = payload,
        id = _payload4.id,
        uniqueEntity = _payload4.uniqueEntity,
        postDispatches = _payload4.postDispatches,
        _payload4$method = _payload4.method,
        method = _payload4$method === void 0 ? 'put' : _payload4$method,
        customMessage = _payload4.customMessage;
  const data = (0, _omit.default)(payload, ['method', 'url', 'uniqueEntity', 'created_date', 'updated_date', 'setUrl', 'customMessage', 'preventGridRequest', 'preventChangeRoute']);
  const url = payload.url || (0, _tools2.getDeleteUrl)(payload);
  const config = {
    method,
    url,
    data
  };
  const resultType = (0, _tools2.getEntityAction)(_types.UPDATE_NODE, uniqueEntity);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);
  const promises = [yield (0, _effects.put)(result), yield (0, _effects.put)((0, _actions.DialogUpdated)())];
  let willChangeRoute = false;

  if (result.type === resultType) {
    const gridProperties = yield (0, _effects.select)(_tools2.getState.bind(`${uniqueEntity}.properties`)); // ---if full dialog is open, remove node from tree, then go up 1 level ---

    const treeNodes = yield (0, _effects.select)(_tools2.getState.bind('global.fullDialog.raw'));

    const _ref3 = treeNodes.find(fi => fi.id === id) || {},
          active = _ref3.active,
          parent = _ref3.parent;

    if (active && parent) {
      // --- go up 1 level ---
      const _ref4 = treeNodes.find(fi => fi.id === parent) || {},
            parent_node_id = _ref4.id,
            parent_type = _ref4.type;

      promises.push((0, _effects.put)({
        type: _types.DELETE_NAVIGATION_NODE,
        payload: {
          id: payload && payload.id
        }
      }), (0, _effects.put)({
        type: _types.GET_NODE_DETAILS + _types.REQUESTED,
        payload: {
          uniqueEntity: parent_type.toLowerCase(),
          id: parent_node_id
        }
      })); // --- end of go up 1 level ---
    } else if (active) {
      // --- navigate to root ---
      willChangeRoute = true;
      promises.push((0, _effects.put)({
        type: _types.SHOW_FULL_DIALOG,
        payload: false
      }), (0, _effects.put)({
        type: _types.CLEAR_NAVI
      }));
    } // --- end of if full dialog is open, go up 1 level ---


    promises.push((0, _effects.put)({
      type: _types.HIDE_DIALOG
    }), (0, _effects.put)((0, _actions.ShowSuccess)({
      message: customMessage || `${(0, _tools2.capitalizeFirstLetter)(uniqueEntity)} successfully deleted.`,
      action: 'postfix_successfully_deleted'
    })));

    if (!payload.preventGridRequest) {
      promises.push((0, _effects.put)({
        type: _types.GET_NODE_GRID + _types.REQUESTED,
        payload: gridProperties,
        resetSelectedRows: true,
        makeDelay: true
      }));
    }

    if (postDispatches) {
      const chainFunctions = (0, _tools.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions(result.payload));
    }
  }

  yield (0, _effects.all)(promises);

  if (willChangeRoute && !preventChangeRoute) {
    yield (0, _effects.call)(_tools2.pushHistory.bind(`/${(0, _tools2.getEntityRoute)(uniqueEntity)}`));
  }
}

function* ExportCSVNode({
  payload
}) {
  const uniqueEntity = payload.uniqueEntity,
        columnFields = payload.columnFields,
        restPayload = _objectWithoutPropertiesLoose(payload, ["uniqueEntity", "columnFields"]);

  const entity = uniqueEntity.toUpperCase(); // restPayload.filter = setFilter(restPayload.filter)

  yield (0, _effects.put)({
    type: `${entity}_GRID_EXPORTING`
  });
  const config = {
    method: 'post',
    url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/grid`,
    data: restPayload
  };
  const resultType = (0, _tools2.getEntityAction)(_types.EXPORT_CSV_NODE, uniqueEntity);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);

  if (result.type === resultType) {
    const file_name = `${(0, _startCase.default)((0, _tools2.getEntityLabel)(uniqueEntity))} ${(0, _moment.default)().format('MM-DD-YYYY')}.csv`;
    const headers = {
      type: 'application/octet-stream'
    };
    (0, _jsonexport.default)(result.payload.data.map(e => columnFields.reduce((acc, curr) => {
      const key = curr.key,
            label = curr.label,
            handler = curr.handler;
      const value = handler ? handler(key, e) : e[curr.key];
      return _objectSpread(_objectSpread({}, acc), {}, {
        [label]: value
      });
    }, {})), (err, csv) => _fileSaver.default.saveAs(new Blob([csv], headers), file_name));
  }

  yield (0, _effects.all)([(0, _effects.put)(result), (0, _effects.put)((0, _actions.ShowSuccess)({
    message: `${(0, _tools2.capitalizeFirstLetter)(uniqueEntity)} successfully exported.`,
    action: 'postfix_table_successfully_exported'
  })), (0, _effects.put)({
    type: `${entity}_GRID_EXPORTED`
  })]);
}

function* UploadImage({
  payload
}) {
  const _payload$resultType = payload.resultType,
        resultType = _payload$resultType === void 0 ? _types.UPLOAD_IMAGE : _payload$resultType;

  try {
    yield (0, _uploadFile.default)(payload);
    yield (0, _effects.put)({
      type: resultType
    });
  } catch (err) {
    yield (0, _effects.put)({
      type: _types.ERROR_400
    });
  }
}

function* RemoveImage({
  payload
}) {
  const config = {
    url: '/upload/file',
    method: 'delete',
    data: payload
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.REMOVE_IMAGE);
  yield (0, _effects.put)(result);
}

function* ReOrderGrid({
  payload
}) {
  const uniqueEntity = payload.uniqueEntity,
        restPayload = _objectWithoutPropertiesLoose(payload, ["uniqueEntity"]);

  const config = {
    method: 'put',
    url: `/${(0, _tools2.getEntityEndpoint)(uniqueEntity)}/batch`,
    data: restPayload
  };
  const resultType = (0, _tools2.getEntityAction)(_types.REORDER_GRID, uniqueEntity);
  const result = yield (0, _effects.call)(_axios.default, config, resultType);
  const promises = [(0, _effects.put)(result)];

  if (result.type === resultType) {
    const gridProperties = yield (0, _effects.select)(_tools2.getState.bind(`${uniqueEntity}.properties`));
    promises.push((0, _effects.put)((0, _actions.ShowSuccess)({
      message: `${(0, _tools2.capitalizeFirstLetter)(uniqueEntity)} successfully updated.`,
      action: 'postfix_successfully_updated'
    })), (0, _effects.put)({
      type: _types.GET_NODE_GRID + _types.REQUESTED,
      payload: gridProperties,
      makeDelay: true
    }));
  }

  yield (0, _effects.all)(promises);
}

module.exports = function* node() {
  // GET
  yield (0, _effects.takeLatest)(_types.GET_NODE_FILTER + _types.REQUESTED, GetNodeFilter);
  yield (0, _effects.takeLatest)(_types.GET_NODE_DETAILS + _types.REQUESTED, GetNodeDetails);
  yield (0, _effects.takeLatest)(_types.GET_MULTIPLE_NODE_DETAILS + _types.REQUESTED, GetMultipleNodeDetails);
  yield (0, _effects.takeLatest)(_types.GET_PREVIEW_DETAILS + _types.REQUESTED, GetPreviewDetails); // POST

  yield (0, _tools2.takeLatestPerEntity)(_types.GET_NODE_GRID + _types.REQUESTED, GetNodeGrid, ({
    payload
  }) => payload.uniqueEntity);
  yield (0, _effects.takeLatest)(_types.CREATE_NODE + _types.REQUESTED, CreateNode);
  yield (0, _effects.takeLatest)(_types.EXPORT_CSV_NODE + _types.REQUESTED, ExportCSVNode); // PUT

  yield (0, _effects.takeLatest)(_types.UPDATE_NODE + _types.REQUESTED, UpdateNode);
  yield (0, _effects.takeLatest)(_types.ADD_NAVIGATION_NODE + _types.REQUESTED, AddNavigationNode);
  yield (0, _effects.takeLatest)(_types.DELETE_NODE + _types.REQUESTED, DeleteNode);
  yield (0, _effects.takeLatest)(_types.UPLOAD_IMAGE + _types.REQUESTED, UploadImage);
  yield (0, _effects.takeLatest)(_types.REORDER_GRID + _types.REQUESTED, ReOrderGrid); // DELETE

  yield (0, _effects.takeLatest)(_types.REMOVE_IMAGE + _types.REQUESTED, RemoveImage);
};
"use strict";

exports.__esModule = true;
exports.UploadImageTab = exports.PatternsTab = void 0;

var _PatternsTab = _interopRequireDefault(require("./PatternsTab"));

exports.PatternsTab = _PatternsTab.default;

var _UploadImageTab = _interopRequireDefault(require("./UploadImageTab"));

exports.UploadImageTab = _UploadImageTab.default;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
"use strict";

exports.__esModule = true;
exports.default = _default;

function _default() {
  return {
    dialogClassName: 'dnaDialog__container logout-container',
    className: 'dnaDialog size-sm warning mainPage dnaDialog--globalCss logout'
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _actions = require("@App/actions");

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleTabChange(value) {
    this.setState({
      currentTab: value
    });
  },

  handleRemove(key) {
    const _this$props$dialog = this.props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog;
    const _this$state$backgroun = this.state.backgroundPhotos,
          backgroundPhotos = _this$state$backgroun === void 0 ? [] : _this$state$backgroun;
    const _dialog$data$callback = dialog.data.callbacks,
          callbacks = _dialog$data$callback === void 0 ? {} : _dialog$data$callback;
    const _callbacks$handleUpda = callbacks.handleUpdateEditorBackgroundPhotos,
          handleUpdateEditorBackgroundPhotos = _callbacks$handleUpda === void 0 ? () => {} : _callbacks$handleUpda;
    const newBackgroundPhotos = backgroundPhotos.filter(e => e.key !== key);
    this.setState({
      backgroundPhotos: newBackgroundPhotos
    }, () => {
      handleUpdateEditorBackgroundPhotos(newBackgroundPhotos);
    });
  },

  handleOnUploadSuccess(uploadedPhotos = []) {
    const _this$props$dialog2 = this.props.dialog,
          dialog = _this$props$dialog2 === void 0 ? {} : _this$props$dialog2;
    const _this$state$backgroun2 = this.state.backgroundPhotos,
          backgroundPhotos = _this$state$backgroun2 === void 0 ? [] : _this$state$backgroun2;
    const _dialog$data$callback2 = dialog.data.callbacks,
          callbacks = _dialog$data$callback2 === void 0 ? {} : _dialog$data$callback2;
    const _callbacks$handleUpda2 = callbacks.handleUpdateEditorBackgroundPhotos,
          handleUpdateEditorBackgroundPhotos = _callbacks$handleUpda2 === void 0 ? () => {} : _callbacks$handleUpda2;
    const newBackgroundPhotos = [...uploadedPhotos, ...backgroundPhotos];
    this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      backgroundPhotos: newBackgroundPhotos
    }), () => {
      handleUpdateEditorBackgroundPhotos(newBackgroundPhotos);
    });
  },

  handleOnUploadError(_ref) {
    let _ref$xhrOrXdr = _ref.xhrOrXdr,
        xhrOrXdr = _ref$xhrOrXdr === void 0 ? {} : _ref$xhrOrXdr,
        rest = _objectWithoutPropertiesLoose(_ref, ["xhrOrXdr"]);

    const error = JSON.parse(xhrOrXdr.responseText);
    const errorMessage = {
      'Invalid SVG.': 'Uploaded Image is currently not supported.'
    }[error.message] || 'Uploaded file is not supported.';
    const failedImage = rest.uploader.methods.getFile(rest.id);
    const failedImageUrl = failedImage ? URL.createObjectURL(failedImage) : '';
    this.setState(prev => ({
      unsupportedBackgroundImages: [...prev.unsupportedBackgroundImages, _objectSpread(_objectSpread({}, rest), {}, {
        errorMessage,
        thumb: failedImageUrl,
        handleRemoveFailedImage: () => this.setState(prevState => ({
          unsupportedBackgroundImages: [...prevState.unsupportedBackgroundImages].filter(e => e.id !== rest.id)
        }))
      })]
    }));
  },

  handleCloseDialog() {
    this.handleHide();
  },

  handleSetIsUploading(isUploading) {
    this.setState({
      isUploading
    });
  },

  handleSetSelectedPattern(selectedPattern) {
    this.setState({
      selectedPattern
    });
  },

  handleApplySelectedBackgroundPattern() {
    const _this$props = this.props,
          _this$props$dialog3 = _this$props.dialog,
          dialog = _this$props$dialog3 === void 0 ? {} : _this$props$dialog3,
          dispatch = _this$props.dispatch;
    const _this$state$backgroun3 = this.state.backgroundPhotos,
          backgroundPhotos = _this$state$backgroun3 === void 0 ? [] : _this$state$backgroun3;
    const _dialog$data$callback3 = dialog.data.callbacks,
          callbacks = _dialog$data$callback3 === void 0 ? {} : _dialog$data$callback3;
    const _callbacks$handleUpda3 = callbacks.handleUpdateEditorBackgroundPhotos,
          handleUpdateEditorBackgroundPhotos = _callbacks$handleUpda3 === void 0 ? () => {} : _callbacks$handleUpda3;
    const _this$state$selectedP = this.state.selectedPattern,
          selectedPattern = _this$state$selectedP === void 0 ? {} : _this$state$selectedP;

    if (!selectedPattern.id) {
      dispatch((0, _actions.ShowError)({
        message: 'No Pattern Selected.'
      }));
      return;
    }

    const id = selectedPattern.id,
          file_path = selectedPattern.file_path,
          thumbnail_path = selectedPattern.thumbnail_path,
          default_scale = selectedPattern.default_scale;

    const _file_path$split = file_path.split('.'),
          file_type = _file_path$split[1];

    const existing = backgroundPhotos.find(e => e.key === selectedPattern.id);

    if (!existing) {
      this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
        recentPatterns: [selectedPattern, ...prev.recentPatterns]
      }), () => {
        handleUpdateEditorBackgroundPhotos([{
          key: id,
          name: id,
          texture: `${window.config.upload}/file?path=${file_path}`,
          thumb: `${window.config.upload}/file?path=${thumbnail_path}`,
          type: file_type,
          is_pattern: true,
          default_scale
        }, ...backgroundPhotos]); // CLOSE DIALOG

        this.handleHide();
      });
    }
  }

};
var _default = EventHandlers;
exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Confirm = _interopRequireDefault(require("@common/components/DialogDefaults/ActionButtons/Confirm"));

exports.default = _Confirm.default;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
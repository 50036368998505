"use strict";

exports.__esModule = true;
exports.default = void 0;

var _tools = require("@23point5/core/controls/utils/tools");

var _tools2 = require("@common/utils/tools");

var _actions = require("@pages/DesignEditor/actions");

var _actions2 = require("@App/actions");

var _validator = _interopRequireDefault(require("validator"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _context = require("@pages/DesignEditor/context");

var _Validations = _interopRequireDefault(require("./Validations"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleFieldValuesChange(values = {}, new_errors = {}) {
    this.setState(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      errors: new_errors,
      fieldValues: _objectSpread(_objectSpread({}, prevState.fieldValues), values)
    }));
  },

  handleInputchange(val, evt) {
    const key = evt.target ? evt.target.id : evt;
    let value = val.target ? val.target.id : val;
    const _this$state = this.state,
          _this$state$fieldValu = _this$state.fieldValues,
          fieldValues = _this$state$fieldValu === void 0 ? {} : _this$state$fieldValu,
          _this$state$errors = _this$state.errors,
          errors = _this$state$errors === void 0 ? {} : _this$state$errors;
    const fields = fieldValues;
    const extras = {
      pricing: _objectSpread({}, fields.pricing)
    };

    if (key === 'availability') {
      value = value ? 'Limited Edition' : 'For Everyone';
    }

    value = key === 'markup' ? (0, _tools2.formatPrice)(val, fields.pricing.markup) : value;

    if (key === 'markup') {
      extras.pricing.markup = value;
      extras.pricing.retail = fields.base_price ? Number(fields.base_price) + Number(value) : 0;
    }

    if (key === 'design_tags' && Array.isArray(value) && value.length === 6) {
      return;
    }

    if (key === 'stock' && Number(value) >= 1000000) {
      return;
    }

    const new_errors = _objectSpread({}, errors);

    delete new_errors[key]; // const updateFn = (prevState) => ({
    //   ...prevState,
    //   ...(key !== 'markup' && { [key]: value }),
    //   ...extras,
    // })

    this.setState(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      errors: new_errors,
      fieldValues: _objectSpread(_objectSpread(_objectSpread({}, prevState.fieldValues), key !== 'markup' && {
        [key]: value
      }), extras)
    }));
  },

  handleTagsInputChange(val, evt) {
    const id = evt.target ? evt.target.id : evt;
    const dispatch = this.props.dispatch;

    if (_validator.default.isEmpty(val)) {
      (0, _tools.debouncer)(() => dispatch((0, _actions.CreateGetTags)()), 300);
      return;
    }

    (0, _tools.debouncer)(() => dispatch((0, _actions.CreateSearchTags)({
      search: val
    })), 300);
  },

  handlePublish() {
    const _this$props = this.props,
          dialog = _this$props.dialog,
          dispatch = _this$props.dispatch,
          history = _this$props.history;
    const _dialog$data = dialog.data,
          data = _dialog$data === void 0 ? {} : _dialog$data;
    const _data$callbacks = data.callbacks,
          callbacks = _data$callbacks === void 0 ? {} : _data$callbacks,
          _data$locationSearchP = data.locationSearchParams,
          locationSearchParams = _data$locationSearchP === void 0 ? {} : _data$locationSearchP;
    const handleUpdateThreedData = callbacks.handleUpdateThreedData;
    const _this$state2 = this.state,
          fieldValues = _this$state2.fieldValues,
          _this$state2$selected = _this$state2.selectedExternalStores,
          selectedExternalStores = _this$state2$selected === void 0 ? [] : _this$state2$selected;

    const productFields = _objectSpread(_objectSpread({}, fieldValues), {}, {
      stock: fieldValues.stock && fieldValues.stock.toString(),
      markup: fieldValues.pricing.markup.toString()
    });

    const required_fields = ['product_name'];

    if (productFields.availability === 'Limited Edition') {
      required_fields.push('stock');
    }

    const custom_label = {
      stock: 'Quantity',
      product_category_id: 'Product Category',
      design_tags: 'Design Tag(s)'
    };

    const custom_validation = _objectSpread({}, productFields.availability === 'Limited Edition' ? {
      stock: value => {
        if (Number(value || 0) < 1) return 'Quantity is required.';
      }
    } : {});

    const _Validate = (0, _Validations.default)(productFields, required_fields, custom_label, custom_validation),
          isValid = _Validate.isValid,
          fieldErrors = _Validate.fieldErrors;

    if (!isValid) {
      this.setState(prev => ({
        errors: _objectSpread(_objectSpread({}, prev.errors), fieldErrors)
      }));
      return;
    }

    if (Object.values(this.state.errors).filter(Boolean).length) return;
    const stock_field = fieldValues.availability !== 'Limited Edition' ? {
      stock: 0
    } : {};
    const publish_fields = !fieldValues.is_publish_to_marketplace || locationSearchParams.store_session_id ? {
      status: 'Approved',
      visibility: 'Private'
    } : {
      status: 'Approved',
      visibility: 'Public'
    };
    const selectedExternalStoresValue = locationSearchParams.store_session_id ? [{
      artist_session_id: locationSearchParams.store_session_id,
      name: locationSearchParams.store_name
    }] : selectedExternalStores;

    const actionPayload = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (0, _omit.default)(fieldValues, ['is_publish_to_marketplace'])), stock_field), publish_fields), {}, {
      is_draft: false,
      selectedExternalStores: selectedExternalStoresValue,
      callback: (result_data = {}, isShowMultiplePublishFailedPrompt = false) => {
        handleUpdateThreedData(result_data);

        if (locationSearchParams.store_session_id) {
          history.push(`/shopify-store-details/${locationSearchParams.store_session_id}`);
        } else {
          if (result_data.visibility === 'Public') {
            history.push('/artist-design?tab=Approved');
          } else {
            history.push('/artist-design?tab=Private');
          }
        } // history.push('/artist-design?tab=Pending_Approval')


        dispatch((0, _actions2.HideDialog)());

        if (isShowMultiplePublishFailedPrompt) {
          setTimeout(() => {
            dispatch((0, _actions2.ShowDialog)({
              uniqueEntity: 'multiple-store-publish-failed',
              data: {},
              context: _context.dialogContext.multipleStorePublishFailed
            }));
          }, 500);
        }
      }
    });

    dispatch((0, _actions.CreatePublishProduct)(actionPayload));
  },

  handleSetSelectedExternalStores(selectedExternalStores = []) {
    this.setState({
      selectedExternalStores
    });
  }

};
var _default = EventHandlers;
exports.default = _default;
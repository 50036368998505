"use strict";

exports.__esModule = true;
exports.default = void 0;

var _v = _interopRequireDefault(require("uuid/v4"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _actions = require("@pages/DesignEditor/actions");

var _tools = require("@23point5/core/controls/utils/tools");

var _actions2 = require("@App/actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const EventHandlers = {
  handleTabChange(nextTab) {
    const currentTab = this.state.currentTab;
    const _this$props = this.props,
          _this$props$dialog = _this$props.dialog,
          dialog = _this$props$dialog === void 0 ? {} : _this$props$dialog,
          dispatch = _this$props.dispatch;
    const _dialog$data = dialog.data,
          data = _dialog$data === void 0 ? {} : _dialog$data;
    const _data$callbacks = data.callbacks,
          callbacks = _data$callbacks === void 0 ? {} : _data$callbacks;
    const _callbacks$handleSetR = callbacks.handleSetRecentElementDialogTab,
          handleSetRecentElementDialogTab = _callbacks$handleSetR === void 0 ? () => {} : _callbacks$handleSetR;
    this.setState({
      currentTab: nextTab,
      patternsSearchValue: '',
      stockImagesSearchValue: '',
      dynamicImagesSearchValue: '',
      librarySearchValue: '',
      subCategoryFilter: []
    }, () => {
      handleSetRecentElementDialogTab(nextTab);
    });
  },

  handleResetSearch() {
    this.setState({
      patternsSearchValue: '',
      stockImagesSearchValue: '',
      dynamicImagesSearchValue: '',
      librarySearchValue: '',
      subCategoryFilter: []
    });
  },

  handleRemove(key) {
    const _this$props$dialog2 = this.props.dialog,
          dialog = _this$props$dialog2 === void 0 ? {} : _this$props$dialog2;
    const _this$state$elementPh = this.state.elementPhotos,
          elementPhotos = _this$state$elementPh === void 0 ? [] : _this$state$elementPh;
    const _dialog$data$callback = dialog.data.callbacks,
          callbacks = _dialog$data$callback === void 0 ? {} : _dialog$data$callback;
    const _callbacks$handleUpda = callbacks.handleUpdateEditorElemementPhotos,
          handleUpdateEditorElemementPhotos = _callbacks$handleUpda === void 0 ? () => {} : _callbacks$handleUpda;
    const newelementPhotos = elementPhotos.filter(e => e.key !== key);
    this.setState({
      elementPhotos: newelementPhotos
    }, () => {
      handleUpdateEditorElemementPhotos(newelementPhotos);
    });
  },

  handleOnUploadSuccess(uploadedPhotos = []) {
    if (!uploadedPhotos.length) return;
    const _this$props$dialog3 = this.props.dialog,
          dialog = _this$props$dialog3 === void 0 ? {} : _this$props$dialog3;
    const _this$state$elementPh2 = this.state.elementPhotos,
          elementPhotos = _this$state$elementPh2 === void 0 ? [] : _this$state$elementPh2;
    const _dialog$data$callback2 = dialog.data.callbacks,
          callbacks = _dialog$data$callback2 === void 0 ? {} : _dialog$data$callback2;
    const _callbacks$handleUpda2 = callbacks.handleUpdateEditorElemementPhotos,
          handleUpdateEditorElemementPhotos = _callbacks$handleUpda2 === void 0 ? () => {} : _callbacks$handleUpda2,
          _callbacks$handleImag = callbacks.handleImageElementUpload,
          handleImageElementUpload = _callbacks$handleImag === void 0 ? () => {} : _callbacks$handleImag,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt;
    const newelementPhotos = [...uploadedPhotos, ...elementPhotos];
    this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      elementPhotos: newelementPhotos
    }), () => {
      // handleUpdateEditorElemementPhotos(newelementPhotos), handleImageElementUpload(uploadedPhotos)
      this.handleHide();

      if (!handleShowPartsDialogPrompt) {
        handleUpdateEditorElemementPhotos(newelementPhotos), handleImageElementUpload(uploadedPhotos);
      } else {
        handleShowPartsDialogPrompt(() => {
          handleUpdateEditorElemementPhotos(newelementPhotos), handleImageElementUpload(uploadedPhotos);
        });
      }
    });
  },

  handleUploadImageSuccess(uploadedImage) {
    if (!uploadedImage) return;
    const _this$props2 = this.props,
          _this$props2$dialog = _this$props2.dialog,
          dialog = _this$props2$dialog === void 0 ? {} : _this$props2$dialog,
          dispatch = _this$props2.dispatch,
          session = _this$props2.session;
    const _dialog$data$callback3 = dialog.data.callbacks,
          callbacks = _dialog$data$callback3 === void 0 ? {} : _dialog$data$callback3;
    const _callbacks$handleUpda3 = callbacks.handleUpdateEditorElemementPhotos,
          handleUpdateEditorElemementPhotos = _callbacks$handleUpda3 === void 0 ? () => {} : _callbacks$handleUpda3,
          _callbacks$handleImag2 = callbacks.handleImageElementUpload,
          handleImageElementUpload = _callbacks$handleImag2 === void 0 ? () => {} : _callbacks$handleImag2,
          _callbacks$handleShow = callbacks.handleShowPartsDialogPrompt,
          handleShowPartsDialogPrompt = _callbacks$handleShow === void 0 ? () => {} : _callbacks$handleShow;
    const fileHash = uploadedImage.file_hash;
    delete uploadedImage.file_hash;
    const artistLibraryRecord = {
      name: uploadedImage.name,
      artist_id: session.id,
      status: 'Active',
      file: uploadedImage,
      file_hash: fileHash
    };
    dispatch((0, _actions.ProcessArtistLibraryNewData)({
      data: [artistLibraryRecord],
      postDispatches: [(resultData = []) => {
        const success_files = resultData.map(e => e.file);
        this.handleHide();

        if (!handleShowPartsDialogPrompt) {
          handleUpdateEditorElemementPhotos(success_files);
          handleImageElementUpload(success_files);
        } else {
          handleShowPartsDialogPrompt(() => {
            handleUpdateEditorElemementPhotos(success_files);
            handleImageElementUpload(success_files);
          });
        }
      }]
    }));
  },

  handleOnUploadError(errorArgs = {}) {
    const dispatch = this.props.dispatch;

    const _errorArgs$xhrOrXdr = errorArgs.xhrOrXdr,
          xhrOrXdr = _errorArgs$xhrOrXdr === void 0 ? {} : _errorArgs$xhrOrXdr,
          rest = _objectWithoutPropertiesLoose(errorArgs, ["xhrOrXdr"]);

    const error = JSON.parse(xhrOrXdr.responseText);
    const errorMessage = {
      'Invalid SVG.': 'Uploaded Image is currently not supported.',
      'File size is too big.': 'File size is too big.'
    }[error.message] || 'Something went wrong, please try again.';
    const failedImage = rest.uploader.methods.getFile(rest.id);
    const failedImageUrl = failedImage ? URL.createObjectURL(failedImage) : '';
    dispatch((0, _actions2.ShowError)({
      message: errorMessage
    })); // this.setState((prev) => ({
    //   unsupportedImages: [
    //     ...prev.unsupportedImages,
    //     {
    //       ...rest,
    //       errorMessage,
    //       thumb: failedImageUrl,
    //       handleRemoveFailedImage: () =>
    //         this.setState((prevState) => ({
    //           unsupportedImages: [...prevState.unsupportedImages].filter((e) => e.id !== rest.id)
    //         }))
    //     }
    //   ]
    // }))
  },

  handleCloseDialog() {
    this.handleHide();
  },

  handleSetIsUploading(isUploading) {
    this.setState({
      isUploading
    });
  },

  handlePatternSelect({
    selectedPattern,
    isFromRecent = false
  }) {
    const _this$props$dialog4 = this.props.dialog,
          dialog = _this$props$dialog4 === void 0 ? {} : _this$props$dialog4;
    const _dialog$data$callback4 = dialog.data.callbacks,
          callbacks = _dialog$data$callback4 === void 0 ? {} : _dialog$data$callback4;
    const handleSetSelectedElement = callbacks.handleSetSelectedElement,
          handleAddElement = callbacks.handleAddElement,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt;
    this.setState({
      selectedPattern: _objectSpread(_objectSpread({}, selectedPattern), {}, {
        isFromRecent
      })
    }, () => {
      const file_path = selectedPattern.file_path,
            thumbnail_path = selectedPattern.thumbnail_path,
            id = selectedPattern.id,
            default_scale = selectedPattern.default_scale,
            _selectedPattern$patt = selectedPattern.pattern_colors,
            pattern_colors = _selectedPattern$patt === void 0 ? {} : _selectedPattern$patt,
            _selectedPattern$filt = selectedPattern.filtered_colors,
            filtered_colors = _selectedPattern$filt === void 0 ? [] : _selectedPattern$filt;
      this.handleHide();

      if (!handleShowPartsDialogPrompt) {
        handleSetSelectedElement(selectedPattern);
        handleAddElement({
          id,
          key: id,
          name: id,
          originalImage: `${window.config.upload}/file?path=${file_path}`,
          thumbnail: `${window.config.upload}/file?path=${thumbnail_path}`,
          type: 'svg',
          is_pattern: true,
          default_scale,
          pattern_colors,
          filtered_colors
        });
      } else {
        handleShowPartsDialogPrompt(() => {
          handleSetSelectedElement(selectedPattern);
          handleAddElement({
            id,
            key: id,
            name: id,
            originalImage: `${window.config.upload}/file?path=${file_path}`,
            thumbnail: `${window.config.upload}/file?path=${thumbnail_path}`,
            type: 'svg',
            is_pattern: true,
            default_scale,
            pattern_colors,
            filtered_colors
          });
        }, {
          selectAllParts: true
        });
      }
    });
  },

  handleStockImageSelect(selectedPhoto) {
    const _this$props$dialog5 = this.props.dialog,
          dialog = _this$props$dialog5 === void 0 ? {} : _this$props$dialog5;
    const _dialog$data$callback5 = dialog.data.callbacks,
          callbacks = _dialog$data$callback5 === void 0 ? {} : _dialog$data$callback5;
    const handleSetSelectedElement = callbacks.handleSetSelectedElement,
          handleImageElementUpload = callbacks.handleImageElementUpload,
          handleUpdateEditorElemementPhotos = callbacks.handleUpdateEditorElemementPhotos,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt;
    const _selectedPhoto$file_p = selectedPhoto.file_path,
          file_path = _selectedPhoto$file_p === void 0 ? '' : _selectedPhoto$file_p,
          thumbnail_path = selectedPhoto.thumbnail_path,
          id = selectedPhoto.id,
          _selectedPhoto$defaul = selectedPhoto.default_scale,
          default_scale = _selectedPhoto$defaul === void 0 ? '5' : _selectedPhoto$defaul,
          name = selectedPhoto.name,
          _selectedPhoto$raw_fi = selectedPhoto.raw_file_path,
          raw_file_path = _selectedPhoto$raw_fi === void 0 ? '' : _selectedPhoto$raw_fi;

    const _file_path$split$reve = file_path.split('.').reverse(),
          _file_path$split$reve2 = _file_path$split$reve[0],
          file_type = _file_path$split$reve2 === void 0 ? '' : _file_path$split$reve2; // handleSetSelectedElement({
    //   ...selectedPhoto,
    //   file_path,
    //   thumbnail_path,
    //   id,
    //   default_scale
    // })
    // handleImageElementUpload([
    //   {
    //     ...selectedPhoto,
    //     id,
    //     key: id,
    //     name,
    //     thumb: thumbnail_path,
    //     texture: file_path,
    //     originalTexture: file_path,
    //     thumbnail: thumbnail_path,
    //     type: file_type,
    //     raw: file_path,
    //     default_scale
    //   }
    // ])
    // handleUpdateEditorElemementPhotos([
    //   {
    //     ...selectedPhoto,
    //     key: id,
    //     name,
    //     texture: file_path,
    //     originalTexture: file_path,
    //     thumb: thumbnail_path,
    //     raw: file_path,
    //     type: file_type
    //   }
    // ])


    this.handleHide();

    if (!handleShowPartsDialogPrompt) {
      handleSetSelectedElement(_objectSpread(_objectSpread({}, selectedPhoto), {}, {
        file_path,
        thumbnail_path,
        id,
        default_scale
      }));
      handleImageElementUpload([_objectSpread(_objectSpread({}, selectedPhoto), {}, {
        id,
        key: id,
        name,
        thumb: thumbnail_path,
        texture: file_path,
        originalTexture: file_path,
        thumbnail: thumbnail_path,
        type: file_type,
        raw: raw_file_path || file_path,
        default_scale
      })]);
      handleUpdateEditorElemementPhotos([_objectSpread(_objectSpread({}, selectedPhoto), {}, {
        key: id,
        name,
        texture: file_path,
        originalTexture: file_path,
        thumb: thumbnail_path,
        raw: raw_file_path || file_path,
        type: file_type
      })]);
    } else {
      handleShowPartsDialogPrompt(() => {
        handleSetSelectedElement(_objectSpread(_objectSpread({}, selectedPhoto), {}, {
          file_path,
          thumbnail_path,
          id,
          default_scale
        }));
        handleImageElementUpload([_objectSpread(_objectSpread({}, selectedPhoto), {}, {
          id,
          key: id,
          name,
          thumb: thumbnail_path,
          texture: file_path,
          originalTexture: file_path,
          thumbnail: thumbnail_path,
          type: file_type,
          raw: raw_file_path || file_path,
          default_scale
        })]);
        handleUpdateEditorElemementPhotos([_objectSpread(_objectSpread({}, selectedPhoto), {}, {
          key: id,
          name,
          texture: file_path,
          originalTexture: file_path,
          thumb: thumbnail_path,
          raw: raw_file_path || file_path,
          type: file_type
        })]);
      }, {
        is_seamless: selectedPhoto.is_seamless,
        stock_image: true
      });
    }
  },

  handleDynamicImageSelect(selectedPhoto) {
    const _this$props$dialog6 = this.props.dialog,
          dialog = _this$props$dialog6 === void 0 ? {} : _this$props$dialog6;
    const _dialog$data$callback6 = dialog.data.callbacks,
          callbacks = _dialog$data$callback6 === void 0 ? {} : _dialog$data$callback6;
    const handleSetSelectedElement = callbacks.handleSetSelectedElement,
          handleImageElementUpload = callbacks.handleImageElementUpload,
          handleUpdateEditorElemementPhotos = callbacks.handleUpdateEditorElemementPhotos,
          handleAddElement = callbacks.handleAddElement,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt;
    const _selectedPhoto$file_p2 = selectedPhoto.file_path,
          file_path = _selectedPhoto$file_p2 === void 0 ? '' : _selectedPhoto$file_p2,
          thumbnail_path = selectedPhoto.thumbnail_path,
          id = selectedPhoto.id,
          _selectedPhoto$defaul2 = selectedPhoto.default_scale,
          default_scale = _selectedPhoto$defaul2 === void 0 ? '5' : _selectedPhoto$defaul2,
          name = selectedPhoto.name;

    const _file_path$split$reve3 = file_path.split('.').reverse(),
          _file_path$split$reve4 = _file_path$split$reve3[0],
          file_type = _file_path$split$reve4 === void 0 ? '' : _file_path$split$reve4;

    this.handleHide();

    if (!handleShowPartsDialogPrompt) {
      handleSetSelectedElement(selectedPhoto);
      handleAddElement({
        id,
        key: id,
        name: id,
        originalImage: `${window.config.upload}/file?path=${file_path}`,
        thumbnail: `${window.config.upload}/file?path=${thumbnail_path}`,
        type: file_type,
        is_dynamic_image: !!file_path.includes('dynamic-image'),
        is_pattern: true,
        default_scale
      });
    } else {
      handleShowPartsDialogPrompt(() => {
        handleSetSelectedElement(selectedPhoto);
        handleAddElement({
          id,
          key: id,
          name: id,
          originalImage: `${window.config.upload}/file?path=${file_path}`,
          thumbnail: `${window.config.upload}/file?path=${thumbnail_path}`,
          type: file_type,
          is_dynamic_image: !!file_path.includes('dynamic-image'),
          is_pattern: true,
          default_scale
        });
      });
    }
  },

  handleTemplateSelect(template = {}) {
    const _this$props$dialog7 = this.props.dialog,
          dialog = _this$props$dialog7 === void 0 ? {} : _this$props$dialog7;
    const _dialog$data$callback7 = dialog.data.callbacks,
          callbacks = _dialog$data$callback7 === void 0 ? {} : _dialog$data$callback7;
    const handleApplyTemplate = callbacks.handleApplyTemplate;
    handleApplyTemplate(template);
    this.handleHide();
  },

  handleSelectElement(element) {
    const _this$props$dialog8 = this.props.dialog,
          dialog = _this$props$dialog8 === void 0 ? {} : _this$props$dialog8;
    const _dialog$data$callback8 = dialog.data.callbacks,
          callbacks = _dialog$data$callback8 === void 0 ? {} : _dialog$data$callback8;
    const handleAddElement = callbacks.handleAddElement,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt; // handleAddElement(element)

    this.handleHide();

    if (!handleShowPartsDialogPrompt) {
      handleAddElement(element);
    } else {
      handleShowPartsDialogPrompt(() => {
        handleAddElement(element);
      }, {
        isShape: true
      });
    }
  },

  handleAddStockImageSearchTag(value) {
    this.setState(prev => ({
      stockImagesSearchValues: [...prev.stockImagesSearchValues, {
        text: value,
        id: (0, _v.default)()
      }]
    }));
  },

  handleAddDynamicImageSearchTag(value) {
    this.setState(prev => ({
      dynamicImagesSearchValues: [...prev.dynamicImagesSearchValues, {
        text: value,
        id: (0, _v.default)()
      }]
    }));
  },

  handleRemoveStockImageSearchTag(value) {
    this.setState(prev => ({
      stockImagesSearchValues: prev.stockImagesSearchValues.filter(stockImage => stockImage.id !== value.id)
    }));
  },

  handleClearStockImageSearchTags() {
    this.setState({
      stockImagesSearchValues: []
    });
  },

  handleSelectCategory(params) {
    const _params$selectedProdu = params.selectedProductCategory,
          selectedProductCategory = _params$selectedProdu === void 0 ? {} : _params$selectedProdu,
          searchValue = params.searchValue,
          search_tags = params.search_tags;
    const _this$state$selectedC = this.state.selectedCategories,
          selectedCategories = _this$state$selectedC === void 0 ? [] : _this$state$selectedC;
    const dispatch = this.props.dispatch;
    const currentTab = this.state.currentTab;
    let stock_images_filters = [];

    if (currentTab === 'stock_images_seamless') {
      stock_images_filters = [{
        key: 'is_seamless',
        value: [true]
      }];
    }

    if (currentTab === 'stock_images_single_part') {
      stock_images_filters = [{
        key: 'is_seamless',
        value: [false]
      }];
    }

    if (!!selectedProductCategory && !!selectedCategories.find(e => e.id === selectedProductCategory.id)) {
      const filteredSelectedCategories = selectedCategories.filter(e => e.id !== selectedProductCategory.id);
      this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
        selectedCategories: filteredSelectedCategories
      }), () => {
        dispatch((0, _actions.CreateGetStockPhotos)({
          search_tags,
          searchValue,
          search_categories: filteredSelectedCategories.filter(Boolean).map(category => category.id),
          filters: stock_images_filters
        }));
      });
      return;
    }

    if (!selectedProductCategory) {
      this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
        selectedCategories: []
      }), () => {
        dispatch((0, _actions.CreateGetStockPhotos)({
          search_categories: [],
          searchValue,
          search_tags,
          filters: stock_images_filters
        }));
      });
      return;
    }

    const newSelectedCategories = [selectedProductCategory];
    this.setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      selectedCategories: newSelectedCategories
    }), () => {
      dispatch((0, _actions.CreateGetStockPhotos)({
        search_tags,
        searchValue,
        search_categories: newSelectedCategories.filter(Boolean).map(category => category.id),
        filters: stock_images_filters
      }));
    });
  },

  handleRemoveDynamicImageSearchTag(value) {
    this.setState(prev => ({
      dynamicImagesSearchValues: prev.dynamicImagesSearchValues.filter(stockImage => stockImage.id !== value.id)
    }));
  },

  handleStockImageSearchValueChange(value) {
    this.setState({
      stockImagesSearchValue: value
    });
  },

  handleSubCategoryFilterChange(value) {
    this.setState({
      subCategoryFilter: value
    });
  },

  handleRemoveImageFromLibrary(records = [], postDispatches = []) {
    const _this$props3 = this.props,
          _this$props3$dialog = _this$props3.dialog,
          dialog = _this$props3$dialog === void 0 ? {} : _this$props3$dialog,
          dispatch = _this$props3.dispatch,
          session = _this$props3.session;
    const data = records[0];
    const isFolder = data.entity === 'folder';
    const isInFolder = data === null || data === void 0 ? void 0 : data.in_folder;
    dispatch((0, _actions.DeleteLibraryImages)({
      records,
      postDispatches: [() => {
        (0, _tools.debouncer)(() => {
          dispatch((0, _actions.CreateGetLibraryImages)({
            searchValue: '',
            preventLoading: true
          }));
          if (isFolder) dispatch((0, _actions.CreateGetFolders)({
            searchValue: '',
            preventLoading: true
          }));
          if (isInFolder) dispatch((0, _actions.CreateGetFolderImages)({
            searchValue: '',
            preventLoading: true,
            entity_id: data.parent_id
          }));
        }, 900);
      }, ...postDispatches]
    }));
  },

  handleRemoveImageFromFolder(record = {}, postDispatches = []) {
    const _this$props4 = this.props,
          _this$props4$dialog = _this$props4.dialog,
          dialog = _this$props4$dialog === void 0 ? {} : _this$props4$dialog,
          dispatch = _this$props4.dispatch,
          session = _this$props4.session;
    dispatch((0, _actions.RemoveImageFromFolder)({
      record,
      postDispatches: [() => {
        (0, _tools.debouncer)(() => {
          dispatch((0, _actions.CreateGetFolderImages)({
            searchValue: '',
            preventLoading: true,
            entity_id: record.folder_id
          }));
        }, 900);
      }, ...postDispatches]
    }));
  },

  handleRenameFile(data, postDispatches = []) {
    const dispatch = this.props.dispatch;
    const isFolder = data.entity === 'folder';
    const isInFolder = data === null || data === void 0 ? void 0 : data.in_folder;
    dispatch((0, _actions.RenameFile)({
      data,
      postDispatches: [() => {
        (0, _tools.debouncer)(() => {
          dispatch((0, _actions.CreateGetLibraryImages)({
            searchValue: '',
            preventLoading: true
          }));
          if (isFolder) dispatch((0, _actions.CreateGetFolders)({
            searchValue: '',
            preventLoading: true
          }));
          if (isInFolder) dispatch((0, _actions.CreateGetFolderImages)({
            searchValue: '',
            preventLoading: true,
            entity_id: data.parent_id
          }));
        }, 900);
      }, ...postDispatches]
    }));
  },

  handleMoveToFolder(data, postDispatches = []) {
    const dispatch = this.props.dispatch;
    const isInFolder = data === null || data === void 0 ? void 0 : data.in_folder;
    dispatch((0, _actions.MoveToFolder)({
      data,
      postDispatches: [() => {
        (0, _tools.debouncer)(() => {
          dispatch((0, _actions.CreateGetLibraryImages)({
            searchValue: '',
            preventLoading: true
          }));

          if (isInFolder || data.parent_folder_id) {
            dispatch((0, _actions.CreateGetFolders)({
              searchValue: '',
              preventLoading: true,
              parent_id: data.parent_folder_id
            }));
            dispatch((0, _actions.CreateGetFolderImages)({
              searchValue: '',
              preventLoading: true,
              entity_id: data.parent_folder_id
            }));
          } else {
            dispatch((0, _actions.CreateGetFolders)({
              searchValue: '',
              preventLoading: true,
              parent_id: data.parent_folder_id
            }));
            dispatch((0, _actions.CreateGetLibraryImages)({
              searchValue: '',
              preventLoading: true
            }));
          }
        }, 900);
      }, ...postDispatches]
    }));
  },

  handleCreateNewFolder(data, postDispatches = []) {
    const dispatch = this.props.dispatch;
    dispatch((0, _actions.CreateNewFolder)({
      data,
      postDispatches: [results => {
        (0, _tools.debouncer)(() => {
          // dispatch(CreateGetLibraryImages({ searchValue: '', preventLoading: true }))
          dispatch((0, _actions.CreateGetFolders)({
            searchValue: '',
            preventLoading: true,
            parent_id: data.parent_id
          })); // if (isInFolder) dispatch(CreateGetFolderImages({ searchValue: '', preventLoading: true, entity_id: data.parent_id }))
        }, 900);
        postDispatches.map(data => data(results));
      }]
    }));
  },

  handleActiveFolder(folderId) {
    this.setState({
      folderId: folderId
    });
  },

  // handleLibraryImageUploadSuccess(uploadedPhotos = []) {
  //   if (!uploadedPhotos.length) return
  //   const { dialog = {}, dispatch, session } = this.props
  //   const { elementPhotos = [] } = this.state
  //   // const { callbacks = {} } = dialog.data
  //   const artistLibraryRecords = uploadedPhotos.map((file) => {
  //     return {
  //       name: file.name,
  //       artist_id: session.id,
  //       status: 'Active',
  //       file: file
  //     }
  //   })
  //   dispatch(
  //     ProcessArtistLibraryNewData({
  //       data: artistLibraryRecords,
  //       postDispatches: [
  //         ({ records: resultRecords = [], recordFiles = [] }) => {
  //           this.setState(
  //             (prev) => ({
  //               ...prev,
  //               tempLibraryRecords: [...prev.tempLibraryRecords, ...resultRecords]
  //             }),
  //             () => {
  //               dispatch(CreateGetLibraryImages({ searchValue: '', preventLoading: true }))
  //             }
  //           )
  //         }
  //       ]
  //     })
  //   )
  // },
  handleLibraryImageUploaded(uploadedImage) {
    if (!uploadedImage) return;
    const _this$props5 = this.props,
          _this$props5$dialog = _this$props5.dialog,
          dialog = _this$props5$dialog === void 0 ? {} : _this$props5$dialog,
          dispatch = _this$props5.dispatch,
          session = _this$props5.session; // const { callbacks = {} } = dialog.data

    const _ref = dialog.data || {},
          _ref$library_images = _ref.library_images,
          library_images = _ref$library_images === void 0 ? [] : _ref$library_images;

    const folderId = this.state.folderId;
    const fileHash = uploadedImage.file_hash;
    delete uploadedImage.file_hash;

    const artistLibraryRecord = _objectSpread({
      name: uploadedImage.name,
      artist_id: session.id,
      status: 'Active',
      file: uploadedImage,
      file_hash: fileHash
    }, folderId && {
      in_folder: true
    });

    dispatch((0, _actions.ProcessArtistLibraryNewData)({
      data: [artistLibraryRecord],
      postDispatches: [(resultData = []) => {
        // dispatch(CreateGetLibraryImages({ searchValue: '', preventLoading: true }))
        if (folderId) {
          // dispatch(CreateGetFolderImages({ searchValue: '', preventLoading: true, entity_id: folderId }))
          dispatch((0, _actions2.SetDialogData)({
            folder_images: resultData
          }));
        } else {
          dispatch((0, _actions2.SetDialogData)({
            library_images: resultData
          }));
        }
      }],
      folderId
    }));
  },

  handleLibraryImageUploadedAndApply(uploadedImage) {
    if (!uploadedImage) return;
    const _this$props6 = this.props,
          _this$props6$dialog = _this$props6.dialog,
          dialog = _this$props6$dialog === void 0 ? {} : _this$props6$dialog,
          dispatch = _this$props6.dispatch,
          session = _this$props6.session;
    const _dialog$data$callback9 = dialog.data.callbacks,
          callbacks = _dialog$data$callback9 === void 0 ? {} : _dialog$data$callback9;
    const handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt,
          _callbacks$handleUpda4 = callbacks.handleUpdateEditorElemementPhotos,
          handleUpdateEditorElemementPhotos = _callbacks$handleUpda4 === void 0 ? () => {} : _callbacks$handleUpda4,
          _callbacks$handleImag3 = callbacks.handleImageElementUpload,
          handleImageElementUpload = _callbacks$handleImag3 === void 0 ? () => {} : _callbacks$handleImag3;

    const _ref2 = dialog.data || {},
          _ref2$library_images = _ref2.library_images,
          library_images = _ref2$library_images === void 0 ? [] : _ref2$library_images;

    const artistLibraryRecord = {
      name: uploadedImage.name,
      artist_id: session.id,
      status: 'Active',
      file: uploadedImage
    };
    dispatch((0, _actions.ProcessArtistLibraryUploadApply)({
      data: [artistLibraryRecord],
      postDispatches: [(resultData = []) => {
        dispatch((0, _actions2.SetDialogData)({
          library_images: [...resultData, ...library_images]
        }));
        const success_files = resultData.map(e => e.file);
        this.handleHide();

        if (!handleShowPartsDialogPrompt) {
          handleUpdateEditorElemementPhotos(success_files);
          handleImageElementUpload(success_files);
        } else {
          handleShowPartsDialogPrompt(() => {
            handleUpdateEditorElemementPhotos(success_files);
            handleImageElementUpload(success_files);
          });
        }
      }]
    }));
  },

  handleLibraryImageUploadError(errorArgs = {}) {
    const dispatch = this.props.dispatch;

    const _errorArgs$xhrOrXdr2 = errorArgs.xhrOrXdr,
          xhrOrXdr = _errorArgs$xhrOrXdr2 === void 0 ? {} : _errorArgs$xhrOrXdr2,
          rest = _objectWithoutPropertiesLoose(errorArgs, ["xhrOrXdr"]);

    const error = JSON.parse(xhrOrXdr.responseText); // console.log(error)

    const errorMessage = {
      'Invalid SVG.': 'Uploaded SVG Image is currently not supported.',
      'File size is too big.': 'File size is too big.'
    }[error.message] || 'Something went wrong, please try again.';
    const failedImage = rest.uploader.methods.getFile(rest.id);
    const failedImageUrl = failedImage ? URL.createObjectURL(failedImage) : '';
    this.setState(prev => ({
      libraryUnsupportedImages: [...prev.libraryUnsupportedImages, {
        data: _objectSpread(_objectSpread({}, rest), {}, {
          errorMessage,
          file: {
            thumb: failedImageUrl
          }
        }),
        handlers: {
          handleRemoveFailedImage: () => this.setState(prevState => ({
            libraryUnsupportedImages: [...prevState.libraryUnsupportedImages].filter(e => e.id !== rest.id)
          }))
        }
      }]
    }));
  },

  handleClearLibraryUnsupportedImages() {
    this.setState({
      libraryUnsupportedImages: []
    });
  },

  handleSetPatternsSearchValue(value) {
    this.setState({
      patternsSearchValue: value
    });
  },

  handleLibrarySearchValue(value) {
    this.setState({
      librarySearchValue: value
    });
  },

  handleDynamicImageSearchValue(value) {
    this.setState({
      dynamicImagesSearchValue: value
    });
  },

  handleSelectFromLibrary(libraryImages = []) {
    if (!libraryImages.length) return;
    const _this$props$dialog9 = this.props.dialog,
          dialog = _this$props$dialog9 === void 0 ? {} : _this$props$dialog9;
    const _dialog$data$callback10 = dialog.data.callbacks,
          callbacks = _dialog$data$callback10 === void 0 ? {} : _dialog$data$callback10;
    const _callbacks$handleUpda5 = callbacks.handleUpdateEditorElemementPhotos,
          handleUpdateEditorElemementPhotos = _callbacks$handleUpda5 === void 0 ? () => {} : _callbacks$handleUpda5,
          _callbacks$handleImag4 = callbacks.handleImageElementUpload,
          handleImageElementUpload = _callbacks$handleImag4 === void 0 ? () => {} : _callbacks$handleImag4,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt;
    this.handleHide();

    if (!handleShowPartsDialogPrompt) {
      handleUpdateEditorElemementPhotos(libraryImages);
      handleImageElementUpload(libraryImages);
    } else {
      handleShowPartsDialogPrompt(() => {
        handleUpdateEditorElemementPhotos(libraryImages);
        handleImageElementUpload(libraryImages);
      });
    }
  },

  handleSolidColorClick(element) {
    const _this$props$dialog10 = this.props.dialog,
          dialog = _this$props$dialog10 === void 0 ? {} : _this$props$dialog10;
    const _dialog$data$callback11 = dialog.data.callbacks,
          callbacks = _dialog$data$callback11 === void 0 ? {} : _dialog$data$callback11;
    const handleAddElement = callbacks.handleAddElement,
          handleShowPartsDialogPrompt = callbacks.handleShowPartsDialogPrompt; // handleAddElement(element)

    this.handleHide();

    if (!handleShowPartsDialogPrompt) {
      handleAddElement(element);
    } else {
      handleShowPartsDialogPrompt(() => {
        handleAddElement(element);
      }, {
        selectAllParts: true
      });
    }
  }

};
var _default = EventHandlers;
exports.default = _default;
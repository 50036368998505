"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = [{
  id: 'artist-neutral-2',
  type: 'neutral',
  path: '/imgs/app/artist-random-placeholders/artist-neutral-2.png'
}, {
  id: 'artist-neutral-3',
  type: 'neutral',
  path: '/imgs/app/artist-random-placeholders/artist-neutral-3.png'
}, {
  id: 'artist-neutral-5',
  type: 'neutral',
  path: '/imgs/app/artist-random-placeholders/artist-neutral-5.png'
}, {
  id: 'artist-neutral-8',
  type: 'neutral',
  path: '/imgs/app/artist-random-placeholders/artist-neutral-8.png'
}];
exports.default = _default;
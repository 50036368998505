"use strict";

exports.__esModule = true;
exports.GetOptionsList = GetOptionsList;
exports.GetResourceDetails = GetResourceDetails;
exports.LikeDislikeDesign = LikeDislikeDesign;
exports.DesignFormOnMount = DesignFormOnMount;
exports.ResetDesignModifiedFlag = ResetDesignModifiedFlag;
exports.GetSharedLatestStyles = GetSharedLatestStyles;
exports.GetGlobalProductListing = GetGlobalProductListing;
exports.ShopifyProductPublishProcessing = ShopifyProductPublishProcessing;
exports.ShopifyProductPublishDoneProcessing = ShopifyProductPublishDoneProcessing;
exports.ShopifyProductPublishClearProcessing = ShopifyProductPublishClearProcessing;
exports.GenerateProductName = GenerateProductName;

var _types = require("@App/types");

function GetOptionsList(data) {
  return {
    type: `${_types.GET_OPTIONS_LIST}${_types.REQUESTED}`,
    payload: data
  };
}

function GetResourceDetails(data) {
  return {
    type: `${_types.GET_RESOURCE_DETAILS}${_types.REQUESTED}`,
    payload: data
  };
}

function LikeDislikeDesign(data) {
  return {
    type: `LIKE_DISLIKE_DESIGN${_types.REQUESTED}`,
    payload: data
  };
}

function DesignFormOnMount(data) {
  return {
    type: `DESIGN_FORM_ONMOUNT${_types.REQUESTED}`,
    payload: data
  };
}

function ResetDesignModifiedFlag() {
  return {
    type: `RESET_DESIGN_MODIFIED_FLAG${_types.REQUESTED}`
  };
}

function GetSharedLatestStyles(data) {
  return {
    type: `GET_SHARED_LATEST_STYLES${_types.REQUESTED}`,
    payload: data
  };
}

function GetGlobalProductListing(data) {
  return {
    type: `GET_GLOBAL_PRODUCT_LISTING${_types.REQUESTED}`,
    payload: data
  };
}

function ShopifyProductPublishProcessing(data) {
  return {
    type: `SHOPIFY_PRODUCT_PUBLISH_PROCESSING`,
    payload: data
  };
}

function ShopifyProductPublishDoneProcessing(data) {
  return {
    type: `SHOPIFY_PRODUCT_PUBLISH_DONE_PROCESSING`,
    payload: data
  };
}

function ShopifyProductPublishClearProcessing(data) {
  return {
    type: `SHOPIFY_PRODUCT_PUBLISH_CLEAR_PROCESSING`,
    payload: data
  };
}

function GenerateProductName(data) {
  return {
    type: `GENERATE_PRODUCT_NAME_REQUESTED`,
    payload: data
  };
}
"use strict";

exports.__esModule = true;
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const isDesktopChecker = () => window.innerWidth > 1366;

const isValidScreenChecker = () => window.innerWidth >= 1366;

var _default = props => {
  const _props$values = props.values,
        integrationValues = _props$values === void 0 ? {} : _props$values;
  const _props$page_props = props.page_props,
        page_props = _props$page_props === void 0 ? {} : _props$page_props;
  const _page_props$location = page_props.location,
        location = _page_props$location === void 0 ? {} : _page_props$location,
        _page_props$dialog = page_props.dialog,
        dialog = _page_props$dialog === void 0 ? {} : _page_props$dialog,
        _page_props$session = page_props.session,
        session = _page_props$session === void 0 ? {} : _page_props$session,
        dispatch = page_props.dispatch;
  const isDialogVisible = dialog.isVisible;
  const _location$state = location.state,
        locationState = _location$state === void 0 ? {} : _location$state;
  const _locationState$viewMo = locationState.viewMode,
        viewMode = _locationState$viewMo === void 0 ? '' : _locationState$viewMo;

  const _useState = (0, _react.useState)(true),
        isLeftPanelVisible = _useState[0],
        setLeftPanelVisible = _useState[1];

  const _useState2 = (0, _react.useState)(isDesktopChecker()),
        isDesktop = _useState2[0],
        setIsDesktop = _useState2[1];

  const _useState3 = (0, _react.useState)(isValidScreenChecker()),
        isValidScreenSize = _useState3[0],
        setIsValidScreenSize = _useState3[1];

  const _useState4 = (0, _react.useState)(viewMode),
        editorViewMode = _useState4[0],
        setEditorViewMode = _useState4[1];

  (0, _react.useEffect)(() => {
    // RESET VIEW MODE ON UNMOUNT
    return () => {
      dispatch({
        type: 'CREATE_PRODUCT_SET_VIEW_MODE',
        payload: ''
      });
    };
  }, []);
  (0, _react.useEffect)(() => {
    window.addEventListener('resize', handleUpdateMedia);
    return () => window.removeEventListener('resize', handleUpdateMedia);
  });
  (0, _react.useEffect)(() => {
    if (location.pathname.includes('customize')) {
      setEditorViewMode('editing');
      return;
    }

    if (!!integrationValues.threedData.is_customize && integrationValues.threedData.status === 'New' && integrationValues.threedData.visibility === 'Private') {
      setEditorViewMode('editing');
      return;
    }

    if (!integrationValues.threedData.is_customize && integrationValues.threedData.status === 'New' && integrationValues.threedData.visibility === 'Private') {
      setEditorViewMode('editing');
      return;
    } // if (
    //   integrationValues.threedData.status === 'Approved' &&
    //   integrationValues.threedData.artist_id === session.id &&
    //   integrationValues.threedData.visibility === 'Private'
    // ) {
    //   setEditorViewMode('editing')
    //   return
    // }


    if (integrationValues.threedData.status === 'Pending Approval' && integrationValues.threedData.artist_id === session.id && !integrationValues.threedData.is_locked) {
      setEditorViewMode('read_only');
      return;
    }

    if (integrationValues.threedData.status === 'Approved' && integrationValues.threedData.artist_id === session.id && !integrationValues.threedData.is_locked) {
      setEditorViewMode('read_only');
      return;
    }

    if (integrationValues.threedData.status === 'Approved' && integrationValues.threedData.artist_id === session.id && integrationValues.threedData.is_locked) {
      setEditorViewMode('editing');
      return;
    }

    if (integrationValues.threedData.status === 'New' && integrationValues.threedData.visibility === 'Private' && integrationValues.threedData.is_revised) {
      setEditorViewMode('editing');
    }
  }, [integrationValues.threedData.status]);

  const handleUpdateMedia = () => {
    setIsDesktop(isDesktopChecker());
    setIsValidScreenSize(isValidScreenChecker());
  };

  const handleToggleLeftPanelVisibility = () => {
    setLeftPanelVisible(!isLeftPanelVisible);
  }; // styles


  const mainPanelStyles = {
    width: isLeftPanelVisible ? `calc(100% - ${isDesktop ? '38%' : '640px'})` : 'calc(100% - 320px)',
    marginLeft: isLeftPanelVisible ? `${isDesktop ? '19%' : '320px'}` : '0'
  };
  const leftPanelStyles = {
    left: isLeftPanelVisible ? '0' : '-308px',
    width: isDesktop ? '19%' : '320px',
    maxWidth: isDesktop ? '19%' : '320px'
  };
  const rightPanelStyles = {
    width: '320px',
    maxWidth: '350px'
  };
  return {
    values: {
      isDesktop,
      isLeftPanelVisible,
      isValidScreenSize,
      editorViewMode
    },
    style_values: {
      mainPanelStyles,
      leftPanelStyles,
      rightPanelStyles
    },
    handlers: {
      handleToggleLeftPanelVisibility
    }
  };
};

exports.default = _default;
"use strict";

exports.__esModule = true;
exports.default = _default;

var _omit = _interopRequireDefault(require("lodash/omit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  const survey_items = [{
    id: '1',
    rating: '3',
    item: 'The design editor was easy to use.'
  }, {
    id: '2',
    rating: '3',
    item: `I've been able to find and use all the features that I need`
  }, {
    id: '3',
    rating: '3',
    item: 'I can upload my images quickly.'
  }];
  return {
    survey_items,
    fieldValues: {},
    errors: {}
  };
}
"use strict";

exports.__esModule = true;
exports.default = useUpload;
exports.createUploader = createUploader;

var _react = require("react");

var _pick = _interopRequireDefault(require("lodash/pick"));

var _get = _interopRequireDefault(require("lodash/get"));

var _intersectionWith = _interopRequireDefault(require("lodash/intersectionWith"));

var _fineUploaderWrappers = _interopRequireDefault(require("fine-uploader-wrappers"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

const uploaderConfigKeys = ['allowedExtensions', 'customHeaders', 'customParams', 'uploadEndpoint', 'deleteEndpoint', 'autoUpload', 'uploaderParams'];

function useUpload(props) {
  const allowedExtensions = props.allowedExtensions,
        _props$autoUpload = props.autoUpload,
        autoUpload = _props$autoUpload === void 0 ? true : _props$autoUpload;
  const _props$onSubmitFile = props.onSubmitFile,
        onSubmitProp = _props$onSubmitFile === void 0 ? () => {} : _props$onSubmitFile,
        _props$onFileUploaded = props.onFileUploaded,
        onFileUploadProp = _props$onFileUploaded === void 0 ? () => {} : _props$onFileUploaded,
        _props$onAllFilesUplo = props.onAllFilesUploaded,
        onAllFilesUploadedProp = _props$onAllFilesUplo === void 0 ? () => {} : _props$onAllFilesUplo,
        _props$onError = props.onError,
        onErrorProp = _props$onError === void 0 ? () => {} : _props$onError,
        _props$onStatusChange = props.onStatusChange,
        onStatusChange = _props$onStatusChange === void 0 ? () => {} : _props$onStatusChange,
        _props$responseValida = props.responseValidator,
        responseValidator = _props$responseValida === void 0 ? (res = {}) => res.success && Object.keys(res).length > 1 : _props$responseValida;

  const _useState = (0, _react.useState)([]),
        submittedFiles = _useState[0],
        setSubmittedFiles = _useState[1];

  const uploader = (0, _react.useMemo)(initUploader, []);
  const isConcurrent = (0, _get.default)(uploader, 'options.chunking.concurrent');
  let pendingResponses = {};
  (0, _react.useEffect)(() => {
    uploader.on('error', onError);
    return () => {
      uploader.on('error', onError);
    };
  }, []);
  (0, _react.useEffect)(() => {
    uploader.on('submitted', onSubmitFile);
    uploader.on('complete', handleFileUploadProp);
    uploader.on('allComplete', handleAllFilesUploaded);
    uploader.on('cancel', onCancel);
    uploader.on('statusChange', onStatusChange);
    uploader.on('uploadChunkSuccess', handleUploadChunkSuccess);
    return () => {
      uploader.off('submitted', onSubmitFile);
      uploader.off('complete', handleFileUploadProp);
      uploader.off('allComplete', handleAllFilesUploaded);
      uploader.off('cancel', onCancel);
      uploader.off('statusChange', onStatusChange);
      uploader.off('uploadChunkSuccess', handleUploadChunkSuccess);
    };
  }, [onSubmitProp, onFileUploadProp, onAllFilesUploadedProp, onStatusChange]);

  function initUploader() {
    return createUploader((0, _pick.default)(props, uploaderConfigKeys));
  }

  function onSubmitFile(id) {
    setSubmittedFiles(prev => [...prev, id]);
    onSubmitProp(id, uploader);
  }

  function handleUploadChunkSuccess(id, chunkData, response = {}) {
    if (isConcurrent && responseValidator(response)) {
      pendingResponses[id] = response;
    }
  }

  function onCancel(id) {
    setSubmittedFiles(prev => prev.filter(e => e !== id).filter(Boolean));
    if (autoUpload) uploader.methods.deleteFile(id);
  }

  function handleReset() {
    setSubmittedFiles([]);
    pendingResponses = {};
    uploader.methods.reset();
  }

  function handleCancelAll() {
    uploader.methods.cancelAll();
  }

  function handleRemoveFileRef(id) {
    uploader.methods.removeFileRef(id);
  }

  function handleFileUploadProp(...responses) {
    const id = responses[0],
          name = responses[1],
          response = responses[2];

    if (isConcurrent && !responseValidator(response)) {
      onFileUploadProp(id, name, pendingResponses[id], handleRemoveFileRef);
      delete pendingResponses[id];
    } else {
      onFileUploadProp(...responses, handleRemoveFileRef);
    }
  }

  function handleAllFilesUploaded() {
    if (onAllFilesUploadedProp(submittedFiles, uploader)) {
      handleReset();
    }
  }

  function onError(id, name, errorReason, xhrOrXdr) {
    setSubmittedFiles(prev => prev.filter(e => e !== id));
    onErrorProp({
      id,
      name,
      errorReason,
      xhrOrXdr,
      uploader
    });
  }

  function analyzeFiles(_x) {
    return _analyzeFiles.apply(this, arguments);
  }

  function _analyzeFiles() {
    _analyzeFiles = _asyncToGenerator(function* (files) {
      const cleanFiles = [];
      let loaded = 0;
      return new Promise(resolve => {
        files.forEach(file => {
          if (!file.type.includes('svg')) {
            loaded += 1;
            cleanFiles.push(file);

            if (loaded === files.length) {
              resolve(cleanFiles);
            }

            return;
          }

          const reader = new FileReader();

          reader.onload = e => {
            const svgData = e.target.result;
            const parser = new DOMParser();
            const svgXml = parser.parseFromString(svgData, 'text/xml');
            const svgs = svgXml.querySelectorAll('svg');
            loaded += 1;

            if (svgXml.getElementsByTagName('parsererror').length > 0) {
              // setFailedUploads(prevState => ([...prevState, files]))
              if (props.dispatch && props.ShowError) {
                props.dispatch(props.ShowError({
                  message: 'Invalid SVG format, recheck the uploaded file.'
                }));
              }

              if (loaded === files.length) {
                resolve(cleanFiles);
              }

              return;
            } // Step 3: Check for embedded base64 data URIs


            const base64Pattern = /data:image\/[a-zA-Z]*;base64,/;

            if (base64Pattern.test(svgData)) {
              // setFailedUploads(prevState => ([...prevState, files]))
              if (props.dispatch && props.ShowError) {
                props.dispatch(props.ShowError({
                  message: 'Invalid SVG format, recheck the uploaded file.'
                }));
              }

              if (loaded === files.length) {
                resolve(cleanFiles);
              }

              return;
            }

            if (svgs.length > 1) {
              // setFailedUploads(prevState => ([...prevState, files]))
              if (props.dispatch && props.ShowError) {
                props.dispatch(props.ShowError({
                  message: 'Invalid SVG format, recheck the uploaded file.'
                }));
              } // 'Invalid SVG format, recheck the uploaded file.'


              if (loaded === files.length) {
                resolve(cleanFiles);
              }

              return;
            }

            cleanFiles.push(file);

            if (loaded === files.length) {
              resolve(cleanFiles);
            }
          };

          reader.readAsText(file);
        });
      });
    });
    return _analyzeFiles.apply(this, arguments);
  }

  function onDrop(_x2) {
    return _onDrop.apply(this, arguments);
  }

  function _onDrop() {
    _onDrop = _asyncToGenerator(function* (accepted) {
      const onDropFiles = props.onDropFiles;
      const cleanFiles = yield analyzeFiles(accepted);
      const exists = (0, _intersectionWith.default)(cleanFiles.map(e => e.name), submittedFiles, (a, b) => a === uploader.methods.getName(b));
      const rejected = cleanFiles.filter(e => !allowedExtensions.includes(uploader.qq.getExtension(e.name).toLowerCase()));

      if (onDropFiles) {
        const filteredAccepted = onDropFiles({
          cleanFiles,
          exists,
          rejected
        });

        if (filteredAccepted && filteredAccepted.length) {
          uploader.methods.addFiles(filteredAccepted);
        }
      } else {
        uploader.methods.addFiles(cleanFiles);
      }
    });
    return _onDrop.apply(this, arguments);
  }

  const state = {
    submittedFiles,
    uploader,
    allowedExtensions
  };
  const handlers = {
    onDrop,
    handleReset,
    triggerUpload: () => uploader.methods.uploadStoredFiles(),
    handleCancelAll
  };
  return [state, handlers];
}

function createUploader(options) {
  const allowedExtensions = options.allowedExtensions,
        customHeaders = options.customHeaders,
        customParams = options.customParams,
        uploadEndpoint = options.uploadEndpoint,
        deleteEndpoint = options.deleteEndpoint,
        _options$autoUpload = options.autoUpload,
        autoUpload = _options$autoUpload === void 0 ? true : _options$autoUpload,
        uploader = options.uploader,
        uploaderParams = options.uploaderParams;
  const coreParams = {
    options: {
      autoUpload,
      debug: process.env.NODE_ENV !== 'production',
      request: {
        endpoint: uploadEndpoint,
        method: 'POST',
        filenameParam: 'filename',
        uuidName: 'uuid',
        totalFileSizeName: 'total_file_size',
        inputName: 'file',
        params: customParams,
        customHeaders
      },
      cors: {
        expected: true,
        sendCredentials: true
      },
      chunking: {
        enabled: true,
        paramNames: {
          chunkSize: 'chunk_size',
          partIndex: 'part_index',
          totalParts: 'total_parts',
          resuming: 'resume'
        }
      },
      resume: {
        enabled: true
      },
      deleteFile: {
        enabled: true,
        endpoint: deleteEndpoint,
        customHeaders
      },
      retry: {
        enableAuto: false,
        maxAutoAttempts: 1,
        preventRetryResponseProperty: 'prevent_retry'
      },
      validation: {
        allowedExtensions
      },
      maxConnections: 5
    }
  };
  return uploader || new _fineUploaderWrappers.default(typeof uploaderParams === 'function' ? uploaderParams(coreParams) : coreParams);
}